import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { message } from 'ant-design-vue';
import { Axios_config, HttpResult } from './types';
import { handleError, handleResult, showStatus } from './handleError';
import { Modal } from 'ant-design-vue';

import { confirm, notify } from '../components/confirm';
import useStore from '@/store/metaData';

// var config_g = require("../../public/json/config");
var CONFIG = (window as any).CONFIG;

var config = {
  // 联调
  // baseURL: process.env.NODE_ENV === 'production' ? `/` : '/api',
  // baseURL: config_g.baseURL,
  // headers: {
  //   // get: {
  //   //   'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
  //   // },
  //   // post: {
  //   //   'Content-Type': 'application/json;charset=utf-8'
  //   // }
  //   get: "'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'",
  //   post: "'Content-Type': 'application/json;charset=utf-8'"
  // },
  // 是否跨站点访问控制请求
  // withCredentials: true,
  // timeout: 30000,
  // transformRequest: [(data: any) => {
  //   data = JSON.stringify(data)
  //   return data
  // }],
  // validateStatus() {
  //   // 使用async-await，处理reject情况较为繁琐，所以全部返回resolve，在业务代码中处理异常
  //   return true
  // },
  // transformResponse: [(data: any) => {
  //   if (typeof data === 'string' && data.startsWith('{')) {
  //     data = JSON.parse(data)
  //   }
  //   return data
  // }]
  baseURL: CONFIG.baseUrl,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },

}
const loadingStart = () => {
  const store = useStore();
  store.screenLoading = true;
}
const loadingEnd = () => {
  const store = useStore();
  store.screenLoading = false;
}
const service = axios.create(config)
service.defaults.headers.post['Content-Type'] = 'application/json';
//封装下post
// @ts-ignore
service.post = function (url, params, isLoading: boolean = false) {
  isLoading && loadingStart();
  return new Promise((resolve, reject) => {
    // console.dir("****************************");
    axios({
      method: 'post',
      url: handleUrl(url),
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    }).then(response => {
      if (response.status == 200) {
        //根据实际情况进行更改
        handleResult(response, isLoading, () => reject(response), (result: any) => resolve(result));
        // resolve(r.result)
      } else {
        console.dir(response);
        loadingEnd();
        reject(response)
      }
    })/* .catch(function(err) {
      console.dir(err);
      console.dir(err.response);
      throw new Error(err)
    }) */
  })

}
//封装下get
// @ts-ignore
service.get = function (url, params, isLoading: boolean = false) {
  isLoading && loadingStart();
  return new Promise((resolve, reject) => {
    // console.dir("****************************");
    let c = {
      method: 'get',
      url: handleUrl(url),
      params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        // "Access-Control-Allow-Origin": "*",
      },
    };
    if (typeof params === 'object' && params && 'responseType' in params) {
      if (params.responseType == 'blob') {
        // @ts-ignore
        c = { ...c, responseType: 'blob' };
      }
    }
    // @ts-ignore
    axios(c).then(response => {
      if (response.status == 200) {

        //根据实际情况进行更改
        let err = handleResult(response, isLoading, (response: any) => reject(response), (result: any) => resolve(result));
        // resolve(r.result)
        err && reject(response)
      } else {
        loadingEnd();
        console.dir(response);
        reject(response)
      }
    })
  })

}
//封装下post
// @ts-ignore
service.put = function (url, params, isLoading: boolean = false) {

  isLoading && loadingStart();
  return new Promise((resolve, reject) => {
    // console.dir("****************************");
    axios({
      method: 'put',
      url: handleUrl(url),
      data: params,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    }).then(response => {
      if (response.status == 200) {
        //根据实际情况进行更改
        handleResult(response, isLoading, () => reject(response), (result: any) => resolve(result));
        // resolve(r.result)
      } else {
        console.dir(response);
        reject(response)
      }
    }).catch(function (err) {
      // console.dir(err);
      loadingEnd();
      // console.dir(err.response);
      reject(err)
      throw new Error(err)
    })
  })

}
//封装下get
// @ts-ignore
service.delete = function (url, params, isLoading: boolean = false, isBody) {
  isLoading && loadingStart();
  return new Promise((resolve, reject) => {
    // console.dir("****************************");
    let obj: any = {
      method: 'delete',
      url: handleUrl(url),
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    }
    isBody ? (obj.data = params) : (obj.params = params);
    axios(obj).then(response => {
      if (response.status == 200) {

        //根据实际情况进行更改
        handleResult(response, isLoading, () => reject(response), (result: any) => resolve(result));
      } else {
        loadingEnd();
        console.dir(response);
        reject(response)
      }
    })
  })

}
//封装下blob
// @ts-ignore
const blob = function (url, params, isLoading: boolean = false) {
  isLoading && loadingStart();
  return new Promise((resolve, reject) => {
    // console.dir("****************************");
    axios({
      method: 'get',
      url: handleUrl(url),
      params,
      responseType: 'blob',
      // headers: {
      //   'Content-Type': 'application/json; charset=utf-8',
      // },
    }).then(response => {
      if (response.status == 200) {

        //根据实际情况进行更改
        let err = handleResult(response, isLoading, (response: any) => reject(response), (result: any) => resolve(result));
        // resolve(r.result)
        err && reject(response)

      } else {
        loadingEnd();
        console.dir(response);
        reject(response)
      }
    })
  })

}
// 请求拦截器
axios.interceptors.request.use((config: AxiosRequestConfig) => {
  //获取token，并将其添加至请求头中
  const store = useStore();
  // @ts-ignore
  let token = store.token;
  // @ts-ignore
  let bearer = store.bearer;
  if (token && bearer) {
    (config as Axios_config).headers.Authorization = `${bearer} ${token}`;
    (config as Axios_config).headers.LoginName = store.userName;
  }
  return config
}, (error) => {
  // 错误抛到业务代码
  error.data = {}
  error.data.msg = '服务器异常，请联系管理员！'
  return Promise.resolve(error)
})

// 响应拦截器
axios.interceptors.response.use((response: AxiosResponse) => {
  const status = response.status
  let msg = ''
  // console.dir(response);
  // if (status < 200 || status >= 300) {
  //   // 处理http错误，抛到业务代码
  //   msg = showStatus(status);

  //   if (typeof response.data === 'string') {
  //     response.data = { msg }
  //   } else {
  //     response.data.msg = msg
  //   }
  // }
  loadingEnd();
  if (response.data.code && response.data.code != 200) {
    // @ts-ignore
    let msg = handleError({ response }) || showStatus(response);
    // message.config({ top: '25vh' });
    // message.error(msg);
    error_(msg)
    return Promise.reject(response.data)
  }
  return Promise.resolve(response)
},
  (error) => {
    if (axios.isCancel(error)) {
      console.log('repeated request: ' + error.message)
    } else {
      // handle error code
      // 错误抛到业务代码
      let msg = handleError(error) || showStatus(error);
      // error.data = {}
      // error.data.msg = '请求超时或服务器异常，请检查网络或联系管理员！'

      // message.config({ top: '25vh' });
      // message.error(msg);
      error_(msg)
    }
    loadingEnd();

    return Promise.reject(error)
  })
const handleUrl = (url: string): string => {
  let hasBaseUrl = new RegExp(config.baseURL, 'i').test(url);
  if (hasBaseUrl) {
    return `${url}`
  }
  let s = `api/${url}`.replace(`//`, '/')

  return `${config.baseURL}${s}`;
}

const error_ = (msg: any) => {
  Modal.error({
    title: 'Error',
    content: msg
  })
}

/** 
 * 继承 Axios
 * 把antdv 的 confirm notify 加 到 http上去 
 * */
export default class Http {
  static notify(arg0: string) {
    throw new Error("Method not implemented.");
  }
  confirm = confirm;
  notify = notify;
  // error = message.error
  error = error_
  get = service.get;
  post = service.post;
  put = service.put;
  delete = service.delete;
  blob = blob;
};

export const http = new Http;
