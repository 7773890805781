import { getFileName } from "@/utile";
var CONFIG = (window as any).CONFIG;

import http from '@/http';
class DownloadService extends http {

    constructor() { super(); }
    /**
     * 下载文件
     * @param file Blob 文件流
     * @param fileName 文件名
     */
    downloadFileByBlob(file: Blob, fileName: string) {
        // @ts-ignore
        if (window.navigator.msSaveBlob) {
            // deal with IE 11, data是第一步toBlob的结果值
            // @ts-ignore
            window.navigator.msSaveOrOpenBlob(file, fileName);
        } else {
            const blob = new Blob([file]);
            const url = window.URL.createObjectURL(blob);
            // 打开新窗口方式进行下载
            // window.open(url); 

            // 以动态创建a标签进行下载
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
        }
    }
    downloadFileByUrl(url: string) {
        url = `${CONFIG.baseUrl}${url}`;
        let fileName = getFileName(url);
        this.fileLinkToStreamDownload(url, fileName);
    } 
    fileLinkToStreamDownload(url: string, fileName: string) {
        // @ts-ignore
        this.get(url, { responseType: 'blob' }).then((blob: Blob) => { // 获取文件流，成功
            this.downloadFileByBlob(blob, fileName)
        }, () => { // 失败
            window.open(url);
        })
    }

    upload(files: any[]) {
        let form = new FormData();
        for (let i = 0; i < files.length; i++) {
            form.append(`file${i}`, files[i].originFileObj || files[i]);
        }
        return this.post('upload/UploadFileAsync', form);
    }
    /**
     * SourceFrom 1: 虚拟目录. 2: 临时文件夹 .3: wwwroot
     */
    download(params: { SourceFrom: number, Path: string; FileName: string }) {
        // @ts-ignore
        return this.blob('download/DownloadByPath', params);
    }

}

export default new DownloadService;