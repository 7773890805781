import { defineComponent, reactive, ref } from "vue";
import { patchValue } from "../../form";
import { TabProps } from "../types";

export default defineComponent({
    emits: ['ok', 'refresh', 'opens',],
    props: {
        // fields: { type: Array, default: () => [] },
        options: {
            // type: () => new TabProps,
            type: Object,
            default: () => new TabProps
        },
        btnText: { type: String, default: () => '' },
        okBtnText: { type: String, default: () => 'Ok' },
        // resetText: { type: String, default: () => 'Reset' },
        closeBtnText: { type: String, default: () => 'Close' },
        showCloseBtn: { type: Boolean, default: () => false },
        // disabled: { type: Boolean, default: () => false },
        // labelWidth: { type: String, default: () => '100px' },
        btnAlign: { type: String, default: 'center' },
        _this: {
            type: Object,
            default: {}
        }
    },
    // props: ['options', 'id', 'canAction', '_model'],

    setup(p, { emit }) {
        const data = reactive({
            visible: false
        });
        const model = reactive({});
        const formListGroupRef = ref();
        const open = (ID: string) => {
            emit('opens', model)
            data.visible = true;
            setTimeout(() => {
                patchValue(model, { ID })
                patchValue(formListGroupRef.value.model, { ID })
            })
        };
        const close = () => {
            emit('refresh', model)
            data.visible = false;
        };
        const handleOk = () => {
            emit('ok', model)
            data.visible = false;
        };
        // const _search = () => {
        //     formRef.value.submit();
        // };
        // const _finish = () => {
        //     emit('ok', model);
        //     handleOk();
        // };
        // const _reset = () => {
        //     formRef.value.reset();
        // };
        return {
            model,
            data,
            formListGroupRef,
            // formRef,
            open,
            close,
            handleOk,
            // _search,
            // _finish,
            // _reset,
        }
    },
    render() {
        const slots = {
            footer: () => (
                // @ts-ignore
                <div style={{ 'text-align': this.btnAlign }}>
                    {/* <a-button size="small" type="primary" onClick={this._search}>{this.okBtnText}</a-button>
                    <a-button size="small" onClick={this._reset}>{this.resetText}</a-button> */}
                    {
                        this.showCloseBtn && (
                            <a-button size="small" onClick={this.close}>{this.closeBtnText}</a-button>
                        )
                    }
                </div>
            )
        }
        return (
            <>
                <a-modal
                    width={this.options.width}
                    visible={this.data.visible}
                    onChange={this.close}
                    title={this.options.tab}
                    onOk={this.handleOk}
                    onOpen={this.open}
                    closable={false}
                    v-slots={slots}
                    wrapClassName={this.options.class}
                >
                    <form-list-group ref='formListGroupRef' options={this.options} componentParent={this._this}></form-list-group>

                </a-modal>
            </>
        );
    }
})