import { computed, defineComponent, inject, reactive, ref, watch } from "vue";

import Card from "@/components/form-list-group/components/card";
import AgGrid from "@/components/ag-table";
import '../index.scss';
import useStore from '@/store/metaData'
import PhotoEditer from '../../photo-editer'

import edit_cellRendererComponent from '@/components/ag-table/edit_cell';
import actionCellRenderer from "../../ag-table/actionCellRenderer";
import { ID_Description } from "@/types";
import Modal from "../components/modal";

import Collapse from "@/components/form-list-group/components/Collapse";
import DownloadService from '@/http/download';
import { patchValue } from "../../form";
import { SetSelectOptions } from "@/utile/form";
import { getTemp1, getTemp2, crud, dragger } from "./getTempPart2";
import { GridApi } from "ag-grid-community";
import { GridEvent } from "@/components/ag-table/types/grid";
import './index.scss'
import { Part2 } from "./usePart";
export default defineComponent({
    components: { Collapse, Card, AgGrid, edit_cellRenderer: edit_cellRendererComponent, actionCellRenderer, Modal, PhotoEditer },
    props: ['options', 'id'],
    emits: ['GetPart3data', 'openPart3'],
    setup(prop, { emit }) {
        let tableRef1 = ref();
        let tableRef2 = ref();
        const photoRef = ref()

        let rowData1: any[] = [];
        let rowData2: any[] = [];

        let selectConfing: any = {};
        let selectOptions: any[] = [];

        let api1: GridApi = new GridApi;
        let api2: GridApi = new GridApi;
        let data = reactive({
            selectConfing,
            selectOptions,
            rowData1, rowData2,
            cannew: true,
            Pstatus: false,
            height1: '',
            height2: '',
            initLocation: '',

            api1,
            api2,
        });
        const setHeight = (arr: any[], height1: number = 50) => {
            let h = 110;
            let l = arr.length;
            let height = h + l * height1;
            if (height > 400) height = 400
            sizeColumnsToFit1()
            sizeColumnsToFit2()
            return height + 'px';
        }
        const length1 = computed(() => data.rowData1)
        const length2 = computed(() => data.rowData2)
        watch([length1, length2], () => {
            data.height1 = setHeight(length1.value)
            data.height2 = setHeight(length2.value, 90)
        })
        const setDisabled = (arr: any) => {
            let e = arr;

            if (e?.ReportStatus == "Completed") {
                data.Pstatus = true
            } else {
                if (e?.['ReportStatus'] == "Completed") {
                    data.Pstatus = true
                } else {
                    if (store.userName?.toUpperCase() == e?.['CreateBy']?.toUpperCase() || store.userName?.toUpperCase() == 'admin'?.toUpperCase()) {
                        data.Pstatus = false
                    } else {
                        data.Pstatus = true
                    }
                }
            }
        }
        /**在userlist筛选会用到 */
        const select2 = ref()
        /**form */
        let modelS = reactive<any>({});
        const formrefS = ref()
        // part3需要以partc为准，存入store
        // watch(data, () => {
        //     if (data.rowData1) {
        //         store.partc_length = data.rowData1.length
        //     }
        // })
        const SetCheckboxSelect = (arr: any) => {
            const { checkbox_list, select_list, TC_list, APBS_list } = prop.options

            // 获取checkbox选中的项
            /**part a */
            let list_true: any[] = [];
            checkbox_list.map((a: any) => {
                arr[a] && list_true.push(a)
            })
            /** PartIIAppointedTC_list*/
            let PartIIAppointedTC_list_true: any[] = [];
            TC_list.map((a: any) => {
                arr[a] && PartIIAppointedTC_list_true.push(a)
            })
            /** PartIIAppointedAPBS_list*/
            let PartIIAppointedAPBS_list_true: any[] = [];
            APBS_list.map((a: any) => {
                arr[a] && PartIIAppointedAPBS_list_true.push(a)
            })
            patchValue(modelS, {
                CheckboxList: list_true,
                PartIIAOthersValue: arr.PartIIAOthersValue,

                PartIIBValue: arr.PartIIBValue,
                PartIIBOthersValue: arr.PartIIBOthersValue,
                PartIIAppointedTC_List: PartIIAppointedTC_list_true,
                PartIIAppointedAPBS_List: PartIIAppointedAPBS_list_true,

                // 传给userlist
                checkbox_list, select_list, TC_list, APBS_list
            })
        }
        const setModalDefault = (z: any) => {
            data.initLocation = z.Location
        }
        const setRowData = (arr: any, force: boolean = false) => {
            setDisabled(arr)


            data.rowData1 = arr[prop.options.rowDatakeyC].Data;
            Object.assign(data.selectOptions, prop.options.PartIIBSelectOptions);
            data.rowData2 = arr[prop.options.rowDatakeyD].Data;
            sizeColumnsToFit2_2()

            data.cannew = force
            SetCheckboxSelect(arr)
            // setTimeout(() => {
            //     tableRef1.value?.grid?.api?.sizeColumnsToFit()
            //     tableRef2.value?.grid?.api?.sizeColumnsToFit()
            // }, 500);
        }
        const getInitSelectData = (o: any) => {
            prop.options.formSelectConfing?.map((x: any) => {
                if (x.type = 'ID_Description') {
                    data.selectOptions[x.key] = o[x.mapKey].map((y: ID_Description) => ({ ...y, label: y.Description, value: y.Description || y.ID }));
                    if (x.key == 'PartIIBValue') {
                        select2.value = data.selectOptions[x.key]
                    }
                }
                else if (x.type = 'ID_Description_ID') {
                    data.selectOptions[x.key] = o[x.mapKey].map((y: ID_Description) => ({ ...y, label: y.Description, value: y.ID }));
                }
                SetSelectOptions(prop.options.formoption.fields || [], x.key, () => data.selectOptions[x.key]);

                SetSelectOptions(prop.options.modal2.fields || [], x.key, () => data.selectOptions[x.key]);
            })
        }


        // 两个modal
        const modelV = reactive({
            visibleC: false,
            visibleD: false
        });
        // modal1
        let modelC = reactive<any>({});
        const formrefC = ref()

        // modal2
        let modelD = reactive<any>({});
        const formrefD = ref()

        // 上传
        let fileList: any[] = [];
        const bb = reactive({
            fileList
        })
        const file_path = ref()
        const photo = reactive({
            isshow: false,
            path: '',
            isDisable: false
        })

        // 表格crud
        const {
            cellAction,
            FinishC, handleOkC, closeC, openC,
            FinishD, handleOkD, closeD, openD,
            partc_m, partd_m, savePhoto
        } = crud(prop, data, tableRef1, tableRef2, emit,
            { modelV, photo, modelD, prop, modelC, formrefC, formrefD, photoRef });

        const store = useStore()
        const photo_m = reactive([])
        const { handleChange, delphoto, beforeUpload, handleDrop } = dragger({ photo, prop, DownloadService, modelD })


        /**清空表格数据并且把new状态改成false */
        const empty_rowdata = () => {
            data.rowData1 = []
            data.rowData2 = []
            data.cannew = true
        }

        const GridParam = (param: any, type: 'rowspan' | 'cellstyle') => {
            const arr = ["ItemNo"];
            if (arr?.includes(param.column.colId)) {

                const first = data.rowData2.findIndex(item => {
                    return arr.every(v => item[v] === param.data[v]);
                });
                if (param.node.childIndex === first) {
                    if (type == 'rowspan') {
                        // 同一条出现的条数
                        const rowspan = data.rowData2.filter(item => {
                            if (arr.length) return arr.every(v => item[v] === param.data[v]);
                        });
                        return rowspan.length * 1
                    } else if (type == 'cellstyle') {
                        return { background: '#efefef' }
                    }
                } else {
                    if (type == 'rowspan') {
                        return 1
                    } else if (type == 'cellstyle') {
                        return {}
                    }
                }
            }
        }
        let c_r = { cellRenderer: 'edit_cellRenderer' };
        let img = { cellRendererParams: { type: 'img', width: '100px', height: '54px' } };
        let partdD = reactive([
            {
                field: 'ItemNo', headerName: '#', maxWidth: 100, minWidth: 70,
                rowSpan: (param: any) => {
                    return GridParam(param, 'rowspan');
                },
                cellStyle: (param: any) => {
                    return GridParam(param, 'cellstyle')
                }
            },
            { field: 'LocatioInspection', headerName: 'Location of Inspection', maxWidth: 300, minWidth: 200, ...c_r, },
            {
                field: 'DeficienciesIdentified', headerName: 'Safe work seen during the inspection', ...c_r, minWidth: 200,
                cellRendererParams: {
                    type: 'select',
                    PopupContaine: (triggerNode: { parentNode: any; }) => triggerNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode
                }
            },
            {
                field: 'Remarks', headerName: 'Remarks', ...c_r, maxWidth: 300, minWidth: 200,
                cellRendererParams: {
                    type: 'select',
                    PopupContaine: (triggerNode: { parentNode: any; }) => triggerNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode
                }
            },
            { field: 'UploadFilePath', headerName: '', autoHeight: true, hide: true, minWidth: 70, },
            { field: 'FilePath', headerName: 'Photo', autoHeight: true, maxWidth: 150, ...img, ...c_r, minWidth: 120, },
            {
                field: '', headerName: 'Action', maxWidth: 200, minWidth: 200,
                sortable: false, cellRenderer: 'actionCellRenderer',
                cellRendererParams: {
                    btns: [
                        { icon: 'icon_tianjia', title: 'Add', action: 'Add', fontSize: '25px', Bgcolor: '#708570' },
                        { icon: 'save1', title: 'Save', action: 'Save', fontSize: '25px', Bgcolor: '#fed42f', Color: '#000' },
                        { icon: 'edit', title: 'Edit', action: 'Rotate', fontSize: '25px', Bgcolor: '#7cc46a' },
                        { icon: 'shanchu1', title: 'Delete', action: 'Delete', fontSize: '25px', Bgcolor: '#fe2f2f' },
                        { icon: '1', title: 'Upload', action: 'Upload', fontSize: '25px', Bgcolor: '#6a8bc4' },
                    ],
                    disabled: false
                }
            },
        ])
        const onGridReady1 = (params: GridEvent) => {
            data.api1 = params.api;
        }
        const onGridReady2 = (params: GridEvent) => {
            data.api2 = params.api;
        }
        const sizeColumnsToFit1 = () => {
            if (!data.rowData1.length) return;
            setTimeout(() => {
                try {
                    data.api1?.sizeColumnsToFit();
                } catch (error) {
                }
            }, 200);
        }
        const sizeColumnsToFit2 = () => {
            if (!data.rowData2.length) return;

            setTimeout(() => {
                try {
                    data.api2?.sizeColumnsToFit();
                } catch (error) {
                }
            }, 200);
        }
        const sizeColumnsToFit2_2 = () => {
            if (!data.rowData2.length) return;

            setTimeout(() => {
                try {
                    data.api2?.sizeColumnsToFit();
                } catch (error) {
                }
            }, 1100);
        }

        const part2 = Part2(emit)

        return {
            tableRef1, tableRef2,
            data, select2,
            setRowData, cellAction,
            getInitSelectData, SetCheckboxSelect,
            modelS, formrefS, empty_rowdata,


            modelV,
            modelC, formrefC, FinishC, handleOkC, closeC, openC,
            modelD, formrefD, FinishD, handleOkD, closeD, openD,

            bb,
            handleChange, handleDrop, beforeUpload,
            setModalDefault,

            delphoto, photo,

            partdD,

            onGridReady1, onGridReady2,
            part2,

            partc_m, partd_m,

            photoRef, savePhoto
        }
    },
    render() {
        const isMobile: any = inject('isMobile')
        const box = () => <>
            <div>
                <Collapse
                    v-slots={{
                        header: () => {
                            return <b style={{ 'color': '#000', 'user-select': 'none' }}>{this.options.text}</b>
                        }
                    }}
                >
                    <b-form
                        fields={this.options.formoption?.fields}
                        model={this.modelS}
                        ref='formrefS'
                        layout="vertical"
                        // labelCol={{ style: { width: "100px" } }}
                        // labelWidth="auto"
                        size="large"
                        class="block"
                        style="flex-grow: 1"
                        disabled={this.data.cannew}
                    >
                    </b-form>
                    {getTemp1.apply(this)}
                    {getTemp2.apply(this)}
                </Collapse>
            </div>
        </>
        const M = () => <>
            <a-drawer
                ref={this.part2.drawRef}
                // title={c.title}
                maskStyle={{ backgroundColor: `transparent !important` }}
                class={'m-drawer-part2'}
                placement={'bottom'}
                closable={false}
                visible={this.part2.draw.visible}
                onClose={this.part2.onClose}
                maskClosable={true}
                push={true}
                height={`calc(100vh - 40px)`}
            >
                {box()}
                <div class=' next'>
                    <a-button type="primary" onClick={this.part2.openPart3} >
                        下一步
                    </a-button>
                </div>
                <div class={'previous'}>
                    <a-button type="primary" onClick={this.part2.onClose} >
                        上一步
                    </a-button>
                </div>
            </a-drawer>
        </>
        return (
            <>
                {isMobile.value ? M() : box()}
            </>
        )
    }
})

