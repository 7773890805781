import verifyPhoto from '@/pipe/verifyPhoto'
import { isArray } from '@vue/shared'
import { defineComponent, reactive, ref } from 'vue'
import style from './documents.module.scss'
import { inject } from 'vue';
import DownloadService from '@/http/download';

interface p {
    rowData: {
        PhotoNo: string,
        UploadFilePath: string,
        FilePath: string,
        Description: string
    }[],
    _this: any
}
export const M_Grid = defineComponent({
    props: ['rowData', '_this'],
    setup(p: p) {
        let click: any = {}
        const data = reactive({
            files: null,
            click
        })
        let inputRef = ref<HTMLInputElement>();

        const selectFiles = (e: Event | Array<any>) => {
            let files: any;
            if (isArray(e)) {
                files = e;
            } else {
                files = (e.target as HTMLInputElement).files || [];
            }
            if (!files.length) return;
            // @ts-ignore
            DownloadService.upload(files).then((x: { FileList: { MD5: string, Path: string }[]; TempPath: string; }) => {
                if (x.FileList.length) {
                    data.click.UploadFilePath = x.FileList[0].Path
                    p._this.iconfn.save_m(data.click, true)
                }
                data.files = null;
            })
        }
        const browser = (x: any) => {
            data.click = x
            inputRef && inputRef.value?.click();
        };
        const isMobile: any = inject('isMobile')
        return () => {
            let arr = p.rowData?.sort((a, b) => a.PhotoNo > b.PhotoNo ? -1 : 1)

            return <>
                <div class={style['host-m']}>
                    {
                        arr?.map(x => {
                            return <div class={style.card} >
                                <div class={style['div-img']} >
                                    <img v-viewer src={verifyPhoto(x.FilePath)} />
                                </div>
                                <div class={{ [style['icons']]: true, 'pointer-events': false }}>
                                    {icon('Upload', () => browser(x), p._this)}
                                    {icon('Download', () => p._this.iconfn.download_m(x), {})}
                                    {icon('Edit', () => p._this.iconfn.rotate_m(x), {})}
                                    {icon('Save', () => p._this.iconfn.save_m(x), p._this)}
                                    {icon('Delete', () => p._this.iconfn.del_m(x), p._this)}
                                </div>
                                <div>
                                    <p>備注</p>
                                    <a-input
                                        value={x['Description']}
                                        onChange={(e: any) => {
                                            x['Description'] = e.target.value || '';
                                        }}
                                        size='large'
                                    ></a-input>
                                </div>
                            </div>
                        })
                    }
                    <input type="file" hidden style="display: none;" multiple ref={inputRef} value={data.files} onChange={selectFiles} accept="image/*" />
                    {
                        !arr?.length && <div class={style['nophoto-show']}>
                            No Rows To Show
                        </div>
                    }
                </div>
                <div class={style['back']}>
                    <a-button type="primary" class={style['btn']}
                        onClick={p._this.closeModal}
                    >
                        返回
                    </a-button>
                </div>
            </>
        }
    }
})
const icon = (type: string, fn: Function, _this: any) => {
    let m: any = {
        Upload: { icon: '#icon-1', bg: '#6a8bc4', color: '#fff' },
        Delete: { icon: '#icon-x', bg: '#fe2f2f', color: '#fff' },
        Download: { icon: '#icon-xiazai1', bg: '#6ac4c4', color: '#fff' },
        Edit: { icon: '#icon-edit', bg: '#7cc46a', color: '#fff' },
        Save: { icon: '#icon-save1', bg: '#fed42f', color: '#000' },
    }
    return <span
        style={`font-size: 25px; background: ${m[type].bg}; color: ${m[type].color}; 
        border-radius: 5px; padding: 5px 12px; margin: 0px 15px 0px 0px;`}
        class={{ 'cursor-not-allowed opacity-0-4-10': _this?.data?.Pstatus }}
    >
        <svg
            onClick={() => fn()}
            class={{ 'icon': true, 'point': true, 'inline-block': true, 'pointer-events': _this?.data?.Pstatus }} aria-hidden="true" >
            <title>{type}</title>
            <use xlinkHref={m[type].icon}></use>
        </svg>
    </span>
}