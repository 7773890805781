import { defineComponent, reactive } from "vue";
import dayjs from 'dayjs'
import style from './Head.module.scss';

export default defineComponent({
    props: ["isLogin"],
    setup() {
        let data: any = reactive({
            logo: '/images/logo_CH_TCJ922.png',
            time: dayjs(new Date()).format("DD-MMM-YYYY HH:mm:ss"),
        });
        let timer: any = null;
        const calcTime = () => {
            timer = null;
            data.time = dayjs(new Date()).format("DD-MMM-YYYY HH:mm:ss")
            timer = setTimeout(() => {
                calcTime();
            }, 1000);
        };
        calcTime();
        const bell = () => {
            // alert('1')
        }
        return {
            data,
            calcTime,
            bell
        };
    },
    render() {

        return (
            <div class={style['host']}>
                <div class={style['left-logo']}>
                    <img src={this.data.logo} />
                    &nbsp;&nbsp;&nbsp;
                    <p>CHEUNG HING</p>
                </div>
                <div class={style['right-time']}>
                    <span><i class="iconfont icon-lingdang1" style={'color:#fff'} onClick={this.bell}></i></span>
                    <p>{this.data.time}</p>
                </div>
            </div>
        )
    }
})