// import { QueryList } from '@angular/core';
// import { SimplePagination } from '../core/types';
// import { plainToClass } from 'class-transformer';
import { useRouter } from "vue-router";
import { isArray } from '@vue/shared';
import { ParamsMap } from "@/components/form-list-group/types/types";
import useWoStore from "@/store/woManagement";

// import { DefectNoList } from '../types/page';
interface QueryList {
    [p: string]: any;
}
interface SimplePagination {
    [p: string]: any;
}
interface plainToClass {
    [p: string]: any;
}
interface DefectNoList {
    [p: string]: any;
}
/**
 * 把 {ID: "1", Description: string} 这种对像 转成 {key:string, value: string}
 */
export const IdDescription2KV = (arr: { ID: string | number, Description: string }[] = []): { key: string | number, value: string }[] => {
    return arr.map(x => ({ key: x.ID + '', value: x.Description }));
}
/**
 * 下拉选项 添加 All，并选择All
 * @param select  SelectControl
 */
// export const AddAndSetAll = (select: SelectControl): SelectControl => {
//     select.options.unshift({ key: '', value: 'All' });
//     select.value = select.options[0].key;
//     return select;
// }

/**
 * 从数组中找到满足条件的第一个 支持2级查找
 * @param arr 
 * @param value 
 * @param key1 一级关键字
 * @param key2? 二级关键字
 * @param ignoreCase? 忽略大小写 默认不忽略
 */
export const findOne = (arr: /* QueryList<any> | */ any[], value: any, key1: any, key2?: any, ignoreCase: boolean = false): any => {
    if (!arr) return null;
    if (key2) {
        let objs = arr.filter(x => {
            if (ignoreCase) {
                return (x[key1][key2] as string)?.toUpperCase() == (value as string)?.toUpperCase();
            }
            return x[key1][key2] == value;
        });
        if (objs.length > 0) return objs[0];
        return null;
    } else {
        let objs = arr.filter(x => {
            if (ignoreCase) {
                return (x[key1] as string)?.toUpperCase() == (value as string)?.toUpperCase();
            }
            return x[key1] == value
        });
        if (objs.length > 0) return objs[0];
        return null;
    }
}
/**
 * 把defectNoList转换成SimplePagination
 * @param defectNoList DefectNoList[]
 */
export const defect2SimplePagination = (defectNoList: DefectNoList[]): SimplePagination[] => {
    return defectNoList.map((defect: DefectNoList) => ({ Id: defect.DefectId, Index: defect.DefectNo }));
}

/**
 * 把Object 为空字符串的属性值转为null
 * @param obj 
 */
export const empty2null = (obj: any) => {
    Object.keys(obj).map((key) => {
        key && obj[key] === '' && (obj[key] = null);
    })
    return obj;
}
/**
 * 把Object 为null的属性值转为空字符串
 * @param obj 
 */
export const null2empty = (obj: any) => {
    Object.keys(obj).map((key) => {
        key && obj[key] === null && (obj[key] = '');
    })
    return obj;
}


/**
 * 获取下拉项值对应的文本
 * @param arr 
 * @param val 
 * @param key 
 * @param key1 
 */
export const getTextByValue = (arr: any[], val: any, key: string, key1: string) => {
    let arr1 = arr.filter(x => x[key] == val);
    if (arr1.length > 0) {
        return arr1[0][key1];
    }
    return null;
}

export const planish = (data: any) => {
    let string = JSON.stringify(data);
    return JSON.parse(string);
}

/**
 * 获取列的宽度
 * @param key 列key
 * @param text 列标题显示字符
 */
export const getColmunWidth = (key: string, text: string = ''): string => {
    let str: string | number = '';
    switch (key) {
        case 'InspectionNo': str = 240; break;
        case 'Street': str = '330'; break;
        case 'Location': str = '330'; break;
        case 'District': str = '100'; break;
        case 'InspectionDateFrom': str = text.length * 8; break;
        case 'InspectionDateTo': str = text.length * 8; break;
        // case 'DurationFrom': str = text.length * 8; break;
        // case 'DurationTo': str = text.length * 8; break;
        case 'InspectionMethod': str = text.length * 8; break;
        case 'Inspectedby': str = text.length * 8; break;
        case 'InspectionFrequency': str = text.length * 8; break;
        // case 'StartChainage': str = text.length * 7; break;
        // case 'EndChainage': str = text.length * 7; break;
        case 'RoadSurface': str = text.length * 9; break;
        case 'RoadLength': str = text.length * 10; break;
        case 'CLCNo': str = text.length * 11; break;
        // case 'LampPN': str = text.length * 11;; break;
        default: str = text.length * 10; break;
    }
    return str + 'px';
}

/**
 * 
 * @param start 起
 * @param end 
 */
export const rangeNum = (start: number, end: number): number[] => {
    let arr = [];
    for (let i = start; i <= end; i++) {
        arr.push(i);
    }
    return arr;
}

/**
 * 把目标数组  当关键字一样 的 对象 属性，复制给源数组
 * @param originArray 源数组
 * @param targetArray 目录数组
 * @param key 关键字
 */
export const assignArray = (originArray: any[], targetArray: any[], key: string) => {
    return originArray.map(x => {
        let o = findOne(targetArray, x[key], key);

        let t = Object.assign({}, x);
        if (o) {
            t = Object.assign({}, o);
        }
        return t;
    });
}

/**
 * 是否字符型数组
 * @param arr 
 */
export const isStringArray = (arr: any[]): boolean => {
    return arr.some(x => typeof x === 'string');
}

/**
 * 
 * @param str 
 * @returns 
 */
export const getSortValue = (str: string): '' | '0' | '1' => {
    if (str) {
        if (str == 'asc') {
            return '0';
        } else return '1';
    } else return '';
}

export const copyProp = function (obj: any) {
    Object.keys(obj).forEach(x => {
        /// @ts-ignore
        this[x] = obj[x];
    });
}

/**
 * 通过映射关系的数组，生成对象
 * @param arr { key: string; mapKey: string; }[]
 * @param o Object    Form 绑定的 model
 * @param rows Object Array
 * @param router router
 * @param fullRow 整行
 */
export const getObjectByMap = function (arr: ParamsMap[], o: any = {}, rows: any[] = [], router?: any, fullRow?: any) {
    let obj: any = {}
    arr.forEach(x => {
        if (x.hasOwnProperty('fixValue')) {
            obj[x.key] = x.fixValue;
        } else if (x.from) {
            if (x.from == 'Row') {
                // model 的 x.rowNoKey 存在
                if (o[x.rowNoKey]) {
                    // 找到 rows 里 (model 的 x.rowNoKey) 值 对应的 row
                    let row: any = rows.find((r, i) => {
                        if (x.isRowIndex) {
                            return i + 1 == o[x.rowNoKey];
                        } else {
                            return o[x.rowNoKey] == r[x.rowNoKey]
                        }
                    });
                    if (row && row[x.mapKey]) obj[x.key] = row[x.mapKey];
                    // 直接传整行
                } else if (x.isFullRow) {
                    obj[x.key] = fullRow || o;
                }
            } else if (x.from == 'Form') {
                if (o[x.mapKey]) obj[x.key] = o[x.mapKey];
                else if (x.hasOwnProperty('defaultValue')) obj[x.key] = x.defaultValue;
            } else if (x.from == 'WoStore_Row') {
                const woStore = useWoStore();
                // 这部分功能 必需 把 vue 的 router 对象传进来
                if (woStore.rows && woStore.rows.length && woStore.globalSearchShowResult && new RegExp(woStore.path, 'i').test(router.currentRoute.value.fullPath)) {
                    woStore.rows[0] && (obj[x.key] = woStore.rows[0][x.mapKey]);
                } else {
                    obj[x.key] = x.defaultValue;
                }
            }
        } else if (o[x.mapKey]) {
            if (x.arr2Str) {
                if (isArray(o[x.mapKey])) {
                    obj[x.key] = (o[x.mapKey])?.join(',');
                } else {
                    obj[x.key] = o[x.mapKey];
                }
            } else {
                obj[x.key] = o[x.mapKey];
            }
        } else if (x.hasOwnProperty('defaultValue')) obj[x.key] = x.defaultValue;
    });
    return obj;
}

/**
 * 
 * @param num 要转换的数字
 * @param PrecisionDigit 要保留的小数点精度
 * @param splite 千位分隔符
 */
export const decimalsNum = (num: number, PrecisionDigit: number = 2, splite: string = ','): string => {
    // num = 1058.61
    // debugger
    let s = '1';
    for (let i = 0; i < PrecisionDigit; i++) { s += '0'; }
    let n = parseInt(s);;
    let str = ''
    str = Math.round(num * n) / n + '';
    if (!/\./.test(str)) {
        str += `.${s.slice(1)}`;
    }
    let arr = str.split('.');
    let N = arr[0];
    let N2 = arr[1];
    // 整数 反转后的数组
    let rN = N.split('')
    rN.reverse();
    let newArr = [];
    while (rN.length) {
        newArr.push(rN.splice(0, 3).reverse().join(''));
        if (rN.length) newArr.push(splite);
    }
    str = newArr.reverse().join('');
    return `${str}.${N2}`;
}