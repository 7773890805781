import useStore from '@/store/metaData';
import { EMPTY_ID } from "@/utile";
import { AgGridRowEvent_e } from '.';
import { patchValue } from "../../../form";


export const curd = (prop: any, emit: any, obj: any) => {
    const store = useStore()
    const { model, data, tableRef, setdata, canSel, ClearData, selectvalue } = obj

    const cruds = (e: AgGridRowEvent_e) => {
        switch (e.currentAction.toLowerCase()) {
            case 'Save'.toLowerCase(): save(e); break;
            case 'Delete'.toLowerCase(): del(e); break;
        }
    }
    const save = (e: AgGridRowEvent_e) => {
        let row = e.data;
        if (row.Status !== '1' && row.Amercement) {
            prop.options.http?.error?.('The performance is not a violation record, and the 罚款 field cannot be ticked. ')
            e.node.setDataValue('Amercement', false);
        }
        let o = {
            ...row,
            LoginName: store.userName,
        }

        prop.options?.http?.editListModal?.(o).then((x: any) => {
            prop.options.http?.notify('The Edit was successful.');
        })
    }

    const del = (e: AgGridRowEvent_e) => {

        prop.options.http?.confirm({
            title: 'Information',
            content: 'Do you want to delete it permanently',
            onOk: () => {
                let row = e.data;

                let o = {
                    ...row,
                    LoginName: store.userName,
                }


                let find = false// data.MainItemList_Code.includes(row.Performance)
                // 有问题，不验证了
                if (find) {
                    prop.options?.http?.error('This Performance is the default 10 Performances record and cannot be deleted.')
                } else {
                    prop.options?.http?.delListModal?.(o).then((x: any) => {
                        data.initData = x
                        // 删除后把右边的list清空
                        emit('change', ' ')

                        setdata();
                        prop.options.http?.notify('The deletion was successful.');
                    })
                    // tableRef.value?.grid?.api?.sizeColumnsToFit()
                }
            }
        })
    }


    // 按钮
    const newL = () => {
        canSel.value = true
    }
    const Cancel = () => {
        ClearData()
    }
    const saveselect = async () => {
        // 可能出现只有Performance没有description
        if (model.Performance) {
            patchValue(model, {
                ...model, LoginName: store.userName,
                FID: prop.id, ID: EMPTY_ID,
            })
            prop.options.http?.addListModal && await prop.options.http.addListModal(model).then((x: any) => {
                data.initData = x
                setdata();
                // 新增后把右边的list清空
                emit('change', ' ')
                ClearData()
                prop.options.http?.notify('The Add was successful.');
            })
            // tableRef.value?.grid?.api?.sizeColumnsToFit()
        } else {
            prop.options.http?.error('Please select one.')
        }
    }
    const selChange = (e: any, item: any) => {
        selectvalue.value = e
        patchValue(model, { Description: item?.Description, Performance: item?.Performance })
    }
    const filterOption = (input: string, option: any) => {
        return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const saveAll = () => {
        let arr = [...data.rowData]
        arr.map(y => {
            if (y.Status !== '1') y.Amercement = null
        })
        let obj: any = {
            // FID: prop.id,
            LoginName: store.userName,
            MajorItems: arr
        }

        prop.options.http?.SaveAll && prop.options.http.SaveAll(obj).then((x: any) => {
            prop.options.http?.notify('The Edit was successful.');
        })
    }
    return {
        cruds,

        newL,
        saveselect,
        selChange,
        filterOption,
        Cancel,
        saveAll
    }
}