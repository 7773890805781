// import { async } from '@angular/core/testing';
// import { DownloadBlobErrorResult } from '../core/types';

export const getFileName = (str: string, DeleteUnderline: boolean = false): string => {
    let reg = /[^\\\/]*[\\\/]+/g;
    let fileName = str.replace(reg, '');
    if (DeleteUnderline && /_/.test(fileName)) {
        fileName = fileName.slice(0, fileName.lastIndexOf('_'));
    }
    return fileName;
}

/**
 * 将file对象（.json文件）转为json对象
 * @param {File} file file对象
 */
export const fileToJson = (file: File | Blob, callback: Function) => {
    const reader = new FileReader()
    reader.onerror = err => {
    } // 失败回调
    reader.readAsText(new Blob([file]), 'utf-8') // 按照utf-8编码解析
    reader.onload = async (res: any) => {
        const result = res.target.result // 得到字符串
        const data = JSON.parse(result) // 解析成json对象
        callback(data);
    }
}
/**
 * 获取分隔符前的字符串
 * @param fileName 
 * @param splite 
 * @returns 
 */
export const getPrefixString = (fileName: string, splite: string = '?') => {
    let i = fileName.lastIndexOf(splite);
    if (i > 0) return fileName.slice(0, fileName.lastIndexOf(splite))
    return fileName;
}

/**
 *
 * 下载文件流Blob时。如果Blob size少于300。基本上就是出错了。把Blob对象转成普通对像 DownloadBlobErrorResult
 * @return blob | {
                status: number,
                statusText: string
            }
 */
// export const getFileResult = (res: any, cb: Function) => {
//     if (res instanceof Blob && res.size < 300) {
//         fileToJson(res, (data: DownloadBlobErrorResult) => {
//             cb({
//                 status: data.code,
//                 statusText: data.msg.title
//             })
//         })
//     } else cb(res);
// }
// /**
//  * 文件上传
//  * @property form
//  * @property fileNames
//  */
// export class FileUpload {
//     form: FormData;
//     fileNames: string[];
// }

// /**
//  * 左右所占百分比
//  * @property left
//  * @property right
//  */
// export class Proportion {
//     left: number;
//     right: number;
// }