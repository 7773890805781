import useStore from "@/store/metaData";
import useWoStore from "@/store/woManagement";
import { ID_Description } from "@/types";
import { getObjectByMap } from "@/utile";
import { SetSelectOptions } from "@/utile/form";
import { isArray } from "@vue/shared";
import { computed, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { patchValue } from "../form";
import { FormlyFieldGroup } from "../form/types";
import { Btn, TabProps } from "./types";
import { Api_handle_result_map, Fn_type } from "./types/map";
import { Form_Event, Form_List_Group_Event } from "./use/useEvent";
import { handel_api_after } from "./use/use_handle_api_before_after";

export default function (
    props: TabProps,
    refs: any
) {
    const { documentRef, formItemDataRef, ListRef, formItemsRef,
        CustomfromRef, CustomfromRef_s, queryBtnModalRef, formBtnRef,
        PartlistRef } = refs

    let model = reactive<any>({});
    const data = reactive<any>({
        selectOptions: {},
        loading: false,
    });
    const router = useRouter();
    const store = useStore();
    const woStore = useWoStore();
    // /** model有主键。并且主键等于主键初始值 */
    // const isAdd = computed(() => model && model.hasOwnProperty(config.formPrimaryKey) && model[config.formPrimaryKey] == config.formPrimaryKeyEmptyValue);
    // /** model有主键。并且主键不等于主键初始值 */
    // const isEdit = computed(() => model && model.hasOwnProperty(config.formPrimaryKey) && model[config.formPrimaryKey] != config.formPrimaryKeyEmptyValue);
    let isAdd = ref<boolean>(false);
    let isEdit = ref<boolean>(false);
    const EmptyValue = props.pageConfig?.formPrimaryKeyEmptyValue
    const _primaryKey = props.pageConfig?.isVirtualFormPrimaryKey ? props.pageConfig.formVirtualPrimaryKey as string : props.pageConfig?.formPrimaryKey as string;
    watch(model, (value) => {
        isAdd.value = model && model.hasOwnProperty(_primaryKey) && model[_primaryKey] && model[_primaryKey] == EmptyValue;
        isEdit.value = model && model.hasOwnProperty(_primaryKey) && model[_primaryKey] && model[_primaryKey] != EmptyValue;
    })

    const canSave = computed(() => {
        let c = props.pageConfig?.canSaveConfig;
        if (c) {
            if (c.target == 'List' && ListRef.value) {
                return (ListRef.value.data.rowData.length);
            }
        }
        const user = props.pageConfig?.edit_del_loginname
        if (user && model && store.userName?.toUpperCase() !== 'admin'?.toUpperCase()) {
            if (model['CreateBy']?.toUpperCase() == store.userName?.toUpperCase()) {
                return isAdd.value || isEdit.value
            } else {
                return isAdd.value
            }
        }
        // 如果是Completed返回false
        if (!!(model.ReportStatus == 'Completed')) {
            return false
        }
        return isAdd.value || isEdit.value
    });
    const canDel = computed(() => {
        const user = props.pageConfig?.edit_del_loginname
        if (user && model && store.userName?.toUpperCase() !== 'admin'?.toUpperCase()) {
            if (model['CreateBy']?.toUpperCase() == store.userName?.toUpperCase()) {
                return isEdit.value
            } else {
                if (props.pageConfig?.del_ignore_role) {
                    const userName = store.userName;
                    // @ts-ignore
                    const userGroup = store.profile?.userGroup;
                    let isShow = userName?.toUpperCase() == 'admin'?.toUpperCase() || userGroup == 'SuperUser';
                    return isShow ? isEdit.value : false
                }
                return false
            }
        }
        return isEdit.value
    });
    const isSel = computed(() => {

        return isEdit.value
    });
    const canNew = computed(() => {
        return true
    });



    const FormEvent: Form_List_Group_Event = Form_Event;



    const getInit = () => {
        // let o: any = props.new_default_obj ? props.new_default_obj : {};
        let row = woStore.rows && woStore.rows.length && woStore.rows[0] ? woStore.rows[0] : {};
        let obj: any = getObjectByMap(props.httpParamsMap?.init || [], row, [], router);
        obj = { ...obj, LoginName: store.userName }
        props.http?.init && props.http.init(obj).then((x: any) => {
            // pagination.totalPages = x.TableData.TotalPages;
            // pagination.totalRecords =x.TableData.TotalRecords;
            // pagination.currentPage = x.TableData.CurrentPage;
            // data.rowData = x.TableData.Data;
            setSelectOptionsByMap(x);
            // 如果已经存在当前行 获取当前行
            if (woStore.rows && woStore.rows.length && woStore.globalSearchShowResult && new RegExp(woStore.path, 'i').test(router.currentRoute.value.fullPath)) {
                patchValue(model, transformFormTypeValue(woStore.rows && woStore.rows.length && woStore.rows[0] ? woStore.rows[0] : {}));
                getRow();
            } else { // 如果没有，初始化 woStore
                initStore();
                documentRef.value && documentRef.value.init();
            }
            let t = props.pageConfig?.initApi_handle_result_target;
            if (props.pageConfig?.initApi_handle_result && t) {
                let f = props.pageConfig?.initApi_handle_result_Fields;
                // console.log(f, t);

                if (f && t == 'List') {
                    let arr = x[f as string];
                    ListRef.value.setSelect(x)
                    arr && ListRef.value.setRowData(arr, true);
                }
                else if (f && t == 'CustomFrom_s') {
                    CustomfromRef_s.value.setSelect(x);
                }
            }
            if (props.pageConfig?.init_then_getList) {
                FormEvent.$getList.next({});
            }
        })
    };

    const getRow = (o?: any, setSearchBar: boolean = false) => {
        if (!o && !woStore.rows[0]) { return }
        let arr1 = props.httpParamsMap?.getRow.length ? props.httpParamsMap?.getRow : [{ key: props.pageConfig?.formPrimaryKey, mapKey: props.pageConfig?.formPrimaryKey }]
        // @ts-ignore
        let obj: any;
        // @ts-ignore
        obj = o ? o : getObjectByMap(arr1, woStore.rows && woStore.rows.length ? woStore.rows[0] : {});
        props.http?.getRow && props.http.getRow(obj).then((x: { Entity: any, [proName: string]: any/* SubConList?: SubCon[]; AreaCode?: any[] */ }) => {
            patchValue(model, { ...x.Entity });
            let t = props.pageConfig?.initApi_handle_result_target;
            if (props.pageConfig?.initApi_handle_result && t) {
                let f = props.pageConfig?.initApi_handle_result_Fields;
                if (f && t == 'List') {
                    let arr = x[f as string];
                    arr && ListRef.value.setRowData(arr, true)
                } else {
                    return
                }
            }
            // setControlledFields();
            initStore(x);
            // 如果配置了 http getRow 的 返回值 映射关系 就 额外的给表单赋值
            if (props.httpResultMap?.getRow?.length) extraPatchValue(x);
            let formSelectConfig = props.pageConfig?.getRow_set_formSelectConfing
            // 处理 表单 额外的 字段 值
            if (formSelectConfig && formSelectConfig.length) {
                let obj: any = {};
                formSelectConfig.map(k => obj[k.key] = x[k.mapKey]);
                patchValue(model, obj);
            }
            handleFormItemData(x);
            setTimeout(() => {
                documentRef.value && documentRef.value.init();
            }, 100);
            if (setSearchBar) {
                let path = router.currentRoute.value.fullPath.split('/').slice(0, 3).join('/');
                let arrName = props.pageConfig?.searchBarShowResultExtraArrName;
                // arrName && 
                woStore.setShowResultByArrAndObj(/* x[arrName] ||  */[], x.Entity, props.pageConfig?.searchBarShowResultConfig || [], path);
                if (props.pageConfig?.search_then_init) {
                    setTimeout(() => {
                        woStore.rows[0] && getInit();
                    }, 100);
                }
                if (props.pageConfig?.search_then_getList) {
                    setTimeout(() => {
                        woStore.rows[0] && formItemDataRef.value &&
                            formItemDataRef.value.getList();
                    }, 100);
                }
            }
            if (formItemsRef && formItemsRef.value) {
                formItemsRef.value.init();
            }
        })
    }
    const handleFormItemData = (obj: any) => {
        if (props.pageConfig?.hasFormItemData) {
            // @ts-ignore
            let arr = obj[props.pageConfig?.formItemData_in_row_fields];
            // arr.map((x: any) => {
            //     x.DBAmount = 2
            // })
            formItemDataRef.value.setRowData(arr);
        }
    }
    const initStore = (x?: { Entity: any, [proName: string]: any }) => {
        if (props.pageConfig?.hasUpdataGlobalSearchPermission && props.pageConfig.updataActionType == 'Form') {
            let path = router.currentRoute.value.fullPath.split('/').slice(0, 3).join('/');
            let arrName = props.pageConfig?.searchBarShowResultExtraArrName;
            let row = x ? x.Entity : woStore.rows;
            arrName && woStore.setShowResultByArrAndObj(x ? x[arrName] : [], row || null, props.pageConfig?.searchBarShowResultConfig || [], path);
        }
    }
    /** 额外的给表单赋值 */
    const extraPatchValue = (obj: any) => {
        let o: any = {};
        props.httpResultMap?.getRow?.map(x => {

            if (x.type == 'ID_Description[]') { }
            if (x.type == 'Object[]') {
                o[x.key] = (obj[x.mapKey] as any[]).map(y => y[x.objectProperty]).join(', ');
            }
            if (x.type == 'Object') {
                o[x.key] = obj[x.mapKey][x.objectProperty];
            }
            if (x.type == 'string[]') { }
            if (x.type == 'combination_string') {
                o[x.key] = model[x.mapKey] + x.combination_fix_value;
            }

        });
        patchValue(model, o);
    }
    /** 把表单的，有些值，转换一下 */
    const transformFormTypeValue = (obj: any): any => {
        props.pageConfig?.transformFieldsArr?.map(x => {
            props.pageConfig?.transformValueArr?.map(y => {
                // obj里的 原字段值 == y.key
                if (obj[x.mapKey] == y.key) {
                    // obj 新加字段 = y.mapKey
                    obj[x.key] = y.mapKey;
                }
            });
        })
        return obj;
    }
    const setSelectOptionsByMap = (o: any) => {
        props.formSelectConfing?.map(x => {

            if (x.type == 'ID_Description') {
                // @ts-ignore
                let arr = x.from == 'store' ? store.menuMetaData.Permission[x.mapKey] : o[x.mapKey];
                // console.log(arr);
                if (x.format) {
                    let formatArr = (x.format as string).split(':');
                    data.selectOptions[x.key] = arr.map((y: ID_Description) => ({
                        ...y, label: formatArr.reduce((a, b) => {
                            if (y.hasOwnProperty(b)) {
                                // @ts-ignore
                                return a + y[b];
                            } else {
                                return a + b;
                            }
                        }, ''), value: y.Value || y.ID || y.Description
                    }));
                } else {
                    // console.log(arr, o, x.mapKey);
                    data.selectOptions[x.key] = arr.map((y: ID_Description) => ({ ...y, label: y.Description || y.ID, value: y.ID || y.Description }));
                }
            } else if (x.type == 'string') {
                data.selectOptions[x.key] = o[x.mapKey].map((y: string) => ({ label: y, value: y }));
            }


            if (x.defaultFirst) patchValue(model, { [x.key]: data.selectOptions[x.key][0].value });
            SetSelectOptions(props.fields || [], x.key, () => data.selectOptions[x.key]);
            if (formBtnRef.value) {
                formBtnRef.value.fields?.map((f: FormlyFieldGroup) => {
                    f.fieldGroup.map(g => {
                        if (g.key == x.key) {
                            SetSelectOptions(formBtnRef.value.fields || [], g.key, () => data.selectOptions[x.key]);
                        }
                    });
                })
            }
        });
        // // 把 Documents 要用的下拉选项。保存到对应 的Documents里
        // props.body?.map(b => {
        //     Object.keys(b).map(rowName => {
        //         // @ts-ignore
        //         let isDocumentsList = (b[rowName] as ComponentConfig).component instanceof DocumentsList;
        //         // @ts-ignore
        //         let c: DocumentsList = b[rowName].component
        //         if (isDocumentsList && c.selectOptionsMap?.length) {
        //             c.selectOptionsMap.map(x => {
        //                 // @ts-ignore
        //                 c.selectOptions[x.key] = o[x.mapKey];
        //             });
        //         }
        //     });
        // });
    }
    const toggle = (c: Btn, cGroup: Btn[]) => {
        if (c.actionTarget == 'List') {
            ListRef.value.toggleRowData(c.attachedValue, c.form_active_field);

            return;
        }

        // @ts-ignore
        let obj: any = getObjectByMap(props.httpParamsMap?.toggle || [], { ...model, [c.form_active_field]: c.attachedValue });
        obj.LoginName = store.userName;
        props.http?.toggle && props.http.toggle(obj).then((x: { Entity: any, [proName: string]: any/* SubConList?: SubCon[]; AreaCode?: any[] */ }) => {
            let msg = `${c.attachedValue} has not been created.`
            if (!x.Entity) {
                return props.http?.error(msg);
            }
            patchValue(model, { ...x.Entity });
            // setControlledFields();
            initStore(x);
            // 如果配置了 http getRow 的 返回值 映射关系 就 额外的给表单赋值
            if (props.httpResultMap?.getRow?.length) extraPatchValue(x);
            let formSelectConfig = props.pageConfig?.getRow_set_formSelectConfing
            // 处理 表单 额外的 字段 值
            if (formSelectConfig && formSelectConfig.length) {
                let obj: any = {};
                formSelectConfig.map(k => obj[k.key] = x[k.mapKey]);
                patchValue(model, obj);
            }
            setTimeout(() => {
                documentRef.value && documentRef.value.init();
            }, 100);

            // patchValue(model, { ...x.Entity });
            // let formSelectConfig = props.pageConfig?.getRow_set_formSelectConfing
            // // 处理 表单 额外的 字段 值
            // if (formSelectConfig && formSelectConfig.length) {
            //     let obj: any = {};
            //     formSelectConfig.map(k => obj[k.key] = x[k.mapKey]);
            //     patchValue(model, obj);
            // }
        })
    }

    const formChange = (e: { key: string, value: any }) => {
        handel_select_change_form_fields(e.key, e.value);
        props.pageConfig?.api_after?.formChanged && handel_api_after(null, props.pageConfig?.api_after?.formChanged, refs, (fn: Fn_type, z: Api_handle_result_map) => {
            if (fn === 'getInitCode') {
                getInitCode(e, z)
            }
        });
    }
    const getInitCode = (x: { key: string, value: any }, z: Api_handle_result_map) => {
        if (z?.fn == 'getInitCode' && z.fields == x.key) {
            let o = { [x.key]: x.value };
            props.http?.getInitCode && props.http?.getInitCode(o).then((x: any) => {
                props.pageConfig?.api_after?.getInitCode && handel_api_after(x, props.pageConfig?.api_after?.getInitCode, refs, (fn: Fn_type, z: Api_handle_result_map) => {

                });
            })
        }
    }

    const formSearch = (e: { key: string, value: any }) => {
        // console.log(e);
    }
    const queryFormSearch = (e: { key: string, value: any, },) => {
        let h = props?.queryModalFormSearchHttpMap?.find(x => x.key == e.key);

        let obj: any = getObjectByMap(h?.httpMap || [], e || {});

        props.http?.search && props.http.search(obj).then((x: string[]) => {
            let fields = props.queryModalFields
            if (isArray(x)) {
                SetSelectOptions(isArray(fields) ? fields : [], e.key, () => [{ label: '', value: '' }]);
                setTimeout(() => {
                    SetSelectOptions(isArray(fields) ? fields : [], e.key, () => x.map(y => ({ label: y, value: y })));
                }, 1);
            }
        })
    }
    const handel_select_change_form_fields = (key: string, value: any) => {
        let map = props.pageConfig?.select_change_form_fields_map;
        if (map) {
            map.map(m => {
                if (m.key == key && m.value == value) {
                    props.fields = m.fields;
                }
            });
        }
    }
    const clear = () => { }
    const cancel = () => { }
    onUnmounted(() => {
        router.afterEach((to, from, failure) => {
            let str1 = to.fullPath.split('/').slice(0, 3).join('/');
            let str2 = from.fullPath.split('/').slice(0, 3).join('/');

            // 长度相同，且前面两段相同 代表还在同一个大的路由里 不清除store
            if (to.fullPath.split('/').length == from.fullPath.split('/').length && str1 === str2) {

            } else { // 清除store
                woStore.$reset();
            }
        })
    });
    if (props.pageConfig?.firstApi) {
        props.pageConfig?.firstApi == 'init' && props.pageConfig?.initApiType == 'Form' && getInit();
    }
    if (props.pageConfig?.defaultModel) {
        patchValue(model, props.pageConfig?.defaultModel)
    }

    return {
        model,
        canSave,
        canDel,
        isAdd,
        canNew,
        isSel,
        formChange,
        getRow,
        clear,
        cancel,
        toggle,
        getInit,
        formSearch,
        queryFormSearch
    }
}