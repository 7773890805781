export class MenuItem {
    id: string = '';
    name: string = '';
    subMenu: Array<MenuItem> = [];
    isActive: boolean = false;
    route: string = '';
    isOpen: boolean = false;
    // @ts-ignore
    parent: MenuItem = undefined;
    parentId: number = NaN;
    ModuleID: string = '';
    RoutePath: string = '';
    FirstMenu: number = NaN;
    FirstMenuRoute: string = '';
    icon: string = '';

    Read?: boolean;
    Create?: boolean;
    Delete?: boolean;
    Enquiry?: boolean;
    Report?: boolean;
    Update?: boolean;
    constructor(opt?: MenuItem) {
        if (opt) {
            this.id = opt.id;
            this.name = opt.name;
            this.Read = opt.Read;
            this.Create = opt.Create;
            this.Delete = opt.Delete;
            this.Enquiry = opt.Enquiry;
            this.Report = opt.Report;
            this.Update = opt.Update;
            this.subMenu = opt.subMenu;
        }
    }
}

export interface MenuPermission {
    Create: boolean;
    Delete: boolean;
    Enquiry: boolean;
    FirstMenu: any
    MDDescription: string;
    MDID: number;
    ModuleID: string;
    Read: boolean;
    Report: boolean;
    Update: boolean;
    ParentID: number;
    RoutePath: string;
}