import { reactive, ref } from "vue";
export const Part1 = () => {
    const draw = reactive({
        visible: false
    })
    const drawRef = ref()
    const showDrawer = () => {
        draw.visible = !draw.visible;
    };
    const onClose = () => {
        draw.visible = !draw.visible;
    }
    return {
        draw, drawRef, showDrawer, onClose,
    }
}

export const Part2 = (emit: any) => {
    const draw = reactive({
        visible: false
    })
    const drawRef = ref()
    const showDrawer = () => {
        draw.visible = !draw.visible;
    };
    const onClose = () => {
        draw.visible = !draw.visible;
    }
    const openPart3 = () => {
        emit('openPart3')
    }
    return {
        draw, drawRef, showDrawer, onClose, openPart3
    }
}
export const Part3 = (emit: any) => {
    const draw = reactive({
        visible: false
    })
    const drawRef = ref()
    const showDrawer = () => {
        draw.visible = !draw.visible;
    };
    const onClose = () => {
        draw.visible = !draw.visible;
    }
    const openPart4 = () => {
        emit('openPart4')
    }
    return {
        draw, drawRef, showDrawer, onClose, openPart4
    }
}
export const Part4 = () => {
    const draw = reactive({
        visible: false
    })
    const drawRef = ref()
    const showDrawer = () => {
        draw.visible = !draw.visible;
    };
    const onClose = () => {
        draw.visible = !draw.visible;
    }
    return {
        draw, drawRef, showDrawer, onClose,
    }
}