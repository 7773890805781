import { FormlyFieldConfig } from "./types"

export default function (item: FormlyFieldConfig, model: any,) {
    // @ts-ignore
    let _this: any = this;
    let cp: any = <></>
    cp = item.templateOptions?.add_target_config?.map((a: any) => {
        let target = a.target
        let obj = {
            otherType: a.otherType,
            mapkey: a.mapkey,
            temp: a,
            model,
            op: a?.op
        }
        let ac: any;

        if (a.select) {
            return item.templateOptions?.options?.filter((e: any) => {
                return e[target] == a?.key
            }).map(b => {
                if (model[item.key] && model[item.key].includes(b.value)) {
                    return getTemp(_this, obj)
                }
            })
        } else {
            /**下拉框筛选 */
            ac = item.templateOptions?.options?.filter(d => {
                return d[a.pkey || 'Description'] == model[item.key]
            }).filter(e => {
                return e[target] == a?.key
            })
            if (ac?.length) {
                return getTemp(_this, obj)
            }
        }
    })
    return <div class={'width-100-100'} style='margin:5px 0; padding: 0 5px;'>{cp}</div>
}

export const getTemp = (_this: any, obj: any) => {
    //**op:给其它使用的 */
    const { model, otherType, mapkey, op, temp } = obj
    switch (otherType) {
        case 'Input':
            return <>
                {
                    <a-input
                        value={model[mapkey]}
                        placeholder={temp?.placeholder || 'Please Enter'}
                        onChange={(g: InputEvent) => _this.handleInput(g, mapkey, model)}
                        allowClear
                        size={_this?.size || 'large'}
                        style='width:100%;'
                    />
                }
            </>
        case 'CheckboxGroup':
            return <a-checkbox-group
                options={op}
                value={model[mapkey]}
                onChange={(e: any) => _this.handleCheckboxGroupValueChange(e, mapkey, model)}
            >
            </a-checkbox-group>
        case 'DatePicker':
            return <>
                <a-date-picker
                    placeholder={temp?.placeholder || 'DD/MM/YYYY'}
                    value={model[mapkey]}
                    onChange={(e: InputEvent) => _this.handleValueChange(e, mapkey, model)}
                    disabled={temp?.disabled || _this.disabled}
                    size={_this.size || 'large'}
                    defaultValue={undefined}
                    show-time={temp?.showTime}
                    format={temp?.format || 'DD/MM/YYYY'}
                    getPopupContainer={(triggerNode: { parentNode: any; }) => triggerNode.parentNode}
                />
            </>
        default:
            return <></>
    }
}