import { Slot, reactive } from "vue";


class GlobalSearchService {
    // @ts-ignore
    public slots = reactive({
        default: '',
    });
    constructor() {}
}
const s = new GlobalSearchService();
export default s;