import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import { App } from 'vue'
import BtnPermission from './permission'

let arr = [
    VueViewer,
]
let directive: any = {
    BtnPermission
}
export default (app: App<Element>) => {
    arr.map((x) => app.use(x))
    Object.keys(directive).map((x) => app.directive(x, directive[x]))
}