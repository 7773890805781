import { defineComponent, ref, reactive, inject } from 'vue';
import useStore from '@/store/metaData';
import { useRouter } from "vue-router";
import { MenuItem } from '@/types/nav';
import Mobile_layout from './layout_m/index.vue'
import Web_layout from './layout_w/index.vue'

export default defineComponent({
  components: {
    Mobile_layout,
    Web_layout
  },
  mounted() {
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const handleMenu = (arr: MenuItem[]) => {
      let COMMON = {
        value: [1, 2, 3,],
        label: 'COMMON FUNCTION'
      };
      let MORE = {
        value: [12],
        label: 'MORE FUNCTION'
      };
      let group = [COMMON, MORE];
      let gArr: any[] = [];
      group.map(g => {
        g.value.length && gArr.push({
          name: g.label,
          id: g.label
        })
        let subG = g.value.map(id => {
          if (typeof id == 'number') {
            return arr.find(x => +x.id == id);
          } else {
            return reactive(id);
          }
        })
        gArr.push(...subG)
      });
      return gArr;
    }
    const menu = handleMenu(store.menu);

    return {
      menu,
      router,
    };
  },
  render() {
    const isMobile: any = inject('isMobile')

    return (
      <>
        {
          isMobile.value ? <Mobile_layout menu={this.menu} /> : <Web_layout menu={this.menu} />
        }
      </>
    )
  }
})
