import { defineStore } from 'pinia'
import { ShowResult, SubCon } from '@/components/form-list-group/types';
export default defineStore('woManagement', {
    state: () => {
        // @ts-ignore
        // let globalSearchShowResult: ShowResult[] = null;
        // let path: string = '';
        let rows: any[] = [];
        return {
            /** 当前路径 */
            path: '',
            /** 当前表格选中行 */
            rows,
            /** 当前 全局searchbar 要显示的结果数据 */
            globalSearchShowResult: null
        }
    },
    getters: {

    },
    actions: {
        /** 通过传的arr和obj设置显示的结果 */
        setShowResultByArrAndObj(arr: SubCon[], obj: any, config: ShowResult[], path: string) {
            this.path = path;
            this.rows = Array.isArray(obj) ? obj : [obj];
            // if (!arr.length || !config.length) return;
            let value: any[] = config.map(x => {
                if (x.isArray) {
                    return {
                        text: arr.map(y => `${y.Code} - [${y.Percent}]`).join(', '),
                        color: x.color
                    }
                    // @ts-ignore
                } else if (x.key == 'CancellOrder' && obj[x.key] === true) {
                    return {
                        text: 'Cancelled Order',
                        color: 'red'
                    }
                } else if (x.key == 'BDRCNo' && obj[x.key]) {
                    return {
                        text: 'BDRC',
                        color: x.color
                    }
                }
                if (obj) {
                    return {
                        text: obj[x.key || ''],
                        color: x.color
                    }
                } else return {
                    text: '',
                    color: x.color
                }
            });
            // @ts-ignore
            this.globalSearchShowResult = value;
            return true
        },
        /** 通过传obj设置显示的结果 只有edit时用 */
        updataShowResultByRow(obj: any) {
            this.rows = Array.isArray(obj) ? obj : [obj];
            
            // @ts-ignore
            this.globalSearchShowResult.map(x => {
                if (x.color == 'red') {
                    // @ts-ignore
                    x.text = obj['CancellOrder'] ? 'Cancelled Order' : false;
                }
            });

        },
        clear() {
            // @ts-ignore
            this.globalSearchShowResult = [];
            this.path = '';
            this.rows = [];
        }
    },
})
