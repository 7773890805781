import { copyProp } from '@/utile';
import { ColumnDef } from '../ag-table/types/column';
import { FormlyFieldGroup } from '../form/types';
import { Api_List } from './types/api_handle';
import { CommonTableFormHttp, CommonTableFormPageConfig, FormSelectConfing, HttpParamsMap, ParamsMap } from './types/types';
import { FileType } from './components/Upload';
/**
 * tab属性
 * @property    tabs    tab属性数组
 * @property    gobalSearchShowText    TAB间全局搜索 共享 显示的文字 对象 数组
 */
export class TabConfig {
    tabs: TabProps[] = [];
    /** TAB间全局搜索 共享 显示的文字 对象 数组 */
    gobalSearchShowText?: ShowTextObj[] = [];
}
/**
 * tab属性
 * @property    key tab标识符
 * @property    tab tab显示名称
 * @property    hasGobalSearchBar   是否有全局搜索框
 * @property    body    tab内容
 * @property    formFields    主表单字段
 * @property    gobalSearchShowText    TAB间全局搜索 共享 显示的文字 对象 数组
 * @property    httpResultMap HTTP接口 返回值 映射关系
 * @property    new_default_obj 
 */
export class TabProps {
    //  /** new 操作时，要给表单的 默认值 */
    new_default_obj?: Object = {};
    /** tab标识符 */
    // formItemData_Pid?: string = 'QID'
    key: string = '';
    /** tab显示名称 */
    tab: string = '';
    /** 是否有全局搜索框 */
    hasGobalSearchBar?: boolean;
    width?: string;
    class?: string;
    /** tab内容 */
    body?: RowStructure[] = [];
    /** 表单字段集 */
    fields?: FormlyFieldGroup[] = [];
    /** 搜索弹窗 的 表单字段集 */
    queryModalFields?: FormlyFieldGroup[] = [];
    globalSearhBarFields?: FormlyFieldGroup[] = [];
    globalSearhBarLabelWidth?: string;
    /** 表单下拉框映射关系
     *  @property key: string
     *  @property mapKey: string
     *  @property type: 'string' | 'ID_Description'
     */
    formSelectConfing?: FormSelectConfing[] = [];
    /** 表格字段集 */
    columnDefs?: ColumnDef[] = [];
    /** http参数映射关系
     * @property init   key: string, mapKey: string
     * @property getRow     key: string, mapKey: string
     * @property getList    key: string, mapKey: string
     * @property add    key: string, mapKey: string
     * @property edit   key: string, mapKey: string
     * @property del    key: string, mapKey: string
     */
    httpParamsMap?: HttpParamsMap = new HttpParamsMap;
    /**
     * HTTP接口 返回值 映射关系
     */
    httpResultMap?: HttpResultMap = new HttpResultMap;
    /** http接口 
     * @property init
     * @property getRow
     * @property getList
     * @property add
     * @property edit
     * @property del
     */
    http?: CommonTableFormHttp = new CommonTableFormHttp;
    /** 页面配置 
     * @property labelWidth : string 
     * @property cardTitle : string 
     * @property formPrimaryKey : string 
     * @property formPrimaryKeyEmptyValue   : string   
     * @property isVirtualFormPrimaryKey     表单主键是否 虚拟主键   @description 是虚拟主键，前端用 虚拟主键 维护 增删改 
     */
    pageConfig?: FormListGroupPageConfig = new FormListGroupPageConfig;
    queryModalFormSearchHttpMap?: {
        key: string, httpMap: ParamsMap[],
    }[]
    constructor(obj?: TabProps) {
        obj && copyProp.apply(this, [obj]);
    }
}
/**
 * Api 请求完成后，处理结果配置
 * @property target :  FormItemData' | 'List' | 'CustomForm'|'CustomFrom_s';
 * @property fields : tring | string[] | ResultMap[]; 
 */
export interface Api_handle_result {
    /** 要处理，额外操作 的组件对象 */
    target: 'FormItemData' | 'List' | 'CustomForm' | 'CustomFrom_s' | 'PartList' | 'CollapseForm' | 'saveMenuMetaData_Permission';
    /** 要处理，额外操作 的 接口返回对象中 的 属性名 */
    fields?: string | string[] | ResultMap[];
}

/**
<<<<<<< HEAD
 * @description 下拉时 改变 表单 的 字段 定义
 */
export interface Select_change_form_fields {
    key: string;
    /** 值 */
    value: any,
    /** value 对应  当前下拉项 值， fields 对应 当前下拉项 值 对应 的 字段*/
    fields: any,
}
/** 
 * Api 请求前，要进行的操作 (一般,从InitData里取东西)
 * @property field : string ; 
 * @property source : initData ; 
 * @property sourceField : string ; 
 */

export interface Api_handle_before {
    field: string;
    source: 'initData';
    sourceField: string;

}
/**
 * @property firstApi : 'init' | 'getList'  进页面，第一个调的接口
 * @property hasUpdataGlobalSearchPermission : boolean  是否有更新全局搜索的权限
 * @property updataActionType : 'ListSelectChange' | 'OnSearch'  更新全局搜索 的操作类型
 */
export class FormListGroupPageConfig extends CommonTableFormPageConfig {
    /** 进页面，第一个调的接口 */
    firstApi?: 'init' | 'getList';
    /** 发起init的类型。如果是Form，由Form发启init,如果是List，由List发启init */
    initApiType?: 'Form' | 'List';
    /** init后，是否 getList */
    init_then_getList?: boolean;
    /** init 接口，是否要处理，额外的操作 */
    initApi_handle_result?: boolean;
    /** 跳转 页面指定位置的class*/
    Jump_page_location_class?: string
    /** 弹出 编辑 图片 对话框 的 列 字段 */
    edit_photo_column_field?: string;

    /** init 接口，要处理，额外操作 的组件对象 */
    initApi_handle_result_target?: 'FormItemData' | 'List' | 'CustomForm' | 'CustomFrom_s' | 'PartList' | 'UserManagement';
    /** init 接口，要处理，额外操作 的 接口返回对象中 的 属性名 */
    initApi_handle_result_Fields?: string | string[] | ResultMap[];
    initApi_handle_result_InitData?: boolean
    /**init 接口，要处理，额外操作 是否不需要获取表格数据 */
    initApi_handle_result_noneed_setdata?: boolean;
    /**init 接口，要处理，额外操作 获取modal的select */
    initApi_handle_result_getmodal_slele?: boolean;
    /** newRow，额外的操作 */
    newRow_handle_before?: Api_handle_result;
    /**
     * 添加点击add操作之前对参数进行筛选
     */
    newRow_before_select?: boolean;
    /**epxort 下载前是否对参数进行筛选  */
    epxort_before_select?: boolean;
    /** 需要筛选的链接路径*/
    // epxort_before_select_http?: 'ReportPath'
    /**del,额外的操作 */
    delApi_handle_result?: Api_handle_result

    /** 下拉时 改变 表单 的 字段 定义, 映射关系 */
    select_change_form_fields_map?: Select_change_form_fields[]
    /** add 接口，是否要处理，额外的操作 */
    /** add 接口，调用前，额外的操作 */
    addApi_handle_before?: Api_handle_before[];
    /** 已经有 addApi_handle_result，addApi_handle_result_target ，addApi_handle_result_Fields 这3个字段处理，add后的操作，但是要3个字段。用这个字段，可以代替这3个字段 */
    addApi_handle_after?: Api_handle_result;
    onSelApi_handle_after?: any;
    editApi_handle_result?: Api_handle_result;

    edit_before_fields?: any;
    addApi_handle_result?: boolean;
    /** add 接口，要处理，额外操作 的组件对象 */
    addApi_handle_result_target?: 'FormItemData' | 'CustomForm';
    /** add 接口，要处理，额外操作 的 接口返回对象中 的 属性名 */
    addApi_handle_result_Fields?: string;

    addApi_before_select?: boolean;
    addApi_select_befotr_select?: any;

    /** 是否有更新全局搜索的权限 */
    hasUpdataGlobalSearchPermission?: boolean;
    canSaveConfig?: ButtonControlledConfig;
    /**  更新全局搜索 的操作类型 */
    updataActionType?: 'ListSelectChange' | 'OnSearch' | 'Form';
    /** 全局搜索 显示结果 规则配置 */
    searchBarShowResultConfig?: ShowResult[];
    /** 全局搜索 显示结果 依赖的 额外数组 名字 */
    searchBarShowResultExtraArrName?: string;
    /** 表单 有的字段 需要转换成其它字段，用来显示 的数组 */
    transformFieldsArr?: ParamsMap[];
    transformValueArr?: ParamsMap[];
    /** 通过已选条件打开对应记录时 传参的映射关系 */
    searchBarOpenMap?: ParamsMap[];
    /**  搜索 后 是否 再 init */
    search_then_init?: boolean;
    /**  搜索 后 是否 再 getList */
    search_then_getList?: boolean;
    /** 有 FormItemData */
    hasFormItemData?: boolean;
    formItemData_Pid?: string = 'QID'
    /** FormItemData 数据 在  getRow 接口 里  的 对应字段名 */
    formItemData_in_row_fields?: string;
    ImportType?: string;
    /** 表单默认值 */
    defaultModel?: any;
    queryModalDefault?: any;
    hasQueryModel_addDefailt?: Object
    /**newVersion从表单拿取的值 */
    newVersion_pickKeys?: (string | { key: string, fix: any })[]
    /**
    * 请求接口前的操作（映射关系）
    */
    api_before?: Api_List;
    /**
     * 请求接口后的操作（映射关系）
     */
    api_after?: Api_List;
    new_default_obj_s?: Object = {};
    /** 列定义规则  用于 动态生成列定义 */
    columnDefRules?: ColumnDefRule;
    /**动态图表 */
    dynamicEchartEcharts?: boolean;
    headerHeight?: number;
    groupHeight?: number;
    /** 弹框配置 */
    ModalParams?: ModalParams;
    /** 关闭弹框后调用getrow刷新表格*/
    refreshmodel_getrow?: boolean
    del_ignore_role?: boolean;
    /**不需要调用gitlist接口排序 */
    NoNeed_gitList_sort?: boolean
    /**confirm校验 */
    confirmverify?: string[] = []
    /** 如果登录名为login或者当前用户，数据才能修改*/
    edit_del_loginname?: boolean;
    /**本身是require的 */
    confirmverifyRequire?: string[] = []
    /**form 选项联动 */
    need_Option_linkage?: boolean;

    hasContractNoRepDistrict?: boolean;
    /**form 选项联动arr 参数 
     * Pa Ch Ch_f t_f*/
    Option_linkage_field_arr?: any;
    /**add后，如果当前数据没有实体那一条，就把实体加入到rowdata中，
     * 作用就是供后面选择表格行，调getrow接口
     *  */
    addEntity2Data?: boolean;
    /**是否需要双击点开modal*/
    need_DoubleClicked_row_modal?: boolean;

    /**修改后更新数据是否有不需要更新表格的数据 从onselectchange取 */
    // edit_updatRow_userowdata?: boolean;
    /**不需要更新表格的key */
    // noneed_updata_rowkey?: string[] = [];

    /**执行修改前对参数进行筛选 */
    editApi_before_select?: boolean;
    /**执行修改前对参数进行筛选 参数 */
    editApi_before_select_key?: any

    /** 修改后筛选tabledata展示其他 */
    edithand_select_tabledata_showother?: boolean
    /**summary 根据这个单独配置x，y */
    chart?: any
    /**summary Summary的十大安全关注，只显示十大的数据*/
    selectRowdata?: boolean
    showzero?: boolean
    hiddenzero?: boolean
    need_DoubleClicked_row_open_part1?: boolean

    setControlledFn?: (fields: FormlyFieldGroup[], isDisabled: boolean) => void
    constructor(obj?: FormListGroupPageConfig) {
        super(obj)
        obj && copyProp.apply(this, [obj]);
    }
}
/**
 * 按钮 受控 状态（disabled） 配置
 */
export interface ButtonControlledConfig {
    target: 'List';
    controlledType: 'length';
}
export class ShowResult {
    key?: string;
    text?: string;
    color?: string;
    backgroundColor?: string;
    order?: number;
    isArray?: boolean;
}
export class SubCon {
    Code: string = '';
    ID: string = '';
    Percent: number = 0;
}
/**
 * TAB间全局搜索 共享 显示的文字 对象
 * @property key    关键字
 * @property text   显示的主文字
 * @property subText    显示的 副 文字
 * @property separator  主文字和副文字分隔符
 * @property subTextPrefix  副文字前缀
 * @property subTextSuffix  副文字后缀
 * @property textColor  主文字颜色
 * @property subTextColor  副文字颜色
 */
export class ShowTextObj {
    /** 关键字 */
    key: string = '';
    /** 显示的主文字 */
    text: string = '';
    /** 显示的 副 文字 */
    subText?: string = '';
    /** 主文字和副文字分隔符 */
    separator?: string = '-';
    /** 副文字前缀 */
    subTextPrefix?: string = '[';
    /** 副文字后缀 */
    subTextSuffix?: string = ']';
    /** 主文字颜色 */
    textColor?: string = '#555';
    /** 副文字颜色 */
    subTextColor?: string = '#555';
}


/**
 * row结构
 * @description 横向布局 最多3例。
 * @description 1.如果只有一列。这一列占100%宽度
 * @description 2.如果有一列以上。每一列占比相加 == 100%宽度;第一列的占比可以是任意正数。
 * @property flex1  组件配置
 * @property flex2? 组件配置
 * @property flex3? 组件配置
 */
export class RowStructure {
    /** 组件配置 */
    flex1?: ComponentConfig = new ComponentConfig;
    /** 组件配置 */
    flex2?: ComponentConfig;
    /** 组件配置 */
    flex3?: ComponentConfig;
    class?: string;
    gutter?: number;

    need_collapse?: boolean = false;
    /**折叠面板header文字 */
    show?: string;
    openshow?: string;

    constructor(obj?: RowStructure) {
        obj && copyProp.apply(this, [obj]);
    }
}
export interface MQ {
    isDark: boolean;
    isInert: boolean;
    isLandscape: boolean;
    isLight: boolean;
    isMotion: boolean;
    isPortrait: boolean;
    lg: boolean;
    lgMinus: boolean;
    lgPlus: boolean;
    md: boolean;
    mdMinus: boolean;
    mdPlus: boolean;
    sm: boolean;
    smMinus: boolean;
    smPlus: boolean;
    xl: boolean;
    xlMinus: boolean;
    xlPlus: boolean;
    xs: boolean;
    xxl: boolean;
    current: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
    motionPreference: string;
    orientation: string;
    theme: string;
}

/**
   * @description 各尺寸屏幕下所占栅格
   * @result :xs="2" :sm="4" :md="6" :lg="8" :xl="10" :xxxl="10"
   * @props offset
   * @props xs
   * @props sm
   * @props md
   * @props lg
   * @props xl
   * @props xxl
   * @props xxxl
   * @props minWidth  最小宽度，最大宽度
   * @props maxWidth  最小宽度，最大宽度
*/
export interface Responsive {
    minWidth?: string;
    maxWidth?: string;
    offset?: number;
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    xxl?: number;
    xxxl?: number;
}
/**
 * 组件配置
 * @property flexProportionNum  所占flex比例number
 * @property component  component配置
 */
export class ComponentConfig {
    /** 所占flex比例number 如果1行只有1个组件。这个数会被忽略。无论这个数是什么 */
    flexProportionNum?: number = 1;
    /** component配置 */
    // @ts-ignore
    component: ComponentBase = null;
    class?: string;
    /**
      * @description 各尺寸屏幕下所占栅格 或者数字 默认24（占一行）
      * @props xs
      * @props sm
      * @props md
      * @props lg
      * @props xl
      * @props xxl
      * @props xxxl
      * @props minWidth  最小宽度，最大宽度
      * @props maxWidth  最小宽度，最大宽度
    */
    responsive?: Responsive | number = 24;
    constructor(obj?: ComponentConfig) {
        obj && copyProp.apply(this, [obj]);
    }
}

export type ComponentType = 'PartIIIIList' | 'PartIIIList' | 'PartIIList' | 'ListModal' | 'CotReportfrom' | 'ShowPhoto'
    | 'BtnsGroup' | 'Form' | 'List' | 'PaginationList' | 'QueryDialog'
    | 'Card' | 'DocumentsList' | 'FormListGroup' |
    'Preview' | 'ProjectPhoto' | 'Upload' | 'Torrent';

/**
 * @property type 
 * @property isCardWrap 
 * @property isShowGlobalSearchResult 
 * @property cardTitle 
 * 
 */
export class ComponentBase {
    type?: ComponentType;
    /** 外面是否包了一层Card */
    isCardWrap?: boolean = false;
    align?: 'left' | 'right' = 'left';
    class?: string;
    /** 是否在Card里 显示 搜索结果 */
    isShowGlobalSearchResult?: boolean = false;
    cardTitle?: string = '';
}

export type ActionType = '' | 'SaveReportAs' | 'PenaltyRecord_ExportExcel' | 'SelExportPDF' | 'ExportPDF' | 'SelectTen' | 'ViewTen' | 'Save' | 'Reset' | 'Delete' | 'Query' | 'QueryModal' | 'Add' | 'New' | 'ExportExcel' | 'AIP' | 'DDA' | 'Toggle' | 'ImportExcel' | 'ExportXML' | 'Back' | 'Clear' | 'Cancel' | 'Search';
export type BtnType = '' | 'primary' | 'dashed' | 'text' | 'link';
export class FormListGroup extends ComponentBase {
    type?: ComponentType = "FormListGroup";
    options: TabProps = new TabProps;
    constructor(obj?: FormListGroup) {
        super();
        obj && copyProp.apply(this, [obj]);
    }
}
export class QueryDialog extends ComponentBase {
    type?: ComponentType = "QueryDialog";
    btnText?: string = '搜索';
    // labelWidth?: string = '120px';
    title?: string = '搜索';
    permissionType?: 'Read' | 'Create' | 'Delete' | 'Enquiry' | 'Report' | 'Update';
    okBtnText?: string = '搜索';
    constructor(obj?: QueryDialog) {
        super();
        obj && copyProp.apply(this, [obj]);
    }
}

/**
 *  HTTP接口 返回值 映射关系
 */
export class HttpResultMap {
    init?: ResultMap[] = [];
    getRow?: ResultMap[] = [];
    getList?: ResultMap[] = [];
    add?: ResultMap[] = [];
    edit?: ResultMap[] = [];
    del?: ResultMap[] = [];

    editByList?: ResultMap[] = [];
    deleteByList?: ResultMap[] = [];
    constructor(obj?: HttpResultMap) {
        obj && copyProp.apply(this, [obj]);
    }
}

/**
 * @property key    真正要传的字段名
 * @property mapKey 目标对象上的映射字段名
 * @property type   'ID_Description[]' | 'string[]' | 'Object[]' | 'Object'
 * @property objectProperty 如果是Object 应该取的 Object属性名
 */
export interface ResultMap {
    /** 真正要传的字段名 */
    key: string;
    /** 目标对象上的映射字段名 */
    mapKey: string;
    /** 
     * @description ID_Description[] 数组
     * @description string[]    数组
     * @description Object[]  数组
     * @description Object  普通对象
     */
    type: 'ID_Description[]' | 'string[]' | 'Object[]' | 'Object' | 'combination_string';
    /** 当 type == combination_string 时，要取的 固定值  */
    combination_fix_value?: any;
    /** 如果是Object 应该取的 Object属性名 */
    objectProperty?: any;
}

/**
 * @property type BtnsGroup
 * @property btns   Btn[] = []
 * @property isCardWrap 外面是否包了一层Card
 * @property isShowGlobalSearchResult 是否在Card里 显示 搜索结果
 */
export class BtnsGroup extends ComponentBase {
    type?: ComponentType = "BtnsGroup";
    formSelectConfing?: FormSelectConfing[] = [];
    fields?: FormlyFieldGroup[] = [];
    btns: (Btn | QueryDialog | Form)[] = [];
    constructor(obj?: BtnsGroup) {
        super();
        obj && copyProp.apply(this, [obj]);
    }
}
/**
 * @property text
 * @property btnType    '' | 'primary' | 'dashed' | 'text' | 'link';
 * @property actionType '' | 'Save' | 'Reset' | 'Delete' | 'Query' | 'QueryModal' | 'Add' | 'New' | 'AIP' | 'DDA';
 * @property size
 * @property isDanger
 */
export class Btn {
    text: string = '';
    /** '' | 'primary' | 'dashed' | 'text' | 'link'; */
    btnType?: BtnType = '';
    /**　权限类型 */
    permissionType?: 'Read' | 'Create' | 'Delete' | 'Enquiry' | 'Report' | 'Update';
    /** ''|'SelExportPDF'|'ExportPDF'|'SelectTen'|'ViewTen' | 'Save' | 'Reset' | 'Delete' | 'Query' | 'QueryModal' | 'Add' | 'New' | 'AIP' | 'DDA'; ExportExcel | Toggle | ImportExcel | ExportXML | Clear | Cancel | Search*/
    actionType: ActionType = '';
    aliasActionType?: ActionType;
    /** 按钮操作 附带的值 一般 和 actionType = Toggle 时用 */
    attachedValue?: any;
    /** 按钮操作 激活状态 对应的表单字段 一般 和 actionType = Toggle 时用 */
    form_active_field?: any;
    /** 当 btnType == Toggle | 时，有用。 指定 操作的对象  */
    actionTarget?: 'Form' | 'List' = 'Form';
    size?: string = 'small';
    isDanger?: boolean = false;
    title?: string = '';
    /**SelExportPDF */
    opconfig?: any;
    /**不是普通权限按钮或者按钮不需要权限单独配置 */
    NotBtn?: boolean;
    class?: string = '';
    style?: string | object = ''
    dirDisabled?: (m: boolean) => boolean;
    fileType?: FileType[] = [];

    fnName?: string

    ref?: string
    with_formDisabled?: boolean
    constructor(obj?: Btn) {
        obj && copyProp.apply(this, [obj]);
    }
}
/**
 * @property isPrimaryForm 是否主表单
 * 
 */
export class Form extends ComponentBase {
    size?: 'large' | 'default' | 'small';
    type?: ComponentType = "Form";
    /** 是否主表单 */
    isPrimaryForm?: boolean = false;
    fields?: FormlyFieldGroup[] = [];
    model?: any;
    labelWidth?: string;
    /**折叠面板内save */
    btns?: any;
    /**折叠面板header文字 */
    show?: string;
    openshow?: string;
    /** new 操作时，要给表单的 默认值 */
    new_default_obj?: Object = {};
    width?: string;
    gutter?: any;
    layout?: 'vertical' | 'inline'
    need_collapse?: boolean = false;
    /**需要不同的布局，summary */
    need_Different_layouts?: boolean;
    /**打开和关闭折叠面板heard文字一样时使用 */
    text?: string;
    refname?: string = '';
    options?: any;
    header_need_changetext?: boolean = true

    isShowtitle?: boolean
    showoptions?: any;
    // @ts-ignore
    Btnslot?: ComponentBase = null;
    BtnSlotClass?: string;
    // @ts-ignore
    titleBtnslot?: ComponentBase = null;
    titleBtnSlotClass?: string;
    /**flex 布局下的垂直对齐方式 */
    GroupTemplateAlign?: 'top' | 'middle' | 'bottom'
    style?: any
    isPart1?: boolean;
    has_MarkAsInactiveCheckbox?: boolean;
    noMarginBottom?: boolean
    constructor(obj?: Form) {
        super();
        obj && copyProp.apply(this, [obj]);
    }
}
export class List extends ComponentBase {
    type?: ComponentType = "List";
    columnDefs?: ColumnDef[] = [];
    tableHeight?: string;
    title?: string;
    autoHeight?: boolean;
    firstApi?: 'init' | 'getList';
    tabelHeaderHeight?: number;
    /** 抑制单击选择 */
    suppressRowClickSelection?: boolean = true;
    /** http参数映射关系
     * @property init   key: string, mapKey: string
     * @property getRow     key: string, mapKey: string
     * @property getList    key: string, mapKey: string
     * @property add    key: string, mapKey: string
     * @property edit   key: string, mapKey: string
     * @property del    key: string, mapKey: string
     */
    httpParamsMap?: HttpParamsMap = new HttpParamsMap;
    /**
     * HTTP接口 返回值 映射关系
     */
    httpResultMap?: HttpResultMap = new HttpResultMap;
    /** http接口 
     * @property init
     * @property getRow
     * @property getList
     * @property add
     * @property edit
     * @property del
     */
    http?: CommonTableFormHttp = new CommonTableFormHttp;
    pageConfig?: FormListGroupPageConfig = new FormListGroupPageConfig;
    /** 是否排序 */
    isOrder?: boolean;
    orderField?: string;
    /** true 降序  ;  false  升序 */
    isDesc?: boolean;

    /** 是否固定排序 */
    isFixedOrder?: boolean;
    fixedOrder_rules?: FixedOrder[];


    /** 默认是否要过滤，配合BtnGroup 的 toggle使用 */
    defaultFilter?: boolean;
    defaultFilter_value?: any;
    defaultFilter_field?: any;

    formSelectConfing?: FormSelectConfing[];
    /** 弹框配置 */
    ModalParams?: ModalParams;
    collapse_heard_text?: string
    need_collapse?: boolean;

    isShowtitle?: boolean
    showoptions?: any
    constructor(obj?: List) {
        super();
        obj && copyProp.apply(this, [obj]);
    }
}
export class ListModal extends ComponentBase {
    type?: ComponentType = "ListModal";
    columnDefs?: ColumnDef[] = [];
    tableHeight?: string;
    title?: string;
    autoHeight?: boolean;
    firstApi?: 'init' | 'getList';
    tabelHeaderHeight?: number;
    /** 抑制单击选择 */
    suppressRowClickSelection?: boolean = true;
    /** http参数映射关系
     * @property init   key: string, mapKey: string
     * @property getRow     key: string, mapKey: string
     * @property getList    key: string, mapKey: string
     * @property add    key: string, mapKey: string
     * @property edit   key: string, mapKey: string
     * @property del    key: string, mapKey: string
     */
    httpParamsMap?: HttpParamsMap = new HttpParamsMap;
    /**
     * HTTP接口 返回值 映射关系
     */
    httpResultMap?: HttpResultMap = new HttpResultMap;
    /** http接口 
     * @property init
     * @property getRow
     * @property getList
     * @property add
     * @property edit
     * @property del
     */
    http?: CommonTableFormHttp = new CommonTableFormHttp;
    pageConfig?: FormListGroupPageConfig = new FormListGroupPageConfig;
    /** 是否排序 */
    isOrder?: boolean;
    orderField?: string;
    /** true 降序  ;  false  升序 */
    isDesc?: boolean;

    /** 是否固定排序 */
    isFixedOrder?: boolean;
    fixedOrder_rules?: FixedOrder[];


    /** 默认是否要过滤，配合BtnGroup 的 toggle使用 */
    defaultFilter?: boolean;
    defaultFilter_value?: any;
    defaultFilter_field?: any;

    formSelectConfing?: FormSelectConfing[];
    /** 弹框配置 */
    ModalParams?: ModalParams;
    constructor(obj?: ListModal) {
        super();
        obj && copyProp.apply(this, [obj]);
    }
}
/** 弹框配置 */
interface ModalParams {
    // @ts-ignore
    photo?: TabProps;
    initData?: any;
    [prop: string]: TabProps;
}

interface FixedOrder {
    order: number,
    field: any,
    color?: any;
}



/** 
 * 列定义规则  用于 动态生成列定义 
 * @property titleFields    在接口返回值中，保存表头的字段(TitleList) 如果有多级，用.隔开
 * @property before ColumnDef[];    固定在前面的列定义
 * @property columnDefs ColumnDef[]; 动态生成的部分
 * @property isGroup        动态生成的部分 是否是 分组的
 * @property groupRule  7String
 * @property after  ColumnDef[];    固定在后面的列定义
*/
export interface ColumnDefRule {
    /** 在接口返回值中，保存表头的字段  (TitleList) 如果有多级，用.隔开 */
    titleFields?: string;
    /** 固定在前面的列定义 */
    before?: ColumnDef[];
    /** 动态生成的部分 */
    columnDefs?: ColumnDef[];
    /** 动态生成的部分 是否是 分组的 */
    isGroup?: boolean;
    /** 7String => 前面7个字符串相同 分为一组; */
    groupRule?: '7String';
    /** 适用所有动态生成的，规则 是一样的。 不然，不能用, 
     * @example '${groupName} 違規次數'
     * */
    groupName?: string;
    /** 空或0 时， 要处理的操作 */
    nullObject?: {
        /** 占位符 */
        placeholder: string;
        headColumn: string[];
        cellClass?: string[];
    }
    /** 固定在后面的列定义 */
    after?: ColumnDef[];
}
export class PaginationList extends ComponentBase {
    type?: ComponentType = "PaginationList";
    hasPagination?: boolean = true;
    suppressRowClickSelection?: boolean;
    /** 表格高度自适应 */
    autoHeight?: boolean;
    /**判断是否添加图表 */
    ischart?: boolean = false;
    btns?: any;

    listCardClass?: string;

    pageConfig?: FormListGroupPageConfig = new FormListGroupPageConfig;

    columnDefs?: ColumnDef[] = [];
    /** 弹框配置 */
    ModalParams?: ModalParams;

    // @ts-ignore
    Btnslot?: ComponentBase = null;
    BtnSlotClass?: string;
    rowClass?: string;
    rowHeight?: number;
    // @ts-ignore
    paginLeftBtnslot?: ComponentBase = null;
    paginLeftBtnslotClass?: string;
    titleClass?: string;
    title?: string;
    titleStyle?: string;
    /**抑制字段点表示法 */
    suppressFieldDotNotation?: boolean;
    defaultColDef?: any
    isFrontSort?: boolean = false;
    constructor(obj?: PaginationList) {
        super();
        obj && copyProp.apply(this, [obj]);
    }
}

export class PartIIList extends ComponentBase {
    type?: ComponentType = "PartIIList";

    pageConfig?: FormListGroupPageConfig = new FormListGroupPageConfig;

    columnDefsC?: ColumnDef[] = [];
    columnDefsD?: ColumnDef[] = [];

    rowDatakeyC?: any;
    rowDatakeyD?: any;
    /** 弹框配置 */
    ModalParams?: ModalParams;
    text?: string;
    http?: CommonTableFormHttp = new CommonTableFormHttp;
    titleC?: any;
    titleD?: any;

    GridTitleC?: any;
    GridTitleD?: any;
    PartIIIIDSelectOptions?: any

    btn?: any;
    op1?: any;
    op2?: any;
    modal1?: any;
    modal2?: any;
    httpParamsMap?: any;
    formoption: any;
    formSelectConfing: any;
    PartGroupC?: any;
    PartGroupD?: any;

    PartIIBSelectOptions?: any
    checkbox_list?: any;
    select_list?: any;
    TC_list?: any;
    APBS_list?: any;
    constructor(obj?: PartIIList) {
        super();
        obj && copyProp.apply(this, [obj]);
    }
}

export interface Part {
    columnDefs?: ColumnDef[];
    getRowDataKey?: string;
    httpKey?: string;
    title?: string;
    gridTitle?: string;
}
export interface PartModal {
    fields?: FormlyFieldGroup[];

}
export class PartIIIList extends ComponentBase {
    type?: ComponentType = "PartIIIList";

    formSelectConfing?: FormSelectConfing[];
    pageConfig?: FormListGroupPageConfig = new FormListGroupPageConfig;
    fields?: FormlyFieldGroup[] = [];
    collapsetext?: string
    http?: CommonTableFormHttp = new CommonTableFormHttp;
    /** http参数映射关系
     * @property init   key: string, mapKey: string
     * @property getRow     key: string, mapKey: string
     * @property getList    key: string, mapKey: string
     * @property add    key: string, mapKey: string
     * @property edit   key: string, mapKey: string
     * @property del    key: string, mapKey: string
     */
    httpParamsMap?: HttpParamsMap = new HttpParamsMap;
    arrkey?: any;
    btn?: any;

    Part1?: {
        key?: string,
        component?: Part,
        modal?: PartModal
    }
    Part2?: {
        key?: string,
        component?: Part,
        modal?: PartModal
    }
    constructor(obj?: PartIIIList) {
        super();
        obj && copyProp.apply(this, [obj]);
    }
}
export class PartIIIIList extends ComponentBase {
    type?: ComponentType = "PartIIIIList";

    pageConfig?: FormListGroupPageConfig = new FormListGroupPageConfig;

    text?: string;
    http?: CommonTableFormHttp = new CommonTableFormHttp;
    /** http参数映射关系
    * @property init   key: string, mapKey: string
    * @property getRow     key: string, mapKey: string
    * @property getList    key: string, mapKey: string
    * @property add    key: string, mapKey: string
    * @property edit   key: string, mapKey: string
    * @property del    key: string, mapKey: string
    */
    httpParamsMap?: HttpParamsMap = new HttpParamsMap;
    fields?: FormlyFieldGroup[] = [];
    formSelectConfing: FormSelectConfing[] = [];

    arrkeys?: any
    constructor(obj?: PartIIIIList) {
        super();
        obj && copyProp.apply(this, [obj]);
    }
}

/**
 * 分隔 卡片
 */

export class SubsectionCard extends ComponentBase {
    type?: ComponentType = "Card";
    text: string = '';

    cname?: 'showtext';
    constructor(obj?: SubsectionCard) {
        super();
        obj && copyProp.apply(this, [obj]);
    }
}


/**
 * Documents List
 */

export class DocumentsList extends ComponentBase {
    type?: ComponentType = "DocumentsList";
    text?: string = 'Documents';
    accept?: string;
    /** Documents 要用的下载选项 */
    selectOptions?: Object = {};
    /** Documents 要用的下载选项 映射关系 */
    selectOptionsMap?: FormSelectConfing[] = [];
    saveAllBtnText?: string = 'Save All';
    deleteAllBtnText?: string = 'Delete All';
    /** 要显示在New按钮边的下拉项 */
    showSelects?: string[] = [];
    columnDefs?: ColumnDef[] = [];
    tableHeight?: string;
    /**
     * 批量操作  批量保存时，传给后端的 List 字段名
     */
    batchEditList_params_fields_name?: string = 'DocumentList';
    /**
     * 批量操作  批量删除时，传给后端的 List 字段名
     */
    batchDeleteList_params_fields_name?: string = 'DocumentList';
    /** 抑制单击选择 */
    suppressRowClickSelection?: boolean = true;
    /** http参数映射关系
     * @property init   key: string, mapKey: string
     * @property getRow     key: string, mapKey: string
     * @property getList    key: string, mapKey: string
     * @property add    key: string, mapKey: string
     * @property edit   key: string, mapKey: string
     * @property del    key: string, mapKey: string
     */
    httpParamsMap?: HttpParamsMap = new HttpParamsMap;
    /**
     * HTTP接口 返回值 映射关系
     */
    httpResultMap?: HttpResultMap = new HttpResultMap;
    /** http接口 
     * @property init
     * @property getRow
     * @property getList
     * @property add
     * @property edit
     * @property del
     */
    http?: CommonTableFormHttp = new CommonTableFormHttp;
    pageConfig?: FormListGroupPageConfig = new FormListGroupPageConfig;
    firstApi?: 'init' | 'getList';
    constructor(obj?: DocumentsList) {
        super();
        obj && copyProp.apply(this, [obj]);
    }
}

/**
 * @property key
 * @property text
 * @property value
 * @property fromArray
 */
export class TotalInfo {
    key?: string;
    text?: any;
    value?: any;
    fromArray?: 'rowData' = 'rowData';
    color?: string = 'red';
    constructor(obj?: TotalInfo) {
        obj && copyProp.apply(this, [obj]);
    }
}

export class ShowPhoto
    extends ComponentBase {
    type?: ComponentType = "ShowPhoto";
    http?: CommonTableFormHttp = new CommonTableFormHttp;
    constructor(obj?: ShowPhoto) {
        super();
        obj && copyProp.apply(this, [obj]);
    }
}
export class Preview extends ComponentBase {
    type?: ComponentType = "Preview";

    value?: any;
    height?: any;
    actionTarget?: string;
    attachedValue?: any;
    hasUpload?: boolean = true;
    fullscreenTarget?: string;
    fullscreenDownTargets?: string[];
    textFontSize?: string;
    showIcon?: boolean;
    http?: CommonTableFormHttp = new CommonTableFormHttp;
    style?: string;
    titleClass?: string;
    title?: string;
    titleStyle?: string;
    constructor(obj?: Preview) {
        super();
        obj && copyProp.apply(this, [obj]);
    }
}
export class Torrent extends ComponentBase {
    type?: ComponentType = "Torrent";

    value?: any;
    height?: any;
    actionTarget?: string;
    attachedValue?: any;
    hasUpload?: boolean = true;
    fullscreenTarget?: string;
    fullscreenDownTargets?: string[];
    textFontSize?: string;
    showIcon?: boolean;
    http?: CommonTableFormHttp = new CommonTableFormHttp;
    style?: string;
    titleClass?: string;
    title?: string;
    titleStyle?: string;
    constructor(obj?: Torrent) {
        super();
        obj && copyProp.apply(this, [obj]);
    }
}

export class ProjectPhoto extends ComponentBase {
    type?: ComponentType = "ProjectPhoto";

    http?: CommonTableFormHttp = new CommonTableFormHttp;
    style?: string;

    constructor(obj?: ProjectPhoto) {
        super();
        obj && copyProp.apply(this, [obj]);
    }
}
export class Upload extends ComponentBase {
    type?: ComponentType = "Upload";

    http?: CommonTableFormHttp = new CommonTableFormHttp;
    style?: string;

    height?: any;
    fontSize?: any = '80px';
    icon?: string = 'upload-cloud';
    multiple?: boolean = true;
    fileType?: FileType[];
    actionTarget?: string;
    attachedValue?: any;
    ignoreDisabled?: boolean;
    actionType?: ActionType[];
    aliasActionType?: ActionType;
    /**满足所有actiontype条件 */
    everyActionType?: boolean = false;

    dropDesc?: string = 'Drop files to upload';
    dropSubDesc?: string = 'or clickto pick manually';
    openFileDialogOnClick?: boolean = true;
    /** 多选上传时，是否 只调一次api */
    multiple_once_api?: boolean = false;

    btn?: Btn
    constructor(obj?: Upload) {
        super();
        obj && copyProp.apply(this, [obj]);
    }
}
