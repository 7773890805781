import { computed, defineComponent, h, ref } from "vue"
import { Form } from "../types"
type CheckboxChangeEvent = Event & {
    target: {
        checked?: boolean;
    };
};
export default defineComponent({
    props: {
        model: {
            type: Object,
            default: {}
        },
        form: {
            type: Form
        }
    },
    setup(p) {
        const disabled = ref(false);
        const isCheck = computed({
            get() {
                return p.model?.['IsCancel'];
            },
            set(val) {
                p.model['IsCancel'] = !!val;
            }
        })
        const onChange = (e: CheckboxChangeEvent) => {
            isCheck.value = e?.target?.checked;
        }
        const setRowData = (arr: any) => {
            disabled.value = !!arr;
        }
        const isCostZero = computed(() => {
            //暂时不用控制是不是0，都放开就行了 
            return true
            let b = p.model?.['Cost'] === 0;
            // 不是0，取消勾选
            if (!b) {
                p.model['IsCancel'] = false
            }
            return b;
        })

        return {
            isCheck, onChange, setRowData, disabled, isCostZero
        }
    },
    render() {
        return (
            <div>
                {
                    h(
                        <a-checkbox
                            v-slots={{
                                default: () => <p class='user-select-none'>Mark as Inactive</p>
                            }}
                        />,
                        {
                            checked: this.isCheck,
                            disabled: this.disabled,
                            // 外部传入disabled为优先
                            // 外部传入是false，判断cost是0才放开
                            // disabled: this.disabled ? true : this.isCostZero ? false : true,
                            onChange: this.onChange,
                            class: 'margin-top-10',
                        },
                    )
                }
            </div>
        )
    }
})
