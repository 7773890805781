import verifyPhoto from "@/pipe/verifyPhoto";
import { patchValue } from "../../form";
import { Api_handle_result_map } from "../types/map";
/**
 * 请求接口前的操作
 */
export const handel_api_before = (o: any, arr: Api_handle_result_map[], refs: any, fn?: any) => {
    arr.map(x => {
        if (x.fn) {
            if (x.fn == 'filter') {
                let obj: any = {};
                x.filter?.map(y => {
                    obj[y] = o[y];
                });
                fn(x.fn, obj);
            } else {
                fn(x.fn, x);
                x.fn2 && fn(x.fn2, x);
            }
        } else {
            if (x.targetType == 'Form') {
                let Form = refs[x.targetRef || '']
                Form.value.reset(o);
            } else if (x.targetType == 'List') {
                let list = refs[x.targetRef || '']
                o[x.fields || ''] = list.value.data.rowData;
            }
        }
    })
}

/**
 * 请求接口后的操作
 */
export const handel_api_after = (o: any, arr: Api_handle_result_map[], refs: any, fn?: any,) => {
    arr.map(x => {
        if (x.api) {
            if (x.api == 'getRow' && fn) {
                if (x.targetType) {
                    x.targetType == 'Form' && fn(`${x.targetType.toLowerCase()}.getRow_form`); // 在useList里调 useForm 里的 getRow_form
                } else {
                    fn(`getRow_form`); // 直接调useForm 里的 getRow_form
                }
            }
            if (x.api == 'init' && fn) {
                if (x.targetType) {
                    x.targetType == 'Form' && fn(`${x.targetType.toLowerCase()}.init_form`); // 在useList里调 useForm 里的 init_form
                } else {
                    fn(`init_form`); // 直接调useForm 里的 init_form
                }
            }
        } else if (x.fn) {
            if (x.attach) {
                fn(x.fn, x.attach);
                x.fn2 && fn(x.fn2, x.attach);
            } else {
                fn(x.fn, x);
                x.fn2 && fn(x.fn2, x);
            }
        } else {
            if (x.targetType == 'List') {
                let list = refs[x.targetRef || '']
                if (x.fields) {
                    let arr: any[] = eval(`o.${x.fields}`);
                    try {
                        list.value.setRowData(arr,);
                    } catch (e) {
                        setTimeout(() => {
                            list.value?.setRowData(arr,);
                        }, 300);
                    }
                } else {
                    try {
                        list.value.setRowData([]);
                    } catch (e) {
                        setTimeout(() => {
                            list.value?.setRowData([]);
                        }, 300);
                    }
                }
            } else if (x.targetType == 'Form') {
                let Form = refs[x.targetRef || ''];
                if (x.fields) {
                    let obj: any;
                    if (x.fields === 'use_o') {
                        obj = o
                    } else {
                        obj = eval(`o.${x.fields}`)
                    }
                    if (x?.attachArr) {
                        x.attachArr?.map(b => {
                            if ('key' in b && 'map' in b) {
                                Object.assign(obj, { [b.map]: obj?.[b.key] })
                            }
                        })
                    }

                    patchValue(Form.value.model, obj);
                } else {
                    Form.value?.reset();
                }

            } else if (x.targetType == 'Preview') {
                let PreviewRef: any = refs[x.targetRef || ''];
                let path = verifyPhoto(getData(o, x));

                if (x?.attachValue && (typeof x?.attachValue == 'object')) {
                    let attach = x.attachValue;
                    if ('reg' in attach) {
                        // 是mp4就通过这个处理成视频,否则先把这个清空,然后通过pdf处理
                        if (attach.reg?.test(path)) {
                            PreviewRef.value?.setRowData(null);
                            PreviewRef = refs[attach?.targetRef || ''];
                        } else {
                            refs[attach?.targetRef || ''].value?.setRowData(null);
                        }
                    }
                }

                if (x.fields) {
                    if (/\./.test(x.fields)) {
                        PreviewRef.value?.setRowData(path);
                    } else {
                        try {
                            getData(o, x) ? PreviewRef.value?.setRowData(path) : PreviewRef.value?.setRowData();
                        } catch (error) {
                            PreviewRef.value?.setRowData(getData(o, x));
                        }
                    }
                } else {
                    PreviewRef.value?.setRowData(null);
                }
            } else if (x.targetType == 'I_Upload') {
                let UploadRef: any = refs[x.targetRef || ''];
                setTimeout(() => {
                    UploadRef.value?.click();
                }, 0);
            } else if (x.targetType == 'MarkAsInactiveCheckbox') {
                let c = refs[x.targetRef || '']
                if (x.fields) {
                    let arr: any[] = eval(`o.${x.fields}`);
                    try {
                        c.value.setRowData(arr,);
                    } catch (e) {
                        setTimeout(() => {
                            c.value?.setRowData(arr,);
                        }, 300);
                    }
                } else {
                    try {
                        c.value.setRowData(x?.attachValue);
                    } catch (e) {
                        setTimeout(() => {
                            c.value?.setRowData(x?.attachValue);
                        }, 300);
                    }
                }
            }
        }
    })
}
const getData = (o: any, x: Api_handle_result_map) => {

    if (x.fields) {
        return eval(`o.${x.fields}`)
    }
}
