import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import useStore from '@/store/metaData';
const loadingEnd = () => {
    const store = useStore();
    store.screenLoading = false;
    
    let l = store.lastOperationTime;
    let n = new Date();
    if (n.valueOf() - new Date(l).valueOf() < 1800000) store.lastOperationTime = n;
    else store.lastOperationTime = l;
}
export const handleError = (err: { toJSON?: any, response: AxiosResponse }, isLoading: boolean = false): string => {
    isLoading && loadingEnd();
    if (!err.response) {
        // @ts-ignore
        return err.message;
    } else if (err.response.data && err.response.data.hasOwnProperty('msg')) {
        return err.response.data.msg;
    }
    return ''
}

/**
   * 处理http请求拿到的结果,如果启用了全屏加载，就关闭
   * @param res http请求拿到的结果
   */
export const handleResult = (res: AxiosResponse, isLoading: boolean, cb: Function, cb2: Function): any => {
    isLoading && loadingEnd();

    if (res.data instanceof Blob) {
        // @ts-ignore
        if (res.size < 300) {
            // cb(res)
            console.log(res);
        } else {
            cb2(res.data)
            // return res.data
        }
        // fileToJson(res, (data: DownloadBlobErrorResult) => {

        //   // a.next({
        //   //   status: data.code,
        //   //   statusText: data.msg.title
        //   // })
        // })
        // const reader = new FileReader()
        // reader.onerror = err => {
        // } // 失败回调
        // reader.readAsText(new Blob([res]), 'utf-8') // 按照utf-8编码解析
        // reader.onload = async (res: any) => {
        //   console.log(res);

        //     const result = res.target.result // 得到字符串
        //     const data = JSON.parse(result) // 解析成json对象
        //     // callback(data);
        //     return data;
        // }
        // console.log(11);

        // let a = bindCallback(reader.onload)(reader as any);
        // a.subscribe(x => {

        // })

    } else {
        if (res.data.code != 200) {
            cb(res)
            return res;
        } else {
            cb2(res.data.result);
        }
    }
}

export const showStatus = (status: number) => {
    let message = ''
    switch (status) {
        case 400:
            message = '请求错误(400)'
            break
        case 401:
            message = '未授权，请重新登录(401)'
            break
        case 403:
            message = '拒绝访问(403)'
            break
        case 404:
            message = '请求出错(404)'
            break
        case 408:
            message = '请求超时(408)'
            break
        case 500:
            message = '服务器错误(500)'
            break
        case 501:
            message = '服务未实现(501)'
            break
        case 502:
            message = '网络错误(502)'
            break
        case 503:
            message = '服务不可用(503)'
            break
        case 504:
            message = '网络超时(504)'
            break
        case 505:
            message = 'HTTP版本不受支持(505)'
            break
        default:
            message = `(${status})!`
    }
    return `${message}`
}