import { CloseOutlined, SaveOutlined } from "@/antd-icon";
import AgGrid from "@/components/ag-table";
import actionCellRenderer from "@/components/ag-table/actionCellRenderer";
import edit_cellRendererComponent from '@/components/ag-table/edit_cell';
import { AgGridRowEvent, GridEvent } from "@/components/ag-table/types/grid";
import Card from "@/components/form-list-group/components/card";
import { FormlyFieldGroup } from "@/components/form/types";
import useStore from '@/store/metaData';
import { ID_Description } from "@/types";
import { GridApi } from "ag-grid-community";
import { computed, defineComponent, inject, reactive, ref, watch } from "vue";
import PhotoEditer from '../../../photo-editer';
import { ListResult } from "../../types/types";
import FormListGroupService from '../../use/service';
import { M_Grid } from "./mobile/documents_m";
import { crud } from "./useFn";

export interface AgGridRowEvent_e extends AgGridRowEvent {
    currentAction: string;
    currentField: string;
    file: { file: File; fileList: File[] }
}

export default defineComponent({
    components: { Card, AgGrid, actionCellRenderer, edit_cellRenderer: edit_cellRendererComponent, PhotoEditer },
    props: ['options', 'id',],
    emits: ['closemodal'],
    setup(p, { emit }) {
        let store = useStore();
        let model = reactive<any>({});
        let formRef = ref();
        let tableRef = ref();
        const photoRef = ref()
        let api: GridApi = new GridApi;
        const data = reactive({
            rowData: [],
            fields: [],
            Pstatus: false,
            api,
        });
        let canSave = ref<boolean>(false);
        let canDelete = ref<boolean>(false);
        const canNew = computed(() => p.id?.length == 1 ? false : !!p.id);
        const onSelectionChanged = (e: any[]) => {
            canSave.value = !!e.length;
            canDelete.value = !!e.length;
        }
        const GetStstus = (e: any) => {
            if (e?.['ReportStatus'] == "Completed") {
                data.Pstatus = true
            } else {
                if (store.userName?.toUpperCase() == e?.['CreateBy']?.toUpperCase() || store.userName?.toUpperCase() == 'admin'?.toUpperCase()) {
                    data.Pstatus = false
                } else {
                    data.Pstatus = true
                }
            }
        }
        const setRowData = (arr: any) => {
            data.rowData = arr;
            sizeColumnsToFit()
        }
        const init = () => {
            if (p.id?.length == 1) {
                data.rowData = []
                // setRowData([])
                canSave.value = false
                canDelete.value = false
                return
            }
            let obj = { ID: p.id, LoginName: store.userName };
            p.options.http?.init && p.options.http?.init(obj).then((x: { TableData: ListResult }) => {
                setForm(x);
                // data.rowData = x.TableData.Data;
                setRowData(x.TableData.Data)

                canSave.value = false
                canDelete.value = false
            })
        }
        const setForm = (x: any) => {
            let arr: FormlyFieldGroup[] = [];
            // 把 Documents 要用的下拉选项。保存到对应 的Documents里
            p.options.selectOptionsMap?.map((c: any) => {

                let options: any[] = [];
                if (c.type == 'ID_Description') {
                    options = x[c.mapKey].map((a: ID_Description) => ({ label: a.Description, value: a.ID }))
                } else if (c.type == 'string') {
                    options = x[c.mapKey].map((a: string) => ({ label: a, value: a }))
                }
                // @ts-ignore
                p.options.selectOptions[c.key] = options;
                arr.push({
                    fieldGroup: [
                        { key: c.key, type: 'Select', templateOptions: { required: true, span: 4, options, importantErrorText: 'Please select !' } },
                    ]
                })
            });
            data.fields = arr as any;
            p.options.selectOptions && (FormListGroupService.selectOption = p.options.selectOptions);
        }
        const getList = () => {
        }
        const newDocuments = () => {
            formRef.value.submit()
        }
        const formChange = (e: { key: string, value: string }) => {
            FormListGroupService.$selectChange.next(e);
        }
        const { savePhoto, iconfn, cellAction, saveList, deleteList, add, downloadlist, deleteList_m, saveList_m } = crud(p, { model, data, tableRef, canSave, canDelete, setRowData, photoRef });
        watch(p, () => {
            if (p.id) {
                init()
                // if (p.options.firstApi) {
                //     if (p.options.firstApi == 'getList') getList();
                //     else if (p.options.firstApi == 'init') init();
                // }
            }
        })

        const onGridReady = (params: GridEvent) => {
            data.api = params.api;
        }
        const sizeColumnsToFit = () => {

            setTimeout(() => {
                try {
                    data.api?.sizeColumnsToFit();
                } catch (error) {
                }
            }, 300);
        }
        const closeModal = () => {
            emit('closemodal',)
        }
        return {
            canNew, canSave, canDelete,
            data, formRef, model, tableRef, GetStstus, onGridReady, iconfn, closeModal, deleteList_m, saveList_m, savePhoto, photoRef,
            init, getList, formChange, cellAction, saveList, deleteList, newDocuments, add, onSelectionChanged, downloadlist
        }
    },
    render() {
        const getcol = this.options.columnDefs.filter((e: any) => e.headerName == "動作")[0]
        const getdisable = getcol.cellRendererParams.btns.filter((e: any) => e.action !== 'Download' && e.action !== 'Rotate')
        if (getdisable) {
            if (this.data.Pstatus) {
                getdisable.map((a: any) => a.need_disable = true)
            } else {
                getdisable.map((a: any) => a.need_disable = false)
            }
        }
        const isMobile: any = inject('isMobile')

        const W = () => <>
            <a-row class='title'>
                <a-col xxl={4} xl={4} xs={24}>
                    <div ><b style='font-size:20px;white-space: nowrap;'>主要項目照片</b></div>
                </a-col>
                <a-col xxl={20} xl={20} xs={24}>
                    <div style={{ textAlign: 'end' }}  >
                        <a-button
                            class="margin-right-15 ant-btn-gray"
                            size='small'
                            icon={<i class="iconfont icon-zhuanfa" style={'font-size:14px;margin-right:9px'}></i>}
                            onClick={this.newDocuments} disabled={this.data.Pstatus || !this.canNew}
                        >新增</a-button>
                        <b-form
                            fields={this.data.fields}
                            model={this.model}
                            layout='inline'
                            // noMarginBottom
                            onChange={this.formChange}
                            // class='margin-left-5'
                            ref="formRef"
                            class='hide'
                            onFinish={this.add}
                        >
                        </b-form>
                        <a-button class="ant-btn-yellow  margin-right-15" size="small" type="primary" icon={<SaveOutlined />}
                            onClick={this.saveList}
                            disabled={this.data.Pstatus || !this.canSave}
                        >全部儲存</a-button>
                        <a-button class='margin-right-15 ant-btn-red' size="small" type="primary" icon={<CloseOutlined />}
                            onClick={this.deleteList}
                            disabled={this.data.Pstatus || !this.canDelete}
                        >全部刪除</a-button>
                        <a-button class=" ant-btn-cyan" size="small" type="primary" icon={<i class="iconfont icon-xiazai1" style={'font-size:14px;margin-right:9px'}></i>}
                            onClick={this.downloadlist}
                            disabled={!this.canNew}>下載相片</a-button>
                    </div>
                </a-col>
            </a-row>
            <div style={'margin:20px'}>
                <ag-grid
                    ref={'tableRef'}
                    style={{ height: this.options.tableHeight || '300px' }}
                    class="ag-theme-alpine"
                    columnDefs={this.options.columnDefs}
                    rowData={this.data.rowData}
                    rowSelection="multiple"
                    suppressRowClickSelection={this.options.suppressRowClickSelection}
                    on_cellMethod={this.cellAction}
                    // on_rowClicked={(e: any) => console.log(e)}
                    // on_sortChanged={this.onSortChanged}
                    on_selectionChanged={this.onSelectionChanged}
                    on_gridReady={this.onGridReady}
                >
                </ag-grid>
            </div>
        </>
        const M = () => <>
            <a-row class='title'>
                <a-col xxl={4} xl={4} xs={24}>
                    <div
                        style={{ marginBottom: '10px' }}
                    ><b style='font-size:20px;white-space: nowrap;'>主要項目照片</b></div>
                </a-col>
                <a-col xxl={20} xl={20} xs={24}>
                    <div style={{ textAlign: 'left' }}  >
                        <a-button
                            class="margin-right-15 ant-btn-gray"
                            size='small'
                            icon={<i class="iconfont icon-zhuanfa" style={'font-size:14px;margin-right:9px'}></i>}
                            onClick={this.newDocuments} disabled={this.data.Pstatus || !this.canNew}
                            style={{ marginBottom: '10px' }}
                        >新增</a-button>
                        <b-form
                            fields={this.data.fields}
                            model={this.model}
                            layout='inline'
                            // noMarginBottom
                            onChange={this.formChange}
                            // class='margin-left-5'
                            ref="formRef"
                            class='hide'
                            onFinish={this.add}
                        >
                        </b-form>
                        <a-button class="ant-btn-yellow  margin-right-15" size="small" type="primary" icon={<SaveOutlined />}
                            onClick={this.saveList_m}
                            disabled={this.data.Pstatus || !this.canNew}
                        >全部儲存</a-button>
                        <a-button class='margin-right-15 ant-btn-red' size="small" type="primary" icon={<CloseOutlined />}
                            onClick={this.deleteList_m}
                            disabled={this.data.Pstatus || !this.canNew}
                        >全部刪除</a-button>
                        <a-button class=" ant-btn-cyan" size="small" type="primary" icon={<i class="iconfont icon-xiazai1" style={'font-size:14px;margin-right:9px'}></i>}
                            onClick={this.downloadlist}
                            disabled={!this.canNew}
                        >下載相片</a-button>
                    </div>
                </a-col>
            </a-row>

            <div style={{ margin: '20px 0 0' }}>
                <M_Grid
                    ref="tableRef"
                    rowData={this.data.rowData}
                    _this={this}
                />
            </div>
        </>
        return (
            <>
                <div class={this.options.class}>
                    {
                        isMobile.value ? M() : W()
                    }
                    <PhotoEditer ref={`photoRef`} onSave={this.savePhoto}></PhotoEditer>
                </div>
            </>
        )
    }
})

