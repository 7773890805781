import { CheckOutlined, CloseOutlined, RightOutlined } from "@/antd-icon";
import DownloadService from '@/http/download';
import verifyPhoto from '@/pipe/verifyPhoto';
import useStore from '@/store/metaData';
import { ref, watchEffect } from 'vue';
import { ProjectPhoto } from "../../types";
import { data, EngineeringPhoto, T_ProjectPhoto } from './type';


export const useTemp = (_this: T_ProjectPhoto) => {
    const customStyle = `
    background: #f7f7f7;
    border-radius: 4px;
    margin-top: 10px;
    overflow: hidden`;

    const editBtn = <a-button
        size="small"
        type="primary"
        class='margin-right-20 ant-btn-yellow '
        icon={<i class="iconfont icon-edit" style={'font-size:14px;margin-right:9px'}></i>}
        onClick={_this.onEdit}
        disabled={!_this.isCan}
    >編輯
    </a-button>;

    const downBtn = <a-button
        size="small"
        type="primary"
        class='ant-btn-cyan'
        icon={<i class="iconfont icon-xiazai1" style={'font-size:14px;margin-right:9px'}></i>}
        disabled={!_this.isCan}
        onClick={_this.onDown}
    >全部下載相片
    </a-button>;

    const noBtn = <a-button
        size="small"
        type="primary"
        class={['margin-right-20 ', _this.data.selNo ? 'ant-btn-red' : 'un-no-sel']}
        icon={<CloseOutlined />}
        disabled={!_this.isCan}
        onClick={_this.onNo}
    >違規
    </a-button>;

    const yesBtn = <a-button
        size="small"
        type="primary"
        class={[_this.data.selYes ? 'ant-btn-green' : 'un-yes-sel']}
        icon={<CheckOutlined />}
        disabled={!_this.isCan}
        onClick={_this.onYes}
    >沒有違規
    </a-button>;

    const collapse = <a-collapse
        v-model:activeKey={_this.data.collapse_activeKey}
        bordered={false}
        style="background: rgb(255, 255, 255)"
        class='project-photo-collapse'
        expandIconPosition='right'
        ghost={true}
        onChange={(key: string[]) => {
            _this.data.collapse_activeKey = key
        }}
        v-slots={{
            expandIcon: ({ isActive }: any) => <RightOutlined rotate={isActive ? -90 : 90} />,
        }}
    >
        {
            _this.data.rows?.length ? _this.data.rows.map((x, i) => {
                return <a-collapse-panel
                    key={_this.data.panelKey + i}
                    forceRender={true}
                    style={customStyle}
                    v-slots={{
                        header: () => {
                            const colors: any = {
                                // '0': '#4040cc',
                                '0': '#6ad13e',
                                '1': '#d63434',
                                '2': '#6ad13e',
                            }
                            return <span style={{ color: colors[x?.Status || '0'], fontWeight: 'bold' }}>{`${x.Code ? (x.Code + '-') : ''} ${x.Description ?? ''} (${x.Photo})`}</span>
                        }
                    }}
                >
                    <a-row gutter={20}>
                        {getColPhoto(x.Photos)}
                    </a-row>
                </a-collapse-panel>
            }) : ''
        }
    </a-collapse>;
    const getColPhoto = (list: EngineeringPhoto[]) => {
        return list.map(x => {
            return <>
                <a-col span='12'>
                    <div class='photo-col'>
                        <img class={'point'} v-viewer src={verifyPhoto(x?.FilePath)} />
                    </div>
                    <div class='photo-text'>
                        {x.PhotoDescription}
                    </div>
                </a-col>
            </>
        })
    }
    return {
        editBtn, downBtn, noBtn, yesBtn, collapse
    }
}
export const useClick = (_this: any, o: { data: data, options: ProjectPhoto }) => {
    const store = useStore();

    const onEdit = () => {
        let formPrimaryKey = _this.options.pageConfig?.formPrimaryKey || 'ID';
        let model = _this.formRef?.model;
        let ID = model[formPrimaryKey];
        let modalRef = _this.modalRef;
        modalRef?.open(ID);
        setTimeout(() => {
            //   当选中一条数据后再点击这个字段,不会调用onSelectionChanged ,所以要在这里打开
            modalRef.formListGroupRef?.listmodalRef.setSelect(_this.photo_modal.modal_slelect_option, _this.data.getRowEntity || _this.model)
            modalRef.formListGroupRef?.listmodalRef.GetId_init(model)
            modalRef.formListGroupRef?.documentRef.GetStstus(model)
        });
    }
    const selectRow = () => {
        o.data.rows = o.data._rows?.filter(x => {
            if (o.data.selNo) {
                if (o.data.selYes) {
                    return true
                } else {
                    return x.Status === '1'
                }
            } else {
                if (o.data.selYes) {
                    return x.Status !== '1'
                } else {
                    return false
                }
            }
        });
    }
    const onNo = () => {
        o.data.selNo = !!!o.data.selNo;
        selectRow();
    }
    const onYes = () => {
        o.data.selYes = !!!o.data.selYes;
        selectRow();
    }
    const onDown = () => {
        let formPrimaryKey = _this.options.pageConfig?.formPrimaryKey || 'ID';
        let model = _this.formRef?.model;
        let ID = model[formPrimaryKey];

        let obj = { ID, LoginName: store.userName }
        o.options.http?.toZipAll && o.options.http?.toZipAll(obj).then((x: any) => {
            DownloadService.downloadFileByUrl(x.ExcelPath);
        });
    }
    return {
        onEdit, onDown, onNo, onYes
    }
}
export const useDisabled = (_this: any,) => {
    const store = useStore();

    const isCan = ref(true);
    watchEffect(() => {
        let canDel = _this.isSel;
        isCan.value = canDel
    })

    return {
        isCan
    }
}