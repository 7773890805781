import { CaretDownOutlined, FullscreenExitOutlined, FullscreenOutlined } from "@/antd-icon";
import { v4 as uuid } from 'uuid';
import { defineComponent, reactive } from "vue";
import { ProjectPhoto } from "../../types";
import "./index.scss";
import { data, EngineeringPhoto, Item } from "./type";
import { useClick, useDisabled, useTemp } from './useData';

export default defineComponent({
    components: {
        FullscreenOutlined,
        FullscreenExitOutlined, CaretDownOutlined
    },
    props: {
        option: {
            default: () => new ProjectPhoto
        },
        _this: {
            type: Object
        },
    },
    setup(p) {
        const data = reactive<data>({
            /**展示的数据 */
            rows: [],
            /**init数据，用于筛选 */
            _rows: [],
            panelKey: uuid().replace(/-/g, ""),
            /**当前展开的key */
            collapse_activeKey: [],
            selNo: true,
            selYes: true,
        })
        const setRowData = (value: any) => {
            let EngineeringPhotoList: EngineeringPhoto[] = value.EngineeringPhotoList;
            let ItemList: Item[] = value.ItemList ?? [];
            if (!EngineeringPhotoList) {//当图片没有返回
                // 计算图片数量
                let Photo = ItemList?.reduce((arr: number, cur: { Photo: number; }) => {
                    return arr + (cur?.Photo ?? 0)
                }, 0);
                // 有图片数量，不用更新了
                if (Photo) {
                    return
                } else {
                    EngineeringPhotoList = []
                };
            }
            //匹配图片
            ItemList?.map(x => {
                x.Photos = EngineeringPhotoList?.filter(y => x.ID === y.FID);
                return x
            })
            // 排序 红色在前
            ItemList = ItemList?.filter(x => x.Status !== '0')?.sort((a, b) => (+a.Status) - (+b.Status));

            data.rows = ItemList;
            data._rows = ItemList;

            // 初始全部打开
            data.collapse_activeKey = Array.from({ length: data.rows?.length }, (x, i) => `${data.panelKey}${i}`);
            // 滚到顶部
            let el = document.querySelector('.project-photo-collapse')
            el?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

            // 默认全选中
            data.selNo = true;
            data.selYes = true;
        }


        const useClicks = useClick(p._this, { data, options: p.option });
        const useDisableds = useDisabled(p._this);
        return {
            setRowData,
            data,
            ...useClicks,
            ...useDisableds,
        }
    },
    render() {
        let c = this.option;

        const { editBtn, downBtn, noBtn, yesBtn, collapse } = useTemp(this);

        return (
            <a-row class='card-list padding-20 project-photo'>
                <a-col lg={10} xs={24} style='font: bold 18px / 23px Arial; color:#565656 '>
                    工程相片
                </a-col>
                <a-col lg={14} xs={24} class='text-right margin-bottom-10'>
                    {editBtn}
                    {downBtn}
                </a-col>
                <a-col span={24} class='margin-bottom-10'>
                    {noBtn}
                    {yesBtn}
                </a-col>
                <a-col span={24}>
                    {collapse}
                </a-col>
            </a-row>
        )
    }
})