import { defineComponent, reactive, ref, inject } from "vue";
import http from "./http";
import storage from "../../share/storage.service";
import { useRouter } from "vue-router";
import useStore from "@/store/metaData";
import { LoginFields } from "./form.fields";
import Mobile_Login from './index_m.vue'
import Web_Login from './index_w.vue'

export default defineComponent({
    components: {
        Mobile_Login,
        Web_Login,
    },
    setup() {
        const model = reactive({});
        const store = useStore();
        const fields = LoginFields();
        let router = useRouter();

        const formRef = ref();
        const onFinish = (values: any) => {
            store.$reset();

            http.login(values).then((x: any) => {
                store.lastOperationTime = new Date();
                // store.setUserName(values.Name);
                toLogin(x);
            });
        };
        const getUrl = (): string => {
            let url = "/InTray/MyInTray";
            let first = store.menu.find(x => x.Read);
            if (first) {
                first.isActive = true;
                url = first.FirstMenuRoute;
            }
            return url;
        };
        const toLogin = (menuMetaData: any) => {
            store.setUserName(menuMetaData.profile.name);

            storage.setMenuMetaData(menuMetaData);
            store.saveMenuMetaData(menuMetaData);
            store.saveMenuPermission(menuMetaData.Permission.MenuPermission);
            router.push(getUrl());
        };
        let name = `ArchSD Works Order System`
        let warning1 = `Safety 網站所載的全部內容及資料， 包括但不限於所有數據、網站的美術設計、瀏覽連繫及軟件程式，均屬祥興建造有限公司所有，且受版權保護。任何人士如未獲祥興事先給予書面許可，一律禁止轉載、發放或擅用本網站的任何內容或資料。閣下或閣下之任何代表人，均不得將文件印刷、複印、複製、分發、傳送、上傳、下載、儲存、向公眾展示、變更或更改。除非獲祥興事先以書面許可，否則閣下不得使用或允許他人使用本公司之圖標、網址或以任何方式將本網頁之任何分頁與其他互聯網頁作連線網頁。 對文件進行的任何未經授權下載、再傳送、復印、更改或用於上述範圍以外的用途，均屬違法。`;;
        let warning2 = `在成功登入服務後，請勿離開電腦擱置操作。當離開電腦時或使用完畢後，切記即時按「退出」離開；`;
        let warning3 = `Safety software that is made available to use on the Site (the "Software") is the copyrighted work of Cheung Hing Construction Company Limited ( "Cheung Hing"), and that all intellectual property rights (which for the purposes of these General Terms and Conditions shall mean intellectual property rights of whatever nature whether registered or unregistered including but not limited to data, trademarks, trade names, copyright, patent, designs, goodwill and rights in confidential information, inventions and know-how and any associated or similar rights (the "Intellectual Property Rights") in the Software belong to "Cheung Hing". Registered User must not, nor should it allow anyone (including the Employees) to, copy, reproduce, decompile, disassemble, modify, reverse engineer, attempt to discover the source code, sell, assign, sub-license, grant a security interest or transfer any right in or otherwise tamper with any of the Software.`;
        let warning4 = `After successfully logging in to the service, please do not leave the computer to suspend the operation. When leaving or after using the computer, remember to click "Logout" to leave immediately`;;
        let warning5 = `Login Account`
        let data = reactive({
            logo: require("@/assets/logo.svg"),
            name,
            warning1,
            warning2,
            warning3,
            warning4,
            warning5
        });

        const reset = () => {
            formRef.value.reset();
        }

        return {
            formRef,
            model,
            fields,
            onFinish,
            data,
            reset,
        };
    },
    render() {
        const isMobile: any = inject('isMobile')
        return (
            <>
                {
                    isMobile.value ? <Mobile_Login _this={this} /> : <Web_Login _this={this} />
                }
            </>
        );
    },
});
