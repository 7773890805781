import { ID_Description } from "@/types";
import { handleDate } from "@/utile";
import dayjs from 'dayjs';
import { ListModal } from "../../types";



type Prop = {
    options: ListModal,
    id: string
}
type ItemOption = {
    Code: string;
    Description: string;
    Cost: number;
    MainItem: boolean;
    OrderNo: number;
    ContractorNo?: any;
    SafeItem?: any;
    Performance: string;
    VersionNoID: number;
    VersionNoShow?: any;
    IsCancel?: any;
    Group?: any;
    Ver: number;
    EffectiveFrom: string;
    EffectiveTo?: any;
    SearchDescription: string;
    SearchCode: string;
    SearchGroup?: string;
    SearchEffectiveFrom: any;
    SearchEffectiveTo?: any;
}


export default (prop: Prop, obj: { data: any, initData: any, row: any }) => {
    const { data, initData, row } = obj

    try {
        prop.options.formSelectConfing?.map((y: any) => {
            let arr: any = [];
            if (y.type == 'string') {
                arr = initData[y.mapKey].map((z: string) => ({ label: z, value: z }))
            } else if (y.type == 'ID_Description') {
                arr = initData[y.mapKey].map((z: ID_Description) => ({ label: z.Description, value: z.ID }))
            }
            if (y.key == 'selectListOption') {
                type ItemOption = {
                    EffectiveFrom: string, Code: string,
                    PPEffectiveEndDate: string,
                    PPEffectiveStartDate: string,
                    IsCancel: boolean
                }
                let opt: ItemOption[] = initData[y.mapKey].map((z: any) => ({ ...z, label: `${z.Code}-${z.Description || ''}`, value: `${z.Performance}-${z.Description}`, Performance: z.Performance, Description: z.Description }));

                try {
                    let date = row?.['InspectionDate'] ?? '';
                    if (date) {
                        // 根据Code分组 和 筛选符合时间区间
                        let opts = opt?.reduce((arr: Map<string, ItemOption[]>, c) => {
                            // 默认不需要判断
                            let isAfter = true;
                            if (c?.PPEffectiveStartDate) {
                                // 有start需要判断在start后
                                isAfter = dayjs(date).isAfter(dayjs(c.PPEffectiveStartDate))
                            }
                            // 默认不需要判断
                            let isBefore = true;
                            if (c?.PPEffectiveEndDate) {
                                // 获取到最后一秒
                                let end = dayjs(c.PPEffectiveEndDate).endOf('day')
                                // 有end需要判断在end后
                                isBefore = dayjs(date).isBefore(end);
                            }
                            // 时间必须这区间内才需要显示
                            if (isAfter && isBefore) {
                                let o = arr.get(c.Code);
                                let arrs = o ? [...o, c] : [c];
                                // 排序
                                arrs.sort((a, b) => dayjs(a.EffectiveFrom).isAfter(dayjs(b.EffectiveFrom)) ? 1 : -1)
                                arr.set(c.Code, arrs);
                            }
                            return arr
                        }, new Map());

                        let arr = [];
                        // 遍历每一个数组
                        for (let v of opts.values()) {
                            // 找到符合的一条
                            let find = v.find((n, i, obj) => {
                                // 在这一条后
                                let isAfter = dayjs(date).isAfter(dayjs(n.EffectiveFrom));
                                // 有下一条
                                if (obj[i + 1]) {
                                    // 在下一条前
                                    let isBefore = dayjs(date).isBefore(dayjs(obj[i + 1].EffectiveFrom))
                                    return isBefore && isAfter
                                }
                                return isAfter
                            })
                            // 有并且IsCancel是false就添加进数组
                            if (find && !find.IsCancel) {
                                arr.push(find);
                            }
                        }
                        // 根据code排序
                        arr.sort((a, b) => a.Code > b.Code ? 1 : -1)
                        opt = arr;
                    }

                    data.selectListOption = opt;
                } catch (error) {

                }
            }
            data.selectConfing[y.key] = arr;
        })

    } catch (error) {

    }

    try {
        return
        // 前端不验证了，不需要获取10大
        let o = { InspectionDate: row?.['InspectionDate'] ?? null }
        o = handleDate(o)
        prop.options.http?.Get10Default && prop.options.http?.Get10Default(o).then((x: ItemOption[]) => {
            data.MainItemList_Code = x.reduce((arr, y) => {
                return [...arr, y.Performance]
            }, [] as string[])
        })
    } catch (error) {

    }
}