import { RollbackOutlined, SaveOutlined } from "@/antd-icon";
import AgGrid from "@/components/ag-table";
import edit_cellRendererComponent from '@/components/ag-table/edit_cell';
import Card from "@/components/form-list-group/components/card";
import useStore from '@/store/metaData';
import { setrowselect } from "@/utile/table";
import { GridApi } from "ag-grid-community";
import { defineComponent, inject, reactive, ref } from "vue";
import actionCellRenderer from "../../../ag-table/actionCellRenderer";
import { AgGridRowEvent, GridEvent } from "../../../ag-table/types/grid";
import Modal from "../modal";
import { curd } from "./useFn";
import useSelect from "./useSelect";



export interface AgGridRowEvent_e extends AgGridRowEvent {
    currentAction: string;
    currentField: string;
    file: { file: File; fileList: File[] }
}
export default defineComponent({
    components: { Card, AgGrid, edit_cellRenderer: edit_cellRendererComponent, actionCellRenderer, Modal },
    props: ['options', 'id',],
    emits: ['change'],
    setup(prop, { emit }) {
        let store = useStore();
        const primaryKey = prop.options.pageConfig?.formPrimaryKey as string;
        const tableRef = ref();
        let rowData: any[] = [];
        let initData: any[] = [];
        let selectConfing: any = {};
        const selectListOption: any[] = []
        const model: any = reactive({})
        let MainItemList_Code: any[] = []

        let api: GridApi = new GridApi;
        let data = reactive({
            rowData,
            initData,
            selectConfing,
            selectListOption,
            MainItemList_Code,
            Pstatus: false,
            api,

        });
        const canSel = ref(false)
        const selectvalue = ref()

        const ClearData = () => {
            selectvalue.value = null
            canSel.value = false
            // 清空选中的那条的model
            Object.keys(model).map(key => {
                delete model[key]
            })
        }
        //获取 处理 数据
        const GetId_init = async (e: any) => {
            // 打开modal时清空表格，下拉
            data.rowData = []
            emit('change', ' ')
            ClearData()

            if (e?.['ReportStatus'] == "Completed") {
                data.Pstatus = true
            } else {
                if (store.userName?.toUpperCase() == e?.['CreateBy']?.toUpperCase() || store.userName?.toUpperCase() == 'admin'?.toUpperCase()) {
                    data.Pstatus = false
                } else {
                    data.Pstatus = true
                }
            }

            // 获取数据
            const id = e.ID
            let obj = { ID: id, LoginName: store.userName };
            prop.options.http?.getRow && await prop.options.http?.getRow(obj).then((x: any) => {
                data.initData = x.Entity.ItemList
                setdata();
            })
            tableRef.value?.clearSelect();
        }
        const setSelect = (initData?: any, row?: any) => {
            useSelect(prop, {
                data, initData, row
            })
        }
        const setdata = () => {
            setrowselect(data.initData, data.selectConfing)
            data.rowData = data.initData
            sizeColumnsToFit()
        }
        const cellAction = (e: AgGridRowEvent) => {
            emit('change', e.data.ID)
        }

        const {
            cruds,
            newL,
            saveselect,
            selChange,
            filterOption,
            Cancel,
            saveAll
        } = curd(prop, emit, {
            model, data, tableRef, setdata,
            canSel, ClearData, selectvalue
        })


        const onGridReady = (params: GridEvent) => {
            data.api = params.api;
        }
        const sizeColumnsToFit = () => {

            setTimeout(() => {
                try {
                    data.api?.sizeColumnsToFit();
                } catch (error) {
                }
            }, 200);
        }
        return {
            data,
            cellAction,
            cruds,
            canSel,
            newL,
            saveselect,
            selChange,
            selectvalue,
            GetId_init,
            tableRef,
            filterOption,
            Cancel,
            setSelect,
            onGridReady,
            saveAll
        }
    },
    render() {
        let obj: any = {};
        if (this.options.tableHeight) {
            obj = { height: this.options.tableHeight, }
        }
        // 把操作图标禁用了
        const getcol = this.options.columnDefs?.filter((e: any) => e.headerName == "動作")[0]
        if (getcol) {
            if (this.data.Pstatus) {
                getcol.cellRendererParams.disabled = true
            } else {
                getcol.cellRendererParams.disabled = false
            }
        }
        const isMobile: any = inject('isMobile')

        const getopts = () => {
            let Codes = this.data.rowData.map(x => x.Code) || [];
            // 表格不能出现相同的，所以要对下拉项进行禁用
            return this.data.selectListOption?.map((x: { Code: string, }) => ({
                ...x, disabled: Codes.includes(x.Code)
            }))
        }

        const W = () => <>
            <a-row class='padding-20'>
                <a-col span='16' class='ant-com' xxl={14} xl={16} xs={24}>
                    {this.canSel ?
                        <a-select
                            value={this.selectvalue}
                            show-search
                            onFilterOption={this.filterOption}
                            allowClear
                            placeholder="Please Select"
                            onChange={(a: any, item: any) => this.selChange(a, item)}
                            getPopupContainer={(triggerNode: { parentNode: any; }) => triggerNode.parentNode}
                            options={getopts()}
                        >
                        </a-select>

                        : <b style='font-size:20px'>{this.options?.title}</b>}
                </a-col>
                <a-col span='8' class='no-scroll' xxl={10} xl={8} xs={24}>
                    {this.canSel ?
                        <div style='text-align: end;'>
                            <a-button class="ant-btn-gray margin-right-10" size="small" type="primary" icon={<RollbackOutlined />} onClick={this.Cancel} style='margin-right:10px' >返回</a-button>
                            <a-button class="ant-btn-yellow" size="small" type="primary" icon={<SaveOutlined />} onClick={this.saveselect}>儲存</a-button>
                        </div>
                        :
                        <div style='text-align: end;'>
                            <a-button class="ant-btn-yellow margin-right-10"
                                disabled={this.data.Pstatus}
                                size="small"
                                type="primary"
                                icon={<SaveOutlined />}
                                onClick={this.saveAll}>全部儲存</a-button>
                            <a-button class="ant-btn-gray" disabled={this.data.Pstatus} size="small" type="primary" icon={<i class="iconfont icon-zhuanfa" style={'font-size:14px;margin-right:9px'}></i>} onClick={this.newL}>新增</a-button>
                        </div>
                    }
                </a-col>
            </a-row>
        </>
        const M = () => <>

            {
                this.canSel ? <a-row class='padding-20'>
                    <a-col class='ant-com' xs={24}>
                        <a-select
                            value={this.selectvalue}
                            show-search
                            onFilterOption={this.filterOption}
                            allowClear
                            placeholder="Please Select"
                            size={'large'}
                            style={{ marginBottom: '10px' }}
                            onChange={(a: any, item: any) => this.selChange(a, item)}
                            getPopupContainer={(triggerNode: { parentNode: any; }) => triggerNode.parentNode}
                            options={getopts()}
                        >
                        </a-select>
                    </a-col>
                    <a-col class='no-scroll' xs={24}>
                        <div style='text-align: end;'>
                            <a-button class="ant-btn-gray margin-right-10" size="small" type="primary" icon={<RollbackOutlined />} onClick={this.Cancel} style='margin-right:10px' >返回</a-button>
                            <a-button class="ant-btn-yellow" size="small" type="primary" icon={<SaveOutlined />} onClick={this.saveselect}>儲存</a-button>
                        </div>
                    </a-col>
                </a-row>
                    :
                    <a-row class='padding-20'>
                        <a-col class='ant-com' xs={10}>
                            <b style='font-size:20px'>{this.options?.title}</b>
                        </a-col>
                        <a-col class='no-scroll' xs={14}>
                            <div style='text-align: end;'>
                                <a-button class="ant-btn-yellow margin-right-10"
                                    disabled={this.data.Pstatus}
                                    size="small"
                                    type="primary"
                                    icon={<SaveOutlined />}
                                    onClick={this.saveAll}>全部儲存
                                </a-button>
                                <a-button class="ant-btn-gray" disabled={this.data.Pstatus} size="small" type="primary" icon={<i class="iconfont icon-zhuanfa" style={'font-size:14px;margin-right:9px'}></i>} onClick={this.newL}>新增</a-button>
                            </div>
                        </a-col>
                    </a-row>}
        </>
        return (
            <div>
                {isMobile.value ? M() : W()}
                <div style={'padding:0 20px 20px'} >
                    <ag-grid
                        ref="tableRef"
                        style={obj}
                        class="ag-theme-alpine"
                        columnDefs={this.options.columnDefs}
                        rowData={this.data.rowData}
                        on_cellClicked={this.cellAction}
                        on_cellMethod={this.cruds}
                        // rowHeight={41}
                        // rowSelection="multiple"
                        // suppressRowClickSelection={true}
                        on_gridReady={this.onGridReady}
                        disabled={this.data.Pstatus}
                    >
                    </ag-grid>
                </div>
            </div >
        )
    }
})
