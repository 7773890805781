import { FormlyFieldGroup, SubmitFailedEvent } from "./types";

export default function useFormEvent() {
    const onFinish = (values: any) => {
        // console.log(values);
        // console.log(formRef);
        // console.log(p.model);
        // console.log(formRef.value.validate());
        // formRef.value.validate().then((x: any) => {
        //     console.log(x);
        //     setTimeout(() => {
        //         formRef.value.resetFields('OldPassword');
        //     }, 1000);
        // })
        // emit('finish', values)
        console.log(values);

    }


    const onFinishFailed = (values: any) => {

    };
    const onValidate = (values: any) => {

    };
    const onSubmit = (values: any) => {

    };
    return {
        onFinish,
        onFinishFailed,
        onValidate,
        onSubmit
    }

}

export type FormInputElement = HTMLInputElement | null;

export const handleFormFinishFailed = (e: SubmitFailedEvent, fields: FormlyFieldGroup[], formID: string) => {
    // console.log(e);
    if (!e.errorFields) return;
    let firstErrorField = e.errorFields[0];
    let field = firstErrorField.name[0];
    let el: FormInputElement = document.querySelector(`#${formID}_${field}`);
    // console.log(el?.tagName);
    // console.dir(el);
    switch (el?.tagName) {
        case 'INPUT':
            el.focus();
            break;
    }
    fields.some(x => x.fieldGroup.some(y => {
        // @ts-ignore
        let isEq = y.key == e.errorFields[0].name[0];
        if (isEq) {
            if (y.templateOptions) {
                y.templateOptions.showErrorTooltip = true;
                setTimeout(() => {
                    y && y.templateOptions && (y.templateOptions.showErrorTooltip = false);
                }, 2000);
                // @ts-ignore
                let err = e.errorFields[0].errors[0];
                // @ts-ignore
                err = err.replace(y.key, y.templateOptions.label);
                y.templateOptions.errorText = err;
            }
        }
        return isEq;
    }));
}


/* 
validateFields	触发表单验证	(nameList?: NamePath[]) => Promise
scrollToField	滚动到对应字段位置
*/