import type { UploadChangeParam, UploadProps } from "ant-design-vue";
import { debounceTime, Subject, type Subscription } from "rxjs";
import { computed, defineComponent, h, onUnmounted, ref } from "vue";
import { Upload } from "../../types";
import './index.scss';

export default defineComponent({
    props: {
        option: {
            default: () => new Upload
        },
        _this: {
            type: Object
        },
        disabled: { type: Boolean },
    },
    emits: ['upload'],
    setup(p, { emit }) {
        let $uploadEv: Subscription;
        let $upload: Subject<any> = new Subject();
        const fileList = ref<any[]>([]);
        const multiple_fileList = ref<any[]>([]);
        const accept: any = computed(() => {
            let arr = p.option.btn ? p.option.btn.fileType || [] : p.option.fileType || [];
            return arr.map(x => FileTypeMap[x]).join(',')
        })
        const handleChange = (info: UploadChangeParam) => {
            const status = info.file.status;
            if (status !== 'uploading') {
            }
            // !p.option?.multiple_once_api && (p._this as any)?.selectFiles([info.file], p.option);
            // if (status === 'done') {
            //     // message.success(`${info.file.name} file uploaded successfully.`);
            // } else if (status === 'error') {
            //     // message.error(`${info.file.name} file upload failed.`);
            // }
        };
        function handleDrop(e: DragEvent) {
            console.log(e);
        }
        const beforeUpload: UploadProps['beforeUpload'] = file => {
            fileList.value = [];
            fileList.value = [...fileList.value, file];
            multiple_fileList.value = [...multiple_fileList.value, file];
            $upload.next(multiple_fileList.value)
            return false;
        };
        $uploadEv = $upload.pipe(debounceTime(500)).subscribe((x: any) => {
            // p.option?.multiple_once_api && (p._this as any)?.selectFiles(x.value, p.option);
            emit('upload', x)

            multiple_fileList.value = []
        })
        onUnmounted(() => {
            $uploadEv && $uploadEv.unsubscribe();
        })
        return {
            fileList,
            handleChange,
            handleDrop,
            accept,
            beforeUpload,
        }
    },
    render() {
        return (
            <a-upload-dragger
                class={['upload-wrap', this.option?.class || ""]}
                disabled={this.disabled}
                fileList={this.fileList}
                name="file"
                accept={this.accept}
                multiple={this.option.multiple}
                showUploadList={false}
                onChange={this.handleChange}
                onDrop={this.handleDrop}
                openFileDialogOnClick={this.option.openFileDialogOnClick}
                before-upload={this.beforeUpload}
            >
                {

                    this.option.btn ?
                        <a-button style={`margin-top: -3px;`} disabled={this.disabled}>{this.option.btn?.text}</a-button>
                        :
                        <>
                            <p class="ant-upload-drag-icon">
                                <svg style={{ 'font-size': this.option.fontSize }} class="icon point inline-block  " aria-hidden="true">
                                    <title>Drop files to upload</title>
                                    <use xlinkHref={`#icon-${this.option.icon}`}></use>
                                </svg>
                            </p>
                            <p class="ant-upload-text">{this.option.dropDesc}</p>
                            <p class="ant-upload-hint">{this.option.dropSubDesc}</p>
                        </>
                }
            </a-upload-dragger>
        )
    }
})

export type FileType = 'TEXT' | 'Excel' | 'PDF' | 'XML' | 'PNG' | 'ZIP' | 'MPG' | 'JPG' | 'CSV' | 'MP4';

export const FileTypeMap = {
    TEXT: 'text/plain',
    Excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    PDF: 'application/pdf',
    PNG: 'image/png',
    ZIP: 'aplication/zip',
    XML: 'text/xml, application/xml',
    MPG: 'video/mpeg',
    JPG: 'image/jpeg',
    CSV: 'text/csv',
    MP4: '.mp4'
}