
import { defineComponent } from 'vue';
import style from './index.module.scss'
import dayjs from 'dayjs'
interface p {
    rowData: {
        ItemNo: number,
        ImmediateFurther: string,
        RequiredIW: string,
        ActionDate: string,
        ReInspectionDate: string,
        Satisfactory: string,
    }[],
    _this: any
}
export const Part3B = defineComponent({
    props: ['rowData', '_this'],
    setup(p: p) {

        return () => <>
            <div class={style['host-m']}>
                {
                    p.rowData?.map(x => {
                        return <div class={{ [style.card]: true, 'form-host': true }} >
                            <div class={style['item-title']}>Item {x.ItemNo}</div>
                            <div>
                                <div class={'margin-bottom-5'}>Further following up</div>
                                <a-input
                                    value={x['ImmediateFurther']}
                                    onChange={(e: any) => {
                                        x['ImmediateFurther'] = e.target.value || '';
                                    }}
                                    size='large'
                                ></a-input>
                            </div>
                            <div>
                                <div class={'margin-bottom-5'}>Details of improvement work done</div>
                                <a-input
                                    value={x['RequiredIW']}
                                    onChange={(e: any) => {
                                        x['RequiredIW'] = e.target.value || '';
                                    }}
                                    size='large'
                                ></a-input>
                            </div>
                            <div>
                                <div class={'margin-bottom-5'}>Action date</div>
                                <a-date-picker
                                    value={x['ActionDate'] ? dayjs(x['ActionDate']) : ''}
                                    onChange={(e: any) => {
                                        x['ActionDate'] = e;
                                    }}
                                    size='large'
                                    defaultValue={undefined}
                                    allowClear={true}
                                    format={'DD/MM/YYYY'}
                                    style="width: 100%"
                                    getPopupContainer={((triggerNode: { parentNode: any; }) => triggerNode.parentNode)}
                                />
                            </div>
                            <div>
                                <div class={'margin-bottom-5'}>Re-Inspection date</div>
                                <a-date-picker
                                    value={x['ReInspectionDate'] ? dayjs(x['ReInspectionDate']) : ''}
                                    onChange={(e: any) => {
                                        x['ReInspectionDate'] = e;
                                    }}
                                    size='large'
                                    defaultValue={undefined}
                                    allowClear={true}
                                    format={'DD/MM/YYYY'}
                                    style="width: 100%"
                                    getPopupContainer={((triggerNode: { parentNode: any; }) => triggerNode.parentNode)}
                                />
                            </div>
                            <div>
                                <div class={'margin-bottom-5'}>Satisfactory</div>
                                <a-select
                                    value={x['Satisfactory']}
                                    onChange={(e: any) => {
                                        x['Satisfactory'] = e;
                                    }}
                                    allowClear
                                    style="width: 100%"
                                    size='large'
                                    options={[
                                        {
                                            value: 'Yes', label: 'Yes',
                                        },
                                        {
                                            value: 'No', label: 'No',
                                        }
                                    ]}
                                    placeholder={'Please Select'}
                                    getPopupContainer={((triggerNode: { parentNode: any; }) => triggerNode.parentNode)}
                                >
                                </a-select>
                            </div>
                            <a-button type="primary" class='edit-btn' onClick={() => { p._this.save2_m(x) }}
                                disabled={p._this.data.parentStatus}
                            >
                                <i class="iconfont icon-zhuanfa" style={'font-size:14px;margin-right:9px'}></i> 修改
                            </a-button>
                        </div>
                    })
                }
                {
                    !p.rowData?.length && <div class={style['nophoto-show']}>
                        No Rows To Show
                    </div>
                }
            </div>
        </>
    }
})
interface cp {
    rowData: {
        ItemNo: number,
        ImmediateFurther: string,
        RequiredIW: string,
        SuggestedDay: string,
        SuggestedOtherValue: string,
    }[],
    _this: any
}
export const Part3C = defineComponent({
    props: ['rowData', '_this'],
    setup(p: cp) {

        return () => <>
            <div class={style['host-m']}>
                {
                    p.rowData?.map(x => {
                        return <div class={{ [style.card]: true, 'form-host': true }} >
                            <div class={style['item-title']}>Item {x.ItemNo}</div>
                            <div>
                                <div class={'margin-bottom-5'}>Immediate suspension of works required</div>
                                <a-select
                                    value={x['ImmediateFurther']}
                                    onChange={(e: any) => {
                                        x['ImmediateFurther'] = e;
                                    }}
                                    allowClear
                                    style="width: 100%"
                                    size='large'
                                    options={[
                                        {
                                            value: 'Yes', label: 'Yes',
                                        },
                                        {
                                            value: 'No', label: 'No',
                                        }
                                    ]}
                                    placeholder={'Please Select'}
                                    getPopupContainer={((triggerNode: { parentNode: any; }) => triggerNode.parentNode)}
                                >
                                </a-select>
                            </div>
                            <div>
                                <div class={'margin-bottom-5'}>Required improvement work</div>
                                <a-textarea
                                    value={x['RequiredIW']}
                                    onChange={(e: any) => {
                                        x['RequiredIW'] = e.target.value || '';
                                    }}
                                    size='large'
                                ></a-textarea>
                            </div>
                            <div>
                                <div class={'margin-bottom-5'}>Suggested days to improve</div>
                                <a-select
                                    value={x['SuggestedDay']}
                                    onChange={(e: any) => {
                                        x['SuggestedDay'] = e;
                                    }}
                                    allowClear
                                    style="width: 100%"
                                    size='large'
                                    options={[
                                        {
                                            "value": 'Immediate',
                                            "label": "Immediate",
                                        },
                                        {
                                            "value": 'Suggested1',
                                            "label": "1 Day",
                                        },
                                        {
                                            "value": 'Suggested3',
                                            "label": "3 Days",
                                        },
                                        {
                                            "value": 'Suggested7',
                                            "label": "7 Days",
                                        },
                                        {
                                            "value": 'SuggestedOther',
                                            "label": "Other",
                                        },
                                    ]}
                                    placeholder={'Please Select'}
                                    getPopupContainer={((triggerNode: { parentNode: any; }) => triggerNode.parentNode)}
                                >
                                </a-select>
                            </div>

                            <div class={{ 'hide': !['SuggestedOther'].includes(x.SuggestedDay) }}>
                                <div class={'margin-bottom-5'}>SuggestedOther</div>
                                <a-input-number
                                    value={x['SuggestedOtherValue']}
                                    onChange={(e: any) => {
                                        x['SuggestedOtherValue'] = e;
                                    }}
                                    size="large"
                                    min={0}
                                    v-slots={{
                                        addonAfter: () => <div>Days</div>
                                    }}
                                />
                            </div>
                            <a-button type="primary" class='edit-btn' onClick={() => { p._this.save1_m(x) }}
                                disabled={p._this.data.parentStatus}
                            >
                                <i class="iconfont icon-zhuanfa" style={'font-size:14px;margin-right:9px'}></i> 修改
                            </a-button>
                        </div>
                    })
                }
                {
                    !p.rowData?.length && <div class={style['nophoto-show']}>
                        No Rows To Show
                    </div>
                }
            </div>
        </>
    }
})