import "video.js/dist/video-js.css";
import { defineComponent, reactive, ref } from 'vue';
import { Torrent } from "../../types";
import './index.scss';
import VideoPlay from './video';

export default defineComponent({
    props: {
        option: {
            default: () => new Torrent
        },
        _this: {
            type: Object
        },
    },
    setup(p,) {
        const videoPlayer = ref();

        const data = reactive<{
            value: boolean,
        }>({
            value: false,
        })

        const setRowData = (value: any) => {
            data.value = !!value;

            // 有值，设置视频
            if (!!value) {
                // 定时 等待组件生成后获取ref
                setTimeout(() => {
                    //识别不了\\,转换一下
                    let val = String.raw`${value}`.replace(/\\/g, '/');
                    videoPlayer.value?.initVideo({
                        src: val,
                        type: "video/mp4",
                    })
                });
            }
        }


        return {
            setRowData,
            data,
            videoPlayer
        }
    },
    render() {

        return (
            <div
                style={[
                    this.option.style || 'width: 100%; height:300px',
                ]}
                class={[
                    this.option?.class || '',
                    'card-list torrent-content'
                ]}
            >
                {
                    this.data.value ?
                        <VideoPlay ref="videoPlayer" /> :
                        <span class='prev-span' style={`font-size:${this.option.textFontSize};`}>Video Preview</span>
                }
            </div>
        )
    }
})