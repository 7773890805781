import { AgGridRowEvent } from "@/components/ag-table/types/grid";
import { computed, defineComponent, reactive } from "vue";


export default defineComponent({
    props: {
        params: Object
    },
    setup(p) {
        let params: AgGridRowEvent;
        params = p.params as AgGridRowEvent;
        const data = reactive({
            value: params.value,
        });
        const key = params.column ? params.column.getColId() : '';

        const format = p.params?.format || 'DD/MM/YYYY';


        //@ts-ignore
        data.value = params.value;
        return {
            key,
            params,
            data,
            format
        }
    },
    render() {
        let that: any = this;
        return (
            <>
                <span>{that['$filters'].date(this.data?.value, this.format)}</span>
            </>
        );
    }
});
