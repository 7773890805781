import verifyPhoto from "@/pipe/verifyPhoto";
import useStore from "@/store/metaData";
import { computed, defineComponent, h, inject, reactive } from "vue";
import style from "./avatar.module.scss";

export default defineComponent({
    setup() {
        let store = useStore();
        const data = reactive({
            LogoImg: '/images/logo_CH_TCJ922.png',
        });
        /**头像路径 */
        const avatarUrl = computed(() => (store as any).avatarUrl)
        /**是否显示默认头像 */
        const isDefault = computed(() => !!!avatarUrl.value)
        return {
            store,
            data,
            avatarUrl,
            isDefault
        }
    },
    render() {
        const getImg = () => {
            let url = this.data.LogoImg;
            if (this.avatarUrl) {
                url = verifyPhoto(this.avatarUrl + '?' + Math.random());
            }

            return () => h('img', {
                src: url,
            })
        }
        return (
            <a-avatar
                class={{ [style['photo']]: this.isDefault, [`margin-right-15`]: true }}
                size={{ xs: 27, sm: 27, md: 27, lg: 52, xl: 52, xxl: 52 }}
                v-slots={
                    {//两种写法的原因是因为渲染出来的不一样
                        icon: this.isDefault ? undefined : getImg(),
                        default: this.isDefault ? getImg() : undefined,
                    }
                }
            >
            </a-avatar>
        )
    }
})