export type HandListType = 'cellAction' | 'DoubleClicked' | 'cellMethod' | 'SortChanged'

export const ListClick = (ClickType: HandListType, pobj: any) => {
    const { e, props, data, model,
        modalRef, photo_modal, part1,
        getList } = pobj
    switch (ClickType) {
        case 'cellAction':
            let key = e.column?.getColId();
            if (key == props.pageConfig?.edit_photo_column_field) {
                // 判断是打开modal // 还是跳到页面指定位置
                if (props.pageConfig?.Jump_page_location_class) {
                    let toElement: any = document.querySelector(`.${props.pageConfig?.Jump_page_location_class}`) as HTMLElement
                    // setTimeout(() => {
                    //     toElement.parentNode.scrollTop = toElement.offsetTop;
                    // }, 500);
                }
                else {
                    let ID = e.data[props.pageConfig?.formPrimaryKey || 'ID'];
                    modalRef.value.open(ID);
                    setTimeout(() => {
                        //   当选中一条数据后再点击这个字段,不会调用onSelectionChanged ,所以要在这里打开
                        modalRef.value.formListGroupRef?.listmodalRef.setSelect(photo_modal.modal_slelect_option, data.getRowEntity || model)
                        modalRef.value.formListGroupRef?.listmodalRef.GetId_init(e)
                    });
                }
            }
            break;

        case 'DoubleClicked':
            if (props.pageConfig?.need_DoubleClicked_row_modal) {
                let ID = e.data[props.pageConfig?.formPrimaryKey || 'ID'];
                let key = e.column?.colDef.cellRenderer;
                if (key !== "actionCellRenderer") {
                    modalRef.value.open(ID);
                    setTimeout(() => {
                        //   当选中一条数据后再点击这个字段,不会调用onSelectionChanged ,所以要在这里打开
                        modalRef.value.formListGroupRef?.listmodalRef.setSelect(photo_modal.modal_slelect_option, data.getRowEntity || model)
                        modalRef.value.formListGroupRef?.listmodalRef.GetId_init(model)
                        modalRef.value.formListGroupRef?.documentRef.GetStstus(model)
                    });
                }
            }

            if (props.pageConfig?.need_DoubleClicked_row_open_part1) {
                part1?.showDrawer()
            }
            break;
        case 'cellMethod':
            cellMethod(pobj)
            break;
        case 'SortChanged':
            data.sort = e;
            if (data?.viewbtnname) {
                return
            } else {
                getList()
            }
            break;
    }
}

import { getFileName } from "@/utile";
import { patchValue } from "../../form";
import { AgGridRowEvent_e } from "../useList";
import useStore from '@/store/metaData';
// @ts-ignore
import DownloadService from "@/http/download";
import { SetRequired } from "@/utile/form";
/**
 * 主表格 图标操作
 */
export const cellMethod = (pobj: any) => {
    const { e, props, data, model, } = pobj
    const store = useStore()

    const Confirm = (e: AgGridRowEvent_e,) => {

        if (e.data?.ID == model?.ID) {
            if (model.Subcontractor == 'New SC') {
                props.http?.error('分判商為New SC,不允許Confirm操作。請選擇正確的Sub Con')
            } else {
                if (model['CreateBy']?.toUpperCase() !== store.userName?.toUpperCase()) {
                    if (store.userName?.toUpperCase() !== 'admin'?.toUpperCase()) {
                        props.http?.error('不允許更改其他人報告')
                        return
                    }
                }
                const cv = props.pageConfig?.confirmverify
                const requirearr = cv.filter((z: any) => !!!model[z])

                if (!!requirearr.length) {
                    requirearr.map((field: string) => {
                        SetRequired(props.fields || [], field, true)
                    })
                    props.http?.error('以下: ' + requirearr.join(' , ') + ' 不能爲空')

                } else {
                    const { ID } = e.data
                    let obj = { LoginName: store.userName, ID }
                    props.http?.Confirm && props.http?.Confirm(obj).then((x: any) => {
                        let Entity = x.Entity || x.CurrentEntity
                        Entity && patchValue(model, { ...Entity });
                        e.node.setDataValue('ReportStatus', x.Entity.ReportStatus);
                        props.http?.notify('The Confirm was successful.');
                    })
                }
            }
        }

    }

    const DownloadMenu = (e: any) => {
        data.exportExcelLoading = true;
        let WorkType = (e && e.$event.item.role) || props.pageConfig?.down_PDF_WorkType

        let obj = {
            // ...getObjectByMap(props.httpParamsMap?.export || [], model),
            WorkType,
            InspectionNo: e.data.ID,
            ReportType: 'DownloadReport',
            LoginName: store.userName,
            FileType: 'pdf',
        }

        props.http?.exportPDF && props.http.exportPDF(obj).then((x: any) => {
            data.exportExcelLoading = false;

            let str = x.ReportPath;
            let ar = str.split(';')
            ar.map((Path: any) => {
                if (Path) {
                    let aa = {
                        SourceFrom: 1,
                        Path,
                        FileName: getFileName(Path)
                    }
                    DownloadService.download(aa).then((x: any) => {
                        DownloadService.downloadFileByBlob(x, getFileName(Path));
                    })
                }
            })
        }).catch(() => data.exportExcelLoading = false);

    }
    const Download = (e: AgGridRowEvent_e) => {
        let { WorkType } = e?.other_params

        let obj = {
            // ...getObjectByMap(props.httpParamsMap?.export || [], model),
            WorkType,
            InspectionNo: e.data.ID,
            ReportType: 'DownloadReport',
            LoginName: store.userName,
            FileType: 'pdf',
        }

        props.http?.exportPDF && props.http.exportPDF(obj).then((x: any) => {
            let str = x.ReportPath;
            let ar = str?.split(';')
            ar?.map((Path: any) => {
                if (Path) {
                    let aa = {
                        SourceFrom: 1,
                        Path,
                        FileName: getFileName(Path)
                    }
                    DownloadService.download(aa).then((x: any) => {
                        DownloadService.downloadFileByBlob(x, getFileName(Path));
                    })
                }
            })
        })
    }
    const Undo = (e: AgGridRowEvent_e,) => {
        const { ID } = e.data
        let obj = { LoginName: store.userName, ID }

        props.http?.Undo && props.http?.Undo(obj).then((x: any) => {
            let Entity = x.Entity || x.CurrentEntity
            Entity && patchValue(model, { ...Entity });
            e.node.setDataValue('ReportStatus', x.Entity.ReportStatus);
            props.http?.notify('The Undo was successful.');
        })
    }
    switch (e.currentAction.toLowerCase()) {
        case 'Confirm'.toLowerCase(): Confirm(e); break;
        case 'DownloadMenu'.toLowerCase(): DownloadMenu(e); break;
        case 'Download'.toLowerCase(): Download(e); break;
        case 'Undo'.toLowerCase(): Undo(e); break;
    }
}