import { BarSeriesOption } from "echarts";

export const getNewXaxis = (xaxisData: any, legendData: any[]) => {
    let arr: any = []

    xaxisData.map((x: string, i: string) => {
        const str = {
            type: "category",
            position: 'bottom',
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            // x轴下面文字描述
            axisLabel: {
                show: true,
                interval: 0,
                color: '#000'
            },
            data: [] as any,
        }

        for (const key of xaxisData) {
            if (x == key) {
                str.data.push(x)
            }
            else {
                str.data.push('')
            }
        }
        arr.push(str)
    })
    return arr
}
export const getNewSeries = (SeriesData: any, p: any) => {
    let legendData: any = [];

    let arr: any = []
    SeriesData?.map((e: BarSeriesOption | any) => {
        legendData.push(e.name)
        e.data.map((z: any, i: any) => {
            const str = {
                name: e.name,
                color: e.color,
                type: "bar",
                position: 'bottom',
                label: {
                    show: true, //开启显示
                    position: 'top', //在上方显示
                    color: 'black',
                    fontSize: 10,
                    formatter: (params: any) => {
                        if (!p.c?.showzero) {
                            return `${params.value ? params.value : ' '}`
                        } else {
                            return params.value
                        }
                    },
                },
                // emphasis: {
                //     // itemStyle: {
                //     //     color: '#a8a8a8' // 设置高亮时的颜色
                //     // }
                // },
                xAxisIndex: null,
                data: [] as any,
            }
            if (z) {
                const arr2 = []
                for (let key2 = 0; key2 < i; key2++) {
                    arr2.push('')
                }
                arr2[i] = z;
                str.data = arr2;
                str.xAxisIndex = i;
                arr.push(str)
            }
        })
    })
    // console.log(arr);
    return { legendData, SeriesData: arr }
}