import { CaretRightOutlined } from "@/antd-icon";
import { defineComponent, reactive } from "vue";
import style from './index.module.scss'
export default defineComponent({
    components: { CaretRightOutlined },
    props: {
        /**  是否有 折叠 的 3角图标 */
        fold: {
            type: Boolean,
            default: false
        },
        /**  是否折叠 */
        isFold: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        noPaddingLeftRight: {
            type: Boolean,
            default: false
        },
    },
    setup(p) {
        const data = reactive({
            isFold: false
        })
        data.isFold = p.isFold
        return { data }
    },
    render() {

        return (
            <a-card
                class={`no-border margin-top-3 ${style['wo-card']}`}
                title=""
                bodyStyle={{ 'padding': '0' }}
            >
                {
                    this.$slots.header ?
                        <div class={style['wo-card-row']}>
                            {
                                this.fold &&
                                <caret-right-outlined onClick={() => !this.disabled && (this.data.isFold = !this.data.isFold)} class={{ 'margin-right-5': true, [style['fold-btn']]: true, [style['is-fold']]: !this.data.isFold }} style='color: #cc6600; font-size: 16px;' />
                            }
                            <span class={style['wo-card-row-content']}>
                                {this.$slots.header()}
                            </span>
                        </div>

                        : ''
                }
                {
                    this.$slots.default && !this.data.isFold ?
                        <div class={{ 'padding-top-4': true, "padding-left-15 padding-right-15": !this.noPaddingLeftRight }}
                            style={`border-left: 1px solid rgb(188, 232, 241); border-right: 1px solid rgb(188, 232, 241); ${!this.$slots.footer ? 'border-bottom: 1px solid rgb(188, 232, 241)' : ''}`}>
                            {this.$slots.default()}
                        </div>
                        : ''

                }
                {
                    this.$slots.footer && !this.data.isFold ?
                        <div
                            class={`${style['wo-card-row']}`}
                            style={{ 'padding': '4px 10px', 'background-color': '#d9edf7', 'border': '1px solid #bce8f1' }}>
                            {this.$slots.footer()}
                        </div>
                        : ''
                }
            </a-card>
        )
    }
})