import { isArray } from '@vue/shared';
import dayjs from 'dayjs'
import { isNil, omitBy } from '.';

/**当字段在这个数组中时，不去验证时间，
 * 比如编号，因为dayjs简单验证会判断成功 */
export const DisArr = ['DailyReport', 'UID', 'ReportRefNo']


/**
 * 统一时间
 * @description 获取统一时间，用来传给后端
 * @example YYYY-MM-DD hh:mm:ss
 * @param date 日期，默认是当前时间
 */
export const getUnityTime = (date: string | Date | number = new Date()): string => {
   // @ts-ignore
   if (!date) return null;
   return dayjs(new Date(date)).format('YYYY-MM-DD HH:mm:ss')
   // return formatDate(new Date(date), 'YYYY-MM-DD HH:mm:ss', 'zh')
}

/**
 * 获取某一天的某一整点对应的时间 默认返回当天8点对应的时间
 * @param point 24小时内的某一整点
 * @param date 日期时间 默认现在
 */
export const getPointTime = (point: number | string = 8, date: string | number | Date = new Date()): Date => {
   let dateStr = dayjs(new Date(date)).format('YYYY-MM-DD');//formatDate(new Date(date), 'YYYY-MM-DD', 'zh')
   let oneHours = 1000 * 60 * 60;
   let zeroPoint = new Date(dateStr).valueOf() - oneHours * 8;
   return new Date(zeroPoint + oneHours * parseInt(point as string));
   return new Date()
}
/**
 * 获取date的某一部分
 * @param format 格式
 * @param date 日期时间 默认现在
 */
export const getPartByDate = (format: string, date: string | number | Date = new Date()): string => {
   // @ts-ignore
   if (!date) return null;
   return dayjs(new Date(date)).format(format)
}
/**
 * 获取某一天的某一整点对应的时间 默认返回当天8点对应的时间
 * @param point 24小时内的某一整点
 * @param date 日期时间 默认现在
 */
export const getDateByPointTime = (point: string | number | Date = 8, date: string | number | Date = new Date()): Date => {
   point = point + '';
   let min = 0;
   if (/:/.test(point)) { // 包含:  8:30
      let arr = point.split(':');
      point = arr[0];
      min = parseInt(arr[1]) * 60;
   }
   let dateStr = dayjs(new Date(date)).format('YYYY-MM-DD');// formatDate(new Date(date), 'YYYY-MM-DD', 'zh')
   let oneHours = 1000 * 60 * 60;
   let zeroPoint = new Date(dateStr).valueOf() - oneHours * 8;
   return new Date(zeroPoint + oneHours * parseInt(point as string) + min * 1000);
}
/**
 * 获取 Submission Date From, To 时间
 * Submission CRHYD
 * @property submission SiSubmission[]
 */
export const getSubmissionDate = (submission: any[]): string | number | Date => {

   if (submission && submission.length > 0) {
      let arr = submission.filter(x => x.ID === 'CRHYD');
      if (arr.length > 0) {
         return arr[0].UpdateOn || null;
      }
      // @ts-ignore
      return null;
   }
   // @ts-ignore
   return null;
}

/**
 * 获取起始时间和结束时间中相差的天数
 * @param previousDay number | string | Date 起始时间
 * @param day number | string | Date   结束时间
 */
export const getDaysApart = (previousDay: number | string | Date, day: number | string | Date): number => {
   return 0
   // if (previousDay && day) {
   //    if (previousDay == day) {
   //       return 1;
   //    }
   //    let start = new Date(previousDay).valueOf();
   //    let end = new Date(day).valueOf();
   //    let apart = end - start;
   //    return getDaysByMS(apart);
   // }
   // return null;
}
/**
 * 从毫秒里 获取 天数
 * @param number number
 */
export const getDaysByMS = (number: number): number => {
   let oneDay = 86400000;
   return Math.ceil(number / oneDay);
}
/**
 * 把 obj 上的所有 日期 字段 转为 特定格式的字符串。返回新的对象
 * @param obj Object
 */
export const handleDate = (obj: any): any => {
   let o: any = {};
   Object.keys(obj).map((x: string) => {
      if (!DisArr.includes(x)) {

         if (isDate(obj[x])) {
            o[x] = getUnityTime(obj[x]);
         } else if (dayjs.isDayjs(obj[x])) {
            o[x] = getUnityTime(obj[x]);
         } else {
            if (/^[a-z0-9]{4}[-][a-z0-9]{2}[-]{1,}[a-z0-9]{2}...../.test(obj[x])) {
               let val = new Date(obj[x]);
               if (isDate(val) && val.valueOf() > 946684800000) { // 大于2000年
                  o[x] = getUnityTime(obj[x]);
               } else o[x] = obj[x];
            } else o[x] = obj[x];
         }
      } else {
         o[x] = obj[x]
      }

   })
   return handleNull(o);
}

export const handleNull = (obj: any) => {
   let o: any = { ...obj };
   Object.keys(o).map(key => {
      if (isNil(o[key])) {
         delete o[key]
      }
   })
   return o;
}

export const handleArrayNull = (obj: any): any => {
   let o: any = {};
   Object.keys(obj).map((x: string) => {
      if (isArray(obj[x])) {
         o[x] = obj[x].filter((y: any) => y != null);
      } else {
         o[x] = obj[x];
      }
   });

   return o
}
/**
 * 把 obj 上的所有 日期 字段 转为 dayjs。返回新的对象
 * @param obj Object
 */
export const handleDate2Dayjs = (obj: any): any => {
   let o: any = {};
   obj && Object.keys(obj).map((x: string) => {
      if (!DisArr.includes(x)) {

         if (isDate(obj[x])) {
            o[x] = dayjs(obj[x]);
         } else {
            if (/^[a-z0-9]{4}[-][a-z0-9]{2}[-]{1,}[a-z0-9]{2}...../.test(obj[x])) {
               let val = new Date(obj[x]);

               if (isDate(val) && val.valueOf() > 946684800000) { // 大于2000年
                  o[x] = dayjs(obj[x]);

               } else {
                  o[x] = obj[x];
               }
            } else {
               o[x] = obj[x]
            };
         }

      } else {
         o[x] = obj[x]
      }
   })
   return o;
}

export const isDate = (val: any): boolean => {
   /**是否含有汉字，有返回true */
   let filter_chinese = /[\u4E00-\u9FA5\uF900-\uFA2D]{1,}/;
   if (val instanceof Date) {
      return true;
   } else if (typeof val == 'string' && val.length > 8 && /[-\/:]/.test(val) && !filter_chinese.test(val)) {
      let d = new Date(val);
      return !!(val && d.toString() != 'Invalid Date' && d.valueOf() > 946684800000);
   }
   return false;
}
// 1577836800000
/**
 * 获取指定日期所在月的第一天所对应日期
 * @param date 日期，可转日期的字符串或数字
 */
export const getMonthFirstDay = (date: Date | string | number = new Date()): Date => {
   return new Date(new Date(date).getFullYear(), new Date(date).getMonth(), 1)
}
/**
 * 获取指定日期所在月的最后一天所对应日期
 * @param date 日期，可转日期的字符串或数字
 */
export const getMonthLastDay = (date: Date | string | number = new Date()): Date => {
   return new Date(new Date(date).getFullYear(), new Date(date).getMonth() + 1, 0)
}

/**
 * 把对像里的From转换成对应月的第一天， to  转换成对应月的最后一天
 */
export const handleFormToDate = (obj: Object): Object => {
   return {}
   // let o: Obj = {};
   // Object.keys(obj).map(key => {
   //    if (/from$/i.test(key)) {
   //       if (obj[key])
   //          o[key] = getMonthFirstDay(obj[key]);
   //    } else if (/to$/i.test(key)) {
   //       if (obj[key])
   //          o[key] = getMonthLastDay(obj[key]);
   //    } else {
   //       o[key] = obj[key];
   //    }
   // })
   // return o;
}


export const dateComparator = (date1: any, date2: any): number => {
   var date1Number = monthToComparableNumber(date1);
   var date2Number = monthToComparableNumber(date2);
   if (date1Number === null && date2Number === null) {
      return 0;
   }
   if (date1Number === null) {
      return -1;
   }
   if (date2Number === null) {
      return 1;
   }
   return date1Number - date2Number;
}
function monthToComparableNumber(date: any) {
   if (date === undefined || date === null || date.length !== 10) {
      return null;
   }
   var yearNumber = date.substring(6, 10);
   var monthNumber = date.substring(3, 5);
   var dayNumber = date.substring(0, 2);
   var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
   return result;
}