export type fntype = 'init' | 'getRow'
export const fn_before = () => {

}
export const fn_after = (obj: any, fntype: fntype) => {

}


import { ID_Description } from "@/types"
import { SetSelectOptions } from "@/utile/form"
import { patchValue, removeDuplicateObj_All, removeDuplicateObj_Id } from "../../form"
import { FormlyFieldGroup } from "../../form/types"
/**
 * uselist文件生成下拉
 */
export const uselist_setSelectOptionsByMap = (obj: any) => {
    const { props, data, o, formBtnRef, store, model } = obj
    props.formSelectConfing?.map((x: any) => {

        if (x.type == 'ID_Description') {
            if (x.from == 'store') {
                // @ts-ignore
                data.selectOptions[x.key] = store.menuMetaData.Permission[x.mapKey].map((y: ID_Description) => (
                    { ...y, label: y.Description, value: y.Value || y.Description }
                ));
            } else {
                if (x.format) {
                    let formatArr = (x.format as string).split(':');
                    data.selectOptions[x.key] = o[x.mapKey].map((y: ID_Description) => ({
                        ...y, label: formatArr.reduce((a, b) => {
                            if (y.hasOwnProperty(b)) {
                                // @ts-ignore
                                return a + y[b];
                            } else {
                                return a + b;
                            }
                        }, ''), value: y.ID || y.Description
                    }));
                } else {
                    // 根据父得到子的下拉
                    if (x.edit_value && x.edit_value == 'ch_option') {
                        let arr = removeDuplicateObj_Id(removeDuplicateObj_All(o[x.mapKey]))
                        // 保存的的ID相同去唯一的，提供初始下拉
                        data.selectOptions[x.key] = arr.map((y: ID_Description) => ({
                            ...y, label: y.Description, value: y.ID || y.Description
                        }));
                        // 保存的是全部的，提供动态筛选下拉
                        data.initSelectOptions[x.key] = o[x.mapKey].map((y: ID_Description) => ({
                            ...y, label: y.Description, value: y.ID || y.Description
                        }));
                    }
                    else {
                        if (x.getvalue == 'Description' && x.needValue) {
                            data.selectOptions[x.key] = o[x.mapKey].map((y: ID_Description) => ({
                                ...y, label: y.Description, value: y.Description || y.ID, Value: y.ID
                            }));
                        } else {
                            data.selectOptions[x.key] = o[x.mapKey].map((y: ID_Description) => ({
                                ...y, label: y.Description, value: y.ID || y.Description
                            }));
                        }
                    }
                }
            }
        } else if (x.type == 'Description_Description') {
            data.selectOptions[x.key] = o[x.mapKey].map((y: ID_Description) => ({
                ...y, label: y.Description, value: y.Description
            }));
        } else if (x.type == 'string') {
            data.selectOptions[x.key] = o[x.mapKey].map((y: string) => ({ label: y, value: y }));
        } else if (x.type == 'GroupList') {
            data.selectOptions[x.key] = o[x.mapKey].map((y: { Description: string; Group: string; }) => ({ ...y, label: y.Group + ' - ' + y.Description, value: y.Group }));
        }


        SetSelectOptions(props.fields || [], x.key, () => data.selectOptions[x.key]);
        // console.log(data.selectOptions);
        if (x.defaultFirst) patchValue(model, { [x.key]: data.selectOptions[x.key][0].value });

        // 设置 查询对话框的下拉项
        props.queryModalFields?.map((q: FormlyFieldGroup) => {
            if (q.fieldGroup[0].key == `Search${x.key}`) {
                SetSelectOptions(props.queryModalFields || [], q.fieldGroup[0].key, () => data.selectOptions[x.key]);
            }
        })
        if (formBtnRef.value) {
            formBtnRef.value.fields?.map((f: FormlyFieldGroup) => {
                // if (q.fieldGroup[0].key == `Search${x.key}`) {
                //     SetSelectOptions(props.queryModalFields || [], q.fieldGroup[0].key , () => data.selectOptions[x.key]);
                // }
                // console.log(f);

            })
        }
    });
}

/**
 * 对part参数进行筛选
 */
export const Part_editApi_before_select = (PartlistRef2: any, PartlistRef4: any) => {

    let {
        // 所有的
        checkbox_list, select_list, TC_list, APBS_list,
        // 选中的
        CheckboxList, PartIIAOthersValue,
        PartIIAppointedTC_List, PartIIAppointedAPBS_List,
        PartIIBValue, PartIIBOthersValue,

    } = PartlistRef2.value.modelS
    /** 全部boolean参数*/
    let aaaa: any = [];
    //checkboxlist
    checkbox_list.forEach((e: any) => {
        aaaa[e] = false
    })
    CheckboxList.forEach((e: any, i: any) => {
        aaaa[e] = true
    });
    // selectlist
    select_list.forEach((e: any, i: any) => {
        aaaa[e] = false
    });
    // 新建时PartIIBValue是没有值的
    if (PartIIBValue) {
        let b = PartlistRef2.value?.select2.filter((a: any) => a.Description == PartIIBValue)[0].ID
        b = `PartII${b.replace(/\s*/g, "")}`
        aaaa[b] = true
    }
    // partb
    TC_list.forEach((e: any, i: any) => {
        aaaa[e] = false
    });
    PartIIAppointedTC_List.forEach((e: any, i: any) => {
        aaaa[e] = true
    });
    APBS_list.forEach((e: any, i: any) => {
        aaaa[e] = false
    });
    PartIIAppointedAPBS_List.forEach((e: any, i: any) => {
        aaaa[e] = true
    });
    // 二级菜单关系
    // 如果父为false，那把其他的都设为false
    if (!aaaa?.PartIIAOthers) {
        PartIIAOthersValue = ''
    }
    if (!aaaa?.PartIIAppointedTC) {
        TC_list.forEach((e: any, i: any) => {
            aaaa[e] = false
        });
    }
    if (!aaaa?.PartIIAppointedAPBS) {
        APBS_list.forEach((e: any, i: any) => {
            aaaa[e] = false
        });
    }
    if (!aaaa.PartIIBOthers) {
        PartIIBOthersValue = ''
    }

    // part4
    const { PartIVReportRSO, PartIVReportRSODate } = PartlistRef4.value.model
    Object.assign(aaaa, PartlistRef4.value.selmodel())
    const part4obj = { PartIVReportRSO, PartIVReportRSODate, }

    let part = {
        ...aaaa,
        // part2
        PartIIAOthersValue, PartIIBValue, PartIIBOthersValue,
        ...part4obj
    }

    return part
}