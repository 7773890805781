export const replaceStr = (str: string, start: number, end: number, char: string) => {
    const strAry = str.split('');
    strAry.map((x, i) => {
        if (i + 1 >= start && i + 1 <= end) {
            strAry[i] = char;
        }
    });
    return strAry.join('');
}

export const EMPTY_ID = '00000000-0000-0000-0000-000000000000';
