<template>
  <a v-if="isExternalLink" v-bind="$attrs" :href="to" target="_blank">
    <slot />
  </a>
  <router-link v-else v-bind="$props" custom v-slot="{ isActive, href, navigate }">
    <span v-bind="$attrs" :href="href" @click="navigate"
      :class="isActive || childActive || paramsActive ? active_Class : inactive_Class">
      <slot />
    </span>
  </router-link>
</template>

<script>
import { RouterLink } from "vue-router";
import { useRouter } from "vue-router";
import { computed } from "vue";

export default {
  name: "AppLink",

  props: {
    // 如果使用 TypeScript，请添加 @ts-ignore
    ...RouterLink.props,
    selfPath: String,
    inactiveClass: String,
  },
  data() {
    return {
      active_Class: "route-actived",
      inactive_Class: "route-inactived",
    };
  },
  computed: {
    isExternalLink() {
      return typeof this.to === "string" && this.to.startsWith("http");
    }
  },
  mounted() {
    // console.log(this.activeClass);
    // console.log(this.navigate);
    // console.log(this.href);
    // console.log(this.isActive);
    // console.log(this.to);
    // console.log(this.$attrs);

    // console.log(this.isExternalLink);
  },
  setup(props) {
    let router = useRouter();
    const childActive = computed(() => {
      if (props.selfPath) {
        return new RegExp(`^${props.selfPath}`).test(router.currentRoute.value.fullPath)
      }
      return false;
    })
    const paramsActive = computed(() => {
      if (props.to) {
        return new RegExp(`^${props.to}`).test(router.currentRoute.value.fullPath)
      }
      return false;
    })
    return { childActive, paramsActive }
  }
};
</script>
<style lang="scss" scoped>
.route-actived {
  color: #708570;
  font-weight: 700;
  // transition: all 0.1s ease-in;
  // font-size: 15px;
}
</style>