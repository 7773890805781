import transition from "ant-design-vue/lib/_util/transition";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
    components: { transition },
    setup() {
        const router = useRouter();
        return {
            router
        };
    },
    render() {
        return (
            <router-view key={this.router.currentRoute.value.fullPath} />
        )
    }
})