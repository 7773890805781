// @ts-ignore
// import { Subject } from "rxjs";
import { defineComponent, reactive, ref } from "vue"


export default defineComponent({
    props: {
        accept: {
            type: String
        },
        multiple: {
            type: Boolean
        },
    },
    emits: ['select'],
    setup(p, { emit }) {

        // let $ev: Subject<any> = new Subject();
        const uploadBtn = ref();
        let fileList: File[] = [];
        const data = reactive({
            fileList
        })
        const beforeUpload = (file: File): boolean => {
            data.fileList = data.fileList.concat(file);
            // $ev.next(data.fileList);
            return false;
        };
        const triggerUpload = () => {
            data.fileList = [];
            uploadBtn.value.click();
        }
        const handleChange = (file: { file: File; fileList: File[] }) => {
            emit('select', file)
        }
        return {
            data,
            uploadBtn,
            triggerUpload,
            handleChange,
            beforeUpload
        }
    },
    render() {
        return (
            <a-upload
                file-list={this.data.fileList}
                name="file"
                // headers="headers"
                beforeUpload={this.beforeUpload}
                accept={this.accept}
                multiple={this.multiple}
                onChange={this.handleChange}
            >
                <div ref="uploadBtn">
                    {this.$slots.default && this.$slots.default()}
                </div>
            </a-upload>

        )
    }
})