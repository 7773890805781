import { App } from 'vue'
import date from './date'
import verifyPhoto from './verifyPhoto'

  
const pipeArr = { date, verifyPhoto }
export default (app: App<Element>) => {
    let obj: any = {}
    // @ts-ignore
    Object.keys(pipeArr).forEach(pipe => obj[pipe] = pipeArr[pipe])
    app.config.globalProperties.$filters = obj
}