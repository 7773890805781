import { copyProp } from "@/utile";
import { AgGridRowEvent } from "./grid";
import AgTable from "../index";

type ParentAgGrid = InstanceType<typeof AgTable>

type Edit_type = 'inputnumber' | 'Checkbox' | 'Icon' |
    'status' | 'status_div' | 'input' |
    'number' | 'date' | 'color_text' |
    'background_text' | 'time' | 'select' |
    'img' | 'No' | 'combinationText' | 'bgcolor_text' |
    'Color_div' | 'render_fn' | 'downloadFileByUrl' | 'text' |
    'cost_text';
export class Edit_Cell_Type {
    value: any;
    type?: Edit_type;
    disabled?: boolean = false
    isNA?: boolean = false
    background?: string;
    format?: 'DD/MM/YYYY';
    placeholder?: '';
    PopupContaine?: any;
    params?: AgGridRowEvent;
    setvalue_redrawRows?: boolean = false

    parents?: ParentAgGrid
    constructor(obj: Edit_Cell_Type) {
        obj && copyProp.apply(this, [obj]);
    }
}