// Name	Minimum width
// xs	0
// sm	576
// md	768
// lg	992
// xl	1200
// xxl	1400
/* 
{
    "current": "xxl",
    "xs": false,
    "smMinus": false,
    "smPlus": true,
    "sm": false,
    "mdMinus": false,
    "mdPlus": true,
    "md": false,
    "lgMinus": false,
    "lgPlus": true,
    "lg": false,
    "xlMinus": false,
    "xlPlus": true,
    "xl": false,
    "xxl": true,
    "orientation": "landscape",
    "isLandscape": true,
    "isPortrait": false,
    "theme": "light",
    "isDark": false,
    "isLight": true,
    "motionPreference": "no-preference",
    "isMotion": true,
    "isInert": false
}
*/
import { computed, provide } from 'vue';
import type { MQ } from './components/form/types';
export default (mq: MQ) => {
    provide('isMobile', computed(() => mq.md || mq.sm || mq.xs))
}