
// export const BROWSER_SESSION_STORAGE = new InjectionToken<Storage>('Browser SessionStorage', {
//   providedIn: 'root',
//   factory: () => sessionStorage
// });
// export const BROWSER_LOCAL_STORAGE = new InjectionToken<Storage>('Browser LocalStorage', {
//   providedIn: 'root',
//   factory: () => localStorage
// });

export class BrowserStorageService {
  private storage: Storage = sessionStorage;
  private local: Storage= localStorage;

  /**
   * 
   * @param key string
   * @param isLocal 是否是 localStorage
   */
  get(key: string, isLocal: boolean = false): any {
    try {
      let value = isLocal ? this.local.getItem(key) : this.storage.getItem(key);
      return JSON.parse(value as string);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /**
   * @param key string
   * @param value any
   * @param isLocal 是否是 localStorage
   */
  set(key: string, value: any, isLocal: boolean = false) {
    try {
      let _value: string = JSON.stringify(value);
      // isLocal ? this.local.setItem(key, _value) : this.storage.setItem(key, _value);
      if (isLocal) {
        this.local.setItem(key, _value)
      } else {
        this.storage.setItem(key, _value)
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * 
   * @param isLocal 是否是 localStorage
   */
  remove(key: string, isLocal: boolean = false) {
    isLocal ? this.local.removeItem(key) : this.storage.removeItem(key);
  }

  /**
   * 
   * @param key string
   * @param isLocal 是否是 localStorage
   */
  clear(isLocal: boolean = false) {
    isLocal ? this.local.clear() : this.storage.clear();
  }

}
export default new BrowserStorageService();