import { inject } from "vue";
import Chart from "./components/echarts/Chart";
import getButton from './handleButton';
import Modal from "./components/modal";
import { ComponentBase, PaginationList, TabProps } from "./types";

export default function (c: ComponentBase, options: TabProps, model: any) {
    let cp: any = <></>
    // @ts-ignore
    let _this: any = this;
    let height = _this.options.pageConfig?.tableHeight || '300px';
    let p = c as PaginationList;
    height = p.autoHeight ? _this.data.height : height;
    let columnDefs = _this.options.columnDefs;
    if (_this.options.pageConfig?.columnDefRules) {
        columnDefs = _this.data.dynamicColumnDefs;
    }
    let dynamicEchartsXdata: any = []
    let dynamicEchartsYdata: any = []
    if (_this.options.pageConfig?.dynamicEchartEcharts) {
        dynamicEchartsXdata = _this.data?.dynamicEchartsXdata
        dynamicEchartsYdata = _this.data?.dynamicEchartsYdata
    }
    const isMobile: any = inject('isMobile')

    if (columnDefs && columnDefs.length) {
        cp = (<div class={p.listCardClass ? p.listCardClass : ''}>
            {p.ischart && !!dynamicEchartsXdata.length && !!dynamicEchartsYdata.length && <a-row class='margin-20'><Chart xaxisData={dynamicEchartsXdata} yaxisData={dynamicEchartsYdata} c={_this.options.pageConfig} /></a-row>}
            {
                p.title &&
                <div class={p?.titleClass || ''} style={p?.titleStyle || ''}>
                    {p.title}
                </div>
            }
            {
                p.Btnslot &&
                <div class={p.BtnSlotClass}>
                    {getButton.apply(_this, [p.Btnslot, _this.options, _this.model])}
                </div>
            }
            <a-row class={`padding-20 ${p.rowClass}`}
                style={{ paddingTop: isMobile.value ? '0 !important' : '' }}
            >
                {
                    isMobile.value ? (c as PaginationList).hasPagination &&
                        <a-col span='24' class="text-right">
                            <pagination-c
                                //  class="text-left"
                                pagination={_this.pagination}
                                showRefresh={true}
                                onRefresh={() => _this.getList()}
                                onPageChange={() => _this.getList()}
                                hidePaginItem={true}
                                viewbtnname={_this.data.viewbtnname}
                            >
                            </pagination-c>
                        </a-col> : ''
                }
                <a-col span='24'>
                    <ag-grid
                        ref="tableRef"
                        style={{ height }}
                        class="ag-theme-alpine"
                        columnDefs={columnDefs}
                        rowData={_this.data.rowData}
                        suppressRowClickSelection={p.suppressRowClickSelection}
                        // on_rowClicked={(e: any) => console.log(e)}
                        on_selectionChanged={_this.onSelectionChanged}
                        on_sortChanged={!p.isFrontSort && _this.HandListClick('SortChanged')}
                        on_cellClicked={_this.HandListClick('cellAction')}
                        on_cellDoubleClicked={_this.HandListClick('DoubleClicked')}
                        on_cellMethod={_this.HandListClick('cellMethod')}
                        rowHeight={p.rowHeight}
                        suppressFieldDotNotation={p.suppressFieldDotNotation}
                        defaultColDef={p.defaultColDef}
                    >
                    </ag-grid>
                </a-col>
                {
                    (c as PaginationList).hasPagination &&
                    <a-col span='24' class="text-right">
                        <pagination-c
                            // class="text-left"
                            pagination={_this.pagination}
                            showRefresh={true}
                            onRefresh={() => _this.getList()}
                            onPageChange={() => _this.getList()}
                            hideTotal={isMobile.value}
                            showLessItems={isMobile.value}
                            viewbtnname={_this.data.viewbtnname}
                            v-slots={{
                                leftBtnSlot: () => <span>
                                    {
                                        p.paginLeftBtnslot &&
                                        <span class={p.paginLeftBtnslotClass || ''}>
                                            {getButton.apply(_this, [p.paginLeftBtnslot, _this.options, _this.model])}
                                        </span>
                                    }
                                </span>
                            }}
                        >
                        </pagination-c>
                    </a-col>
                }
                <Modal ref={`modalRef`} options={_this.options.pageConfig?.ModalParams?.photo} onRefresh={_this.refreshPhoto} _this={_this}></Modal>{/* onRefresh={_this.refreshPhotoNumber}  */}
            </a-row>
        </div>
        )
    } else {
        cp = ''
    }
    return cp
}
