import { computed, defineComponent, inject, reactive, ref, watch } from "vue";

import Card from "@/components/form-list-group/components/card";
import AgGrid from "@/components/ag-table";
import '../index.scss';
import useStore from '@/store/metaData'

import edit_cellRendererComponent from '@/components/ag-table/edit_cell';
import actionCellRenderer from "../../ag-table/actionCellRenderer";
import { ListResult } from "../types/types";
import { AgGridRowEvent_e } from "../components/documents";
import Modal from "../components/modal";
import { getObjectByMap } from "@/utile";

import Collapse from "@/components/form-list-group/components/Collapse";

export default defineComponent({
    components: { Collapse, Card, AgGrid, edit_cellRenderer: edit_cellRendererComponent, actionCellRenderer, Modal, Part3B },
    // props: ['options', 'id',],
    props: {
        options: {
            // type: () => new PartIIIList,
            default: () => new PartIIIList
        },
        id: {
            type: String
        },
    },
    emits: ['openPart4'],
    setup(prop, { emit }) {
        let store = useStore();

        let tableRef1 = ref();
        let tableRef2 = ref();
        let rowData1: any[] = [];
        let rowData2: any[] = [];
        let initrowData1: any[] = [];

        let api1: GridApi = new GridApi;
        let api2: GridApi = new GridApi;
        let data = reactive({
            initrowData1,
            rowData1,
            rowData2,
            parentStatus: false,
            height1: '',
            height2: '',

            api1,
            api2,
        });
        const setHeight = (arr: any[]) => {
            let h = 110;
            let l = arr.length;
            let height = h + l * 40;
            if (height > 400) height = 400
            sizeColumnsToFit1()
            sizeColumnsToFit2()
            return height + 'px';
        }
        const length1 = computed(() => data.rowData1)
        const length2 = computed(() => data.rowData2)
        watch([length1, length2], () => {
            data.height1 = setHeight(length1.value)
            data.height2 = setHeight(length2.value)
        })
        const setDisabled = (arr: any) => {
            let e = arr;

            if (e?.ReportStatus == "Completed") {
                data.parentStatus = true
            } else {
                if (e?.['ReportStatus'] == "Completed") {
                    data.parentStatus = true
                } else {
                    if (store.userName?.toUpperCase() == e?.['CreateBy']?.toUpperCase() || store.userName?.toUpperCase() == 'admin'?.toUpperCase()) {
                        data.parentStatus = false
                    } else {
                        data.parentStatus = true
                    }
                }
            }
        }

        /**设置行数据 */
        const setRowData = (arr: any,) => {
            if (arr.ReportStatus) {
                setDisabled(arr)
            }

            data.rowData1 = arr['PartIIICList']?.Data || arr['PartIIIAList']?.Data;
            data.rowData2 = arr['PartIIIDList']?.Data || arr['PartIIIBList']?.Data;
        }

        const { cellAction, save1_m, save2_m } = crud(prop, data, tableRef1, tableRef2);


        /**清空操作，在uselist使用 */
        const clearPart = () => {
            data.rowData1 = []
            data.rowData2 = []
        }

        const onGridReady1 = (params: GridEvent) => {
            data.api1 = params.api;
        }
        const onGridReady2 = (params: GridEvent) => {
            data.api2 = params.api;
        }
        const sizeColumnsToFit1 = () => {
            if (!data.rowData1.length) return;

            setTimeout(() => {
                try {
                    data.api1?.sizeColumnsToFit();
                } catch (error) {
                }
            }, 200);
        }
        const sizeColumnsToFit2 = () => {
            if (!data.rowData2.length) return;

            setTimeout(() => {
                try {
                    data.api2?.sizeColumnsToFit();
                } catch (error) {
                }
            }, 200);
        }
        const part3 = Part3(emit)

        return {
            // ref
            setRowData, clearPart,
            // 
            tableRef1, tableRef2,
            data, cellAction, save1_m, save2_m,
            onGridReady1, onGridReady2, part3

        }
    },
    render() {
        const isMobile: any = inject('isMobile')
        const box = () => <>
            <div>
                <Collapse
                    v-slots={{
                        header: () => {
                            return <b style={{ 'color': '#000', 'user-select': 'none' }}>{this.options?.collapsetext}</b>
                        }
                    }}
                >
                    {getTemp1.apply(this, [this.options?.Part1])}
                    {getTemp2.apply(this, [this.options?.Part2])}
                </Collapse>
            </div >
        </>
        const M = () => <>
            <a-drawer
                ref={this.part3.drawRef}
                // title={c.title}
                maskStyle={{ backgroundColor: `transparent !important` }}
                class={'m-drawer-part3'}
                placement={'bottom'}
                closable={false}
                visible={this.part3.draw.visible}
                onClose={this.part3.onClose}
                maskClosable={true}
                push={true}
                height={`calc(100vh - 40px)`}
            >
                {box()}
                <div class=' next'>
                    <a-button type="primary" onClick={this.part3.openPart4} >
                        下一步
                    </a-button>
                </div>
                <div class={'previous'}>
                    <a-button type="primary" onClick={this.part3.onClose} >
                        上一步
                    </a-button>
                </div>

            </a-drawer>
        </>
        return (
            <>
                {isMobile.value ? M() : box()}
            </>
        )
    }
})

import { handleDate } from "@/utile";
import { PartIIIList } from "../types";
import { GridEvent } from "@/components/ag-table/types/grid";
import { GridApi } from "ag-grid-community";
import { Part3 } from "./usePart";
import { Part3B, Part3C } from "./usePart3";
const crud = (p: any, data: any, tableRef1: any, tableRef2: any) => {
    let PrimaryKey = p.options.pageConfig?.formPrimaryKey;
    const HttpKey1 = p.options.Part1.component.httpKey
    const HttpKey2 = p.options.Part2.component.httpKey

    const store = useStore();

    const cellAction = (e: AgGridRowEvent_e) => {
        switch (e.currentAction.toLowerCase()) {
            case 'Save1'.toLowerCase(): save1(e); break;
            case 'Save2'.toLowerCase(): save2(e); break;
        }
    }

    const save1 = (e: AgGridRowEvent_e) => {
        let obj: any = { FID: p.id, LoginName: store.userName, ...e?.data, PartGroup: HttpKey1 }
        obj = { ...obj, ...getObjectByMap(p.options.httpParamsMap?.edit || [], e?.data) }

        const { PartIIISuggested_key } = p.options?.arrkey
        // 存在
        if (PartIIISuggested_key.includes(obj['SuggestedDay'])) {
            PartIIISuggested_key.map((e: string | number) => {
                obj[e] = null
            })
            obj[obj['SuggestedDay']] = true

            if (obj['SuggestedDay'] !== 'SuggestedOther') {
                obj.SuggestedOtherValue = null
            } else {
                obj.SuggestedOtherValue = `${obj.SuggestedOtherValue}`
            }
        }

        p.options.http?.editPart3 && p.options.http?.editPart3(obj).then((x: ListResult) => {
            data.rowData1 = x.Data;

            // setTimeout(() => {
            //     tableRef1.value?.grid?.api?.sizeColumnsToFit();
            // }, 0);
            p.options.http?.notify('The Edit was successful.');
        });
    }

    const save2 = (e: AgGridRowEvent_e,) => {
        let obj: any = { FID: p.id, LoginName: store.userName, ...e?.data, PartGroup: HttpKey2 }
        obj = { ...obj, ...getObjectByMap(p.options.httpParamsMap?.edit || [], e?.data) }
        obj = handleDate(obj);

        p.options.http?.editPart3 && p.options.http?.editPart3(obj).then((x: ListResult) => {
            data.rowData2 = x.Data;
            // setTimeout(() => {
            //     tableRef2.value?.grid?.api?.sizeColumnsToFit();
            // }, 0);
            p.options.http?.notify('The Edit was successful.');
        });
    }
    const save1_m = (e: any) => {
        let obj: any = { FID: p.id, LoginName: store.userName, ...e, PartGroup: HttpKey1 }
        obj = { ...obj, ...getObjectByMap(p.options.httpParamsMap?.edit || [], e) }

        const { PartIIISuggested_key } = p.options?.arrkey
        // 存在
        if (PartIIISuggested_key.includes(obj['SuggestedDay'])) {
            PartIIISuggested_key.map((e: string | number) => {
                obj[e] = null
            })
            obj[obj['SuggestedDay']] = true

            if (obj['SuggestedDay'] !== 'SuggestedOther') {
                obj.SuggestedOtherValue = null
            } else {
                obj.SuggestedOtherValue = `${obj.SuggestedOtherValue}`
            }
        }

        p.options.http?.editPart3 && p.options.http?.editPart3(obj).then((x: ListResult) => {
            data.rowData1 = x.Data;
            p.options.http?.notify('The Edit was successful.');
        });
    }
    const save2_m = (e: any,) => {
        let obj: any = { FID: p.id, LoginName: store.userName, ...e, PartGroup: HttpKey2 }
        obj = { ...obj, ...getObjectByMap(p.options.httpParamsMap?.edit || [], e) }
        obj = handleDate(obj);

        p.options.http?.editPart3 && p.options.http?.editPart3(obj).then((x: ListResult) => {
            data.rowData2 = x.Data;
            // setTimeout(() => {
            //     tableRef2.value?.grid?.api?.sizeColumnsToFit();
            // }, 0);
            p.options.http?.notify('The Edit was successful.');
        });
    }
    return {
        cellAction, save1_m, save2_m
    }
}

export function getTemp1(this: any, part: any,) {

    // 把操作图标禁用了
    const getcol = part.component.columnDefs.filter((e: any) => e.headerName == "Action")[0]
    if (getcol) {
        if (this.data.parentStatus) {
            getcol.cellRendererParams.disabled = true
        } else {
            getcol.cellRendererParams.disabled = false
        }
    }
    const { PartIIISuggested_key } = this.options?.arrkey

    // 自定义字段
    this.data.rowData1?.map((e: any) => {
        PartIIISuggested_key.map((a: any) => {
            if (e[a]) {
                e['SuggestedDay'] = a
            }
        })
    })
    const isMobile: any = inject('isMobile')

    return <div style={'margin :10px 0'}>
        <div style={'font-weight: 700;font-size: 20px;margin:5px 0'}><b>{part.component.title}</b></div>
        <div class='flex' style={'justify-content: space-between'}>{part.component.gridTitle}</div>
        {
            isMobile.value ? <Part3C
                ref="tableRef1"
                rowData={this.data.rowData1}
                _this={this}
            /> :
                <ag-grid
                    ref="tableRef1"
                    style={{ height: this.data.height1 || '200px' }}
                    class="ag-theme-alpine"
                    columnDefs={part.component.columnDefs}
                    rowData={this.data.rowData1}
                    on_cellMethod={this.cellAction}
                    suppressRowClickSelection={true}
                    on_gridReady={this.onGridReady1}

                >
                </ag-grid>
        }
    </div>
}
export function getTemp2(this: any, part: any,) {
    const isMobile: any = inject('isMobile')
    // 把操作图标禁用了
    const getcol = part.component.columnDefs.filter((e: any) => e.headerName == "Action")[0]
    if (getcol) {
        if (this.data.parentStatus) {
            getcol.cellRendererParams.disabled = true
        } else {
            getcol.cellRendererParams.disabled = false
        }
    }

    return <div style={'margin :10px 0'}>
        <div style={'font-weight: 700;font-size: 20px;margin:5px 0'}>{part.component.title}</div>
        <div class='flex' style={'justify-content: space-between'}>{part.component.gridTitle}</div>
        {
            isMobile.value ? <Part3B
                ref="tableRef2"
                rowData={this.data.rowData2}
                _this={this}
            /> : <ag-grid
                ref="tableRef2"
                style={{ height: this.data.height2 || '200px' }}
                class="ag-theme-alpine"
                columnDefs={part.component.columnDefs}
                rowData={this.data.rowData2}
                on_cellMethod={this.cellAction}
                suppressRowClickSelection={true}
                on_gridReady={this.onGridReady2}
            >
            </ag-grid>
        }

    </div>
}