import { ID_Description, LoginData, MenuItem, MenuPermission } from '../types';
import { defineStore } from 'pinia'
import { from, groupBy, map, mergeMap, reduce } from "rxjs";
import { copyProp, getLastUrl, replaceStr } from "@/utile";
// export const { result: LocalMenu } = require('../../public/json/menu.json')
// @ts-ignore
import menuJson from '../../public/json/menu.json'
let LocalMenu = menuJson.result;

export default defineStore('menu', {
    state: () => {
        let menu: MenuItem[] = [];
        // @ts-ignore
        let menuMetaData: LoginData = {};
        let menuPermission: MenuPermission[] = [];
        let screenLoading: boolean = false;
        // @ts-ignore
        let trackBreadcrumb: { name: string } = null;

        let initDepartment: any = ''
        return {
            menu,
            menuMetaData,
            userName: '',
            menuPermission,
            screenLoading,
            trackBreadcrumb,
            /** 最后操作时间 */
            lastOperationTime: new Date(),

            /** Safety/MajorItemsInformation中巡查人职位初始值 在getinit时根据登录名筛选所得 
             * 2023.8.14,这个属性不需要了
             */
            initDepartment
        }
    },
    getters: {
        getStaffPost(): string {
            return this.menuMetaData.profile?.StaffPost || ''
        },
        getEnglishName(): string {
            return this.menuMetaData.profile?.EnglishName || ''
        },
        contractList(): ID_Description[] {
            return this.menuMetaData.Permission.ContractorNoPermission || []
        },
        SubConPermission(): ID_Description[] {
            return this.menuMetaData.Permission.SubConPermission || []
        },
        DistrictPermission(): ID_Description[] {
            return this.menuMetaData.Permission.DistrictPermission || []
        },

        activeMenu(state) {
            return state.menu.find(x => x.isActive == true);
        },
        activeMenus(state) {
            // @ts-ignore
            if (!this.activeMenu) {
                return [];
            }
            // @ts-ignore
            return [this.activeMenu, this.activeMenu.subMenu.find(x => x.isActive == true)];
        },
        token(state) {
            // @ts-ignore
            return state.menuMetaData.access_token
        },
        bearer(state) {
            // @ts-ignore
            return state.menuMetaData.token_type
        },
        profile(state) {
            // @ts-ignore
            return state.menuMetaData.profile
        },
        avatarUrl(state) {
            let arr = state.menuMetaData?.Config?.PersonalSettingList || [];
            if (arr.length) {
                let o = arr.find(x => x.GroupType == 'HeadImage');
                if (o) {
                    return o.SettingValue;
                }
            }
            return '';
        }
    },
    actions: {
        setStaffPost(x: string) {
            this.menuMetaData.profile.StaffPost = x || ''
        },
        saveAvatar(x: any) {
            let arr = this.menuMetaData?.Config?.PersonalSettingList || [];
            if (arr.length) {
                let o = arr.find(x => x.GroupType == 'HeadImage');
                if (o) {
                    o = Object.assign(o, x);
                }
            } else {
                arr.push(x)
            }
        },
        saveMenuMetaData_Permission(Permission: any) {
            Object.assign(this.menuMetaData.Permission, Permission)
        },
        saveMenuMetaData(menuMetaData: any) {
            this.menuMetaData = menuMetaData;
        },
        saveMenuPermission(menu: MenuPermission[]) {
            this.menuPermission = menu;
            this.getMenuMateData();
        },
        setUserName(userName: string) {
            this.userName = userName;
        },
        setTrackBreadcrumb(obj: { name: string }) {
            // this.trackBreadcrumb = obj;
        },
        getMenuMateData() {
            let menu: any[] = [];
            let menuMateData = this.menuPermission;
            let _menuMateData: MenuPermission[] = LocalMenu as MenuPermission[];
            if (!menuMateData) return;
            // 把登录后获取的菜单数据,更新到本地菜单
            _menuMateData.map(x => {
                let y = menuMateData.find(z => z.ModuleID == x.ModuleID);
                // y && copyProp.apply(x, [y]);
                if (y) {
                    x.Create = y.Create;
                    x.Delete = y.Delete;
                    x.Enquiry = y.Enquiry;
                    x.FirstMenu = y.FirstMenu;
                    x.Read = y.Read;
                    x.Report = y.Report;
                    x.Update = y.Update;
                }
            });
            from(_menuMateData)
                .pipe(
                    groupBy((p: MenuPermission) => p.ParentID),
                    mergeMap((group$: any) => group$.pipe(reduce((acc: any, cur: any) => [...acc, cur], [group$.key]))),
                    map((a: any) => {
                        let ParentID: number = a[0];
                        let arr = (a as Array<MenuPermission>).slice(1);
                        let group = new MenuItem();
                        let g = _menuMateData.find(z => z.MDID == ParentID);
                        if (arr.length >= 1 && g) {
                            group = this.handleMenu(g, arr);
                            return group;
                        }
                    })
                )
                .subscribe((group: any) => group && menu.push(group));
            let hasActive = menu.some(m => m && m.isActive);
            if (!hasActive && menu.length) menu[0].isActive = true;
            this.menu = this.clearParent(menu);

        },
        handleMenu(g: MenuPermission, s: MenuPermission[], parent?: MenuItem) {
            let group = new MenuItem();
            group.id = g.MDID.toString();
            group.name = g.MDDescription;
            group.Read = g.Read;
            group.Create = g.Create;
            group.Delete = g.Delete;
            group.Enquiry = g.Enquiry;
            group.Report = g.Report;
            group.Update = g.Update;
            group.isActive = false;
            group.isOpen = true;
            group.parentId = g.ParentID;
            group.ModuleID = g.ModuleID;
            group.RoutePath = g.RoutePath;
            group.FirstMenu = g.FirstMenu;
            parent && (group.parent = parent);
            group.route = parent ? `${parent.route}/${g.RoutePath}` : `/${g.RoutePath}`;

            group.subMenu = s
                .filter((x) => x.ParentID.toString() == group.id)
                .map((x) => this.handleMenu(x, s, group));
            group.FirstMenuRoute = this.getFirstMenu(group);

            const iconarr = [
                { idname: '1', icon: 'icon-24gl-shieldCheck' },
                { idname: '2', icon: 'icon-jianchabaogao1' },
                { idname: '3', icon: 'icon-navicon-chyj' },
                { idname: '12', icon: 'icon-shezhi2' },
            ]
            group.icon = iconarr.find(z => z.idname == g.MDID.toString())?.icon || ''

            return group;
        },
        getFirstMenu(group: MenuItem) {
            if (group.FirstMenu) {
                let FirstMenu = group.subMenu.find(x => x.id == group.FirstMenu + '');
                return FirstMenu ? FirstMenu.route : group.subMenu[0].route;
            } else if (group.subMenu.length) {
                return group.subMenu[0].route
            }
            return '';
        },
        clearParent(arr: any[]): any[] {
            return arr.map(x => {
                if (x) {
                    x.parent && delete x.parent;
                    if (x.subMenu) {
                        x.subMenu = this.clearParent(x.subMenu);
                    }
                    return x;
                }
            });
        },
        setActiveSubMenu(path: string) {
            this.menu.map(x => {
                if (x.isActive) {
                    x.subMenu.map(m => {
                        m.isActive = new RegExp(m.RoutePath, 'i').test(path);
                        if (m.isActive) {
                            let lastUrl = getLastUrl(path);
                            if (lastUrl != m.RoutePath) {
                                this.setTrackBreadcrumb({ name: lastUrl });
                            } else {
                                // @ts-ignore
                                this.setTrackBreadcrumb(null);
                            }
                        }
                    });
                }
            });

        }
    },
})