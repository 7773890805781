import DownloadService from "@/http/download";
import { isArray } from "@vue/shared";
import { Btn, TabProps } from "../types";
import { Api_handle_result_map, Fn_type } from "../types/map";
import { handel_api_after } from "./use_handle_api_before_after";

export default (e: Event | Array<any>, c: Btn, p: TabProps, refs: any, cb?: Function,) => {

    let files: any;
    if (isArray(e)) {
        files = e;
    } else {
        files = (e.target as HTMLInputElement).files || [];
    }
    // @ts-ignore
    DownloadService.upload(files).then((x: { FileList: { MD5: string, Path: string }[]; TempPath: string; Path: any[] }) => {
        let obj: any = {};
        if (c) {
            let UploadPath = x.FileList.length ? x.FileList[0].Path : '';
            obj = {
                UploadPath
            }
        }
        // http.notify('The upload was successful.');

        let afterArr: Api_handle_result_map[] = [];
        p.pageConfig?.api_after?.upload && handel_api_after(obj, p.pageConfig?.api_after?.upload, refs, (fn: Fn_type, o: Api_handle_result_map) => {
            if (cb) {
                cb?.(fn, obj, o)
            }
        });
    }).catch(() => { });
}
