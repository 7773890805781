import { computed, defineComponent } from "vue";
import { ArrowRightOutlined, CaretRightOutlined } from "@/antd-icon";
import style from './track.module.scss';
import useStore from '@/store/metaData';
import { MenuItem } from "@/types";
import GlobalSearchService from '@/share/global-search.service';
export default defineComponent({
    components: { CaretRightOutlined, ArrowRightOutlined },
    props: ['menu'],
    setup() {
        const store = useStore();

        let menu = computed(() => {
            if (store.trackBreadcrumb) {
                // @ts-ignore
                return [...store.activeMenus, store.trackBreadcrumb]
            }
            // @ts-ignore
            return store.activeMenus;
        });
        const SearchSlots = computed(() => GlobalSearchService.slots.default);

        return {
            menu,
            SearchSlots
        };
    },
    render() {
        const slots = {
            separator: () => <i class="iconfont icon-youjiantou " style={'width: 0.002px;height: 0.002px;'} />
            // separator: ">"
        };

        return (
            <div class={{ "text-left": true, "flex": true, [style.host]: true }}>
                <a-breadcrumb class={{ [style.breadcrumb]: true, 'breadcrumb': true }} v-slots={slots}>
                    {
                        this.menu.map((x: MenuItem) => {
                            return (
                                <a-breadcrumb-item class={{ [style['breadcrumb-item']]: true, 'breadcrumb-item': true }}>{x?.name}
                                </a-breadcrumb-item>
                            )
                        })
                    }
                </a-breadcrumb>
                {
                    this.SearchSlots ? this.SearchSlots : ''
                }
            </div>
        )
    }
})