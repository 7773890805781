// import { Observable } from "rxjs";
import dayjs, { Dayjs } from 'dayjs';

export interface FormlyFieldGroup {
    /** fieldGroupClassName */
    className?: string;
    /** fieldGroup */
    fieldGroup: FormlyFieldConfig[];
}
export type FormlyFieldType =
    'Text' |
    'AutoComplete' |
    'Button' |
    'SubsectionCard' |
    'Cascader' |
    'Checkbox' |
    'CheckboxGroup' |
    'CheckboxGroup_Radio' |
    'CheckboxGroup_Button_Radio' |
    'DatePicker' |
    'Form' |
    'Input' |
    'InputSearch' |
    'TextArea' |
    'Password' |
    'InputNumber' |
    'Radio' |
    'RadioGroup' |
    'Rate' |
    'Select' |
    'Slider' |
    'Switch' |
    'TimePicker' |
    'Upload' |
    'Placeholder' |
    'CheckboxText' |
    'Array' |
    'CheckboxList' |
    'ConditionList' |
    'MultipleButton' |
    'List' |
    'MultipleSelect'
    ;
export enum FormlyFieldTypeEnum {
    Button = "Button",
    SubsectionCard = "SubsectionCard",
    Text = 'Text',
    AutoComplete = 'AutoComplete',
    Cascader = 'Cascader',
    Checkbox = 'Checkbox',
    CheckboxGroup = 'CheckboxGroup',
    CheckboxGroup_Radio = 'CheckboxGroup_Radio',
    CheckboxGroup_Button_Radio = 'CheckboxGroup_Button_Radio',
    DatePicker = 'DatePicker',
    Form = 'Form',
    Input = 'Input',
    InputSearch = 'InputSearch',
    TextArea = 'TextArea',
    Password = 'Password',
    InputNumber = 'InputNumber',
    Radio = 'Radio',
    RadioGroup = 'RadioGroup',
    Rate = 'Rate',
    Select = 'Select',
    Slider = 'Slider',
    Switch = 'Switch',
    TimePicker = 'TimePicker',
    Upload = 'Upload',
    CheckboxText = 'CheckboxText',
    Placeholder = 'Placeholder',
    Array = 'Array',
    CheckboxList = 'CheckboxList',
    ConditionList = 'ConditionList',
    MultipleButton = 'MultipleButton',
    List = 'List',
    MultipleSelect = 'MultipleSelect'
}

export class FormlyFieldConfig {
    key: string = '';
    /** 'Text' |
    'AutoComplete' |
    'Button' |
    'SubsectionCard' |
    'Cascader' |
    'Checkbox' |
    'CheckboxGroup' |
    'CheckboxGroup_Radio|
    'CheckboxGroup_Button_Radio'|
    'DatePicker' |
    'Form' |
    'Input' |
    'InputSearch' |
    'TextArea' |
    'Password' |
    'InputNumber' |
    'Radio' |
    'RadioGroup' |
    'Rate' |
    'Select' |
    'Slider' |
    'Switch' |
    'TimePicker' |
    'Upload' |
    'Placeholder' |
    'CheckboxText' |
    'Array' ; */
    type?: FormlyFieldType;
    /**
     * If you wish, you can specify a specific `name` for your field. This is useful if you're posting the form to a server using techniques of yester-year.
     */
    name?: string;
    /**
     * Whether to hide the field. Defaults to false. If you wish this to be conditional use `hideExpression`
     */
    hide?: boolean;
    /**
     * You can specify your own class that will be applied to the `formly-field` component.
     */
    className?: string;
    /**
     * Use `defaultValue` to initialize it the model. If this is provided and the value of the model at compile-time is undefined, then the value of the model will be assigned to `defaultValue`.
     */
    defaultValue?: any;
    templateOptions?: FormlyTemplateOptions;
    /**
     * A field group is a way to group fields together, making advanced layout very simple.
     * It can also be used to group fields that are associated with the same model (useful if it's different than the model for the rest of the fields).
     */
    fieldGroup?: FormlyFieldConfig[];
    fieldArray?: FormlyFieldGroup[];
    /**
     * Safety>Summary表单中，判断是否加ant-col
     *  */
    cname?: 'nocol';
    /**
     * Safety>Summary表单中，判断是否加card
     */
    cname2?: 'nocard';
    getcolumnDefs?: any
}

export interface FormlyTemplateOptions {
    /** 附带标识 */
    attachedIdentify?: 'Add' | 'Insert' | 'Reset' | 'Update';
    /** 按钮类型 或 其它 */
    type?: string | 'primary' | 'ghost' | 'dashed' | 'link' | 'text' | 'default';
    /** 按钮文字 */
    btnText?: any;
    /**
     * label 标签的文本
     * @see https://next.antdv.com/components/form-cn#API
     */
    label?: string;

    transform?: (x: any) => string
    /** label的宽度 */
    labelWidth?: string;
    /** 在row里所占 col */
    span?: number;
    offset?: number;
    /** Text时有用 */
    style?: any;
    formItemStyle?: any;
    /** label 标签布局，同 <Col> 组件，设置 span offset 值，如 {span: 3, offset: 12} 或 sm: {span: 3, offset: 12} */
    labelCol?: Object;
    /** 需要为输入控件设置布局样式时，使用该属性，用法同 labelCol */
    wrapperCol?: Object;
    /** 前缀 */
    prefix?: any;
    /** 后缀 */
    suffix?: any;
    /**button图标 */
    icon?: any;
    iconstyle?: any;
    /** 标签文本对齐方式 */
    labelAlign?: 'left' | 'right';
    /** 关闭，按数量自动计算flex-base的宽度百分比 */
    noFlexBase?: boolean
    isRow?: boolean;
    rules?: object | Array<any>;
    placeholder?: string;
    disabled?: boolean;
    format?: string;
    showTime?: boolean;
    options?: any[] /* | Observable<any[]> */;
    rows?: number;
    cols?: number;
    description?: string;
    hidden?: boolean;
    max?: number;
    min?: number;
    minLength?: number;
    maxLength?: number;
    /** 根据规则计算出的来的文字 */
    errorText?: string;
    /** 错误文本 比 errorText 权重高 */
    importantErrorText?: string;
    showErrorTooltip?: boolean;
    // @ts-ignore
    pattern?: any;
    /** 和其它字段相等 密码和确认密码验证 */
    equalTarget?: string;
    /** 是否必填，如不设置，则会根据校验规则自动生成 */
    required?: boolean;
    change_clear_Fields?: string[];
    tabindex?: number;
    readonly?: boolean;
    attributes?: {
        [key: string]: string | number;
    };
    step?: number;
    /** 配合 label 属性使用，表示是否显示 label 后面的冒号	 */
    colon?: boolean;
    /**
     * 额外的提示信息，和 help 类似，当需要错误信息和提示文案同时出现时，可以使用这个。
     * @see https://next.antdv.com/components/form-cn#API
     */
    extra?: string;
    /** 
     * 配合 validateStatus 属性使用，展示校验状态图标，建议只配合 Input 组件使用 
     * @see https://next.antdv.com/components/form-cn#API
     */
    hasFeedback?: boolean;
    /**
     * 提示信息，如不设置，则会根据校验规则自动生成
     * @see https://next.antdv.com/components/form-cn#API
     */
    help?: string;
    /**
     * 设置子元素 label htmlFor 属性
     * @see https://next.antdv.com/components/form-cn#API
     */
    htmlFor?: string;
    /** 校验状态，如不设置，则会根据校验规则自动生成，可选：'success' 'warning' 'error' 'validating' */
    validateStatus?: string;
    /** 当某一规则校验不通过时，是否停止剩下的规则的校验。antd 2.0 */
    validateFirst?: boolean;
    /** 设置字段校验的时机 antd 2.0 */
    validateTrigger?: string;
    /** Placeholder 要 真正显示 的内容 */
    ref?: any;
    PopupContaine?: any
    rowSpan?: any
    // focus?: FormlyAttributeEvent;
    // blur?: FormlyAttributeEvent;
    // keyup?: FormlyAttributeEvent;
    // keydown?: FormlyAttributeEvent;
    // click?: FormlyAttributeEvent;
    // change?: FormlyAttributeEvent;
    // keypress?: FormlyAttributeEvent;
    // templateManipulators?: TemplateManipulators;
    [additionalProperties: string]: any;
    /**是否有显示其它 */
    add_target?: boolean;
    /**显示其他的配置 */
    add_target_config?: Array<add_target_config>;

    attchValue?: any;
    allowClear?: boolean
    _options?: any[]
    /** 不可选择的日期	(currentDate: dayjs) => boolean	无	 */
    disabledDate?: (currentDate: Dayjs) => boolean;
    /**是否显示增减按钮 */
    controls?: boolean
}
export interface add_target_config {
    /**根据这个获取当前选中的那一条数据 */
    target: 'value' | 'ID';
    pkey?: string;
    /**当前的value等于这个值展示其它 */
    key?: string;
    /**展示的其它所对应的value */
    mapkey?: string;
    /**展示的其他是什么类型的 */
    otherType?: FormlyFieldType;
    /**自定义的options */
    op?: any;
    /** */
    select?: boolean
}
export interface ErrorField {
    errors: string[];
    name: string[];
    warnings: string[];
}

export interface SubmitFailedEvent {
    values: any;
    errorFields: ErrorField[];
    outOfDate: boolean;
}

export interface Form_Instance {
    // @ts-ignore
    fields: Proxy
    formID: string;
    // @ts-ignore
    formRef: Proxy
    getFormControlRules: (item: any) => {};
    handleCheckboxValueChange: (obj: any, key: any) => {};
    handleInput: (e: any, key: any) => {};
    handleRadioValueChange: (obj: any, key: any) => {};
    handleUploadChange: (info: any, key: any) => {};
    handleValueChange: (value: any, key: any) => {};
    labelWidth: string;
    // @ts-ignore
    model: Proxy
    onFinish: () => {};
    onFinishFailed: (e: any) => {};
    onSubmit: () => {};
    onValidate: () => {};
    size: any;
}

export interface Form_Instance {
    /** 触发表单提交事件 */
    submit: () => {};
    /** 表单重置 */
    reset: (arg?: any) => {};
}
export interface MQ {
    isDark: boolean;
    isInert: boolean;
    isLandscape: boolean;
    isLight: boolean;
    isMotion: boolean;
    isPortrait: boolean;
    lg: boolean;
    lgMinus: boolean;
    lgPlus: boolean;
    md: boolean;
    mdMinus: boolean;
    mdPlus: boolean;
    sm: boolean;
    smMinus: boolean;
    smPlus: boolean;
    xl: boolean;
    xlMinus: boolean;
    xlPlus: boolean;
    xs: boolean;
    xxl: boolean;
    current: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
    motionPreference: string;
    orientation: string;
    theme: string;
}