import { FormlyFieldConfig, FormlyFieldGroup } from '@/components/form/types';
import { findOne } from './transform';

export function SetSelectOptions(fields: FormlyFieldGroup[], field: string, cb: () => Array<{ label: string; value: any }>): FormlyFieldConfig {
    let f: FormlyFieldConfig;
    fields.some(x => x.fieldGroup.some(y => {
        if (y.key == field && y.templateOptions) {
            y.templateOptions.options = cb();
            f = y;
        }
        return y.key == field;
    }));
    // @ts-ignore
    return f;
}

export function SetDisabled(fields: FormlyFieldGroup[], field: string, isDisabled: boolean = true): FormlyFieldConfig {
    let f: FormlyFieldConfig;
    fields.some(x => x.fieldGroup.some(y => {
        if (y.key == field && y.templateOptions) {
            y.templateOptions.disabled = isDisabled;
            f = y;
        }
        return y.key == field;
    }));
    // @ts-ignore
    return f;
}
export function SetRequired(fields: FormlyFieldGroup[], field: string, isDisabled: boolean = true): FormlyFieldConfig {
    let f: FormlyFieldConfig;
    fields.some(x => x.fieldGroup.some(y => {
        if (y.key == field && y.templateOptions) {
            y.templateOptions.required = isDisabled;
            f = y;
        }
        return y.key == field;
    }));
    // @ts-ignore
    return f;
}
export function SetPlaceHoderFieldsRef(fields: FormlyFieldGroup[], field: string, ref: any): FormlyFieldConfig {
    let f: FormlyFieldConfig;
    fields.some(x => x.fieldGroup.some(y => {
        if (y.key == field && y.templateOptions) {
            y.templateOptions.ref = ref;
            f = y;
        }
        return y.key == field;
    }));
    // @ts-ignore
    return f;
}
/* 
    export function SetSelectOptionsFormArray(form: FormGroup, pControlName: string, controlName: string, index: number, cb: () => Array<KV> | Array<any>): GF_select {
        let control: C_FormArray = (form.controls[pControlName] as any); //._fields
        
        if (control) {
            let fields = control._fields;
            if (fields && fields.length > 0 && fields[0]) {
                let field: GF_select = fields[0];
                let f = field?.fieldGroup[index];
                let c = f?.fieldGroup[0];
                let c_field = findOne(c.fieldGroup, controlName, 'key');
                c_field.templateOptions.options = cb();
                return c_field;
            }
        }

        return null;
    }
  

    export function SetTemplate(form: FormGroup, controlName: string, ref: any): GF_base {
        let control = (form.controls[controlName] as any); //._fields
        if (control) {
            let fields = control._fields;
            if (fields && fields.length > 0) {
                let field: GF_base = fields[0];
                field.templateOptions.ref = ref;
                return field;
            }
        }
    }
    export function SetEnablePart(form: FormGroup, controlName: string, enableParts: number[]): GF_base {
        let control = (form.controls[controlName] as any); //._fields
        if (control) {
            let fields = control._fields;
            if (fields && fields.length > 0) {
                let field: GF_base = fields[0];
                field.templateOptions.enableParts = enableParts;
                return field;
            }
        }
    }
    export function SetRequired(form: FormGroup, controlName: string, Required: boolean = true): GF_base {
        let control = (form.controls[controlName] as any); //._fields
        if (control) {
            let fields = control._fields;
            if (fields && fields.length > 0) {
                let field: GF_base = fields[0];
                field.templateOptions.required = Required;
                return field;
            }
        }
    } 
*/
/**
 * 
 * @param key 
 * @returns 
 */
export const getItem = (key: string, fields: FormlyFieldGroup[]): FormlyFieldConfig => {
    // @ts-ignore
    let item: FormlyFieldConfig = null;
    let arr = fields;
    arr.some((x: FormlyFieldGroup) => x.fieldGroup.some(y => {
        if (y.key == key) item = y;
    }))
    return item;
}