import { SortEventByAgGrid } from '@/types/table';
import { ColumnApi, ICellRendererParams, RowNode } from 'ag-grid-community';
import { onUnmounted, reactive, ref } from 'vue';
import { AgGridRowEvent, Grid, GridEvent } from "./types/grid";


export default function useGridEvent(p: any, emit: any) {
    const grid = reactive<Grid>({
        api: undefined,
        columnApi: undefined,
    });

    const gridReady = (params: GridEvent) => {
        grid.api = params.api;
        grid.columnApi = params.columnApi;
        setTimeout(() => {
            params.api.sizeColumnsToFit();
            setTimeout(() => {
                params.api.sizeColumnsToFit();
            }, 10);
        }, 10);
        // 添加
        window.addEventListener('resize', sizeColumnsToFit);
        emit('_gridReady', params)
    }

    const sizeColumnsToFit = () => {
        setTimeout(function () {
            grid?.api?.sizeColumnsToFit?.();
            setTimeout(function () {
                grid?.api?.sizeColumnsToFit?.();
            }, 200);
        }, 20);
    }
    onUnmounted(() => {
        // 移除
        window.removeEventListener('resize', sizeColumnsToFit);
    })

    const cellValueChanged = (e: any) => {
        emit('_cellValueChanged', e);
    }
    const rowSelected = (e: any) => {
        emit('_rowSelected', e);
    }
    const clearSelect = () => {
        grid.api?.deselectAll();
    }
    const selectionChanged = () => {
        var selectedRows = grid.api?.getSelectedRows();
        emit('_selectionChanged', selectedRows);
    }
    const cellClicked = (e: AgGridRowEvent) => {
        emit('_cellClicked', e);
    }
    const cellDoubleClicked = (e: AgGridRowEvent) => {
        emit('_cellDoubleClicked', e);
    }
    const rowDragEnd = (e: any) => {
        emit('_rowDragEnd', e);
    }
    const rowDragMove = (e: any) => {
        emit('_rowDragMove', e);
    }
    const rowClicked = (e: AgGridRowEvent) => {
        return
        if (!p.clickSelect) return;
        let node = e.node;
        if (node.isSelected()) {
            node.setSelected(false);
        } else if (!p.suppressRowClickSelection) {
            node.setSelected(true);
        }
        emit('_rowClicked', e);
    }
    const rowDoubleClicked = (e: AgGridRowEvent) => {
        emit('_rowDoubleClicked', e);
        if (!p.doubleClickSelect) return;
        if (!p.clickSelect) {
            let node = e.node;
            if (node.isSelected()) {
                node.setSelected(false);
            } else if (!p.suppressRowClickSelection) {
                node.setSelected(true);
            }
            emit('_rowClicked', e);
        }
    }
    const sortChanged = (e: { columnApi: ColumnApi }) => {
        let arrs = e.columnApi.getAllColumns()
        let colum: SortEventByAgGrid;
        let hasSort = arrs?.some(x => {
            if (x.getSort()) {
                colum = {
                    row: x,
                    sort: (x.getSort() as 'asc' | 'desc')
                };
                return x;
            }
        });
        // @ts-ignore
        if (hasSort) emit('_sortChanged', colum);
        else emit('_sortChanged', null);
    }
    const getAllRows = () => {
        let rowData: any[] = [];
        grid.api?.forEachNode(node => rowData.push(node.data));
        return rowData;
    }
    const getAllNodes = (): RowNode[] => {
        let nodes: RowNode[] = [];
        grid.api?.forEachNode(node => nodes.push(node));
        return nodes;
    }
    const methodFromCell = (params: ICellRendererParams) => {
        emit('_cellMethod', params);
    }

    return {
        grid,
        gridReady,

        cellValueChanged,
        rowSelected,
        clearSelect,
        selectionChanged,
        cellClicked,
        cellDoubleClicked,
        rowDragEnd,
        rowDragMove,
        rowClicked,
        rowDoubleClicked,
        sortChanged,
        getAllRows,
        getAllNodes,
        methodFromCell,
    }
}