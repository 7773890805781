import { ListResult } from '@/components/form-list-group/types/types';
import DownloadService from '@/http/download';
import verifyPhoto from "@/pipe/verifyPhoto";
import useStore from '@/store/metaData';
import { getFileName, getObjectByMap, getPrefixString, handleDate } from "@/utile";
import { AgGridRowEvent_e } from '.';
import { DocumentsList } from '../../types';

export const crud = (p: { options: DocumentsList, id: string }, obj: any) => {
    const { model, data, tableRef, canSave, canDelete, setRowData, photoRef } = obj

    // let api: GridApi = tableRef.value.grid.api
    let PrimaryKey = p.options.pageConfig?.formPrimaryKey;
    const store = useStore();

    const cellAction = (e: AgGridRowEvent_e) => {
        switch (e.currentAction.toLowerCase()) {
            case 'Save'.toLowerCase(): save(e); break;
            case 'Delete'.toLowerCase(): del(e); break;
            case 'Upload'.toLowerCase(): upload(e); break;
            case 'Download'.toLowerCase(): download(e); break;
            case 'Search'.toLowerCase(): search(e); break;
            case 'Rotate'.toLowerCase(): rotate(e); break;
        }
    }
    const rotate = (e: AgGridRowEvent_e,) => {
        // let obj: any = { ...e.node.data, Source: 'T' }
        // obj = { ...obj, ...getObjectByMap(p.options.httpParamsMap?.photoRotate || []) };
        // p.options.http?.photoRotate && p.options.http?.photoRotate(obj).then((x: { FilePath: string }) => {
        //     e.node.data.FilePath = x.FilePath;
        //     e.node.setData(e.node.data);
        // });
        showPhotoEditer(e)
    }
    const add = () => {
        let obj: any = { FID: p.id, ...model, LoginName: store.userName, Source: 'T' }
        obj = { ...obj, ...getObjectByMap(p.options.httpParamsMap?.add || []) };
        p.options.http?.add && p.options.http?.add(obj).then((x: ListResult) => {
            // data.rowData = x.TableData || x.Data;
            setRowData(x.TableData || x.Data)
        })
    }
    const saveList = () => {
        // @ts-ignore
        let DocumentList = tableRef.value.grid.api.getSelectedRows()
        let obj: any = { FID: p.id, LoginName: store.userName, [p.options.batchEditList_params_fields_name || '']: DocumentList }
        obj = { ...obj, ...getObjectByMap(p.options.httpParamsMap?.editByList || []) };
        obj = handleDate(obj);
        p.options.http?.editByList && p.options.http?.editByList(obj).then((x: ListResult) => {
            // data.rowData = x.TableData || x.Data;
            setRowData(x.TableData || x.Data)
            p.options.http?.notify('The Edit was successful.');
            canSave.value = false;
            canDelete.value = false;
        });
    }
    const deleteList = () => {
        p.options.http?.confirm({
            title: 'Information',
            content: 'Do you want to delete it permanently',
            onOk: () => {
                // @ts-ignore
                let DocumentIDList = tableRef.value.grid.api.getSelectedRows().map((x: any) => x[PrimaryKey]);
                let obj: any = { FID: p.id, LoginName: store.userName, [p.options.batchDeleteList_params_fields_name || '']: DocumentIDList }
                p.options.http?.deleteByList && p.options.http?.deleteByList(obj).then((x: ListResult) => {
                    // data.rowData = x.TableData || x.Data;
                    setRowData(x.TableData || x.Data)
                    p.options.http?.notify('The deletion was successful.');
                    canSave.value = false;
                    canDelete.value = false;
                });
            }
        })
    }
    const save = (e: AgGridRowEvent_e, isUpload?: boolean) => {
        let obj: any = { FID: p.id, LoginName: store.userName, ...e?.data, Source: 'T' }
        obj = { ...obj, ...getObjectByMap(p.options.httpParamsMap?.edit || [], e?.data) }
        obj = handleDate(obj);
        p.options.http?.edit && p.options.http?.edit(obj).then((x: ListResult) => {
            e.node.setData(x.CurrentEntity);
            // data.rowData = x.TableData || x.Data;
            setRowData(x.TableData || x.Data)


            isUpload ? p.options.http?.notify('The Upload was successful.') : p.options.http?.notify('The Edit was successful.');
        });
    }
    const del = (e: AgGridRowEvent_e) => {
        p.options.http?.confirm({
            title: 'Information',
            content: 'Do you want to delete it permanently',
            onOk: () => {
                let obj: any = { FID: p.id, LoginName: store.userName, Source: 'T' }
                if (PrimaryKey) {
                    obj[PrimaryKey] = e?.data[PrimaryKey]
                }
                p.options.http?.del && p.options.http?.del(obj).then((x: ListResult) => {
                    // data.rowData = x.TableData || x.Data;
                    setRowData(x.TableData || x.Data)

                    p.options.http?.notify('The deletion was successful.');
                });
            }
        })

    }
    const upload = (e: AgGridRowEvent_e) => {
        // @ts-ignore
        DownloadService.upload(e.file.fileList).then((x: { FileList: { MD5: string, Path: string }[]; TempPath: string; }) => {
            if (x.FileList.length) {
                e.node.setDataValue('UploadFilePath', x.FileList[0].Path);
                save(e, true)
            }
        })
    }
    const download = (e: AgGridRowEvent_e) => {
        let Path: string = getPrefixString(e.data.FilePath);
        let obj = {
            SourceFrom: 1,
            Path,
            FileName: getFileName(Path)
        }
        DownloadService.download(obj).then((x: any) => {
            DownloadService.downloadFileByBlob(x, getFileName(Path));
        })
    }
    const search = (e: AgGridRowEvent_e) => {
        let FilePath = e.data.FilePath;
        if (FilePath) {
            FilePath = verifyPhoto(FilePath);
            window.open(FilePath)
        }
        p.options.http?.error('No files!');
    }
    const downloadlist = () => {
        let obj: any = { ID: p.id, LoginName: store.userName }

        p.options.http?.toZip && p.options.http?.toZip(obj).then((x: ListResult) => {
            DownloadService.downloadFileByUrl(x.ExcelPath);
        });


    }


    const del_m = (e: any) => {
        p.options.http?.confirm({
            title: 'Information',
            content: 'Do you want to delete it permanently',
            onOk: () => {
                let obj: any = { FID: p.id, LoginName: store.userName, Source: 'T' }
                if (PrimaryKey) {
                    obj[PrimaryKey] = e[PrimaryKey]
                }
                p.options.http?.del && p.options.http?.del(obj).then((x: ListResult) => {
                    setRowData(x.TableData || x.Data)
                    p.options.http?.notify('The deletion was successful.');
                });
            }
        })
    }
    const rotate_m = (e: any) => {
        // let obj: any = { ...e, Source: 'T' }
        // obj = { ...obj, ...getObjectByMap(p.options.httpParamsMap?.photoRotate || []) };
        // p.options.http?.photoRotate && p.options.http?.photoRotate(obj).then((x: { FilePath: string }) => {
        //     e.FilePath = x.FilePath;
        // });
        showPhotoEditer(e, true)

    }
    const download_m = (e: any) => {
        let Path: string = getPrefixString(e.FilePath);
        let obj = {
            SourceFrom: 1,
            Path,
            FileName: getFileName(Path)
        }
        DownloadService.download(obj).then((x: any) => {
            DownloadService.downloadFileByBlob(x, getFileName(Path));
        })
    }
    const save_m = (e: any, isUpload?: boolean) => {
        let obj: any = { FID: p.id, LoginName: store.userName, ...e, Source: 'T' }
        obj = { ...obj, ...getObjectByMap(p.options.httpParamsMap?.edit || [], e) }
        obj = handleDate(obj);
        p.options.http?.edit && p.options.http?.edit(obj).then((x: ListResult) => {
            setRowData(x.TableData || x.Data)
            isUpload ? p.options.http?.notify('The Upload was successful.') : p.options.http?.notify('The Edit was successful.');
        });
    }
    const deleteList_m = () => {
        if (data.rowData.length)
            p.options.http?.confirm({
                title: 'Information',
                content: 'Do you want to delete it permanently',
                onOk: () => {

                    let DocumentIDList: string[] = []
                    data.rowData.map((x: any) => DocumentIDList.push(x[PrimaryKey || '']))

                    let obj: any = { FID: p.id, LoginName: store.userName, [p.options.batchDeleteList_params_fields_name || '']: DocumentIDList }
                    p.options.http?.deleteByList && p.options.http?.deleteByList(obj).then((x: ListResult) => {
                        data.rowData = x.TableData || x.Data;
                        // setRowData(x.TableData || x.Data)
                        p.options.http?.notify('The deletion was successful.');
                        // canSave.value = false;
                        // canDelete.value = false;
                    });
                }
            })
    }
    const saveList_m = () => {
        if (!data.rowData.length) return;
        let obj: any = { FID: p.id, LoginName: store.userName, [p.options.batchEditList_params_fields_name || '']: data.rowData }
        obj = { ...obj, ...getObjectByMap(p.options.httpParamsMap?.editByList || []) };
        obj = handleDate(obj);
        p.options.http?.editByList && p.options.http?.editByList(obj).then((x: ListResult) => {
            data.rowData = x.TableData || x.Data;
            // setRowData(x.TableData || x.Data)
            p.options.http?.notify('The Edit was successful.');
            // canSave.value = false;
            // canDelete.value = false;
        });
    }
    const savePhoto = (photo: { ID: string, file: string }) => {
        let obj = {
            Base64String: photo.file, ID: photo.ID,
            LoginName: store.userName, UpdateBy: store.userName
        }
        p.options.http?.photoEdit && p.options.http?.photoEdit(obj).then((x: { FilePath: string, ID: string }) => {
            p.options.http?.notify('Saved successfully..');
            data.rowData = data.rowData.map((y: any) => {
                if (y.ID == x.ID) {
                    y.FilePath = x.FilePath
                }
                return y
            })
        });

    }
    const showPhotoEditer = (e: any, isMobile: boolean = false) => {
        if (isMobile) {
            photoRef.value.Open({ ID: e.ID, path: e.FilePath });
        } else {
            photoRef.value.Open({ ID: e.data.ID, path: e.data.FilePath });
        }
    }
    return {
        cellAction,
        add,
        saveList,
        deleteList,
        downloadlist,
        deleteList_m,
        saveList_m,
        iconfn: {
            del_m, rotate_m, download_m, save_m
        },
        savePhoto, showPhotoEditer
    }
}