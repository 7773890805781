import { defineComponent, inject, ref } from "vue";
import { ReloadOutlined } from '@/antd-icon';
import './index.scss'
import { Pagination } from "@/utile";

export default defineComponent({
    components: { ReloadOutlined },
    data() {
        return {
            current: 1
        };
    },
    props: {
        pagination: {
            default: () => new Pagination()
        },
        showRefresh: {
            type: Boolean
        },
        isAntdv: {
            type: Boolean
        },
        hidePaginItem: {
            type: Boolean,
            default: () => false
        },
        hideTotal: {
            type: Boolean,
            default: () => false
        },
        showLessItems: {
            type: Boolean,
            default: () => false
        },
        viewbtnname: {
            type: Boolean,
            default: () => false
        }
    },
    emits: ["pageChange", "refresh"],
    setup(p: any, obj: any) {
        const onChange = (current: number, pageSize: number) => {
            p.pagination.currentPage = current
            obj.emit("pageChange", pageInformation());
        };
        const onShowSizeChange = (current: number, pageSize: number) => {
            p.pagination.currentPage = current;
            p.pagination.pageSize = pageSize;
            p.pagination.totalPages = Math.ceil(p.pagination.totalRecords / pageSize);
            // obj.emit("pageChange", pageInformation());
        };

        // -----------------------
        const mouseEnter = () => {

        }
        const goPage = (e: any) => {
            if (e.keyCode == 13) {
                location();
            }
        }
        const location = () => {
            if ((p.pagination.currentPage || 0) > (p.pagination.totalPages || 0))
                p.pagination.currentPage = p.pagination.totalPages;
            if ((p.pagination.currentPage || 1) <= 0) p.pagination.currentPage = 1;
            p.pagination.currentPage = parseInt(p.pagination.currentPage + '');
            obj.emit("pageChange", pageInformation());
        }
        const rowsPerPageOptionsChange = () => {
            p.pagination.currentPage = 1;
            obj.emit("pageChange", pageInformation());
        }
        const first = () => {
            p.pagination.currentPage = 1;
            obj.emit("pageChange", pageInformation());
        }
        const last = () => {
            p.pagination.currentPage = p.pagination.totalPages;
            obj.emit("pageChange", pageInformation());
        }
        const previous = () => {
            p.pagination.currentPage != undefined && (p.pagination.currentPage = p.pagination.currentPage - 1);
            obj.emit("pageChange", pageInformation());
        }
        const next = () => {
            p.pagination.currentPage != undefined && (p.pagination.currentPage = p.pagination.currentPage + 1);
            obj.emit("pageChange", pageInformation());
        }
        const pageInformation = () => {
            let data = {
                currentPage: p.pagination.currentPage,
                pageSize: parseInt(p.pagination.pageSize + ''),
            };
            p.pagination.currentPage = p.pagination.currentPage;
            return data;
        }
        const p_refresh = () => {
            obj.emit("refresh");
        }
        return {
            onChange,
            onShowSizeChange,



            mouseEnter,
            goPage,
            location,
            rowsPerPageOptionsChange,
            first,
            last,
            previous,
            next,
            pageInformation,
            p_refresh,
        }
    },
    render() {
        const isMobile: any = inject('isMobile')
        const paslot = {
            buildOptionText: (props: any) => <span>{props.value}</span>,
            itemRender: ({ type, originalElement }: any) => {
                if (type === 'prev') {
                    return <a-button>上一頁</a-button>
                } else if (type === 'next') {
                    return <a-button >下一頁</a-button>
                } else {
                    return <a>{originalElement}</a>
                }
            }
        }
        const R = () => {
            if (this.hidePaginItem) {
                return <div class={{ "paginationhost margin-top-0": true }}>
                    <div class={`pageWrap flex align-items-center justify-content-end`}>
                        <div class={"left"}>
                            顯示&nbsp;
                            <span class={"red"}>
                                {this.pagination.currentPage ? this.pagination.currentPage : 1}&nbsp;
                                <p class={"black"}>-</p>&nbsp;
                                {this.pagination.totalPages}
                            </span>
                            &nbsp;的&nbsp;
                            <span class={"red"}>{this.pagination.totalRecords}</span>&nbsp;結果
                        </div>
                    </div >
                </div >
            } else if (this.hideTotal) {
                return <div class={{ "paginationhost": true, 'paginationhost-m': isMobile.value }}>
                    <div class={`pageWrap flex align-items-center justify-content-center`}>
                        <a-pagination
                            class={"right"}
                            current={this.pagination.currentPage}
                            pageSize={this?.viewbtnname ? 9999 : this.pagination.pageSize}
                            pageSizeOptions={this.pagination.rowsPerPageOptions}
                            onChange={this.onChange}
                            onShowSizeChange={this.onShowSizeChange}
                            total={this.pagination.totalRecords}
                            showLessItems={this.showLessItems}
                            v-slots={paslot}
                        >
                        </a-pagination>
                    </div >
                </div >
            } else {
                return <div class={{ "paginationhost": true }}>
                    <div class={`pageWrap flex align-items-center justify-content-between`}>
                        <div class={"left"}>
                            顯示&nbsp;
                            <span class={"red"}>
                                {this.pagination.currentPage ? this.pagination.currentPage : 1}&nbsp;
                                <p class={"black"}>-</p>&nbsp;
                                {this.pagination.totalPages}
                            </span>
                            &nbsp;的&nbsp;
                            <span class={"red"}>{this.pagination.totalRecords}</span>&nbsp;結果
                            {
                                this.$slots.leftBtnSlot?.()
                            }
                        </div>
                        <a-pagination
                            class={"right"}
                            current={this.pagination.currentPage}
                            pageSize={this?.viewbtnname ? 9999 : this.pagination.pageSize}
                            pageSizeOptions={this.pagination.rowsPerPageOptions}
                            onChange={this.onChange}
                            onShowSizeChange={this.onShowSizeChange}
                            total={this.pagination.totalRecords}
                            showLessItems={this.showLessItems}
                            v-slots={paslot}
                        >
                        </a-pagination>
                    </div >
                </div >
            }
        }
        return (
            <>
                {R()}
            </>
        )
    }
})