import videojs from "video.js";
import Player from 'video.js/dist/types/player';
import "video.js/dist/video-js.css";
import { defineComponent, onBeforeMount, onMounted, onUnmounted, reactive, ref, toRefs } from 'vue';

export default defineComponent({
    setup() {
        let player: Player; //vue有bug 这个写成响应式数据 切换video地址会加载不出来
        let myPlay = ref<HTMLVideoElement>();
        let init = reactive({
            options: defaultOption,
        })
        onMounted(() => {
            player = videojs(myPlay.value!, init.options)

            player.on("loadstart", function () {
                // console.log("开始请求数据 ");
            });
            player.on("progress", function () {
                // console.log("正在请求数据 ");
            });
            player.on("loadedmetadata", function () {
                // console.log("获取资源长度完成 ");
            });
            player.on("canplaythrough", function () {
                // console.log("视频源数据加载完成");
            });
            player.on("waiting", function () {
                // console.log("等待数据");
            });
            player.on("play", function () {
                // console.log("视频开始播放");
            });
            player.on("playing", function () {
                // console.log("视频播放中");
            });
            player.on("pause", function () {
                // console.log("视频暂停播放");
            });
            player.on("ended", function () {
                // console.log("视频播放结束");
            });
            player.on("error", function () {
                // console.log("加载错误");
            });
            player.on("seeking", function () {
                // console.log("视频跳转中");
            });
            player.on("seeked", function () {
                // console.log("视频跳转结束");
            });
            player.on("ratechange", function () {
                // console.log("播放速率改变");
            });
            player.on("timeupdate", function () {
                // console.log("播放时长改变");
            });
            player.on("volumechange", function () {
                // console.log("音量改变");
            });
            player.on("stalled", function () {
                console.log("网速异常");
            });
            window.addEventListener('keydown', handleKeyDown);
        })
        const initVideo = (opt: any) => {
            player?.src(opt)
            player?.play()
        }
        onBeforeMount(() => {
            player?.dispose()
        })
        onUnmounted(() => {
            player?.dispose()
            window.removeEventListener('keydown', handleKeyDown);
        })
        const handleKeyDown = (e: KeyboardEvent) => {
            if (!player) return;
            switch (e.key) {
                case 'ArrowLeft':
                    // 向左箭头键 - 后退10秒
                    player.currentTime(Math.max(0, player.currentTime()! - 5));
                    break;
                case 'ArrowRight':
                    // 向右箭头键 - 快进10秒
                    player.currentTime(Math.min(player.duration()!, player.currentTime()! + 5));
                    break;
            }
        }
        return {
            myPlay,
            ...toRefs(init),
            initVideo,
        }
    },
    render() {

        return (
            <video ref="myPlay" style="width: 100%; height: 100%" class="video-js"></video>
        )
    }
})
const defaultOption = {
    /**自动播放，它有五个选项，可以是boolean也可以是字符串：1. false：不自动播放；2. true：自动播放，但是如果浏览器不允许自动播放的话就会失效；3. “muted”：静音后自动播放。因为浏览器实际上是不允许自动播放声音，所以静音后自动播放基本不会失效，但是没有声音需要自己处理一下；4. “play”：自动播放，与true效果一样；5. “any”：自动播放，如果浏览器阻止的话会先静音再自动播放 */
    autoplay: 'muted',
    /**是否循环播放 */
    loop: true,
    /**是否显示控制组件（包括控制栏和大播放按钮等）。如果是true即显示，同时支持一些用户操作，比如单击视频暂停/播放，双击全屏等。如果是false则不显示，同时也禁止了用户操作，这样我们需要自己实现控制 */
    controls: true,
    /**预加载 */
    preload: "auto",
    /**封面 */
    // poster: 'http://d2zihajmogu5jn.cloudfront.net/elephantsdream/poster.png',
    /**如果设置为 true，则不会立即触发不兼容源错误，而是会在第一次用户交互时发生。这对于 Google 的“移动友好”测试工具非常有用，该工具无法播放视频，但您可能不希望看到显示错误。 */
    suppressNotSupportedError: true,
    /**视频播放速度 */
    playbackRates: [0.5, 1, 1.5, 2, 3, 4],
    controlBar: {
        // 设置控制条组件
        currentTimeDisplay: true,
        durationDisplay: true,
        timeDivider: true,
        remainingTimeDisplay: false,
        volumePanel: {
            inline: false
        },
        children: [
            { name: 'playToggle' }, // 播放/暂停按钮
            { name: 'download' }, // 播放/暂停按钮
            { name: 'currentTimeDisplay' }, // 视频当前已播放时间
            { name: 'progressControl' }, // 播放进度条
            { name: 'durationDisplay' }, // 视频播放总时间
            { name: 'playbackRateMenuButton', },// 倍速播放
            {
                name: 'volumePanel', // 音量控制
                inline: false // 不使用水平方式
            },
            { name: 'FullscreenToggle' } // 全屏
        ]
    }
}