
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import antd from './antd'
import pipe from './pipe'
import directive from './directive'
/// @ts-ignore
import FormListGroup from './components/form-list-group'
import Form from './components/form'
import { Vue3ProgressPlugin } from '@marcoschulte/vue3-progress';
// import 'ant-design-vue/dist/antd.less';
import 'ant-design-vue/dist/antd.css';
import './styles.scss'
// @ts-ignore
import { Vue3Mq } from "vue3-mq";
import store from './store'
// import '../public/iconfont/iconfont'
// import * as dayjs from 'dayjs'
// require('dayjs/locale/en')
// dayjs.locale('en')
const app = createApp(App)
directive(app)
antd(app)
pipe(app)
app.use(store)
    .use(Vue3ProgressPlugin)
    .use(router)
    .use(Vue3Mq)
    .component('b-form', Form)
    .component('form-list-group', FormListGroup)
    .mount('#app')
