import { ColumnDef } from "@/components/ag-table/types/column";
import { FormlyFieldGroup } from "@/components/form/types";
import { copyProp } from "@/utile";


export interface InitListResult {
    TableData: {
        Data: any[];
        CurrentPage: number;
        TotalPages: number;
        TotalRecords: number;
        CurrentEntity: any;
        TitleList: any[];

        MainItemList?: any[]
    };
    [props: string]: any;
}

/**
 * 获取用户列表结果
 * @property CurrentEntity
 * @property CurrentPage
 * @property TableData
 * @property TotalPages
 * @property TotalRecords
 */
export interface ListResult {
    CurrentEntity: any;
    Entity: any;
    CurrentPage: number;
    TableData: any[];
    Data?: any;
    TotalPages: number;
    TotalRecords: number;
    [props: string]: any;
}


interface HttpFnInaerface {
    (args?: any): Promise<any>
}

export class CommonTableFormHttp {
    init?: HttpFnInaerface;
    getRow?: HttpFnInaerface;
    getList?: HttpFnInaerface;
    add?: HttpFnInaerface;
    edit?: HttpFnInaerface;
    del?: HttpFnInaerface;
    export?: HttpFnInaerface;
    search?: HttpFnInaerface;
    toggle?: HttpFnInaerface;
    editByList?: HttpFnInaerface;
    addEdit?: HttpFnInaerface;
    deleteByList?: HttpFnInaerface;
    // Import Api
    initImport?: HttpFnInaerface;
    addImport?: HttpFnInaerface;
    generatorJobRefNo?: HttpFnInaerface;

    insert?: HttpFnInaerface;
    insertbyadd?: HttpFnInaerface;

    // Excel
    toexcel?: HttpFnInaerface;
    toxml?: HttpFnInaerface;

    photoRotate?: HttpFnInaerface;
    updataRowPhotoNumber?: HttpFnInaerface;
    [prop: string]: any;
    constructor(obj?: CommonTableFormHttp) {
        obj && copyProp.apply(this, [obj])
    }
}

/**
 * 保存 obj 的 key 和 targetObj 的 mapKey 映射关系
 * @description 取目标对象上的mapKey对应字段的值，放到新对象key对应字段上
 */
export interface ParamsMap {
    /** 真正要传的字段名 */
    key: string;
    /** 目标对象上的映射字段名 */
    mapKey: string;
    fixValue?: any;
    defaultValue?: any;
    from?: 'Form' | 'Row' | 'store' | 'WoStore_Row';
    /** Model 和 Row 当中都存在的 属性 */
    rowNoKey?: any;
    /** Model中rowNoKey对应的值，是不是rows中的index */
    isRowIndex?: boolean;
    /** 是不是整行 结合 from == Row 时 生效 */
    isFullRow?: boolean;
    arr2Str?: boolean;
}
export class HttpParamsMap {
    init: ParamsMap[] = [];
    getRow: ParamsMap[] = [];
    getList: ParamsMap[] = [];
    add: ParamsMap[] = [];
    edit: ParamsMap[] = [];
    del: ParamsMap[] = [];
    toggle: ParamsMap[] = [];

    export?: ParamsMap[] = [];
    toxml?: ParamsMap[] = [];
    editByList?: ParamsMap[] = [];
    deleteByList?: ParamsMap[] = [];

    addImport?: ParamsMap[] = [];
    insert?: ParamsMap[] = [];
    insertbyadd?: ParamsMap[] = [];
    search?: ParamsMap[] = [];

    // photo
    photoRotate?: ParamsMap[] = [];



    updataRowPhotoNumber?: ParamsMap[] = [];

    viewTen?: ParamsMap[] = [];
    selTen?: ParamsMap[] = [];
}
export interface Code_ID_Percen {
    Code: string;
    ID: string;
    Percen: number;
}

export interface FormSelectConfing extends ParamsMap {
    type: 'string' | 'ID_Description' | 'Code_ID_Percen' | 'Description_Description' | 'GroupList';
    /** label显示的字符串格式 以 : 分隔 */
    format?: string;
    /** 真正要绑定的字段 ,默认 绑定ID， 如果这个字段传了。以这个字段优先 */
    value?: string;
    /**value取Description */
    getvalue?: 'Description'
    /**下拉添加字段Value，取的是ID的值 */
    needValue?: boolean
    /** 默认把第一项 赋值 给 当前表单项 */
    defaultFirst?: boolean;
    /**value 子下拉 */
    edit_value?: 'ch_option'
    /**自定义获取到label value对应的 */
    customize?: any
}

/**
 * @property    labelWidth  label 宽度
 * @property    queryModallabelWidth  label 宽度
 * @property    cardTitle  card 标题
 * @property    formPrimaryKey  表单 主键
 * @property    formPrimaryKeyEmptyValue    表单 主键 空值
 * @property    isVirtualFormPrimaryKey     表单主键是否 虚拟主键   @description 是虚拟主键，前端用 虚拟主键 维护 增删改
 * @property    formVirtualPrimaryKey  表单 虚拟 主键
 * @property    controlledFields  受控的表单字段
 * @property    hasExport  是否有导出按钮
 * 
 */
export class CommonTableFormPageConfig {
    /**
     * label 宽度
     */
    labelWidth?: string = '';
    /**
     * label 宽度
     */
    queryModallabelWidth?: string = '';
    /**
     * card 标题
     */
    cardTitle?: string = '';
    /**
     * 表单 主键
     */
    formPrimaryKey?: string = '';
    /**
     * 表单 次要主键，当 表单有 虚拟主建时。设置真正的主建
     */
    secondPrimaryKey?: string = '';
    /**
     * 表单 主键 空值
     */
    formPrimaryKeyEmptyValue?: string = '';
    /**
     * part所有下拉框多选框的参数
     */
    Pard_AllParams?: any;
    /**
     * 表单主键是否 虚拟主键
     * @description 是虚拟主键，前端用 虚拟主键 维护 增删改
     */
    isVirtualFormPrimaryKey?: boolean = false;
    /**
     * 表单 虚拟 主键
     */
    formVirtualPrimaryKey?: string = '';
    /** 受控的表单字段 */
    controlledFields?: string[] = [];
    /** 是否有导出按钮 */
    hasExport?: boolean;
    hasQrcode?: boolean;
    tableHeight?: string;
    down_PDF_WorkType?: string;
    /** getRow 时 设置 表单的下拉选项 */
    getRow_set_formSelectConfing?: FormSelectConfing[];
    constructor(obj?: CommonTableFormPageConfig) {
        obj && copyProp.apply(this, [obj])
    }
}
/**
  * @property fields    表单字段集
  * @property queryModalFields  搜索弹窗 的 表单字段集
  * @property formSelectConfing 表单下拉框映射关系
  * @property columnDefs    表格字段集
  * @property httpParamsMap http参数映射关系
  * @property http  http接口
  * @property pageConfig    页面配置
 */
export class CommonTableFormProps {
    /** 表单字段集 */
    fields: FormlyFieldGroup[] = [];
    /** 搜索弹窗 的 表单字段集 */
    queryModalFields: FormlyFieldGroup[] = [];
    /** 表单下拉框映射关系
     *  @property key: string
     *  @property mapKey: string
     *  @property type: 'string' | 'ID_Description'
     */
    formSelectConfing: FormSelectConfing[] = [];
    /** 表格字段集 */
    columnDefs: ColumnDef[] = [];

    /** http参数映射关系
     * @property init   key: string, mapKey: string
     * @property getRow     key: string, mapKey: string
     * @property getList    key: string, mapKey: string
     * @property add    key: string, mapKey: string
     * @property edit   key: string, mapKey: string
     * @property del    key: string, mapKey: string
     */
    httpParamsMap: HttpParamsMap = new HttpParamsMap;
    /** http接口 
     * @property init
     * @property getRow
     * @property getList
     * @property add
     * @property edit
     * @property del
     */
    http: CommonTableFormHttp = new CommonTableFormHttp;
    /** 页面配置 
     * @property labelWidth : string 
     * @property cardTitle : string 
     * @property formPrimaryKey : string 
     * @property formPrimaryKeyEmptyValue   : string   
     * @property isVirtualFormPrimaryKey     表单主键是否 虚拟主键   @description 是虚拟主键，前端用 虚拟主键 维护 增删改 
     */
    pageConfig: CommonTableFormPageConfig = new CommonTableFormPageConfig;
}
