
import { CloseOutlined } from '@/antd-icon';
import verifyPhoto from '@/pipe/verifyPhoto';
import { defineComponent, reactive, ref } from 'vue';
import style from './index.module.scss'
interface cp {
    rowData: {
        ItemNo: number,
        LocatioInspection: string,
        DeficienciesIdentified: string,
        Remarks: string
    }[],
    _this: any
}
export const Part2C = defineComponent({
    props: ['rowData', '_this'],
    setup(p: cp) {

        return () => <>
            <div class={style['host-m']}>
                {
                    p.rowData?.map(x => {
                        return <div class={{ [style.card]: true, 'form-host': true }} >
                            <div class={style['item-title']}>Item {x.ItemNo}</div>
                            <div>
                                <div class={'margin-bottom-5'}>Location of Inspection</div>
                                <a-input
                                    value={x['LocatioInspection']}
                                    onChange={(e: any) => {
                                        x['LocatioInspection'] = e.target.value || '';
                                    }}
                                    size='large'
                                ></a-input>
                            </div>
                            <div>
                                <div class={'margin-bottom-5'}>Deficiencies Identified</div>
                                <a-textarea
                                    value={x['DeficienciesIdentified']}
                                    onChange={(e: any) => {
                                        x['DeficienciesIdentified'] = e.target.value || '';
                                    }}
                                    size='large'
                                ></a-textarea>
                            </div>
                            <div>
                                <div class={'margin-bottom-5'}>Remarks</div>
                                <a-textarea
                                    value={x['Remarks']}
                                    onChange={(e: any) => {
                                        x['Remarks'] = e.target.value || '';
                                    }}
                                    size='large'
                                ></a-textarea>
                            </div>
                            <a-button type="primary" class='edit-btn' onClick={() => { p._this.partc_m.SaveC_m(x) }}
                                disabled={p._this.data.Pstatus}
                            >
                                <i class="iconfont icon-zhuanfa" style={'font-size:14px;margin-right:9px'}></i> 修改
                            </a-button>
                            <a-button type="primary" danger class='del-btn' onClick={() => { p._this.partc_m.DelC_m(x) }}
                                disabled={p._this.data.Pstatus}
                            >
                                <CloseOutlined />删除
                            </a-button>
                        </div>
                    })
                }
                {
                    !p.rowData?.length && <div class={style['nophoto-show']}>
                        No Rows To Show
                    </div>
                }
            </div>
        </>
    }
})

interface dp {
    rowData: {
        ItemNo: number,
        LocatioInspection: string,
        DeficienciesIdentified: string,
        Remarks: string,
        FilePath: string
    }[],
    _this: any,
    selectOptions: any
}
import DownloadService from '@/http/download';
import { isArray } from '@vue/shared'
export const Part2D = defineComponent({
    props: ['rowData', '_this', 'selectOptions'],
    setup(p: dp) {
        let click: any = {}
        const data = reactive({
            files: null,
            click
        })
        let inputRef = ref<HTMLInputElement>();

        const selectFiles = (e: Event | Array<any>) => {
            let files: any;
            if (isArray(e)) {
                files = e;
            } else {
                files = (e.target as HTMLInputElement).files || [];
            }
            if (!files.length) return;
            // @ts-ignore
            DownloadService.upload(files).then((x: { FileList: { MD5: string, Path: string }[]; TempPath: string; }) => {
                if (x.FileList.length) {
                    data.click.UploadFilePath = x.FileList[0].Path
                    p._this.partd_m.UploD_m(data.click)
                }
                data.files = null;
            })
        }

        const browser = (x: any) => {
            data.click = x
            inputRef && inputRef.value?.click();
        };

        return () => <>
            <div class={{ [style['host-m']]: true, [style['part2d_g']]: true }}>
                {
                    p.rowData?.map(x => {
                        return <div class={{ [style.card]: true, 'form-host': true }} >
                            <div class={style['item-title']}>Item {x.ItemNo}</div>
                            <div>
                                <div class={'margin-bottom-5'}>Location of Inspection</div>
                                <a-input
                                    value={x['LocatioInspection']}
                                    onChange={(e: any) => {
                                        x['LocatioInspection'] = e.target.value || '';
                                    }}
                                    size='large'
                                ></a-input>
                            </div>
                            <div class={{ [style['div-img']]: true, 'margin-bottom-10': true, 'margin-top-10': true }} >
                                <img v-viewer src={verifyPhoto(x.FilePath)+ '?' + Math.random()} />
                            </div>
                            <div>
                                <div class={'margin-bottom-5'}>Safe work seen during the inspection</div>
                                <a-select
                                    value={x['DeficienciesIdentified']}
                                    onChange={(e: any) => {
                                        x['DeficienciesIdentified'] = e;
                                    }}
                                    style="width: 100%"
                                    size='large'
                                    options={p.selectOptions?.DeficienciesIdentified || []}
                                    getPopupContainer={((triggerNode: { parentNode: any; }) => triggerNode.parentNode)}
                                ></a-select>
                            </div>
                            <div>
                                <div class={'margin-bottom-5'}>Remarks</div>
                                <a-select
                                    value={x['Remarks']}
                                    onChange={(e: any) => {
                                        x['Remarks'] = e;
                                    }}
                                    style="width: 100%"
                                    size='large'
                                    options={p.selectOptions?.Remarks || []}
                                    getPopupContainer={((triggerNode: { parentNode: any; }) => triggerNode.parentNode)}
                                ></a-select>
                            </div>
                            <a-button type="primary" class='add-btn' onClick={() => { p._this.partd_m.openD_m(x) }}
                                disabled={p._this.data.Pstatus}
                            >
                                <i class={`iconfont icon-icon_tianjia`} style={`font-size:14px;margin-right:9px`}></i> 新增
                            </a-button>
                            <a-button type="primary" class='rote-btn' onClick={() => { p._this.partd_m.RotateD_m(x) }}
                                disabled={p._this.data.Pstatus}
                            >
                                <i class="iconfont icon-edit" style={'font-size:14px;margin-right:9px'}></i> 編輯
                            </a-button>
                            <a-button type="primary" class='upload-btn' onClick={() => { browser(x) }}
                                disabled={p._this.data.Pstatus}
                            >
                                <i class="iconfont icon-1" style={'font-size:14px;margin-right:9px'}></i> 上傳
                            </a-button>
                            <a-button type="primary" class='edit-btn' onClick={() => { p._this.partd_m.SaveD_m(x) }}
                                disabled={p._this.data.Pstatus}
                            >
                                <i class="iconfont icon-zhuanfa" style={'font-size:14px;margin-right:9px'}></i> 修改
                            </a-button>
                            <a-button type="primary" danger class='del-btn' onClick={() => { p._this.partd_m.DelD_m(x) }}
                                disabled={p._this.data.Pstatus}
                            >
                                <CloseOutlined />刪除
                            </a-button>
                        </div>
                    })
                }
                <input type="file" hidden style="display: none;" multiple ref={inputRef} value={data.files} onChange={selectFiles} accept="image/*" />
                {
                    !p.rowData?.length && <div class={style['nophoto-show']}>
                        No Rows To Show
                    </div>
                }
            </div>
        </>
    }
})