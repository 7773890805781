import { FirstMenu, LoginData, Permission, Profile } from "@/types";
import { BrowserStorageService } from "./browser-storage.service";


/**
 * 配置Token,菜单等数据到localStorage, 
 * 并计算每个页面和页面中CRUD按钮的
 * Read: true
 * Create: true
 * Update: true
 * Delete: true
 * Enquiry: true
 * Report: true
 * 权限
 * @function setUserName 设置UserName
 * @function getUserName 获取UserName
 * @function setToken 设置token
 * @function getToken 获取token
 * @function setTokenType 设置token_type
 * @function getTokenType 获取token_type
 * @function setProfile 设置profile
 * @function getProfile 获取profile
 * @function setFirstMenu 设置FirstMenu
 * @function getFirstMenu 获取FirstMenu
 * @function setMenuPermission 设置MenuPermission
 * @function getMenuPermission 获取MenuPermission
 * @function setMenuMetaData 设置MenuPermission
 * @function clear
 */
export class PermissionService {
  private storage: BrowserStorageService = new BrowserStorageService()
  /**
   * 设置lastOperationDate
   */
  setLastOperationDate(value: any) {
    this.storage.set('lastOperationDate', value, true);
  }

  /**
   * 获取lastOperationDate
   */
  getLastOperationDate(): string {
    return this.storage.get('lastOperationDate', true);
  }
  /**
   * 设置UserName
   */
  setUserName(value: any) {
    this.storage.set('userName', value, true);
  }

  /**
   * 获取UserName
   */
  getUserName(): string {
    return this.storage.get('userName', true);
  }
  /**
   * 设置token
   */
  setToken(value: any) {
    this.storage.set('token', value, true);
  }

  /**
   * 获取token
   */
  getToken(): string {
    return this.storage.get('token', true);
  }

  /**
   * 设置token_type
   */
  setTokenType(value: any) {
    this.storage.set('token_type', value, true);
  }

  /**
   * 获取token_type
   */
  getTokenType(): string {
    return this.storage.get('token_type', true);
  }

  /**
   * 设置profile
   */
  setProfile(value: any) {
    this.storage.set('profile', value, true);
  }

  /**
   * 获取profile
   */
  getProfile(): Profile {
    return this.storage.get('profile', true);
  }

  /**
   * 设置FirstMenu
   */
  setFirstMenu(value: any) {
    this.storage.set('firstMenu', value);
  }

  /**
   * 获取FirstMenu
   */
  getFirstMenu(): Array<FirstMenu> {
    return this.storage.get('firstMenu');
  }

  /**
   * 设置MenuPermission
   */
  setMenuPermission(value: any) {
    this.storage.set('menuPermission', value, true);
  }

  /**
   * 获取MenuPermission
   */
  getMenuPermission(): Permission {
    return this.storage.get('menuPermission', true);
  }

  /**
   * 设置contractNo 保存在loaclStorage
   */
  setContractNo(value: any) {
    this.storage.set('contractNo', value, true);
  }
  /**
   * 获取contractNo 保存在loaclStorage
   */
  getContractNo() {
    return this.storage.get('contractNo', true);
  }
  /**
  * PWDErrorCount 保存在loaclStorage
  */
  setPWDError(value: any) {
    this.storage.set('PWDError', value, true);
  }
  /**
   * PWDErrorCount 保存在loaclStorage
   */
  getPWDError() {
    return this.storage.get('PWDError', true);
  }
  clearPWDError() {
    return this.storage.remove('PWDError', true);
  }
  /**
   * 设置Navs 保存在loaclStorage
   */
  setNavs(value: any) {
    this.storage.set('Navs', value, true);
  }
  /**
   * 获取Navs 保存在loaclStorage
   */
  getNavs() {
    return this.storage.get('Navs', true);
  }
  /**
   * 设置MenuPermission
   */
  setMenuMetaData(x: LoginData) {
    this.setToken(x.access_token);
    this.setTokenType(x.token_type);
    this.setProfile(x.profile);
    // this.setFirstMenu(x.FirstMenu);
    this.setMenuPermission(x.Permission);
  }

  clear() {
    this.storage.clear(true);
    this.storage.clear();
  }
}
export default new PermissionService();