import { AgGridRowEvent } from "@/components/ag-table/types/grid";
import { computed, defineComponent, reactive, ref } from "vue";
import useStore from "@/store/metaData";

import Upload from '@/components/ag-table/upload';
import { Accept } from "@/types/file";
import { isArray } from "@vue/shared";
interface CellBtn {
    icon: string, title: string,
    action: string, fontSize: string,
    other: any, class: string,
    other_params: any, Bgcolor: string, Color: string,
    /**icon是否需要单独disable */ need_disable: boolean,
    /**需要与指定fiels对比触发disable*/ need_target: boolean,
    /**need_disable need_target有 配置 */config: any,
    edit_del_loginname?: boolean,
    // 图标是否隐藏
    hide?: Function;
}

export default defineComponent({
    components: { Upload },
    // props: {
    //     params: Object
    // },
    props: ['params'],
    setup(p) {
        let params: AgGridRowEvent;
        let uploadRef = ref();
        params = p.params as AgGridRowEvent;
        let isBranch: boolean = false;
        const data = reactive({
            params,
            value: params.value,
            btns: [],
            isBranch,
            row: params.data
        });
        const key = params.column ? params.column.getColId() : '';
        //@ts-ignore
        data.btns = params['btns'] || [];
        //@ts-ignore
        data.isBranch = params['isBranch'] || false;
        const accept = computed(() => {
            let str = '*';
            if (new RegExp('photo', 'i').test(key)) {
                str = Accept.image;
            }

            return str;
        })
        const invokeParentMethod = (e: MouseEvent, btn: CellBtn) => {
            let obj = {
                currentField: key,
                currentAction: btn.action,
                $event: e
            }
            if (btn.action.toLowerCase() == 'Upload'.toLowerCase()) {
                uploadRef.value && uploadRef.value.triggerUpload();
            } else {
                if (btn.other) {
                    params.context?.componentParent?.methodFromCell({ ...params, ...obj, other: btn.other })
                } else if (btn.other_params) {
                    params.context?.componentParent?.methodFromCell({ ...params, ...obj, other_params: btn.other_params })
                }
                else {
                    //@ts-ignore
                    params.context?.componentParent?.methodFromCell({ ...params, ...obj, })
                }
            }
        }
        const selectFile = (file: { file: File; fileList: File[] }) => {
            let obj = {
                currentField: key,
                currentAction: 'Upload',
                file
            }
            //@ts-ignore
            params.context?.componentParent?.methodFromCell({ ...params, ...obj })
        }
        // console.log(data.btns);

        return {
            uploadRef,
            key,
            params,
            data,
            accept,
            invokeParentMethod,
            selectFile
        }
    },
    render() {
        const bgicon: any = {
            save: '/svg/sav.svg', del: '/svg/Del.svg',
            upl: '/svg/upl.svg'
        }
        const store = useStore();

        // 控制图标是否隐藏
        const hide = (btn: CellBtn) => {
            if (btn.hide) {
                return btn.hide(this.data.params.data)
            }
            return false;
        }

        return (
            <>
                <div class={`${this.params.disabled && 'cursor-not-allowed opacity-0-4-10'}`} style={`font-size:26px; `}>
                    {
                        this.data.btns.length && this.data.isBranch ?
                            <a-row type="flex">
                                {

                                    <a-col span={24}>
                                        {
                                            this.data.btns.filter((btn: CellBtn) => ['Save', 'Upload'].some(b => btn.action == b))
                                                .map((btn: CellBtn, i) => (
                                                    <svg style={{ 'font-size': btn.fontSize, 'margin-left': i == 0 ? '1px' : '' }} class="icon point inline-block margin-bottom-4 margin-right-8" aria-hidden="true" onClick={(e: any) => this.invokeParentMethod(e, btn)}>
                                                        <title>{btn.title}</title>
                                                        <use xlinkHref={`#icon-${btn.icon}`}></use>
                                                    </svg>
                                                ))
                                        }
                                    </a-col>
                                }
                                {
                                    <a-col span={24}>
                                        {

                                            this.data.btns.filter((btn: CellBtn) => ['Delete', 'Download', 'Rotate'].some(b => btn.action == b))
                                                .map((btn: CellBtn) => (
                                                    <svg style={{ 'font-size': btn.fontSize }} class="icon point inline-block margin-bottom-4 margin-right-8" aria-hidden="true" onClick={(e: any) => this.invokeParentMethod(e, btn)}>
                                                        <title>{btn.title}</title>
                                                        <use xlinkHref={`#icon-${btn.icon}`}></use>
                                                    </svg>
                                                ))
                                        }
                                    </a-col>
                                }
                            </a-row>
                            :
                            (
                                <div class={`flex align-items-center ${this.params.disabled && 'pointer-events'}`}>
                                    {
                                        this.data.btns.map((btn: CellBtn) => {
                                            const svgstyle: any = {
                                                'font-size': btn.fontSize, 'background': btn.Bgcolor, 'color': btn.Color || '#fff',
                                                'border-radius': '5px', 'padding': '5px', 'margin': '0 5px 0 0'
                                            }
                                            let svgflag: boolean = false
                                            if (btn.need_disable) {
                                                if (btn.need_target) {
                                                    if (isArray(btn?.config.target)) {
                                                        if (btn?.config.target.includes(this.data.value)) {
                                                            svgflag = true
                                                        }
                                                    } else {
                                                        if (this.data.value == btn?.config.target) {
                                                            svgflag = true
                                                        }
                                                    }
                                                } else {
                                                    svgflag = true
                                                }
                                            }

                                            if (btn?.edit_del_loginname) {
                                                if (this.data.row && store.userName?.toUpperCase() !== 'admin'?.toUpperCase()) {
                                                    // 如果这条数据的CreateBy不是当前用户，禁用。是就不用处理
                                                    if (this.data.row['CreateBy']?.toUpperCase() != store.userName?.toUpperCase()) {
                                                        svgflag = true
                                                    }
                                                }
                                            }
                                            switch (btn.title) {
                                                case 'Undo':

                                                    return <div class={`${svgflag && 'cursor-not-allowed opacity-0-4-10'}`}>
                                                        {/* <svg
                                                            style={svgstyle}
                                                            class={{ 'icon': true, 'point': true, 'inline-block': true, 'pointer-events': svgflag }} aria-hidden="true" onClick={(e: any) => this.invokeParentMethod(e, btn)}>
                                                            <title>{btn.title}</title>
                                                            <use xlinkHref={`#icon-${btn.icon}`}></use>
                                                        </svg> */}
                                                        <img src="/images/uodo.png" style={{ 'font-size': btn.fontSize, 'border-radius': '5px', }}
                                                            class={{ 'icon': true, 'point': true, 'inline-block': true, 'pointer-events': svgflag }} aria-hidden="true"
                                                            onClick={(e: any) => this.invokeParentMethod(e, btn)} />
                                                    </div>
                                                default:
                                                    return !hide(btn) && <div class={`${svgflag && 'cursor-not-allowed opacity-0-4-10'}`}>
                                                        <svg
                                                            style={svgstyle}
                                                            class={{ 'icon': true, 'point': true, 'inline-block': true, 'pointer-events': svgflag }} aria-hidden="true" onClick={(e: any) => this.invokeParentMethod(e, btn)}>
                                                            <title>{btn.title}</title>
                                                            <use xlinkHref={`#icon-${btn.icon}`}></use>
                                                        </svg>
                                                    </div>
                                            }
                                        })
                                    }
                                </div>
                            )
                    }
                </div>
                <Upload style="display: none;" ref="uploadRef" onSelect={this.selectFile} accept={this.accept}></Upload>
            </>

        );
    }
});
