import { RouteRecordRaw } from 'vue-router'
import RouterWrap from './router-empty';
import RouterForceRefreshWrap from './router-empty-force-refresh';
import RouterKeepAliveWrap from './router-empty-keep-alive';

let SafetyArr = (pPath: string) => {
    return ['MajorItemsInformation', 'PenaltyRecord', 'Summary']
        .map(path => ({ path: `${pPath}/${path}`, component: () => import('../views/1-Safety') }));
}
let InspectionReportArr = (pPath: string) => {
    return ['menuInspectionReport',]
        .map(path => ({ path: `${pPath}/${path}`, component: () => import('../views/1-InspectionReport') }));
}
let InventoryArr = (pPath: string) => {
    return ['VenueList', 'StaffList', 'DistrictCode', 'SubconList', 'PerformanceList', 'DescriptionList', 'WorksOrderList', 'ContractorNoList', 'VersionList']
        .map(path => ({ path: `${pPath}/${path}`, component: () => import('../views/1-Inventory') }));
}
const Routes: Array<RouteRecordRaw> = [
    {
        path: '/Safety',
        component: RouterForceRefreshWrap, children: [
            ...SafetyArr('/Safety'),
        ]
    },
    {
        path: '/InspectionReport',
        component: RouterForceRefreshWrap, children: [
            ...InspectionReportArr('/InspectionReport'),
        ]
    },
    {
        path: '/Inventory',
        component: RouterForceRefreshWrap, children: [
            ...InventoryArr('/Inventory'),
        ]
    },
    {
        path: '/Settings', component: RouterWrap, children: [
            { path: 'Password', component: () => import('../views//2-settings/change-password') },



            { path: 'UserManagement', component: () => import('../views/2-settings/user-management') },
            { path: 'RoleManagement', component: () => import('../views/2-settings/role-management') },
            { path: 'Manuals', component: () => import('../views/2-settings/Manuals/index') },
            // @ts-ignore
            // { path: 'VersionManagement', component: () => import('../views/2-settings/version-management/b') },
        ]
    },
]
export default Routes;