import { defineComponent, reactive, ref } from "vue";
import { ColumnDef, defaultColDef } from "./types/column";
import { AgGridVue } from "ag-grid-vue3";
import usrGridEvent from "./grid-event";
import '../../styles/ag-table.scss'
export default defineComponent({
    components: {
        AgGridVue,
    },
    // props: ['columnDefs', 'rowData'],
    /**
     * 所有ag-grid 的 属性，全可直接传 并 使用
     * @see https://www.ag-grid.com/vue-data-grid/column-definitions/#default-column-definitions
     */
    props: {
        // columnDefs: Array,
        // rowData: Array,
        // suppressRowClickSelection: Boolean,
        // doubleClickSelect: Boolean,
        // clickSelect: Boolean,
        // defaultColDef: {  /*type: () => ColumnDef, default: () => defaultColDef*/ },
        defaultColDef: {
            type: Object, default: () => ({
                sortable: true,
                // resizable: true,
                cellStyle: {
                    // 'line-height': '40px',   // 行高设置同步：跟rowHeight属性设置值一致
                },
            })
        },
        rowSelection: { type: String, default: 'single' },
        clickSelect: { type: Boolean, default: true },
        rowHeight: { type: Number, default: 40 },
        headerHeight: { type: Number, default: 40 },
        suppressFieldDotNotation: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
    },
    emits: [
        '_cellValueChanged',
        '_rowSelected',
        '_selectionChanged',
        '_cellClicked',
        '_rowDragEnd',
        '_rowDragMove',
        '_rowClicked',
        '_rowDoubleClicked',
        '_rowClicked',
        '_sortChanged',
        '_cellMethod',
        '_cellDoubleClicked',
        '_gridReady',
    ],
    setup(p, { emit }) {
        const tableRef = ref();
        const context = reactive({});
        const {
            gridReady,
            grid,
            cellValueChanged,
            rowSelected,
            clearSelect,
            selectionChanged,
            cellClicked,
            cellDoubleClicked,
            rowDragEnd,
            rowDragMove,
            rowClicked,
            rowDoubleClicked,
            sortChanged,
            getAllRows,
            getAllNodes,
            methodFromCell,
        } = usrGridEvent(p, emit);
        return {
            grid,
            tableRef,
            context,
            gridReady,
            cellValueChanged,
            rowSelected,
            clearSelect,
            selectionChanged,
            cellClicked,
            cellDoubleClicked,
            rowDragEnd,
            rowDragMove,
            rowClicked,
            rowDoubleClicked,
            sortChanged,
            getAllRows,
            getAllNodes,
            methodFromCell,
        }
    },
    render() {
        this.context = {
            componentParent: this
        }
        const sorticons = {
            sortAscending: '<div class="margin-bottom-3 margin-right-5">▼</div>',
            sortDescending: '<div class="margin-bottom-4 margin-right-5">▲</div>',
        }
        return (
            <ag-grid-vue
                ref="tableRef"
                defaultColDef={this.defaultColDef}
                value={this.$attrs}
                context={this.context}
                class="ag-theme-balham ag-table"
                rowHeight={this.rowHeight}
                headerHeight={this.headerHeight}
                animateRows={true}
                icons={sorticons}


                // columnDefs={this.columnDefs}
                // rowData={this.rowData}
                rowSelection={this.rowSelection}
                onGridReady={this.gridReady}
                onRowClicked={this.rowClicked}
                onSortChanged={this.sortChanged}
                onSelectionChanged={this.selectionChanged}
                onCellClicked={this.cellClicked}
                onCellDoubleClicked={this.cellDoubleClicked}
                onCellValueChanged={this.cellValueChanged}
                onRowSelected={this.rowSelected}
                onRowDoubleClicked={this.rowDoubleClicked}
                onRowDragEnd={this.rowDragEnd}
                onRowDragMove={this.rowDragMove}

                suppressFieldDotNotation={this.suppressFieldDotNotation}
                rowBuffer={this.defaultColDef?.rowBuffer}

                disabled={this.disabled}
            >
            </ag-grid-vue>
        );
    }
})

/* 
<ag-grid-angular 
    [ngStyle]="{'height': height, 'width': width, 'min-height': minHeight}"
    class="ag-theme-balham scroll-mini {{ className }}"
    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    
    [rowDragManaged]="rowDragManaged"
    [singleClickEdit]="singleClickEdit"
    [editType]="editType"
    
    [suppressClickEdit]="suppressClickEdit"
    [rowMultiSelectWithClick]="rowMultiSelectWithClick"
    [components]="components"
    [frameworkComponents]="frameworkComponents"
    [groupSuppressAutoColumn]="groupSuppressAutoColumn"
    [suppressRowClickSelection]="suppressRowClickSelection"
    [groupDefaultExpanded]="groupDefaultExpanded"
    [rowSelection]="rowSelection"
    [groupSelectsChildren]="groupSelectsChildren"
    [animateRows]="animateRows"
    [suppressMoveWhenRowDragging]="true"
    [getRowHeight]="getRowHeight"
    [rowHeight]="rowHeight"
     
    [context]="context"
    [rowBuffer]="rowBuffer"
    [suppressMaxRenderedRowRestriction]="suppressMaxRenderedRowRestriction"
    >
</ag-grid-angular>
*/