import { App } from 'vue'
import {
  // ConfigProvider,
  Button,
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  Radio,
  Rate,
  Select,
  Slider,
  Switch,
  TimePicker,
  Upload,
  Form,
  Menu,
  Avatar,
  Dropdown,
  Image,

  Cascader,
  AutoComplete,
  Row,
  Col,
  Transfer,
  Breadcrumb,
  Spin,
  Card,
  Tooltip,
  Modal,
  Tabs,
  Collapse,
  Pagination,
  Drawer,
} from 'ant-design-vue'

const components = [
  // ConfigProvider,
  Button,
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  Radio,
  Rate,
  Select,
  Slider,
  Switch,
  TimePicker,
  Upload,
  Form,
  Menu,
  Avatar,
  Dropdown,
  Image,

  Cascader,
  AutoComplete,
  Row,
  Col,
  Transfer,
  Breadcrumb,
  Spin,
  Card,
  Tooltip,
  Modal,
  Tabs,
  Collapse,
  Pagination,
  Drawer
]

export default (app: App<Element>) => {

  components.forEach(c => {
    app.use(c);
  });
}
