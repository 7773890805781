import { MailOutlined } from '@/antd-icon';
import useStore from '@/store/metaData';
import { MenuItem } from '@/types';
import { defineComponent, reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import http from "../../../views/12-login/http";
import AppLink from '../../AppLink.vue';
import Avatar from '../avatar';
import style from "./nav.module.scss";

export default defineComponent({
    props: {
        menu: {
            default: () => [new MenuItem]
        }
    },
    setup(p) {
        const store = useStore();
        let router = useRouter();
        const userName = store.userName;
        // @ts-ignore
        const userGroup = store.profile.userGroup;
        // @ts-ignore
        const EnglishName = store.getEnglishName

        const rootSubmenuKeys: string[] = []
        const openKeys: Array<string> = []
        const selectedKeys: Array<string> = []
        p.menu.forEach(e => {
            if (e.route) {
                rootSubmenuKeys.push(e.id)
                if (e.isActive) {
                    openKeys.push(e.id)
                    if (e.subMenu.length && e.subMenu.some(z => z.isActive)) {
                        selectedKeys.push(e.subMenu?.find(z => z.isActive)!.id)
                    }
                }
            }
        })

        const state = reactive({
            rootSubmenuKeys,
            openKeys,
            selectedKeys,
        });
        const onOpenChange = (openKeys: string[]) => {
            const latestOpenKey = openKeys.find(key => state.openKeys.indexOf(key) === -1);
            if (state.rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
                state.openKeys = openKeys;
            } else {
                state.openKeys = latestOpenKey ? [latestOpenKey] : [];
            }
        };
        const onSelect = (obj: { item: any, key: string, selectedKeys: string[] }) => {
            state.selectedKeys = obj.selectedKeys;
        };
        const logout = () => {
            if (!store.userName) router.push('/login');
            http.confirm({
                title: 'Information',
                content: 'Do you want to log out ?',
                onOk: () => {
                    let obj = { Name: store.userName };
                    http.exit(obj).then(() => {
                        execLogout();
                    }).catch((e) => {
                        execLogout()
                    })
                }
            })
        }
        const execLogout = () => {
            indexedDB.deleteDatabase('post_cache')
            localStorage.clear();
            store.$reset();
            router.push('/login');
            try {
                const CACHE_NAME = 'cache-v1';
                if (window.caches) {
                    window.caches.has(CACHE_NAME).then((has: boolean) => {
                        has && caches.delete(CACHE_NAME)
                    })
                }
            } catch (error) {
                console.log(error);
            }
            // const cache = await caches.open(CACHE_NAME);
            // caches.delete(CACHE_NAME)
            // (await cache.keys()).map(x => {
            //     cache.delete(x)
            // })
            // console.log(cache);


        }
        let timer: any = null;
        const calcTimeout = () => {
            let t = store.lastOperationTime;
            let n = new Date();
            let m30 = 1800000;
            // m30 = 60000;
            if (m30 <= n.valueOf() - new Date(t).valueOf()) {
                return execLogout();
            }
            timer = setTimeout(() => {
                timer = null;
                calcTimeout();
            }, 5000);
        }
        calcTimeout();
        return {
            userName, userGroup, EnglishName,
            logout,
            ...toRefs(state),
            onOpenChange,
            onSelect
        };
    },
    render() {
        const expandIconopen = <i class="iconfont icon-youjiantou" style={`font-weight:400;transform:rotate(90deg);`} />
        const expandIconclose = <i class="iconfont icon-youjiantou" style={`font-weight:400;`} />
        const submenuslot = (x: MenuItem,) => {
            return {
                icon: () => x.icon ? <i class={`iconfont ${x.icon}`} style={"font-size:17px"}></i> : <MailOutlined />,
                expandIcon: (even: { isOpen: boolean }) => even.isOpen ? expandIconopen : expandIconclose,
                title: () => <AppLink to={x.FirstMenuRoute} selfPath={x.route}
                    onClick={(e: any) => {
                        if (this.openKeys?.includes(x.id)) {
                            let ev = e || window.event
                            ev.stopPropagation()
                        }
                        // const fa = this.menu.find(z => z.id == this.openKeys[0])
                        // this.selectedKeys.push(fa?.subMenu.find(z => fa.FirstMenu == z.id as any)?.id!)
                    }}> {x.name}</AppLink>
            }
        }
        const getMenu = (newMenu: MenuItem[]) => {
            return newMenu.map((m: MenuItem) => (
                <>
                    {
                        m && ('Read' in m ? m.Read : true) && <>
                            {
                                m?.subMenu?.length ? (
                                    //第一级路由
                                    <a-sub-menu key={m.id} class={m.isActive ? 'menu-bg' : ''}
                                        v-slots={submenuslot(m)}
                                    >
                                        <div>
                                            {m.subMenu.map((sm) => (
                                                sm.Read && (
                                                    sm.subMenu?.length ? (
                                                        <a-sub-menu key={sm.id} class={sm.isActive ? 'menu-bg' : ''}
                                                            v-slots={submenuslot(sm)}
                                                        >
                                                            <div>
                                                                {getMenu(sm.subMenu)}
                                                            </div>
                                                        </a-sub-menu>
                                                    ) :
                                                        <AppLink to={sm.route} class={style['longtext']}>
                                                            {/* 第二级路由 */}
                                                            <a-menu-item key={sm.id}>
                                                                {sm.name}
                                                            </a-menu-item>
                                                        </AppLink>
                                                )
                                            ))}
                                        </div>
                                    </a-sub-menu>
                                ) : (
                                    m.route ?
                                        <AppLink to={m.route} class={style['longtext']}>
                                            {/* 第二级路由 */}
                                            <a-menu-item key={m.id}>
                                                {m.name}
                                            </a-menu-item>
                                        </AppLink>
                                        :
                                        <a-menu-item key={m.id} class={style['title']}>{m.name}</a-menu-item>
                                )
                            }
                        </>
                    }
                </>
            ))
        }
        const store = useStore();

        return (
            <div class={`${style['host']}`} >
                <div class={style['top']}>
                    <Avatar />
                    <div class={style['name']}>
                        <span><b>{this.userName}</b></span>
                        <span>{this.EnglishName}</span>
                    </div>
                    <div class={`${style['menu']} navhost`}>
                        <a-menu
                            mode="inline"
                            openKeys={this.openKeys}
                            onOpenChange={this.onOpenChange}
                            selectedKeys={this.selectedKeys}
                            onSelect={this.onSelect}
                        >
                            {
                                getMenu(this.menu)
                            }
                        </a-menu>
                    </div >
                </div >
                <div class={style['foot']}>
                    <a-button type="link" size="small" onClick={this.logout} icon={<i class="iconfont icon-lock1" style={'font-size:2rem'} ></i>} >Logout</a-button>
                </div>
            </div >
        )
    }
})