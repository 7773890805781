import { h, inject } from "vue";
import Collapse from "./components/Collapse";
import getButton from './handleButton';
import { ComponentBase, Form, TabProps } from "./types";
import MarkAsInactiveCheckbox from "./Widgets/MarkAsInactiveCheckbox";


export default function (c: ComponentBase, options: TabProps, model: any) {
    let cp: any = <></>
    const isMobile: any = inject('isMobile')

    // @ts-ignore
    let _this: any = this;

    let formC = c as Form;
    let btn: any = <></>
    let titleBtn: any = <></>

    btn = formC.Btnslot && getButton.apply(_this, [formC.Btnslot, _this.options, _this.model])
    titleBtn = formC.titleBtnslot && getButton.apply(_this, [formC.titleBtnslot, _this.options, _this.model])
    let fdiv: any = <></>
    let aa: any = <></>
    fdiv = <>
        {
            formC.isPrimaryForm ?
                (
                    <>
                        <b-form
                            fields={_this.options.fields}
                            model={_this.model}
                            layout={formC.layout || 'vertical'}
                            labelCol={{ style: { width: _this.options?.pageConfig?.labelWidth || '170px' } }}
                            onChange={_this.formChange}
                            ref="formRef"
                            onFinish={_this.finish}
                            size={formC.size || 'large'}
                            GroupTemplateAlign={formC?.GroupTemplateAlign}
                            // onSearch={_this?.formSearch}
                            disabled={_this.data?.formDisabled}
                            noMarginBottom={formC.noMarginBottom}
                        >
                        </b-form>
                    </>
                )
                :
                (
                    <>
                        <b-form
                            fields={formC.fields}
                            model={formC.model}
                            layout={formC.layout || 'vertical'}
                            onChange={_this.formChange}
                            labelCol={{ style: { width: formC?.labelWidth || '170px' } }}
                            ref="formRef"
                            onFinish={_this.finish}
                            size={formC.size || 'large'}
                            GroupTemplateAlign={formC?.GroupTemplateAlign}
                            // onSearch={_this?.formSearch}
                            noMarginBottom={formC.noMarginBottom}
                        >
                        </b-form>
                    </>
                )
        }
    </>
    aa = <>
        <div class={`${formC.titleBtnSlotClass}`}>{titleBtn}</div>
        {fdiv}
        {formC.has_MarkAsInactiveCheckbox ? h(<MarkAsInactiveCheckbox />, { model: _this.model, form: formC, ref: 'MarkAsInactiveCheckboxRef' }) : ''}
        <div class={`${formC.BtnSlotClass} flex justify-content-end ${formC.has_MarkAsInactiveCheckbox ? '' : 'margin-top-15'}`}>{btn}</div>
    </>
    if (formC.need_collapse) {
        let HeardTextSlot = {
            header: () => {
                return <b style={{ 'color': '#000', 'user-select': 'none' }}>{formC.text}</b>
            },
            open_header: () => {
                return <b style={{ 'color': '#000', 'user-select': 'none' }}>{formC.openshow}</b>
            },
            close_header: () => {
                return <b style={{ 'color': '#000', 'user-select': 'none' }}>{formC.show}</b>
            },
        }

        cp = <>
            <>{
                formC?.isPart1 ? <a-drawer
                    ref={_this.part1.drawRef}
                    // title={c.title}
                    maskStyle={{ backgroundColor: `transparent !important` }}
                    class={'m-drawer-part1'}
                    placement={'bottom'}
                    closable={false}
                    visible={_this.part1.draw.visible}
                    onClose={_this.part1.onClose}
                    maskClosable={true}
                    push={true}
                    height={`calc(100vh - 40px)`}
                    forceRender={true}
                >
                    <Collapse
                        v-slots={HeardTextSlot}
                        header_need_changetext={formC.header_need_changetext}
                    >
                        {aa}
                    </Collapse>
                    <div class={'next'}>
                        <a-button type="primary" onClick={_this.PartlistRef2?.part2.onClose} >
                            下一步
                        </a-button>
                    </div>
                    <div class='previous'>
                        <a-button type="primary" onClick={_this.part1.onClose} >
                            返回
                        </a-button>
                    </div>
                </a-drawer> : <Collapse
                    v-slots={HeardTextSlot}
                    header_need_changetext={formC.header_need_changetext}
                >
                    {aa}
                </Collapse>
            }
            </>
        </>
    }
    else if (formC.need_Different_layouts) {
        cp =
            <>
                <div class={'summary-left flex justify-content-end align-items-end'}>
                    {fdiv}
                    {btn[0]}
                </div>
                <div class='summary-right'>{btn[1]}</div>
            </>
    }
    else if (formC.isShowtitle) {

        cp = <div class={formC.showoptions.class}>
            <div><p style={{ padding: '20px 0 0 24px' }} class={{ 'ptitle': true, 'padding-left-10': isMobile.value }}>{formC.showoptions.title}</p></div>
            <div style={'padding:0 0 10px 10px '}>{aa}</div>
        </div>
    } else {
        cp = aa
    }

    return <div class={formC.class} style={formC.style}>{cp}</div>
}
