// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from "echarts/core";
// 引入柱状图图表，图表后缀都为 Chart
import {
    // 系列类型的定义后缀都为 SeriesOption
    BarChart, BarSeriesOption,
} from "echarts/charts";
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
    // 组件类型的定义后缀都为 ComponentOption
    TitleComponent, TitleComponentOption,
    TooltipComponent, TooltipComponentOption,
    GridComponent, GridComponentOption,
    LegendComponent, LegendComponentOption,
    // 数据集组件
    DatasetComponent, DatasetComponentOption,
    // 内置数据转换器组件 (filter, sort)
    TransformComponent,
    DataZoomComponent,
} from "echarts/components";
// 标签自动布局、全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";

// 通过 ComposeOption 来组合出一个只有必须组件和图表的 Option 类型
export type ECOption = echarts.ComposeOption<
    | BarSeriesOption
    | LegendComponentOption
    | TitleComponentOption
    | TooltipComponentOption
    | GridComponentOption
    | DatasetComponentOption
>;

// 注册必须的组件
echarts.use([
    BarChart,


    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    DatasetComponent,
    TransformComponent,
    DataZoomComponent,

    CanvasRenderer,
    LabelLayout,
    UniversalTransition,
]);

export default echarts;

export const getColor16 = () => {//十六进制颜色随机
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    const color = `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
    // console.log(Math.random().toString(16).substr(2, 6));

    // return color;
    return '#' + Math.random().toString(16).slice(2, 8)?.toUpperCase()
}