import { Subject, Subscription } from "rxjs";

export class Form_List_Group_Event {
    $getList: Subject<any> = new Subject();
}

export const Form_Event = new Form_List_Group_Event();

export default () => {
    // @ts-ignore
    let $getListEv: Subscription = null;

    return {
        $getListEv
    }
}