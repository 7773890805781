import { defineComponent, onBeforeMount, onMounted, onUnmounted, onUpdated, reactive } from "vue";

export default defineComponent({
    props: ['value', 'options', 'disabled'],
    emits: ['change'],
    setup(p, { emit }) {

        const data: any = reactive<any>({
            tabs: p.options,
            model: [],
            values: p.value
        })
        const clickItem: any = (e: string | number) => {
            // 获取当前点击
            const temp = data.tabs[e]
            // 给所有的设为false,当前的设为true
            data.tabs.map((val: any) => {
                val.bOn = false
            })
            temp.bOn = true

            data.values = []
            // 当前选中的传出去
            data.values.push(temp.value)

            emit('change', data.values[0])
        }


        onBeforeMount(() => {
            // console.log('onBeforeMount');
            // console.log(data.values);

        })
        onMounted(() => {
            // console.log(data.values);

            if (data.values) {
                data.tabs.filter((a: { value: any; }) => a.value == data.values).bOn = true
                data.tabs.forEach((a: { value: any; bOn: boolean; }) => {
                    if (a.value == data.values) {
                        a.bOn = true
                    } else {
                        a.bOn = false

                    }
                })
                // console.log(data.tabs);

            } else {
                data.tabs.map((val: any) => {
                    val.bOn = false
                })

            }
        })
        // onBeforeUpdate(() => {
        //     console.log('onBeforeUpdate');
        //     console.log(data.values);

        // })
        onUpdated(() => {
            // console.log('onUpdated');

            data.values = p.value;
            if (data.values) {
                // console.log(data.values);

                data.tabs.forEach((a: { value: any; bOn: boolean; }) => {
                    if (a.value == data.values) {
                        a.bOn = true
                    } else {
                        a.bOn = false

                    }
                })
            } else {
                data.tabs.map((val: any) => {
                    val.bOn = false
                })

            }
        });
        onUnmounted(() => {
            // console.log('onUnmounted');
            // console.log(data.value);

        })
        return {
            data,
            clickItem,
        }
    },
    render() {
        let strstyle = 'border: 1px solid #d9d9d9;background-color: #f5f5f5;'
        return (
            <>
                <div class={`${this.disabled && 'cursor-not-allowed'}`} style={`${this.disabled && strstyle}`}>
                    <ul

                        class={`checkbox_button ${this.disabled && 'pointer-events  opacity-0-4-10'}`}
                    >
                        {
                            this.data.tabs.map((item: any, index: any) => (
                                <div class={`${item.disabled && 'cursor-not-allowed'}`}>
                                    <li
                                        style={`${item.disabled && strstyle}`}
                                        onClick={() => this.clickItem(index)}
                                        class={{ 'active': item.bOn, 'item point': true, 'pointer-events': item.disabled }}
                                    >
                                        <span class='padding-left-10 padding-right-10'>{item.label}</span>
                                    </li>
                                </div>
                            ))
                        }
                    </ul >
                </div>
            </>

        )
    }

})
