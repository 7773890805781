import { SearchOutlined } from '@/antd-icon';
import AgGrid from "@/components/ag-table";
import Card from "@/components/form-list-group/components/card";
import CheckboxBtn from '@/components/form/components/CheckboxBtn';
import { FormChangeEvent } from '@/types';
import { handleDate2Dayjs } from '@/utile';
import { isArray } from '@vue/shared';
import type { FormInstance, UploadChangeParam } from 'ant-design-vue';
import dayjs from 'dayjs';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { defineComponent, inject, onBeforeUnmount, onUnmounted, ref } from 'vue';
import { handleFormFinishFailed } from './formEventFuction';
import FormServe from './formResetService';
import style from './index.module.scss';
import './index.scss';
import useRules from './rules';
import Select_Other_show from './ShowOther';
import { FormlyFieldConfig, FormlyFieldGroup, FormlyFieldTypeEnum, MQ, SubmitFailedEvent } from './types';

export default defineComponent({
    components: {
        Card, SearchOutlined, AgGrid,
    },
    // props: ['model', 'fields', 'labelWidth', 'size', 'showRequire'],
    props: {
        model: {
            type: Object,
            default() {
                return {}
            }
        },
        fields: {
            type: Array,
            default() {
                return []
            }
        },
        labelWidth: {
            type: String,
            default() {
                return '100px'
            }
        },
        size: {
            type: String,
            default() {
                return 'small'
            }
        },
        showRequire: {
            type: Boolean,
            default() {
                return true
            }
        },
        // form-item是否有Margin-Bottom
        noMarginBottom: {
            type: Boolean,
            default() {
                return false
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false
            }
        },
        GroupTemplateAlign: {
            type: String,
            default() {
                return 'top'
            }
        }
    },
    emits: ['change', 'input', 'finish', 'finishFailed', 'validate', 'submit', 'search', 'openBySearch', 'btnClick'],
    setup(p: any, { emit }) {
        onBeforeUnmount(() => {
            r_$changeEv && r_$changeEv.unsubscribe();
        });
        const r_$change: Subject<any> = new Subject();
        let r_$changeEv: Subscription;
        const formRef = ref<FormInstance>();
        const autoCompleteRef = ref();

        r_$changeEv = r_$change.pipe(debounceTime(200)).subscribe((evData: FormChangeEvent) => {
            emit('change', evData);
            validateFields(evData)
        })
        const validateFields = (evData: FormChangeEvent) => {
            formRef.value && formRef.value.validateFields()
                .then((x: any) => {
                    handleTooltipStatus(evData.key);
                })
                .catch((err: SubmitFailedEvent) => {
                    handleTooltipStatus(evData.key);
                });
        }
        const formID = 'form_' + uuid().slice(0, 8);
        const submitBtn = ref<any>();
        const { getFormControlRules } = useRules(p.model, formRef);
        const handleInput = (e: any, key: string, model: any) => {
            model[key] = e.target.value;
            const evData = { key, value: model[key] };
            emit('input', evData);
            r_$change.next(evData);

        }
        const handleValueChange = (value: any, key: string, model: any, item?: FormlyFieldConfig) => {
            model[key] = value;
            const evData = { key, value: model[key] };
            emit('input', evData);
            r_$change.next(evData);

            if (item?.templateOptions?.attchValue) {
                let attrValue = item.templateOptions?.attchValue

                if (attrValue.from) {
                    let start = value
                    let end = model[attrValue.targetfield]
                    let result = attrValue.fn(start, end, true)
                    model[attrValue.targetfield] = dayjs(result)
                } else {
                    let start = model[attrValue.targetfield]
                    let end = value
                    let result = attrValue.fn(start, end, false)
                    model[attrValue.targetfield] = dayjs(result)
                }
            }
            if (item?.templateOptions?.change_clear_Fields?.length) {
                item.templateOptions.change_clear_Fields.map(y => {
                    model[y] = null;
                })
            }
        }
        const handleRadioValueChange = (obj: { target: { id: string, prefixCls: string, type: string, defaultChecked: boolean, value: any, checked: boolean } }, key: string, model: any) => {
            model[key] = obj.target.value;
            const evData = { key, value: model[key] };
            emit('input', evData);
            r_$change.next(evData);
        }
        const handleCheckboxValueChange = (obj: { target: { id: string, prefixCls: string, type: string, defaultChecked: boolean, checked: boolean } }, key: string, model: any) => {
            const evData = { key, value: model[key] };
            model[key] = obj.target.checked;
            emit('input', evData);
            r_$change.next(evData);
        }
        const handleCheckboxGroupValueChange = (obj: { target: { id: string, prefixCls: string, type: string, defaultChecked: boolean, checked: boolean } }, key: string, model: any) => {
            const evData = { key, value: model[key] };
            // model[key] = obj.target.checked;
            model[key] = obj;
            emit('input', evData);
            r_$change.next(evData);
        }
        const handleCheckboxGroupValueChange_Radio = (obj: any, key: string, model: any) => {
            const evData = { key, value: model[key] };
            model[key] = obj.at(-1);
            emit('input', evData);
            r_$change.next(evData);
        }
        const handleCheckboxGroupValueChange_ButtonRadio = (obj: any, key: string, model: any) => {
            const evData = { key, value: model[key] };
            model[key] = obj;
            emit('input', evData);
            r_$change.next(evData);
        }
        const handleUploadChange = (info: UploadChangeParam, key: string, model: any) => {
            console.log(info);
        }

        const onFinish = (...args: any[]) => {
            emit('finish', ...args);
        };
        const onFinishFailed = (e: SubmitFailedEvent) => {
            handleFormFinishFailed(e, p.fields, formID);
            emit('finishFailed', e);
        }
        const onValidate = (...args: any[]) => {
            // console.log(args);
            emit('validate', ...args);
        };
        const onSubmit = (args: any) => {
            emit('submit', args);
        }
        // const reset = (obj?: any) => {
        //     formRef.value && formRef.value.resetFields();
        //     setTimeout(() => {
        //         formRef.value && formRef.value.clearValidate();
        //         obj && patchValue(p.model, obj);
        //     }, 300);
        // }
        const reset = (obj?: any, isClear: boolean = false) => {
            formRef.value && formRef.value.resetFields();
            if (isClear) {
                Object.keys(p.model).map(x => {
                    if (typeof p.model[x] == 'boolean') {
                        p.model[x] = false;
                    } else if (typeof p.model[x] == 'string') {
                        p.model[x] = '';
                    } else {
                        p.model[x] = undefined;
                    }
                })
            }
            setTimeout(() => {
                formRef.value && formRef.value.clearValidate();
                obj && patchValue(p.model, obj);
            }, 300);
        }
        if (!p.fields) throw new Error('表单的fields数组字段必传');
        const handleTooltipStatus = (key: string) => {
            p.fields.some((x: FormlyFieldGroup) => x.fieldGroup.some(y => {
                let isEq = y.key == key;
                if (isEq) {
                    if (y.templateOptions) {
                        y.templateOptions.showErrorTooltip = false;
                    }
                }
                return isEq;
            }));
        }
        const btnClick = (item: FormlyFieldConfig) => {
            emit('btnClick', item.templateOptions?.attachedIdentify);
        }
        const submit = () => {
            submitBtn.value.$el.click();
        }
        const search = (value: any, key: string) => {
            p.model[key] = value;
            const evData = { key, value };
            emit('search', evData);
        }
        const searchChange = (key: string, value: any) => {
            p.model[key] = value;
            const evData = { key, value };
            emit('input', evData);
        }
        const OpenBySearch = (key: string) => {
            emit('openBySearch', { value: p.model[key], key });
        }


        // 每次赋值，清掉验证
        let $ev_patch: Subscription;
        onUnmounted(() => {
            $ev_patch?.unsubscribe();
        })
        $ev_patch = FormServe.$patchValue.subscribe(() => {
            formRef.value && formRef.value.clearValidate();
        });

        return {
            submit,
            submitBtn,
            formID,
            formRef,
            autoCompleteRef,
            handleInput,
            handleValueChange,
            handleRadioValueChange,
            handleCheckboxValueChange,
            handleCheckboxGroupValueChange_Radio,
            handleCheckboxGroupValueChange_ButtonRadio,
            handleCheckboxGroupValueChange,
            handleUploadChange,
            getFormControlRules,
            onFinish,
            onFinishFailed,
            onValidate,
            onSubmit,
            reset,
            search,
            searchChange,
            OpenBySearch,
            btnClick,
            r_$changeEv,
            r_$change
        };
    },
    render() {
        const size = 'small';

        const getFormItemTemplate = (item: FormlyFieldConfig, group: FormlyFieldGroup, formControl: any, noLabel: boolean = false) => {
            let length = group.fieldGroup.length;
            let width = 100 / length;
            let span = item.templateOptions?.span || (item.cname == 'nocol' ? null : Math.ceil(24 / length));

            let BootStrap: any = {};
            let to = item.templateOptions;
            let hasOtherSpan = to?.xs || to?.sm || to?.md || to?.lg || to?.xl;
            if (hasOtherSpan) {
                to?.xs && (BootStrap.xs = to?.xs);
                to?.sm && (BootStrap.sm = to?.sm);
                to?.md && (BootStrap.md = to?.md);
                to?.lg && (BootStrap.lg = to?.lg);
                to?.xl && (BootStrap.xl = to?.xl);
                to?.xxl && (BootStrap.xl = to?.xxl);
            } else {
                BootStrap = { span, xxl: span }
            }
            // @ts-ignore
            let mq: MQ = inject("mq");
            if (mq.md || mq.sm || mq.xs || mq.lg) {
                item.cname == 'nocol' ? '' : span = 24;
            }
            const slots = {
                title: () => <span>{item.templateOptions?.importantErrorText || item.templateOptions?.errorText}</span>
            };
            return (
                <a-tooltip placement="right" v-slots={slots} trigger={''} visible={item.templateOptions?.showErrorTooltip}
                    getPopupContainer={item.templateOptions?.PopupContaine || ((triggerNode: { parentNode: any; }) => triggerNode.parentNode)}
                    mouseLeaveDelay={2}
                >
                    <a-col
                        span={span}
                        offset={item.templateOptions?.offset} style={item.templateOptions?.formItemStyle}>
                        <a-form-item
                            class={{
                                [style['form-item']]: true, hide: item.hide,
                                [style['form-item-row']]: item.templateOptions?.isRow,
                                [`${item.className}`]: true,
                                'margin-bottom-5': !this.noMarginBottom,
                                [style['margin-bottom-5']]: !this.noMarginBottom
                            }}
                            // { /* 'flex-basis': item.templateOptions?.noFlexBase ? '' : width + '%',  */ }
                            style={`display: ${item.hide ? 'none' : ''}; ${item.templateOptions?.formItemStyle}  `}
                            name={item.key}
                            label={noLabel ? '' : item.templateOptions?.label}
                            labelCol={item.templateOptions?.labelCol}
                            wrapperCol={item.templateOptions?.wrapperCol}
                            labelAlign={item.templateOptions?.labelAlign}
                            colon={item.templateOptions?.colon}
                            extra={item.templateOptions?.extra}
                            hasFeedback={item.templateOptions?.hasFeedback}
                            help={item.templateOptions?.help}
                            required={item.templateOptions?.required}
                            rules={item.templateOptions?.rules || this.getFormControlRules(item)}
                        >
                            {formControl}
                        </a-form-item>
                    </a-col>
                </a-tooltip>
            )
        }
        const getGroupTemplate = (group: FormlyFieldGroup, obj: any, key?: string, index?: number) => {
            let model = obj;
            if (key && index !== undefined) {
                if (!obj[key]) obj[key] = [];
                if (!obj[key][index]) {
                    obj[key][index] = {}
                    model = obj[key][index];
                } else {
                    model = obj[key][index];
                }
            }
            let classNameObj: any = {};
            let className = group.className?.split(' ');
            if (className?.length) {
                className.map(c => {
                    classNameObj[c] = true;
                });
            }
            return (
                <a-row class={{ [style['form-group']]: true, ...classNameObj }} align={this.GroupTemplateAlign} gutter={10}>
                    {
                        group.fieldGroup.map((item) => {
                            let Fdiv = <></>
                            let cp: any = <></>
                            switch (item.type) {
                                case FormlyFieldTypeEnum.AutoComplete:
                                    cp = getFormItemTemplate(item, group, (
                                        <a-auto-complete
                                            value={model[item.key]}
                                            options={item.templateOptions?.options}
                                            ref="autoCompleteRef"
                                            filterOption={false}
                                            onSelect={(e: InputEvent) => this.search(e, item.key)}
                                        // onChange={(e: InputEvent) => this.search(e?.target?.value, item.key)}
                                        >
                                            <a-input-search size={this.size || size} onChange={(e: InputEvent) => {
                                                // @ts-ignore
                                                this.searchChange(item.key, e?.target?.value)
                                            }} placeholder=" "
                                                enter-button
                                                onSearch={() => {
                                                    setTimeout(() => {
                                                        this.autoCompleteRef.blur()
                                                        model[item.key] && this.submitBtn.$el.click();
                                                    }, 0);
                                                }}></a-input-search>
                                        </a-auto-complete>
                                    ));
                                    break;
                                case FormlyFieldTypeEnum.SubsectionCard:
                                    cp = (
                                        <a-col span={item.cname == 'nocol' ? '' : "24"} class="margin-bottom-6 ">{/* padding-right-10 */}
                                            {item.cname2 == 'nocard' ? (
                                                <div style='font-size:25px'>{item.templateOptions?.label}</div>
                                            ) :
                                                (<Card fold={true} isFold={true} disabled={true} v-slots={{
                                                    header: () => (
                                                        <b style={{ 'color': '#c60' }}>{item.templateOptions?.label}</b>
                                                    )
                                                }}>
                                                </Card>)
                                            }
                                        </a-col>
                                    );
                                    break;
                                case FormlyFieldTypeEnum.Text:
                                    cp = getFormItemTemplate(item, group, (
                                        // model[item.key] &&
                                        // <span style={item.templateOptions?.style}>{model[item.key] || item.defaultValue} </span>

                                        (model[item.key] !== undefined || item.defaultValue !== undefined) && (
                                            <>
                                                <span style={item.templateOptions?.style}>{model[item.key] || item.defaultValue}</span>
                                                {item.templateOptions?.html && item.templateOptions?.html}
                                            </>
                                        )
                                    ));
                                    break;
                                case FormlyFieldTypeEnum.Button:
                                    cp = getFormItemTemplate(item, group, (
                                        <a-button
                                            onClick={() => this.btnClick(item)}
                                            size={this.size || size}
                                            disabled={item.templateOptions?.disabled || this.disabled}
                                            style={item.templateOptions?.style}
                                            class={item.templateOptions?.class}
                                            type={item.templateOptions?.type}
                                            icon={<i class={`iconfont ${item.templateOptions?.icon}`} style={item.templateOptions?.iconstyle}></i>}
                                        >{item.templateOptions?.btnText}</a-button>
                                    ));
                                    break;
                                case FormlyFieldTypeEnum.Checkbox:
                                    cp = getFormItemTemplate(item, group, (
                                        <a-checkbox checked={model[item.key]} onChange={(e: any) => this.handleCheckboxValueChange(e, item.key, model)} disabled={item.templateOptions?.disabled || this.disabled} />
                                    ));
                                    break;
                                case FormlyFieldTypeEnum.CheckboxGroup:
                                    cp = getFormItemTemplate(item, group, (
                                        <>
                                            <a-checkbox-group
                                                options={item.templateOptions?.options}
                                                value={model[item.key]}
                                                onChange={(e: any) => this.handleCheckboxGroupValueChange(e, item.key, model)}
                                                disabled={item.templateOptions?.disabled || this.disabled}
                                            >
                                            </a-checkbox-group>
                                        </>
                                    ));
                                    break;
                                case FormlyFieldTypeEnum.CheckboxGroup_Button_Radio:
                                    cp = getFormItemTemplate(item, group, (
                                        <>
                                            <CheckboxBtn
                                                disabled={item.templateOptions?.disabled || this.disabled}
                                                options={item.templateOptions?.options}
                                                value={model[item.key]}
                                                onChange={(e: any) => this.handleCheckboxGroupValueChange_ButtonRadio(e, item.key, model)}
                                            ></CheckboxBtn>
                                        </>
                                    ));
                                    break;
                                case FormlyFieldTypeEnum.CheckboxGroup_Radio:
                                    cp = getFormItemTemplate(item, group, (
                                        <>
                                            <a-checkbox-group
                                                options={item.templateOptions?.options}
                                                value={model[item.key]}
                                                onChange={(e: any) => this.handleCheckboxGroupValueChange_Radio(e, item.key, model)}
                                                disabled={item.templateOptions?.disabled || this.disabled}
                                            >
                                            </a-checkbox-group>
                                        </>
                                    ));
                                    break;
                                case FormlyFieldTypeEnum.DatePicker:
                                    cp = getFormItemTemplate(item, group, (
                                        <>
                                            <a-date-picker
                                                placeholder={item.templateOptions?.placeholder || 'DD/MM/YYYY'}
                                                value={model[item.key]}
                                                onChange={(e: InputEvent) => this.handleValueChange(e, item.key, model, item)}
                                                disabled={item.templateOptions?.disabled || this.disabled}
                                                size={this.size || size}
                                                defaultValue={undefined}
                                                disabledDate={item.templateOptions?.disabledDate}
                                                allowClear={(item.templateOptions?.allowClear !== undefined) ? item.templateOptions.allowClear : true}
                                                show-time={item.templateOptions?.showTime}
                                                format={item.templateOptions?.format || 'DD/MM/YYYY'}
                                                getPopupContainer={item.templateOptions?.PopupContaine || ((triggerNode: { parentNode: any; }) => triggerNode.parentNode)}
                                            />
                                        </>
                                    ));
                                    break;
                                case FormlyFieldTypeEnum.Input:
                                    let Input_text = model[item.key];
                                    if (item.templateOptions?.transform) {
                                        // 给readonly是true 转换显示的文字
                                        Input_text = item.templateOptions?.transform?.(model);
                                    }
                                    cp = getFormItemTemplate(item, group, (
                                        <a-input
                                            value={Input_text}
                                            onChange={(e: InputEvent) => this.handleInput(e, item.key, model)}
                                            allowClear
                                            size={this.size || size}
                                            disabled={item.templateOptions?.disabled || this.disabled}
                                            readonly={item.templateOptions?.readonly}
                                        />
                                    ));
                                    break;
                                case FormlyFieldTypeEnum.InputSearch:
                                    cp = getFormItemTemplate(item, group, (
                                        <a-select
                                            v-slots={{
                                                suffixIcon: () => (<SearchOutlined style="font-size: 16px; margin-top: -3px; color: #fff;" onClick={() => this.OpenBySearch(item.key)} />)
                                            }}
                                            value={model[item.key]}
                                            show-search
                                            placeholder=" "
                                            // default-active-first-option={false}
                                            // show-arrow={false}
                                            filter-option={false}
                                            not-found-content={null}
                                            size={this.size || size}
                                            options={item.templateOptions?.options}
                                            onSearch={(e: string) => this.search(e, item.key)}
                                            onChange={(e: InputEvent) => this.searchChange(item.key, e)}
                                            allowClear={true}
                                        >
                                        </a-select>
                                    ));
                                    break;
                                case FormlyFieldTypeEnum.TextArea:
                                    cp = getFormItemTemplate(item, group, (
                                        <a-textarea value={model[item.key]} onChange={(e: InputEvent) => this.handleInput(e, item.key, model)} allowClear size={this.size || size} disabled={item.templateOptions?.disabled || this.disabled} rows={item.templateOptions?.rows} />
                                    ));
                                    break;
                                case FormlyFieldTypeEnum.Placeholder:
                                    cp = getFormItemTemplate(item, group, (
                                        item.templateOptions?.ref ? item.templateOptions?.ref : ''
                                    ));
                                    break;
                                case FormlyFieldTypeEnum.Password:
                                    cp = getFormItemTemplate(item, group, (
                                        <a-input-password value={model[item.key]} onChange={(e: InputEvent) => this.handleInput(e, item.key, model)} allowClear size={this.size || size} disabled={item.templateOptions?.disabled || this.disabled} autocomplete={item.templateOptions?.autocomplete} />
                                    ));
                                    break;
                                case FormlyFieldTypeEnum.InputNumber:
                                    cp = getFormItemTemplate(item, group, (
                                        <a-input-number value={model[item.key]} onChange={(e: InputEvent) => this.handleValueChange(e, item.key, model)} allowClear
                                            addon-before={item.templateOptions?.prefix} addon-after={item.templateOptions?.suffix}
                                            disabled={item.templateOptions?.disabled || this.disabled}
                                            min={item.templateOptions?.min ?? 1} max={item.templateOptions?.max} size={this.size || size}
                                            formatter={item.templateOptions?.formatter || null}
                                            parser={item.templateOptions?.parser || null}
                                            controls={item.templateOptions?.controls ?? true}
                                            style="width: 100%"
                                        >
                                        </a-input-number>
                                    ));
                                    break;
                                case FormlyFieldTypeEnum.Radio:
                                    cp = getFormItemTemplate(item, group, (
                                        <a-radio checked={model[item.key]} onChange={(e: any) => this.handleRadioValueChange(e, item.key, model)} disabled={item.templateOptions?.disabled || this.disabled} />
                                    ));
                                    break;
                                case FormlyFieldTypeEnum.RadioGroup:
                                    cp = getFormItemTemplate(item, group, (
                                        <a-radio-group
                                            value={model[item.key]}
                                            onChange={(e: any) => this.handleRadioValueChange(e, item.key, model)}
                                            disabled={item.templateOptions?.disabled || this.disabled}
                                            button-style="solid"
                                        >
                                            {
                                                item.templateOptions?.name == 'button' ?
                                                    item.templateOptions?.options?.map(o =>
                                                        <a-radio-button value={o.value} style={o.style}
                                                            class={model[item.key] == o.value ? o.class : null}
                                                        > {o.label}</a-radio-button>
                                                    )
                                                    : item.templateOptions?.options?.map(o => (
                                                        <a-radio value={o.value}>{o.label}</a-radio>
                                                    ))
                                            }
                                        </a-radio-group>
                                    ));
                                    break;
                                case FormlyFieldTypeEnum.Rate:
                                    cp = getFormItemTemplate(item, group, (
                                        <a-rate value={model[item.key]} onChange={(e: InputEvent) => this.handleValueChange(e, item.key, model)} disabled={item.templateOptions?.disabled || this.disabled} />
                                    ));
                                    break;
                                case FormlyFieldTypeEnum.Select:
                                    cp = getFormItemTemplate(item, group, (
                                        <>
                                            <a-select value={model[item.key]} onChange={(e: InputEvent) => this.handleValueChange(e, item.key, model, item)}
                                                allowClear={(item.templateOptions?.allowClear !== undefined) ? item.templateOptions.allowClear : true}
                                                size={this.size || size} disabled={item.templateOptions?.disabled || this.disabled}
                                                options={(item.templateOptions?._options ?? item.templateOptions?.options) || []}
                                                getPopupContainer={item.templateOptions?.PopupContaine || ((triggerNode: { parentNode: any; }) => triggerNode.parentNode)}
                                                placeholder={item.templateOptions?.placeholder || 'Please Select'}
                                                showSearch={true}
                                                filterOption={(input: string, option: any) => {
                                                    let label = option.label || option.Description || '';
                                                    return `${label}`.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                }}
                                            >
                                            </a-select>
                                        </>
                                    ));
                                    break;
                                case FormlyFieldTypeEnum.MultipleSelect:
                                    let value = model[item.key];
                                    if (!value) { value = [] }
                                    if (isArray(value)) {

                                    } else {
                                        value = (value as string).split(',')
                                    }
                                    // 去掉undefined和null
                                    value = value?.filter((v: string) => (v ?? '') !== '');

                                    cp = getFormItemTemplate(item, group, (
                                        <>
                                            <a-select value={value} onChange={(e: InputEvent) => this.handleValueChange(e, item.key, model)}
                                                allowClear={false}
                                                mode="multiple"
                                                size={this.size || size} disabled={item.templateOptions?.disabled || this.disabled}
                                                options={item.templateOptions?.options || []}
                                                getPopupContainer={item.templateOptions?.PopupContaine || ((triggerNode: { parentNode: any; }) => triggerNode.parentNode)}
                                                placeholder={item.templateOptions?.placeholder || 'Please Select'}

                                            >
                                            </a-select>
                                        </>
                                    ));
                                    break;

                                case FormlyFieldTypeEnum.Slider:
                                    cp = getFormItemTemplate(item, group, (
                                        <a-slider value={model[item.key]} onChange={(e: InputEvent) => this.handleValueChange(e, item.key, model)} disabled={item.templateOptions?.disabled || this.disabled} />
                                    ));
                                    break;
                                case FormlyFieldTypeEnum.Switch:
                                    cp = getFormItemTemplate(item, group, (
                                        <a-switch checked={model[item.key]} onChange={(e: InputEvent) => this.handleValueChange(e, item.key, model)} disabled={item.templateOptions?.disabled || this.disabled} />
                                    ));
                                    break;
                                case FormlyFieldTypeEnum.TimePicker:
                                    cp = getFormItemTemplate(item, group, (
                                        <a-time-picker placeholder=" " showNow={true} size={this.size || size} disabled={item.templateOptions?.disabled || this.disabled}
                                            value={model[item.key]} onChange={(e: InputEvent) => this.handleValueChange(e, item.key, model)} value-format={item.templateOptions?.format || 'HH:mm:ss'}
                                            getPopupContainer={item.templateOptions?.PopupContaine || ((triggerNode: { parentNode: any; }) => triggerNode.parentNode)}
                                        />
                                    ));
                                    break;
                                case FormlyFieldTypeEnum.Upload:
                                    cp = getFormItemTemplate(item, group, (
                                        <a-upload
                                            file-list={model[item.key]}
                                            name="file"
                                            multiple={true} disabled={item.templateOptions?.disabled || this.disabled}
                                            headers={{
                                                authorization: 'authorization-text'
                                            }}
                                            onChange={(e: any) => this.handleUploadChange(e, item.key, model)}
                                        >
                                            <a-button>
                                                {/* <upload-outlined></upload-outlined> */}
                                                Click to Upload
                                            </a-button>
                                        </a-upload>

                                    ));
                                    break;
                                case FormlyFieldTypeEnum.CheckboxText:
                                    cp = getFormItemTemplate(item, group, (
                                        <a-checkbox checked={model[item.key]} onChange={(e: any) => this.handleCheckboxValueChange(e, item.key, model)} disabled={item.templateOptions?.disabled || this.disabled} ><span class={`checkbox-inner`}>{item.templateOptions?.label}</span>
                                        </a-checkbox>
                                    ), true);
                                    break;
                                case FormlyFieldTypeEnum.Array:
                                    cp = getFormItemTemplate(item, group, (
                                        getArrarGroupTemplate(item, group, model)
                                    ));
                                    break;
                            }
                            if (item.templateOptions?.add_target) {
                                Fdiv = <>
                                    {cp}
                                    <span class='width-100-100'>{Select_Other_show.apply(this, [item, model])}</span>
                                </>
                            } else {
                                Fdiv = cp
                            }
                            return Fdiv
                        })
                    }
                </a-row>
            )
        }
        const getArrarGroupTemplate = (item: FormlyFieldConfig, group: FormlyFieldGroup, obj: any) => {
            // let m = model[item.key] ? 
            // if (!obj[item.key]) {
            //     obj[item.key] = [];
            //     console.log(obj);
            //     item.fieldArray?.map((x, i) => obj[item.key][i] = {})
            // }
            return <a-space class="ant-row">
                {
                    item.fieldArray?.map((arrItem, i) => {
                        return getGroupTemplate(arrItem, obj, item.key, i);
                    })
                }
            </a-space>
        }

        return (
            <a-form
                id={this.formID}
                name={this.formID}
                ref="formRef"
                class={{ [style['form-host']]: true, 'no-show-require': !this.showRequire, }}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                onValidate={this.onValidate}
                onSubmit={this.onSubmit}
                hideRequiredMark={true}
                model={this.model} value={this.$attrs} >
                {
                    // @ts-ignore
                    this.fields.map((group: FormlyFieldGroup) => {
                        return getGroupTemplate(group, this.model);
                    })
                }
                <a-button type="primary" html-type="submit" style="display: none;" ref="submitBtn">Submit</a-button>
                {this.$slots.default ? this.$slots.default() : ''}
            </a-form>
        )
    }
});

/**
 * 
 * @param model reactive 对象
 * @param obj 要赋值给 model 的 键值对
 * @example patchValue(model, { aaa: 111 })
 */
export const patchValue = (model: any, obj: any) => {
    obj = handleDate2Dayjs(obj)
    Object.assign(model, obj)
    FormServe.$patchValue.next({ model, obj, })
}
/**
 * 把放进来的reactive对象全部清空为null
 * @param model  reactive 对象
 */
export const patchValue_null = (model: any) => {
    Object.keys(model).forEach((key) => {
        model[key] = null;
    })
}
/**
 * 筛选删除数组中完全一样的对象 保留一个
 * @param arr 
 * @returns 
 */
export const removeDuplicateObj_All = (arr: any[]) => {
    const newArr: any = [];
    for (const t of arr) {
        if (
            newArr.find(
                (c: any) => {
                    let keys = Object.keys(c)
                    const kkks = keys.filter(k => c[k] == t[k])
                    return keys.length === kkks.length
                }
            )
        ) {
            continue;
        }
        newArr.push(t);
    }
    return newArr;
};

/**
 * 筛选数据，相同只取一个 ，根据ID，只保留一个
 * @param arr 
 * @returns 
 */
export const removeDuplicateObj_Id = (arr: any[]) => {
    let obj: any = {};
    arr = arr.reduce((newArr, next) => {
        obj[next.ID] ? "" : (obj[next.ID] = true && newArr.push(next));
        return newArr;
    }, []);
    return arr;
};

