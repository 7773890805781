import { patchValue, patchValue_null } from "@/components/form";
import { http } from '@/http';
import useStore from "@/store/metaData";
import useWoStore from "@/store/woManagement";
import { SortEventByAgGrid } from "@/types/table";
import { getObjectByMap, getSortValue, handleArrayNull, handleDate } from "@/utile";
import { getItem, SetDisabled, SetRequired, SetSelectOptions } from "@/utile/form";
import { Pagination, QueryParams } from "@/utile/query";
import { updatRowForNodes } from "@/utile/table";
import { GridApi, RowNode } from "ag-grid-community";
import { v4 as uuid } from 'uuid';
import { computed, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { AgGridRowEvent } from "../ag-table/types/grid";
import { FileType, FileTypeMap } from "./components/Upload";
import { Btn, TabProps } from "./types";
import { Api_handle_result_map, Fn_type } from "./types/map";
import { InitListResult, ListResult } from "./types/types";
import handle_upload from "./use/handle_upload";
import { Part_editApi_before_select, uselist_setSelectOptionsByMap } from "./use/hand_before_after";
import { BtnClick, HandButtonType } from "./use/hand_btnclick_fns";
import { HandListType, ListClick } from "./use/hand_listclick_fns";
import { handel_api_after, handel_api_before } from './use/use_handle_api_before_after';



export interface AgGridRowEvent_e extends AgGridRowEvent {
    currentAction: string;
    currentField: string;
    file: { file: File; fileList: File[] },
    other_params: any
}



export default function useList(
    props: TabProps, model: any, refs: any, canSave: any
) {
    const { formRef, tableRef, ListRef, queryBtnModalRef,
        formBtnRef, modalRef, listmodalRef, PartlistRef2,
        PartlistRef3, PartlistRef4, ShowPhotoref, documentRef, part1
    } = refs
    // @ts-ignore
    // const sort: SortEventByAgGrid = reactive<SortEventByAgGrid>({});
    const pagination: Pagination = reactive<Pagination>(new Pagination());
    const store = useStore();
    const woStore = useWoStore();
    const router = useRouter();


    // console.log(props, model, formRef, tableRef, ListRef, queryBtnModalRef, formBtnRef);

    let isNew = ref<boolean>(false);
    let primaryKey = props.pageConfig?.formPrimaryKey as string;
    if (props.pageConfig?.isVirtualFormPrimaryKey && props.pageConfig?.secondPrimaryKey) primaryKey = props.pageConfig?.isVirtualFormPrimaryKey && props.pageConfig?.secondPrimaryKey;
    const _primaryKey = props.pageConfig?.isVirtualFormPrimaryKey ? props.pageConfig?.formVirtualPrimaryKey as string : props.pageConfig?.formPrimaryKey as string;
    const addObj = { [_primaryKey]: props.pageConfig?.formPrimaryKeyEmptyValue };
    let files: any;
    let initData: any;
    // @ts-ignore
    let currentBtn: Btn = undefined;
    const data = reactive<any>({
        rowData: [],
        sort: undefined,
        QueryParams: new QueryParams,
        selectOptions: {},
        initSelectOptions: {},
        files,
        loading: false,
        QRCoderPath: '',
        exportLoading: false,
        importExcelLoading: false,
        exportExcelLoading: false,
        exportXMLLoading: false,
        height: '',
        initData,
        dynamicColumnDefs: [],
        dynamicEchartsXdata: {},
        dynamicEchartsYdata: {},
        SelectMainItem: undefined,
        viewbtnname: false,
        onSelect_init_rowdata: [],
        getRowEntity: {},
        /** 当前点击的按钮 */
        currentBtn,
        formDisabled: false
    });
    const photo_modal = reactive<any>({
        modal_slelect_option: [],
    })
    const canExport = computed(() => !!data.rowData.length);
    const canImport = computed(() => !!data.rowData.length);
    const canSaveReportAs = computed(() => !!(model.ReportStatus == 'Completed'))

    const setHeight = (arr: any[]) => {
        let h = 110;
        let l = arr.length;
        let height = h + l * 40;
        if (height > 400) height = 400
        return height + 'px';
    }

    watch(data, (value) => {
        setTimeout(() => {
            data.height = setHeight(data.rowData);
            tableRef.value?.grid?.api?.sizeColumnsToFit();
            data.rowData.length && setTimeout(() => {
                let headerHeight = props.pageConfig?.headerHeight;
                let groupHeight = props.pageConfig?.groupHeight;
                headerHeight && tableRef.value?.grid?.api?.setHeaderHeight(headerHeight);
                headerHeight && (tableRef.value?.grid?.api as GridApi).setGroupHeaderHeight(groupHeight)
            }, 0);
        }, 100);
    });
    // onMounted(() => {
    //     setSelectRow();
    // });
    /** 当有搜索结果时，重新选择当前行 */
    const setSelectRowByStoreSearchResult = () => {
        // 有搜索结果，且 路由对的上。重新选择行
        if (woStore.globalSearchShowResult && new RegExp(woStore.path, 'i').test(router.currentRoute.value.fullPath)) {
            selectCurrentRow(woStore.rows[0]);
        }
    }

    const getInit = () => {
        let row = woStore.rows && woStore.rows.length ? woStore.rows[0] : {};
        let obj: any = getObjectByMap(props.httpParamsMap?.init || [], row || {});
        obj.LoginName = store.userName;

        props.http?.init && props.http.init(obj).then((result: InitListResult) => {
            let x = result;
            if ('Entity' in result) {
                x = result.Entity
            }
            data.initData = x;
            pagination.totalPages = x.TableData.TotalPages;
            pagination.totalRecords = x.TableData.TotalRecords;
            pagination.currentPage = x.TableData.CurrentPage;
            data.rowData = x.TableData.Data;

            setSelectOptionsByMap(x);
            // 父下拉初始有默认值，子与它联动的需要初始值，在这里存入
            // 然后data配置中直接从store取
            if (router.currentRoute.value.fullPath == '/Safety/MajorItemsInformation') {
                store.initDepartment = x.InspectionList?.find((a: any) => a.ID?.toUpperCase() == store.userName?.toUpperCase())?.Value || ''
            }
            let t = props.pageConfig?.initApi_handle_result_target;
            if (props.pageConfig?.initApi_handle_result && t) {
                let f = props.pageConfig?.initApi_handle_result_Fields;
                if (f && t == 'List') {

                    if (props.pageConfig.initApi_handle_result_noneed_setdata) {
                        ListRef.value?.setSelect(x)
                    } else {
                        let arr = x[f as string];
                        ListRef.value?.setSelect(x)
                        arr && ListRef.value?.setRowData(x);
                    }
                }
                else if (f && t == 'CustomFrom_s') {
                    // CustomfromRef_s.value.setSelect(x);
                }
                else if (f && t == 'PartList') {

                    PartlistRef2.value?.getInitSelectData(x)
                    PartlistRef4.value?.getInitSelectData(x)
                }
            }

            if (props.pageConfig?.initApi_handle_result_getmodal_slele) {
                photo_modal.modal_slelect_option = x
            }
            data.viewbtnname = false
            props.pageConfig?.api_after?.init && handel_api_after(x, props.pageConfig?.api_after?.init, refs, (fn: Fn_type) => {
                if (fn === 'selectCurrentRow') {
                    selectCurrentRow();
                }
            });
        })
    };

    const setSelectOptionsByMap = (o: any) => {
        const obj = { props, data, o, formBtnRef, store, model }
        uselist_setSelectOptionsByMap(obj)
    }
    //当前选择的行
    const onSelectionChanged = (arr: any[]) => {
        let r = arr[0];
        data.onSelect_init_rowdata = r

        // formRef.value && formRef.value.reset();
        if (!r) return;
        let arr1 = props.httpParamsMap?.getRow.length ? props.httpParamsMap?.getRow : [{ key: props.pageConfig?.formPrimaryKey, mapKey: props.pageConfig?.formPrimaryKey }]
        // @ts-ignore
        let obj: any = getObjectByMap(arr1, r);
        obj.LoginName = store.userName;
        obj = {
            ...data.QueryParams,
            ...obj
        }
        r && props.http?.getRow && props.http.getRow(obj).then((x: { Entity: any, [proName: string]: any /* SubConList?: SubCon[]; AreaCode?: any[] */ }) => {
            let editObj = props.pageConfig?.isVirtualFormPrimaryKey ? {
                [_primaryKey as string]: uuid()
            } : {}
            let Entity = x.Entity || x.CurrentEntity || x
            data.getRowEntity = Entity
            patchValue(model, { ...Entity, ...editObj });
            setconfirmverifyFields()
            setControlledFields();
            if (props.pageConfig?.hasUpdataGlobalSearchPermission && props.pageConfig.updataActionType == 'ListSelectChange') {
                let path = router.currentRoute.value.fullPath.split('/').slice(0, 3).join('/');
                let arrName = props.pageConfig?.searchBarShowResultExtraArrName;
                arrName && woStore.setShowResultByArrAndObj(x[arrName] || [], x.Entity, props.pageConfig?.searchBarShowResultConfig || [], path);
            }
            let r = props.pageConfig?.editApi_handle_result;
            if (r) {
                let f = r.fields;
                let t = r.target;
                if (f && t == 'List') {
                    let arr = Entity[f as any];
                    // ListRef.value.setSelect(x)
                    selectCurrentRow();
                    arr && ListRef.value.setRowData(Entity);

                    x.PhotoList && ShowPhotoref.value.getid && ShowPhotoref.value.getid(x)
                } else if (f && t == 'CustomFrom_s') {
                    // CustomfromRef_s.value.setSelect(x);
                }
                else if (f && t == 'PartList') {
                    if (x.PartIICList && x.PartIIDList && x.PartIIICList && x.PartIIIDList) {
                        let { PartIICList, PartIIDList, PartIIICList, PartIIIDList } = x
                        patchValue(model, { PartIICList, PartIIDList, PartIIICList, PartIIIDList });
                    }
                    PartlistRef2.value?.setModalDefault(model)

                    PartlistRef2.value.setRowData(model)
                    PartlistRef3.value.setRowData(model)
                    PartlistRef4.value.GetFormModels(model)
                }
            }
            props.pageConfig?.api_after?.getRow && handel_api_after(x, props.pageConfig?.api_after?.getRow, refs, (fn: Fn_type, z: Api_handle_result_map) => {
                if (fn === 'handle_Inactive') {
                    handle_Inactive(x, z)
                } else if (fn === 'handle_Inactive_checkbox') {
                    handle_Inactive_checkbox(x, z)
                }
            });
        })
        if (props.pageConfig?.onSelApi_handle_after && props.pageConfig.onSelApi_handle_after.target == 'CollapseForm') {

            data.SelectMainItem = r.MainItem;
        }

    };
    const getList = (query?: QueryParams, o: any = {}) => {
        let obj = new QueryParams();
        obj.PageNumber = pagination.currentPage;
        obj.PageSize = pagination.pageSize;
        if (query) {
            obj = new QueryParams({ ...obj, ...query });
            obj.PageNumber = 1;
        } else if (data.QueryParams) {
            obj = new QueryParams({ ...data.QueryParams, ...obj });
        }
        if (data.sort) {
            obj.IsDesc = getSortValue(data.sort.sort);
            obj.OrderByField = data.sort.row.getColId()
        } else {
            obj.IsDesc = '';
            obj.OrderByField = '';
        }
        obj = { ...obj, ...getObjectByMap(props.httpParamsMap?.getList || []) };
        data.QueryParams = obj;
        obj = { ...obj, ...o }

        // summary调用接口排序报错，所以直接使用aggrid的
        if (props.pageConfig?.NoNeed_gitList_sort) {
            return
        }

        obj = handleDate(obj);
        props.http?.getList && props.http.getList(obj).then((x: InitListResult) => {

            pagination.totalPages = x.TableData.TotalPages
            pagination.totalRecords = x.TableData.TotalRecords;
            pagination.currentPage = x.TableData.CurrentPage;
            setSelectRowByStoreSearchResult();
            if (props.pageConfig?.initApiType == 'List') {
                selectCurrentRow();
            }
            data.rowData = x.TableData.Data;
            // 排序时改按钮名字
            data.viewbtnname = false
        })

    };
    const save = (isnew?: boolean) => {
        isNew.value = !!isnew;
        formRef.value && formRef.value.submit();
    }
    const saveList = () => {
        let obj: any = { LoginName: store.userName };
        obj = { ...obj, ...getObjectByMap(props.httpParamsMap?.editByList || [], ListRef.value.data) };

        props.http?.editByList && props.http.editByList(obj).then((x: any) => {
            props.httpResultMap?.editByList?.map(y => {
                x[y.mapKey] && ListRef.value.setRowData(x[y.mapKey], true);
            })
            http.notify('Saved successfully..');
        })
    }
    const hand_newsc = (arr: any[]) => {
        if (props.pageConfig?.edithand_select_tabledata_showother) {
            arr.map(x => {
                if (x.Subcontractor == 'New SC') {
                    x.Subcontractor = x.SubcontractorValue
                }
            })
        }
    }
    const add = () => {
        patchValue(model, {
            UpdateBy: store.userName, UpdateOn: new Date(),
            CreateBy: store.userName, CreateOn: new Date(),
            LoginName: store.userName
        });
        let obj: any = { ...model };
        if (data.sort) {
            obj.IsDesc = getSortValue(data.sort.sort);
            obj.OrderByField = data.sort.row.getColId()
        } else {
            obj.IsDesc = '';
            obj.OrderByField = '';
        }
        if (data.QueryParams) {
            obj = new QueryParams({ ...obj, ...data.QueryParams, });
        }
        if (props.pageConfig?.addApi_before_select && props.pageConfig?.addApi_select_befotr_select?.target == 'PartList') {
            let part = Part_editApi_before_select(PartlistRef2, PartlistRef4)

            obj = {
                ...obj,
                ...part
            };
        }

        obj = { ...obj, ...getObjectByMap(props.httpParamsMap?.add || [], obj), };
        obj = handleArrayNull(obj);
        obj = handleDate(obj);
        obj = handleAddBefore(obj);
        props.http?.add && props.http.add(obj).then((x: ListResult) => {
            let Entity = x.Entity || x.CurrentEntity
            if (props.pageConfig?.initApiType == 'List') {
                let Data = x.TableData || x.Data;
                if (props.pageConfig?.addEntity2Data) {
                    if (Entity) {
                        let some = Data?.some(b => {
                            if (b[primaryKey] == Entity[primaryKey]) {
                                return true;
                            }
                        })
                        if (!some) {
                            Data.unshift(Entity);
                        }
                    }
                }

                data.rowData = Data;

                pagination.currentPage = x.CurrentPage;
                pagination.totalPages = x.TotalPages;
                pagination.totalRecords = x.TotalRecords;
                isNew.value && newRow();
                selectCurrentRow();
            } else {
                woStore.updataShowResultByRow(Entity);
            }
            hand_newsc(data.rowData)

            Entity && patchValue(model, { ...Entity });
            http.notify('Saved successfully..');
            let r = props.pageConfig?.addApi_handle_after;
            if (r) {
                let f = r.fields;
                let t = r.target;
                if (f && t == 'List') {
                    let arr = Entity[f as any];
                    // ListRef.value.setSelect(x)
                    selectCurrentRow();
                    arr && ListRef.value.setRowData(Entity);
                } else if (f && t == 'CustomFrom_s') {
                    // CustomfromRef_s.value.setSelect(x);
                } else if (f && t == 'CollapseForm') {
                    // 处于查看10item状态时刷新表格
                    if (data.viewbtnname && model.MainItem) {
                        HandButtonClick('RefreshTen')()
                    } else {
                        data.viewbtnname = false
                    }
                    model.MainItem = null
                } else if (t == 'saveMenuMetaData_Permission') {
                    store.saveMenuMetaData_Permission(x['Permission'])
                }
            }
        })
    }
    const handleAddBefore = (obj: any) => {

        let o = Object.assign({}, obj);
        let before = props.pageConfig?.addApi_handle_before;
        if (!before) return o
        before.map(x => {
            if (x.source == 'initData') {
                o[x.field] = data.initData[x.sourceField];
            }
        })
        return o;
    }
    const edit = (afterFn?: Function) => {
        patchValue(model, { UpdateBy: store.userName, UpdateOn: new Date(), });
        let obj: any = { ...model };
        if (data.sort) {
            obj.IsDesc = getSortValue(data.sort.sort);
            obj.OrderByField = data.sort.row.getColId()
        } else {
            obj.IsDesc = '';
            obj.OrderByField = '';
        }
        if (data.QueryParams) {
            obj = new QueryParams({ ...data.QueryParams, ...obj });
        }
        /**执行修改前对参数进行筛选 */
        if (props.pageConfig?.editApi_before_select && props.pageConfig?.editApi_before_select_key) {
            switch (props.pageConfig?.editApi_before_select_key.target) {
                case 'Subcontractor':
                    if (obj['Subcontractor'] !== 'New SC') {
                        obj['SubcontractorValue'] = null
                    }
                    break;
                case 'PartList':
                    let part = Part_editApi_before_select(PartlistRef2, PartlistRef4)

                    obj = {
                        ...obj,
                        ...part
                    };
                    break;
            }
        }
        obj = { ...obj, ...getObjectByMap(props.httpParamsMap?.edit || [], obj) };

        const fn = () => {
            obj = handleDate(obj);
            props.http?.edit && props.http.edit(obj).then((x: ListResult) => {
                afterFn && afterFn()

                // x 都不存在，不进行操作，直接弹成功信息
                if (!x) return http.notify('Saved successfully..');
                let Entity = x.CurrentEntity || x.Entity
                data.getRowEntity = Entity
                Entity && patchValue(model, { ...Entity });

                // 如果当前 调用init的是List 才 进行表格操作
                if (props.pageConfig?.initApiType == 'List') {
                    // 有返回TableData，用TableData覆盖表格
                    let TableData = x.TableData || x.Data;
                    if (TableData) {
                        data.rowData = TableData;
                        pagination.currentPage = x.CurrentPage;
                        pagination.totalPages = x.TotalPages
                        pagination.totalRecords = x.TotalRecords;
                    } else { // 没有 TableData。有返回CurrentEntity，用CurrentEntity覆盖表格里的对应row数据
                        let nodes = tableRef.value.getAllNodes();
                        updatRowForNodes(primaryKey, Entity[primaryKey], nodes, Entity)
                    }
                    isNew.value && newRow();
                    selectCurrentRow();
                } else {
                    woStore.updataShowResultByRow(Entity);
                }
                let r = props.pageConfig?.editApi_handle_result;
                if (r) {
                    let f = r.fields;
                    let t = r.target;
                    if (f && t == 'List') {
                        // let arr = Entity[f as any];
                        // ListRef.value.setSelect(x)
                        selectCurrentRow();

                        let arr = x[f as string];
                        arr && ListRef.value?.setRowData(x);
                    } else if (f && t == 'CustomFrom_s') {
                        // CustomfromRef_s.value.setSelect(x);
                    }
                    else if (f && t == 'PartList') {
                        // 重新给part2的表单赋值
                        PartlistRef4.value.GetFormModels(model)
                        PartlistRef2.value.SetCheckboxSelect(model)
                        PartlistRef2.value?.setModalDefault(model)

                    } else if (t == 'saveMenuMetaData_Permission') {
                        store.saveMenuMetaData_Permission(x['Permission'])
                    }
                }
                if (data.viewbtnname/*  && !model.MainItem */) {
                    HandButtonClick('RefreshTen')(true)
                }
                http.notify('Saved successfully...');


                props.pageConfig?.api_after?.edit && handel_api_after(x, props.pageConfig?.api_after?.edit, refs, (fn: Fn_type, z: Api_handle_result_map) => {
                    if (fn === 'uploadList_Cell_byShow') {
                        // 前端从init里取出来后，更新show
                        let attach: {
                            option: string
                            Entity: string
                            targetField: string
                            pickField: string
                            colKey: string
                        } = z.attachValue;

                        if (attach) {
                            try {
                                let options = data.selectOptions?.[attach.option] || [];
                                let opt = options.find((y: { [k: string]: any; }) => y[attach.targetField] === eval(`x.${attach.Entity}`));
                                let v = opt[attach.pickField];
                                let nodes = tableRef.value.getAllNodes();
                                nodes.some((n: RowNode) => {
                                    let isFind = n.data[primaryKey] === Entity[primaryKey];
                                    isFind && n.setDataValue(attach.colKey, v)
                                    return isFind;
                                })
                            } catch (error) {

                            }
                        }
                    } else if (fn === 'handle_Inactive') {
                        handle_Inactive(x, z)
                    } else if (fn === 'handle_Inactive_checkbox') {
                        handle_Inactive_checkbox(x, z)
                    } else if (fn === 'updatRowForNodes') {
                        if (z.updatRowForNodesFn) {
                            let nodes = tableRef.value.getAllNodes();
                            updatRowForNodes(primaryKey, Entity[primaryKey], nodes, z.updatRowForNodesFn(Entity))
                        }
                    }
                });
            })
        }

        let validateBefore: any;
        props.pageConfig?.api_before?.edit && handel_api_before(obj, props.pageConfig?.api_before?.edit, refs, (fn: Fn_type, x: Api_handle_result_map) => {
            if (fn === 'validateBefore') {
                validateBefore = x
            }
        });
        obj = handleDate(obj);

        if (validateBefore && validateBefore?.validateBeforeFn) {//有验证配置 
            let o: Api_handle_result_map = validateBefore;
            o.validateBeforeFn?.(
                (params) => {
                    if (params) {
                        obj = { ...obj, ...params }
                    }
                    fn()
                },
                props, { params: obj })
        } else {//没有直接调
            fn()
        }

    }
    const handle_Inactive = (x: any, o: Api_handle_result_map = {}) => {
        let z: Api_handle_result_map;
        if (o?.getMap_by_currentBtnRef) {
            z = o.getMap_by_currentBtnRef(data.currentBtn)
        } else {
            z = o;
        }

        let isCancel = false;
        if (z.fields) {
            try {
                isCancel = eval(`x.${z.fields}`);
            } catch (e) {
            }
        } else {
            isCancel = z?.attachValue
        }

        data.formDisabled = isCancel
    }
    const handle_Inactive_checkbox = (x: any, z: Api_handle_result_map = {}) => {
        let o: Api_handle_result_map;
        if (z?.getMap_by_currentBtnRef) {
            o = z.getMap_by_currentBtnRef(data.currentBtn)
            setTimeout(() => {
                data.currentBtn = undefined
            }, 1000);
        } else {
            o = z;
        }

        let c = refs?.[o.targetRef || ''];

        if (c) {
            try {
                c.value.setRowData(o?.attachValue);
            } catch (e) {
                setTimeout(() => {
                    c.value?.setRowData(o?.attachValue);
                }, 300);
            }
        }

        o?.setControlledFn?.(props.fields || [], o?.attachValue)
    }
    const newRow = () => {


        const fn = () => {
            if (props.pageConfig?.newRow_before_select) {
                // 全部清空，因为可能不止UID，传''都会报错
                patchValue_null(model)
                PartlistRef2.value.SetCheckboxSelect(model)
            }
            if (props.pageConfig?.initApiType == 'List') {
                tableRef.value.clearSelect();
            }
            setconfirmverifyFields()
            setControlledFields(false);
            let b = props.pageConfig?.newRow_handle_before;
            if (b) {
                let t = b.target, f = b.fields;
                if (t == 'List') {
                    // 清空List 记录 (使用场景 safety 第一个页面)
                    ListRef.value.data.rowData = [];
                }
                else if (t == 'PartList') {
                    PartlistRef2.value.empty_rowdata()

                    PartlistRef3.value.clearPart()

                    PartlistRef4.value.clearPart()
                    PartlistRef4.value.GetFormModels(model)

                } else if (t == 'CollapseForm') {
                    // 新增时清除mainitem
                    model.MainItem = false;
                    data.SelectMainItem = null

                }
            }
            props.pageConfig?.api_after?.new && handel_api_after(null, props.pageConfig?.api_after?.new, refs, (fn: Fn_type, obj?: Api_handle_result_map) => {
                if (fn === 'addVersionList') {
                    http.confirm({
                        title: 'Information',
                        content: obj?.attachValue ?? '',
                        onOk: () => {
                            eval(`${obj?.fn2}?.()`)
                        }
                    })
                } else if (fn === 'handle_Inactive') {
                    handle_Inactive(null, obj)
                } else if (fn === 'handle_Inactive_checkbox') {
                    handle_Inactive_checkbox(null, obj)
                }
            });

            if (props.pageConfig?.defaultModel) {
                patchValue(model, props.pageConfig?.defaultModel)
            }
        }
        if (props.pageConfig?.need_DoubleClicked_row_open_part1) {
            part1?.showDrawer()
            setTimeout(() => {
                formRef.value && formRef.value.reset(addObj, true);
                fn()
            });
        } else {
            formRef.value && formRef.value.reset(addObj, true);
            fn()
        }
    }
    /** 设置受控字段 */
    const setControlledFields = (isDisabled = true) => {
        props.pageConfig?.controlledFields?.map(field => {
            SetDisabled(props.fields || [], field, isDisabled);
        });
        props.pageConfig?.setControlledFn?.(props.fields || [], isDisabled);
    }
    /** 设置不必填字段 */
    const setconfirmverifyFields = (isDisabled = false) => {
        props.pageConfig?.confirmverify?.map(field => {
            SetRequired(props.fields || [], field, isDisabled);
        });
        props.pageConfig?.confirmverifyRequire?.map(field => {
            SetRequired(props.fields || [], field);
        });
    }
    const del = () => {
        http.confirm({
            title: 'Information',
            content: 'Do you want to delete it permanently',
            onOk: () => {
                let delParamsArr = props.httpParamsMap?.del;
                let obj: any = {
                    LoginName: store.userName,
                    UpdateBy: store.userName
                };
                if (delParamsArr && delParamsArr.length > 0) {
                    obj = { ...obj, ...getObjectByMap(delParamsArr, model) }
                } else {
                    obj = { ...obj, [primaryKey]: model[primaryKey] }
                }
                if (data.sort) {
                    obj.IsDesc = getSortValue(data.sort.sort);
                    obj.OrderByField = data.sort.row.getColId()
                } else {
                    obj.IsDesc = '';
                    obj.OrderByField = '';
                }
                if (data.QueryParams) {
                    obj = new QueryParams({ ...data.QueryParams, ...obj });
                }

                props.http?.del && props.http.del(obj).then((x: ListResult) => {
                    if (props.pageConfig?.initApiType == 'List') {
                        let TableData = x.TableData || x.Data;
                        if (TableData) {
                            data.rowData = TableData;
                            pagination.currentPage = x.CurrentPage;
                            pagination.totalPages = x.TotalPages
                            pagination.totalRecords = x.TotalRecords;
                        }
                        tableRef.value.clearSelect();
                    }
                    woStore.clear();
                    formRef.value && formRef.value.reset();

                    http.notify('The deletion was successful.');

                    let b = props.pageConfig?.delApi_handle_result;
                    if (b) {
                        let t = b.target, f = b.fields;
                        if (t == 'PartList') {
                            // 清空part2上面表单数据
                            if (props.pageConfig?.newRow_before_select) {
                                const { PageNumber, PageSize, } = model
                                patchValue_null(model)
                                patchValue(model, {
                                    // ...aaaa,
                                    PreparedBy: store.userName,
                                    CompletedDate: new Date(),
                                    PageNumber,
                                    PageSize
                                })
                                PartlistRef2.value.SetCheckboxSelect(model)
                            }
                            PartlistRef2.value.empty_rowdata()

                            PartlistRef3.value.clearPart()

                            PartlistRef4.value.clearPart()
                            PartlistRef4.value.GetFormModels(model)

                        }
                        else if (f && t == 'List') {
                            ListRef.value.data.rowData = [];
                        }
                        else if (t == 'CollapseForm') {
                            // 删除时如果处于查看状态就刷新并清空mainitem
                            if (data.viewbtnname) {
                                HandButtonClick('RefreshTen')(true)
                            }
                            model.MainItem = null
                            data.SelectMainItem = null
                        } else if (t == 'saveMenuMetaData_Permission') {
                            store.saveMenuMetaData_Permission(x['Permission'])
                        }
                    }

                    props.pageConfig?.api_after?.del && handel_api_after(x, props.pageConfig?.api_after?.del, refs);

                })
            }
        })
    }
    const selectCurrentRow = (row?: any) => {

        setTimeout(() => {
            if (!tableRef.value) { return }

            let nodes = tableRef.value.getAllNodes()
            // let nodes = tableRef.value.grid.api.getRenderedNodes();
            nodes && nodes.some((node: RowNode) => {
                let obj: any = node.data;
                if (row) {
                    if (obj[primaryKey] == row[primaryKey]) {
                        node.setSelected(true);
                        return true;
                    }
                } else {
                    if (obj[primaryKey] == model[primaryKey]) {
                        node.setSelected(true);
                        return true;
                    }
                }
            })
        }, 10);
    }

    const HandListClick = (ClickType: HandListType) => {

        return (e: AgGridRowEvent | AgGridRowEvent_e | SortEventByAgGrid | boolean) => {
            const pobj = {
                e, props, data, model,
                modalRef, photo_modal,
                part1, getList,
            }
            ListClick(ClickType, pobj)
        }
    }
    const HandButtonClick = (BtnType: HandButtonType) => {

        return (e?: any) => {
            let pobj = {
                data, pagination, model, props, refs, e, woStore,
                selectCurrentRow, store, tableRef, edit, canSave, canSaveReportAs
            }
            BtnClick(BtnType, pobj)
        }
    }
    if (props.pageConfig?.need_Option_linkage && props.pageConfig?.Option_linkage_field_arr) {

        let array = props.pageConfig?.Option_linkage_field_arr
        array.map((e: any) => {
            let ID = props.pageConfig?.formPrimaryKey ?? 'ID';
            const { Pa, t_f, Ch, Ch_f, needselect } = e
            const Pa_value = computed(() => ({
                val: model[Pa],
                ID: model[ID]
            }))
            switch (t_f) {
                case 'select_select':
                    watch(Pa_value, (a, b) => {
                        if (a?.val !== b?.val) {
                            if (a.val) {
                                SetSelectOptions(props.fields || [], Ch, () => data.initSelectOptions[Ch]?.filter((c: any) => c[Ch_f] === a.val));
                            } else {
                                SetSelectOptions(props.fields || [], Ch, () => data.selectOptions[Ch]);
                            }
                        }
                        // //没有id的时候 * 点击new后 ID==初始id
                        // //选中一条切换时，有ID，直接切换a,有a有b * 选中一条时，有ID，直接清空a
                        if (
                            !model[ID]
                            || (model[ID] === addObj[ID])
                            // || (model[ID] && a && b)
                            // || (model[ID] && !a && b)
                        ) {
                            model[Ch] = null
                        }

                    }, { immediate: true, deep: true })
                    break;
                case 'select_input':
                    watch(Pa_value, (a, b) => {
                        if (a.val) {
                            if (needselect) {
                                let aa: any = data.selectOptions[Pa]?.filter((c: any) => c['value'] === a.val)[0]?.Value
                                if (aa) model[Ch] = aa
                            }
                            else { model[Ch] = a.val }
                        } else {
                            model[Ch] = null
                        }
                    }, { immediate: true, deep: true })
                    break;
            }
        })
    }
    if (props.pageConfig?.hasContractNoRepDistrict) {
        const v = computed(() => {
            return { ID: model['ID'], NO: model['ContractorNo'] }
        })
        watch(
            v,
            (ID, old) => {
                let cur = model['ContractorNo'];
                if (props.fields) {
                    let targetList = data.initData?.['ContractNoRepDistrictList'] || [];
                    // 去掉空格对比
                    let str = cur?.replace(/\s/g, "");
                    let find = targetList?.find((x: { ID: any; }) => x.ID === str);
                    let targetItem = getItem('District', props.fields);
                    if (targetItem) {
                        // 找到对应那条并且有值
                        if (find && find?.['RepDistrictCode']) {
                            // 获取那条上的
                            let findArr: any[] = find?.['RepDistrictCode']?.split(',') || [];
                            let d = model['District'];
                            // 加入model上的
                            d && findArr.push(d);
                            let options = targetItem.templateOptions?.options || [];
                            let opt = options.filter(x => findArr?.includes(x.value));
                            targetItem.templateOptions!._options = opt;
                        } else {//没值直接设置所有
                            targetItem.templateOptions!._options = targetItem.templateOptions?.options
                        }
                    }
                }
            }, { immediate: true, deep: true })
    }
    if (props.pageConfig?.firstApi) {
        props.pageConfig?.firstApi == 'init' && props.pageConfig?.initApiType == 'List' && getInit();
        props.pageConfig?.firstApi == 'getList' && getList();
    }
    const getAccept = (c: Btn) => {
        let arr: FileType[] = [];
        if (c && c.fileType) arr = c.fileType;
        if (arr?.length) return arr?.map(x => FileTypeMap[x]).join(',')
        return 'text/*';
    }
    const i_selectFiles = (e: any, c: Btn) => {
        handle_upload(e, c, props, refs, (fn: any, obj: any, o?: Api_handle_result_map) => {
            if (fn == 'patchValue_model') {
                patchValue(model, obj)
            } else if (fn == 'save') {
                setTimeout(() => {
                    save()
                });
            }
        })
    }

    const NewVersion = () => {
        tableRef.value.clearSelect();
        props.http?.getMaxVersionNo && props.http?.getMaxVersionNo({ Code: model?.['Code'] }).then((x: { Data: number }) => {
            let Data = x?.Data || 0;
            let obj: any = { Ver: Data };
            props.pageConfig?.newVersion_pickKeys?.map(x => {
                if (typeof x === 'object' && 'key' in x) {
                    obj[x.key] = x.fix
                } else {
                    obj[x] = model[x]
                }
            })
            formRef.value && formRef.value.reset({ ...addObj, ...obj }, true);

            props.pageConfig?.api_after?.NewVersion && handel_api_after(x, props.pageConfig?.api_after?.NewVersion, refs, (fn: Fn_type, z: Api_handle_result_map) => {
                if (fn === 'handle_Inactive') {
                    handle_Inactive(x, z)
                } else if (fn === 'handle_Inactive_checkbox') {
                    handle_Inactive_checkbox(x, z)
                }
            });
        })
    }
    const resetTableSort = () => {
        data.sort = null;
        tableRef.value?.grid?.columnApi?.applyColumnState({
            defaultState: { sort: null },
        })
    }
    const fns = {
        canExport,
        pagination,
        canSaveReportAs,
        data,
        canImport,
        save,
        saveList,
        onSelectionChanged,
        getList,
        add,
        edit,
        del,
        newRow,
        getInit,
        HandButtonClick,
        HandListClick,
        photo_modal,
        getAccept,
        i_selectFiles,
        NewVersion,
        setControlledFields,
        resetTableSort
    }
    return {
        ...fns
    }
}