import type { Directive, DirectiveBinding } from "vue";
import RouteService from "@/share/router.service";
import useStore from '@/store/metaData';
let isUnMounted: boolean = false
const setPermission = (el: HTMLButtonElement, binding: DirectiveBinding<any>, time: number = 1000) => {
    let timer = setTimeout(() => {
        !isUnMounted && setPermission(el, binding);
        clearTimeout(timer);
    }, time);
    let value = binding.value;
    let show = value && value.show;
    let c = RouteService.$currentRouter
    const store = useStore();
    let menu = store.menu.find(x => x.isActive);

    if (!(c && c.currentRoute)) return;
    let cMenu = menu?.subMenu.find(x => new RegExp(x.route, 'i').test(c.currentRoute.value.path));
    // 'Read' | 'Create' | 'Delete' | 'Enquiry' | 'Report' | 'Update';
    const defaultType: any = {
        New: 'Create',
        Save: 'Update',
        Delete: 'Delete',
        Download: 'Report',
        Export: 'Report',
        Report: 'Report',
        Query: 'Enquiry',
    }
    let text = el?.innerText;
    let type: string;
    if (value && value.type) {
        type = value.type;
    } else {
        Object.keys(defaultType).map(x => {
            if (new RegExp(x, 'i').test(text)) {
                type = defaultType[x];
            }
        })
    }
    if (cMenu) {
        // @ts-ignore
        let p = cMenu[type];

        let btn = value?.c;

        if (btn && btn?.dirDisabled) {
            p = btn.dirDisabled(p)
        }

        // @ts-ignore
        // text == '' && console.log(p, type, text);
        if (!p) el.setAttribute('disabled', '');
        // el.style.display = 'none'
    }
}

const a: Directive = {
    // 在绑定元素的 attribute 前
    // 或事件监听器应用前调用
    created(el, binding, vnode, prevVnode) {
        // 下面会介绍各个参数的细节
        isUnMounted = false;
    },
    // 在元素被插入到 DOM 前调用
    beforeMount(el, binding, vnode, prevVnode) { },
    // 在绑定元素的父组件
    // 及他自己的所有子节点都挂载完成后调用
    mounted(el, binding, vnode, prevVnode) {
        // console.log(el);
        // setPermission(el);
        setPermission(el, binding, 10);
    },
    // 绑定元素的父组件更新前调用
    beforeUpdate(el, binding, vnode, prevVnode) { },
    // 在绑定元素的父组件
    // 及他自己的所有子节点都更新后调用
    updated(el, binding, vnode, prevVnode) {
    },
    // 绑定元素的父组件卸载前调用
    beforeUnmount(el, binding, vnode, prevVnode) {
        isUnMounted = true;
    },
    // 绑定元素的父组件卸载后调用
    unmounted(el, binding, vnode, prevVnode) { },

}


export default (() => a)();
