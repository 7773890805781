import { computed, defineComponent, h, inject, reactive, ref } from "vue";
import useStore from "@/store/metaData";

import verifyPhoto from "@/pipe/verifyPhoto";
import { isArray } from '@vue/shared';
import DownloadService from '@/http/download';
import http from "../../views/12-login/http";
import style from "./avatar.module.scss";

export default defineComponent({
    setup() {
        let store = useStore();
        let inputRef = ref<HTMLInputElement>();
        const data = reactive({
            files: null,
            LogoImg: '/images/logo_CH_TCJ922.png',
        });

        const selectFiles = (e: Event | Array<any>) => {
            let files: any;
            if (isArray(e)) {
                files = e;
            } else {
                files = (e.target as HTMLInputElement).files || [];
            }
            if (!files.length) return;

            // @ts-ignore
            DownloadService.upload(files).then((x: { FileList: { MD5: string, Path: string }[]; TempPath: string; }) => {
                uploadAvatar(x.FileList.length ? x.FileList[0].Path : '');
                data.files = null;
            })
        }
        const uploadAvatar = (UploadPath: any) => {
            let obj = {
                LoginName: store.userName,
                UpdateBy: store.userName,
                ConfigItemList: [
                    {
                        GroupType: "HeadImage",
                        UploadPath,
                        SettingName: "HImage",
                        SettingValue: ""
                    }
                ]
            };
            http.saveAvatar(obj).then((x: any) => {
                let arr = x.PersonalSettingList || [];
                if (arr.length) {
                    let o = arr.find((y: any) => y.GroupType == 'HeadImage');
                    if (o) {
                        o.SettingValue = o.SettingValue + `?${Math.random()}`;
                        store.saveAvatar(o);
                    }
                }
            })
        }
        const browser = () => {
            inputRef && inputRef.value?.click();
        };

        /**头像路径 */
        const avatarUrl = computed(() => (store as any).avatarUrl)
        /**是否显示默认头像 */
        const isDefault = computed(() => !!!avatarUrl.value)
        return {
            inputRef,
            browser,
            selectFiles,
            store,
            data,
            avatarUrl,
            isDefault
        }
    },
    render() {
        const isMobile: any = inject('isMobile')

        const getImg = () => {
            let url = this.data.LogoImg;
            if (this.avatarUrl) {
                url = verifyPhoto(this.avatarUrl + '?' + Math.random());
            }

            return () => h('img', {
                src: url,
            })
        }
        return (
            <div
                style={{ marginTop: isMobile.value ? '10px' : '24px', marginBottom: isMobile.value ? '10px' : '20px' }}
                class={{ [style['photo']]: this.isDefault }}
            >
                <a-avatar
                    size={isMobile.value ? 90 : 70}
                    class={`point`}
                    onClick={this.browser}
                    v-slots={
                        {//两种写法的原因是因为渲染出来的不一样
                            icon: this.isDefault ? undefined : getImg(),
                            default: this.isDefault ? getImg() : undefined,
                        }
                    }
                >
                </a-avatar>
                <input type="file" hidden style="display: none;" multiple ref="inputRef" value={this.data.files} onChange={this.selectFiles} accept="image/*" />
            </div>
        )
    }
})