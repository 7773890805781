import { LoadingOutlined } from "@/antd-icon";
import { computed, defineComponent, h, inject } from "vue";
import useStore from '@/store/metaData';
import style from './index.module.scss';

export default defineComponent({
    setup() {
        const isMobile: any = inject('isMobile');

        const indicator = h(LoadingOutlined, {
            style: {
                fontSize: '36px',
            },
            spin: true,
        });
        const store = useStore();
        const loading = computed(() => store.screenLoading);
        return () => loading.value && <div class={style.host} style={{ left: isMobile.value ? '10px' : '187px' }}>
            <a-spin class={style.spin} indicator={indicator} />
        </div>
    }
})