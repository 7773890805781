import { copyProp } from ".";

/**
 * @property PageNumber
 * @property PageSize
 * @property OrderByField
 * @property IsDesc
 */
export class QueryParams {
    PageNumber?: number;
    PageSize?: number;
    OrderByField?: string;
    IsDesc?: string;
    OrderField?:string
    constructor(obj?: QueryParams) {
        obj && copyProp.apply(this, [obj]);
    }
}

/**
 * @property currentPage 当前页码
 * @property totalPages 总页数
 * @property totalRecords 总记录数
 * @property rowsPerPageOptions 每页可以显示多少条
 * @property pageSize 当前分页尺寸
 */
export class Pagination {
    /** 当前页码 */
    currentPage?: number = 1;
    /** 总页数 */
    totalPages?: number = 1;
    /** 总记录数 */
    totalRecords?: number = 0;
    /** 每页可以显示多少条 */
    rowsPerPageOptions?: (number | string)[] = [10, 20, 50, 100];
    /** 当前分页尺寸 */
    pageSize?: number = 10;
    constructor(obj?: Pagination) {
        obj && copyProp.apply(this, [obj]);
    }
}