import { useProgress } from '@marcoschulte/vue3-progress'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '../views/12-login'
// @ts-ignore
import Layout from "../components/layout";
import useStore from '@/store/metaData';

import routesArr from './routes';
import { getLastUrl } from '@/utile';
import { MenuItem } from '@/types';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    // component: () => import('../views/1-home/Home.vue')
    component: Layout,
    children: [
      ...routesArr,
      { path: 'not-accessible', name: 'notAccessible', component: () => import('../components/not-accessible') },
      { path: '404', name: '404', component: () => import('../components/404') },
    ]
  },
  { path: '/login', name: 'Login', component: Login },
  // { path: '/:pathMatch(.*)*', redirect: '/' }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
let progress: any;
router.beforeEach((to, from, next) => {
  // store.dispatch('onLoading', true);
  // console.log(store.state.isLoading);
  // const store = useStore();
  // let isAuth = store.userName;
  // progress = useProgress().start();
  // let ignorePathArr = ['login'];
  // if (isAuth) {
  //   next();
  // } else {
  //   let isIgnore = to.path != '/' && ignorePathArr.some(x => x.toLowerCase() == getLastUrl(to.path).toLowerCase());
  //   isIgnore ? next() : next({ path: '/login' });
  // }


  const store = useStore();
  let currMenu: MenuItem;
  store.menu.some(x => {
    let path: string = to.path;
    let redirectedFrom: string = '';
    if (to.redirectedFrom) {
      redirectedFrom = to.redirectedFrom?.path;
    }
    if (to.params.tab) {
      path = path.replace(`/${to.params.tab}`, '');
    }
    if (path == redirectedFrom) path;
    let isFind = x.subMenu.find(y => new RegExp(path, 'i').test(y.route))
    x.isActive = !!isFind;
    if (isFind) {
      currMenu = isFind;
      let otherMenu = store.menu.filter(y => x.id != y.id);
      otherMenu.map(y => y.isActive = false);
    } else {
      let isFind1 = x.subMenu.find(y => y.subMenu.find(z => new RegExp(path, 'i').test(z.route)))
      if (isFind1) {
        currMenu = isFind1;
        x.subMenu.some(y => {
          let f = y.subMenu.some(z => new RegExp(path, 'i').test(z.route));
          y.isActive = f;
          return f
        })
        let otherMenu = store.menu.filter(y => x.id != y.id);
        otherMenu.map(y => y.isActive = false);
      } else if (new RegExp(path, 'i').test(x.route)) {
        isFind = x;
        x.isActive = !!isFind;
        currMenu = isFind;
        let otherMenu = store.menu.filter(y => x.id != y.id);
        otherMenu.map(y => y.isActive = false);
      }
    }
    return isFind;
  })
  // @ts-ignore
  let isAuth = currMenu && currMenu.Read;
  let isLogined = !!store.userName;
  progress = useProgress().start();
  let ignorePathArr = ['login', 'not-accessible', '404'];
  let isIgnore = to.path != '/' && ignorePathArr.some(x => x.toLowerCase() == getLastUrl(to.path).toLowerCase());
  if (isLogined) {
    // console.log(isAuth, to);

    if (isAuth) {
      if (to.matched.length) { // 匹配到 做了页面
        next();
      } else {
        console.info(`URL: ${to.fullPath}. The Page is Building...`);
        next({ path: '/404' }); // 匹配到 没做页面
      }
    } else {
      if (isIgnore) {
        next();
        // @ts-ignore
      } else if (currMenu) {
        progress.finish();
        next({ path: '/not-accessible' });
      } else {
        progress.finish();
        next({ path: '/404' });
      }
    }
  } else {
    isIgnore ? next() : next({ path: '/login' });
  }

});
// 这里为了让效果明显一些加了延时
router.afterEach((to, from) => {
  const store = useStore();
  setTimeout(function () {
    // store.dispatch('onLoading', false);
    // console.log(store.state.isLoading);
    store.setActiveSubMenu(to.path);
    setTimeout(() => {
      store.screenLoading = false;
      progress.finish();
    }, 10000);
  }, 50);
  progress.finish();

  document.querySelector('.main-wraper-count')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
});
export default router
