import { SaveOutlined } from "@/antd-icon";
import Collapse from "@/components/form-list-group/components/Collapse";
import useStore from '@/store/metaData';
import { ID_Description } from "@/types";
import { SetSelectOptions } from "@/utile/form";
import { defineComponent, inject, reactive, ref } from "vue";
import { patchValue } from "../../../form";
import '../index.scss';
import { Part4 } from "../usePart";

export default defineComponent({
    components: {
        Collapse,
    },
    props: ['options', 'id', '_this'],
    setup(prop) {
        /**下拉对应fields */
        const { FormDescriptionKey } = prop.options.arrkeys

        let store = useStore();
        let selectOptions: any[] = [];
        const formref = ref()
        let model = reactive<any>({});

        let data = reactive({
            canSave: true,
            selectOptions,
        });
        const setDisabled = (arr: any) => {
            let e = arr;

            if (e?.ReportStatus == "Completed") {
                data.canSave = true
            } else {
                if (e?.['ReportStatus'] == "Completed") {
                    data.canSave = true
                } else {
                    if (store.userName?.toUpperCase() == e?.['CreateBy']?.toUpperCase() || store.userName?.toUpperCase() == 'admin'?.toUpperCase()) {
                        data.canSave = false
                    } else {
                        data.canSave = false
                    }
                }
            }
        }

        const GetFormModels = (parentModel: any) => {
            // if (parentModel?.ReportStatus == "Completed") {
            //     data.canSave = true
            // } else {
            //     if (parentModel?.['ReportStatus'] == "Completed") {
            //         data.canSave = true
            //     } else {
            //         if (store.userName?.toUpperCase() == parentModel?.['CreateBy']?.toUpperCase() || store.userName?.toUpperCase() == 'admin'?.toUpperCase()) {
            //             data.canSave = false
            //         } else {
            //             data.canSave = true
            //         }
            //     }
            // }
            // console.log(data.canSave);
            if (parentModel.ReportStatus) {
                setDisabled(parentModel)
            }

            // 取出这几个中为true的，也就是选中的key。获取后删除前六个字母PartIV，给大写前加空格后去前后空格
            const FormDescription = FormDescriptionKey.filter((e: string) => parentModel[e]).map((z: string) => {
                return z?.slice(6).replace(/([A-Z])/g, ' $1').trim()
            })
            let arr = FormDescription.sort((a: string, b: string) => a > b ? 1 : -1)

            patchValue(model, {
                ...parentModel,
                FormDescription: arr,
            });
        }

        /**获取下拉 */
        const getInitSelectData = (o: any) => {
            prop.options.formSelectConfing?.map((x: any) => {
                data.selectOptions[x.key] = o[x.mapKey].map((y: ID_Description) => ({ ...y, label: y.Description, value: y.Description || y.ID }));
                let arr = selectOptions[x.key]?.sort((a: { label: string, value: string }, b: { label: string, value: string }) => a.label > b.label ? 1 : -1)

                SetSelectOptions(prop.options.fields || [], x.key, () => arr);
            })
        }
        /**在进行修改时替换返回下拉对应正确的model */
        const selmodel = () => {

            let ooo: any = []

            FormDescriptionKey.map((e: string | number) =>
                ooo[e] = false
            )
            // 因为多选会出现第一个是undefind
            model.FormDescription && model.FormDescription[0] && model.FormDescription?.map((x: any) => {
                let aa = `PartIV${x.replace(/\s*/g, "")}`
                ooo[aa] = true
            })
            // // 如果FormDescription有值才改对应key为true
            // if (model.FormDescription) {
            //     let aa = `PartIV${model.FormDescription.replace(/\s*/g, "")}`
            //     ooo[aa] = true
            // }
            return ooo
        }
        const save = () => {

            // patchValue(model, {
            //     UpdateBy: store.userName, UpdateOn: new Date(),
            //     ...selmodel()
            // });

            // prop.options.http?.edit && prop.options.http.edit(model).then((x: ListResult) => {
            //     GetFormModels(x.CurrentEntity)
            //     prop.options.http?.notify('The Edit was successful.');
            // })
            prop._this.save()
        }
        const clearPart = () => {
            data.canSave = true
            formref.value?.reset()
        }
        const part4 = Part4()

        return {
            // ref
            getInitSelectData, GetFormModels, selmodel, clearPart,
            // 
            data, formref, model,
            save, part4

        }
    },
    render() {
        const isMobile: any = inject('isMobile')
        const box = () => <>
            <div>
                <Collapse
                    v-slots={{
                        header: () => {
                            return <b style='color:#000;user-select:none'>{this.options.text}</b>
                        }
                    }}
                >
                    <b-form
                        fields={this.options?.fields}
                        model={this.model}
                        ref='formref'
                        layout="vertical"
                        disabled={this.data.canSave}
                        size='large'
                    >
                    </b-form>
                    <div class='flex justify-content-end margin-top-15'>
                        {/* 仅能修改part4的，part1的表单无法修改 */}
                        <a-button
                            v-BtnPermission={{ type: 'Update' }}
                            class={{ "ant-btn-yellow": true, "w-100-100": isMobile.value }}
                            icon={<SaveOutlined />}
                            type='primary' onClick={this.save}
                            disabled={this.data.canSave}>
                            Save
                        </a-button>
                    </div>
                </Collapse>
            </div >
        </>
        const M = () => <>
            <a-drawer
                ref={this.part4.drawRef}
                // title={c.title}
                maskStyle={{ backgroundColor: `transparent !important` }}
                class={'m-drawer-part4'}
                placement={'bottom'}
                closable={false}
                visible={this.part4.draw.visible}
                onClose={this.part4.onClose}
                maskClosable={true}
                push={false}
                height={`calc(100vh - 40px)`}
            >
                <>
                    {box()}
                    <div class={'previous'}>
                        <a-button type="primary" onClick={this.part4.onClose} >
                            上一步
                        </a-button>
                    </div>
                </>
            </a-drawer>
        </>
        if (isMobile.value) {
            return M()
        }
        return box()
    }
})
