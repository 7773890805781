
import { CloseOutlined, DownloadOutlined, SaveOutlined } from "@/antd-icon";
import QueryModal from '@/components/form-list-group/components/drawer';
import useStore from "@/store/metaData";
import Card from "./components/card";
import { BtnsGroup, ComponentBase, Form, QueryDialog, TabProps } from "./types";

export default function (c: ComponentBase, options: TabProps, model: any) {
    let cp: any = <></>
    // @ts-ignore
    let _this: any = this;
    let showCardWrap: any, showGlobalSearchResult: boolean, showCardTitle;
    const store = useStore();
    let btnG: BtnsGroup = c as BtnsGroup;
    showCardWrap = btnG.isCardWrap;
    showGlobalSearchResult = btnG.isShowGlobalSearchResult || false;


    cp = btnG.btns.map((x, i) => {

        if (x instanceof Form) {
            let f: Form = x as Form;
            return <b-form
                fields={f.fields}
                model={_this.model}
                layout='inline'
                labelCol={{ style: { width: f?.labelWidth || '170px', } }}
                onChange={_this.formChange}
                class='margin-top-6 inline-flex'
                style={`width: ${f.width}`}
                ref="formBtnRef"
                onFinish={_this.finish}
            >
            </b-form>
        } else if (x instanceof QueryDialog) {
            let QModal = x as QueryDialog;
            return <QueryModal
                ref='queryBtnModalRef'
                fields={_this.options.queryModalFields}
                btnText={QModal.btnText}
                labelWidth={_this.options.pageConfig?.queryModallabelWidth}
                defaultModel={_this.options.pageConfig?.queryModalDefault}
                hasQueryModel_addDefailt={_this.options.pageConfig?.hasQueryModel_addDefailt}
                title={QModal.title}
                onOk={_this.getList}
                okBtnText={QModal.okBtnText}
                onFormSearch={_this.queryFormSearch}
                permissionType={x.permissionType || 'Enquiry'}
            ></QueryModal>;
        } else {
            let fn: Function;

            let _permissionType: string = '';
            let _class: any = '';
            let _Icon: any;
            let _disabled: boolean = false;
            let _fn: Function;
            let _loading: boolean = false;
            let _type: string = '';
            let _text: string = ''
            if (x.NotBtn) {
                switch (x.actionType) {
                    case 'SelExportPDF':
                        fn = _this.HandButtonClick('epxortPDF');
                        const dropdown_overlay = {
                            overlay: () => {
                                return <a-menu onClick={fn} >
                                    {
                                        x.opconfig.option.map((e: any, i: any) => (
                                            <a-menu-item
                                                key={i}
                                                icon={<img src={e.img} alt="" style={{ 'margin-right': '10px' }} />}
                                                role={e.role}
                                                class='btn-menu-text'
                                            >
                                                <a-button
                                                    type="text"
                                                    disabled={!_this.isSel}
                                                    size={'small'}
                                                    id='btn-bg-text'
                                                    v-BtnPermission={{ type: 'Report' }}
                                                >{e.text}</a-button>
                                            </a-menu-item>
                                        ))
                                    }
                                </a-menu>
                            }
                        }
                        return <a-dropdown v-slots={dropdown_overlay} >
                            <a-button
                                v-BtnPermission={{ type: x.permissionType || 'Report' }}
                                class="margin-right-5 ant-btn-orange"
                                style={''} title={x.title || ''} danger={x.isDanger}
                                type={x.btnType} icon={<DownloadOutlined />}
                                size={x.size}
                                // loading={_this.data.exportExcelLoading}
                                disabled={!_this.isSel} >{x.text}</a-button>
                        </a-dropdown>
                    case 'ImportExcel':
                        fn = _this.ImportExcel;
                        return <>
                            <a-button
                                v-BtnPermission={{ type: x.permissionType || 'Report' }}
                                title={x.title || ''} danger={x.isDanger}
                                type={x.btnType} size={x.size}
                                loading={_this.data.importExcelLoading}
                                onClick={() => fn()}
                                class="margin-right-5">{x.text}</a-button>
                            <input type="file" hidden style="display: none;"
                                multiple ref="excelRef" value={_this.data.files}
                                onChange={_this.selectFiles}
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            />
                        </>
                    case 'SelectTen':
                        fn = _this.HandButtonClick('SelectTen');
                        if (x?.with_formDisabled) {
                            if (!_disabled) {
                                _disabled = _this.data?.formDisabled;
                            }
                        }
                        return <a-checkbox
                            checked={_this.model.MainItem}
                            onChange={(e: any) => fn(e)}
                            disabled={_disabled}
                        ><p class='user-select-none'>{x.text}</p>
                        </a-checkbox>
                    case 'ViewTen':
                        _this.data.viewbtnname ? fn = () => {
                            _this.getInit()
                            _this.resetTableSort()
                        } : fn = _this.HandButtonClick('ViewTen');
                        return <a-button
                            // v-BtnPermission={{ type: x.permissionType || '' }}
                            class="margin-right-5 ant-btn-blue"
                            icon={<i class="iconfont icon-chaxun" style={'color:#fff;font-size:13px;margin-right:9px'}> </i>}
                            title={x.title || ''} danger={x.isDanger} type={x.btnType} size={x.size} onClick={() => fn()}
                            style={`font-size:14px;`}>
                            {_this.data.viewbtnname ? 'View All' : x.text}
                        </a-button>
                }
            } else {
                switch (x.actionType) {
                    case 'Save':
                        _permissionType = 'Update';
                        _fn = _this.save;
                        if (x.actionTarget) {
                            switch (x.actionTarget) {
                                case 'List': _fn = _this.saveList; break;
                            }
                        }
                        _class = "margin-right-5 ant-btn-yellow";
                        _Icon = <SaveOutlined />;
                        _disabled = !_this.canSave;
                        break;
                    case 'Delete':
                        _permissionType = 'Delete';
                        _fn = _this.del;
                        _class = "margin-right-5 ant-btn-red";
                        _Icon = <CloseOutlined />;
                        _disabled = !_this.canDel;
                        break;
                    case 'New':
                        _permissionType = 'Create';
                        _fn = _this.newRow;
                        _class = "margin-right-5 ant-btn-gray";
                        _Icon = <i class="iconfont icon-zhuanfa" style={'font-size:14px;margin-right:9px'}></i>;
                        _disabled = !_this.canNew;
                        break;
                    case 'ExportExcel':
                        _permissionType = 'Report';
                        _fn = _this.HandButtonClick('epxort');
                        _class = "margin-right-5 ant-btn-cyan";
                        _Icon = <DownloadOutlined />;
                        let enabel = _this.options.pageConfig?.initApiType == 'List' || _this.options.pageConfig?.firstApi == "getList"
                        _disabled = enabel ? false : !_this.canExport;
                        // _loading = _this.data.exportExcelLoading;
                        break;
                    case 'SaveReportAs':
                        _permissionType = 'Create';
                        _fn = _this.HandButtonClick(x.actionType);
                        _class = 'margin-right-5 ant-btn-orange';
                        _Icon = <SaveOutlined />;
                        // _loading = _this.data.exportExcelLoading;
                        _disabled = !_this.isSel || !_this.canSaveReportAs
                        break;
                    case 'PenaltyRecord_ExportExcel':
                        _permissionType = 'Report';
                        _fn = _this.HandButtonClick(x.actionType);
                        _class = 'margin-right-5 ant-btn-orange';
                        _Icon = <DownloadOutlined />;
                        // _loading = _this.data.exportExcelLoading;
                        break;
                    case 'ExportPDF':
                        _permissionType = 'Report';
                        _fn = _this.HandButtonClick('epxortPDF');
                        _class = 'margin-right-5 ant-btn-orange';
                        _Icon = <DownloadOutlined />;
                        _disabled = !_this.isSel;
                        // _loading = _this.data.exportExcelLoading;
                        break;
                    case 'Search':
                        _permissionType = 'Enquiry';
                        _fn = _this.HandButtonClick(x.actionType);
                        _class = 'margin-right-5 ant-btn-blue';
                        _Icon = <i class="iconfont icon-chaxun" style={'color:inherit;font-size:13px;margin-right:9px'}></i>;
                        _loading = _this.data.exportXMLLoading;
                        break;
                    case 'ExportXML':
                        _permissionType = 'Report';
                        _fn = _this.HandButtonClick(x.actionType);
                        _class = 'margin-right-5 ';
                        _loading = _this.data.exportXMLLoading;
                        break;
                    case 'AIP':
                    case 'DDA':
                        // _permissionType = '';
                        _fn = _this.HandButtonClick('epxort');
                        _class = 'margin-right-5 ';
                        _disabled = !_this.canExport;
                        break;
                    case 'Clear':
                        // _permissionType = '';
                        _fn = _this.clear;
                        _class = 'margin-right-5 ';
                        _disabled = !_this.canExport;
                        break;
                    case 'Cancel':
                        // _permissionType = '';
                        _fn = _this.cancel;
                        _class = 'margin-right-5 ';
                        _disabled = !_this.canExport;
                        break;
                    case 'Toggle':
                        // _permissionType = '';
                        _fn = _this.toggle(x, btnG);
                        let field = x.actionTarget == 'List' ?
                            (_this.ListRef ? _this.ListRef.data.active_field : '')
                            :
                            _this.model[x.form_active_field];
                        _type = field && field == x.attachedValue ? 'primary' : ''
                        _class = { 'margin-right-10': true, 'margin-left-13': i == 0, 'margin-bottom-10': true };
                        _disabled = !_this.isSel;
                        break;
                    case 'Back':

                        break;
                }
                if (x?.aliasActionType) {
                    switch (x.aliasActionType) {
                        case 'Delete':
                            _disabled = !_this.canDel;
                            break;
                    }
                }
                if (x?.fnName) {
                    _fn = _this[x?.fnName]
                }
                if (x?.with_formDisabled) {
                    if (!_disabled) {
                        _disabled = _this.data?.formDisabled;
                    }
                }
                return <>
                    <a-button
                        v-BtnPermission={{ type: x.permissionType || _permissionType, c: x }}
                        class={`${x.class} ${_class} `}
                        style={x.style}
                        icon={_Icon}
                        title={x.title || ''}
                        danger={x.isDanger}
                        type={_type || x.btnType || 'primary'}
                        size={x.size}
                        disabled={_disabled}
                        onClick={() => {
                            _this?.data && (_this.data.currentBtn = x);
                            _fn?.()
                        }}
                        loading={_loading}
                    >
                        {_text || x.text}
                    </a-button></>
            }
        }
    });
    let arr: any = []
    if (_this.woStore.globalSearchShowResult) {
        arr = Array.isArray(_this.woStore.globalSearchShowResult) ? _this.woStore.globalSearchShowResult : []
    }
    let btnSlot = {
        header: () => {
            let dom: any = cp;
            if (btnG.align) {
                dom = <div style={{ textAlign: btnG.align }} class={c.class}>{cp}</div>
            }
            if (showGlobalSearchResult) {
                dom = <div style={{ textAlign: btnG.align }}>
                    {cp}
                    {
                        <span class="margin-left-30">
                            {
                                arr.map((x: any) => x.text && (
                                    <span class="margin-left-24">
                                        <span style={{ color: x.color }}>{x.text}</span>
                                    </span>
                                ))
                            }
                        </span>
                    }
                </div>
            }
            return dom;
        }
    }
    if (showCardWrap) return <Card v-slots={btnSlot} ></Card>

    return cp;
}
