import { defineComponent, inject, reactive, ref } from "vue";
import { v4 as uuid } from 'uuid';

export default defineComponent({
    components: {},
    props: {
        /**  打开关闭 头部是否需要改变文字 */
        header_need_changetext: {
            type: Boolean,
            default: false
        },
        /**  是否折叠 */
        isFold: {
            type: Boolean,
            default: false
        },
        class: {
            type: String,
            default: ''
        }
    },
    setup(p) {
        const panelKey = ref(uuid().replace(/-/g, ""))
        const change_header_text_flag: any = ref(!p.isFold)
        const onchange = (key: any) => {
            key.indexOf(panelKey.value) == -1 ? change_header_text_flag.value = false : change_header_text_flag.value = true
        }
        return {
            change_header_text_flag,
            onchange,
            panelKey
        }
    },
    render() {
        let HeardTextSlot = {
            header: () => {
                return (
                    this.header_need_changetext ?
                        (this.change_header_text_flag ? this.$slots.open_header && this.$slots.open_header() : this.$slots.close_header && this.$slots.close_header())
                        : this.$slots.header && this.$slots.header()

                )
            }
        }
        const isMobile: any = inject('isMobile')
        const W = () => <>
            <a-collapse
                defaultActiveKey={this.isFold ? '' : this.panelKey}
                class='collapse-box'
                expandIcon={({ isActive }: any) => <i class="iconfont icon-xia1 margin-right-0" style={` ${isActive ? ('transform:rotateX(360deg)') : ('transform:rotateX(180deg)')};color:#707070;  font-size:20px; transition: all 0.4s ease-in; font-weight:600 `} />}
                onChange={this.onchange}
                ghost
            >
                <a-collapse-panel
                    class={`collapse-main ${this.class}`}
                    key={this.panelKey}
                    v-slots={HeardTextSlot}
                    bordered='false' >
                    {this.$slots.default && this.$slots.default()}
                </a-collapse-panel>
            </a-collapse>
        </>
        const M = () => <div class={{ 'card-list padding-15': true, [this.class]: true }}>
            <div class={{ 'padding-bottom-15': true }} style={{ fontSize: '1.5rem' }}>
                {
                    this.header_need_changetext ? (this.change_header_text_flag ? this.$slots.open_header && this.$slots.open_header() : this.$slots.close_header && this.$slots.close_header())
                        : this.$slots.header && this.$slots.header()
                }
            </div>
            {this.$slots.default && this.$slots.default()}
        </div>
        return (
            <>
                {isMobile.value ? M() : W()}
            </>
        )
    }
})