import { CaretDownOutlined, FullscreenExitOutlined, FullscreenOutlined } from "@/antd-icon";
import { defineComponent, reactive } from "vue";
import { Preview } from "../../types";
import "./index.scss";

export default defineComponent({
    components: {
        FullscreenOutlined,
        FullscreenExitOutlined, CaretDownOutlined
    },
    props: {
        option: {
            default: () => new Preview
        },
        _this: {
            type: Object
        },
    },
    setup(p) {
        const data = reactive({
            fullscreen: false,
            teleport: true,
            isExpand: false
        })
        const setRowData = (value: any) => {
            p.option.value = value;
        }


        const expand = () => {
            let el: any = document.querySelector('.fullscreen-wrapper');
            let targets = p.option.fullscreenDownTargets;
            let height = 0;
            targets?.map(t => {
                let target: any = document.querySelector(t || '');
                if (target) {
                    height += target.clientHeight;
                }
            });
            el.style.height = height + 'px';
            data.isExpand = true;
        }


        return {
            setRowData,
            data,
            expand
        }
    },
    render() {
        let c = this.option;

        let _this: any = this._this;
        // @ts-ignore
        let CONFIG: any = window.CONFIG;
        const text = c.value;
        const pattern = CONFIG.baseUrl;
        const replacement = '';
        const result = text?.replace(pattern, replacement);
        const getTlp = () => {
            return c.value ? <>
                <div class="fullscreen-wrapper" style="width: 100%; height: 100%; position: relative;">
                    <embed src={this.option.value} type="application/pdf" style={`background: #f5f5f5; border-radius: 5px;height: ${c.height || '150px'}; `} width="100%" height="100%"></embed>
                </div >
            </>
                :
                <>
                    <object data={this.option.value}
                        style={`background: #f5f5f5; border-radius: 5px;height: ${c.height || '150px'}; `}
                        type='application/pdf' width="100%" height="100%">
                    </object>
                    {
                        !this.option.value &&
                        <span
                            style={`
                                        position: absolute;
                                        font-size: 32px;
                                        font-weight: 600;
                                        color: #707070;
                                        font-size:${this.option.textFontSize};
                                    `}
                        >PDF Preview</span>
                    }
                </>
        }
        return (
            <div style={this.option.style || ''} class={this.option?.class || ''}>
                {
                    this.option?.title &&
                    <div class={this.option?.titleClass || ''} style={this.option?.titleStyle || ''}>
                        {this.option.title}
                    </div>
                }
                {
                    <div style={[`
                            display: flex;
                            flex-grow: 1;
                            justify-content: center;
                            align-items: center;
                            position: relative;
                        `,]}
                    >

                        {getTlp()}
                    </div>
                }
            </div>
        )
    }
})