import { App } from "vue"
import dayjs from 'dayjs'
var CONFIG = (window as any).CONFIG;



export default (value: any, args?: any) => {
  // if (value) return dayjs(value).format(format)
  if (!value || value == 'NoPhoto' || value == 'images/NoPhoto.gif' || value == 'EMMS_Files\\') {
    return '/images/noimg.gif';
  } else {
    if (args && args.length > 0) {
      let arr = (args as string).split('|');
      if (arr.length > 1) {
        let beReplacedStr: string = arr[1];
        let str = arr[0];
        value = (value as string).replace(beReplacedStr, str);
        if (/assets/.test(value)) {
          return value;
        }
        return CONFIG.baseUrl + value;
      } else {
        console.log(value);

        let suffix = value.substring(value.lastIndexOf('.'));
        let prefix = value.substring(0, value.lastIndexOf('.'));
        let str = args[0];
        let url = prefix + str + suffix;
        return (value == 'NoPhoto' || value == 'images/NoPhoto.gif') ? '/images/noimg.gif' : CONFIG.baseUrl + url;
      }
    }
    if (/assets/.test(value)) {
      return value;
    }
    return CONFIG.baseUrl + value;
  }
}

