
// @ts-ignore
import { debounceTime, Subject, Subscription } from "rxjs";
import { onBeforeUnmount, Ref, watchEffect } from "vue";
import { FormlyFieldConfig } from "./types";

/**
 * @see https://next.antdv.com/components/form-cn#components-form-demo-useForm-merge
 * @options trigger: 校验触发的时机	'blur' | 'change' | ['change', 'blur']	- 
 * @options enum: 枚举类型	string	- 
 * @options len: 字段长度	number	- 
 * @options max: 最大长度	number	- 
 * @options message: 校验文案	string	- 
 * @options min: 最小长度	number	- 
 * @options pattern: 正则表达式校验	RegExp	- 
 * @options required: 是否必选	boolean	false 
 * @options transform: 校验前转换字段值	function(value) => transformedValue:any	- 
 * @options type: 内建校验类型，可选项	string	'string' 
 * @options validator: 自定义校验（注意，callback 必须被调用）	function(rule, value, callback)	- 
 * @options whitespace: 必选时，空格是否会被视为错误	boolean	false 
 */
interface Rule {
    /** 	校验触发的时机	'blur' | 'change' | ['change', 'blur']	- */
    trigger?: 'blur' | 'change' | ['change', 'blur'];
    /** 	枚举类型	string	- */
    enum?: string;
    /** 	字段长度	number	- */
    len?: number;
    /** 	最大长度	number	- */
    max?: number;
    /** 	校验文案	string	- */
    message?: string;
    /** 	最小长度	number	- */
    min?: number;
    /** 	正则表达式校验	RegExp	- */
    pattern?: RegExp;
    /** 	是否必选	boolean	false */
    required?: boolean;
    /** 	校验前转换字段值	function(value) => transformedValue:any	- */
    transform?: Function;
    /** 	内建校验类型，可选项	string	'string' */
    type?: string;
    /** 	自定义校验（注意，callback 必须被调用）	function(rule, value, callback)	- */
    validator?: Function;
    /** 	必选时，空格是否会被视为错误	boolean	false */
    whitespace?: boolean;
}

interface RuleObject {
    field: string;
    fullField: string;
    trigger: string;
    type: string;
    validator: Function;
}

/**
 * @param model form绑定的model对象
 */
export default function useRules(model: Object, formRef: Ref<any>) {
    const r_$change: Subject<any> = new Subject();
    let r_$changeEv: Subscription;
    onBeforeUnmount(() => {
        r_$changeEv && r_$changeEv.unsubscribe();
    });
    r_$changeEv = r_$change.pipe(debounceTime(500)).subscribe((key: string) => {
        formRef.value.validateFields([key]);
    })
    const getFormControlRules = (item: FormlyFieldConfig): object | Array<Rule> => {
        let arr: Array<Rule> = [];
        let to = item.templateOptions;
        // if (to?.max) arr.push({ max: , message: `The maximum length cannot exceed ${}` });
        // if (to?.min) arr.push({ max: , message: `` });
        if (to?.minLength) arr.push({ min: to.minLength, message: `The minimum length cannot be less than ${to.minLength}` });
        if (to?.maxLength) arr.push({ max: to.maxLength, message: `The maximum length cannot exceed ${to.maxLength}` });
        if (to?.pattern) arr.push({ pattern: to.pattern, message: `Format error` });
        if (to?.equalTarget) {
            arr.push({ validator: (_rule: RuleObject, value: string) => matchValidator(_rule, value, model, to?.equalTarget), trigger: 'change' });
            watchEffect(() => {
                // @ts-ignore
                model[item.key] && r_$change.next(item.key);
            })
        }
        return arr;
    }
    return {
        getFormControlRules
    }
}
const matchValidator = async (_rule: RuleObject, value: string, model: Object, target: string = '') => {
    if (value === '') {
        return Promise.reject(`Please input the ${_rule.field}`);
        // @ts-ignore
    } else if (model[target]) {
        // @ts-ignore
        if (value !== model[target]) {
            return Promise.reject(`The ${_rule.fullField} does not match the ${target}`);
        }
    } else {
        return Promise.resolve();
    }
}