import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { Modal, notification } from 'ant-design-vue';
import { NotificationArgsProps } from 'ant-design-vue/lib/notification';
import { createVNode, VNode } from 'vue';

interface confirmOptions {
    title: string;
    content: any;
    onOk?: Function;
    onCancel?: Function;
    okText?: string;
    cancelText?: string;
}

export const confirm = (option: confirmOptions) => {
    // console.log(option);

    Modal.confirm({
        title: option.title,
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { style: 'color:red;' }, option.content || ''),
        okText: option.okText || 'OK',
        cancelText: option.cancelText || 'Cancel',
        onOk() {
            option.onOk && option.onOk();
        },
        onCancel() {
            option.onCancel && option.onCancel();
        },
        class: '',
    });
}

/**
 * 
 * @see https://next.antdv.com/components/notification-cn#API
 * @param btn VNode | (() => VNode);
 * @param bottom string;
 * @param class string;
 * @param description string |  VNode | (() => VNode);
 * @param duration number;
 * @param getContainer (() => HTMLElement) | (() => Body);
 * @param icon  VNode | (() => VNode);
 * @param key string;
 * @param message string |  VNode | (() => VNode);
 * @param placement 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'topRight' | string;
 * @param style Object | string;
 * @param onClose Function;
 * @param onClick Function;
 * @param top string;
 * @param closeIcon VNode | (() => VNode);
 */
export interface notifyOptions {
    /** 自定义关闭按钮	VNode | () => VNode	-	 */
    btn: VNode | (() => VNode);
    /** 消息从底部弹出时，距离底部的位置，单位像素。	string	24px	 */
    bottom: string;
    /** 自定义 CSS class	string	-	 */
    class: string;
    /** 通知提醒内容，必选	string | VNode | () => VNode	-	 */
    description: string | VNode | (() => VNode);
    /** 默认 4.5 秒后自动关闭，配置为 null 则不自动关闭	number	4.5	 */
    duration: number;
    /** 配置渲染节点的输出位置	() => HTMLNode	() => document.body	 */
    getContainer: (() => HTMLElement) | (() => Body);
    /** 自定义图标	VNode | () => VNode	-	 */
    icon: VNode | (() => VNode);
    /** 当前通知唯一标志	string	-	 */
    key: string;
    /** 通知提醒标题，必选	string | VNode | () => VNode	-	 */
    message: string | VNode | (() => VNode);
    /** 弹出位置，可选 topLeft topRight bottomLeft bottomRight	string	topRight	 */
    placement: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'topRight' | string;
    /** 自定义内联样式	Object | string	-	 */
    style: Object | string;
    /** 点击默认关闭按钮时触发的回调函数	Function	-	 */
    onClose: Function;
    /** 点击通知时触发的回调函数	Function	-	 */
    onClick: Function;
    /** 消息从顶部弹出时，距离顶部的位置，单位像素。	string	24px	 */
    top: string;
    /** 自定义关闭图标	VNode | () => VNode */
    closeIcon: VNode | (() => VNode);
}
interface Notify {
    success: (arg: notifyOptions) => void;
    error: (arg: notifyOptions) => void;
    info: (arg: notifyOptions) => void;
    warning: (arg: notifyOptions) => void;
    warn: (arg: notifyOptions) => void;
    open: (arg: notifyOptions) => void;
    close: (key: String) => void;
    destroy: () => void;
}
/**
 * 
 * @see https://next.antdv.com/components/notification-cn#API
 * @param success 
 * @param error 
 * @param info 
 * @param warning 
 * @param warn 
 * @param open 
 * @param close 
 * @param destroy 
 */
export const notify = (option: notifyOptions | string, key: string = ''): Notify => {
    // console.log(option);
    /* 
        notification.success(config)
        notification.error(config)
        notification.info(config)
        notification.warning(config)
        notification.warn(config)
        notification.open(config)
        notification.close(key: String)
        notification.destroy()
    */
    let o: any = {
        success: notification.success(option as NotificationArgsProps),
        error: notification.error(option as NotificationArgsProps),
        info: notification.info(option as NotificationArgsProps),
        warning: notification.warning(option as NotificationArgsProps),
        warn: notification.warn(option as NotificationArgsProps),
        open: notification.open(option as NotificationArgsProps),
        close: notification.close(key),
        destroy: notification.destroy()
    }
    if (typeof option == 'string' && option) {
        notification.success({
            description: '',
            message: (option as any),
            duration: 2,
            class: 'success-notify'
        });
    }
    return o;
}
