import { ref } from "vue";
import { TabProps } from "../types";
import useStore from '@/store/metaData'
import DownloadService from '@/http/download';
import { getObjectByMap } from "@/utile";

export default (prop: TabProps, data: any, model: any) => {
    let store = useStore();
    const excelRef = ref();
    const selectFiles = (e: Event) => {
        let files = (e.target as HTMLInputElement).files || [];
        data.uploading = true;
        // @ts-ignore
        DownloadService.upload(files).then((x: { FileList: { MD5: string, Path: string }[]; TempPath: string; }) => {
            x.FileList.length && _ImportExcel(x);
        }).catch(() => data.uploading = false);
    }
    const ImportExcel = () => {
        excelRef && excelRef.value?.click();
    };
    const _ImportExcel = (o: { FileList: { Path: string }[] }) => {
        let obj: any = {
            ImportType: prop.pageConfig?.ImportType,
            LoginName: store.userName,
            ContractorNo: model['ContractorNo'] || model['ContractNo'],
            FilePathList: o.FileList.map(x => x.Path)
        }
        obj = { ...obj, ...getObjectByMap(prop.httpParamsMap?.addImport || [], model) }
        // console.log(model, obj);
        // console.log(prop);
        // PostID=当前WO的ID
        prop.http?.addImport && prop.http?.addImport(obj).then(x => {
            prop.http?.notify('Import Excel successfully..');
        });
    }
    return {
        ImportExcel,
        selectFiles,
        excelRef
    }
}