import { AgGridRowEvent } from "@/components/ag-table/types/grid";
// @ts-ignore
import { debounceTime, filter, Subject, Subscription } from "rxjs";
import { defineComponent, nextTick, onBeforeUnmount, onMounted, reactive, ref } from "vue";
import dayjs from 'dayjs'
import verifyPhoto from '@/pipe/verifyPhoto'
import './index.scss';
import { Edit_Cell_Type } from "./types/edit_cell";
import Edit_cells_server from "./edit_cell_service";
import DownloadService from '@/http/download';
export interface Edit_cell_Rules {
    key: string;
    row_key: string;
    col_keys: string[];
}
export default defineComponent({
    props: {
        params: Object
    },
    setup(p) {
        const inputRef = ref()
        const r_$change: Subject<any> = new Subject();
        let r_$changeEv: Subscription;
        let r_$ev_blur: Subscription;

        onBeforeUnmount(() => {
            r_$changeEv && r_$changeEv.unsubscribe();
            r_$ev_blur?.unsubscribe?.();
        });
        let params: AgGridRowEvent;
        params = p.params as AgGridRowEvent;

        let parents = params.context?.componentParent;

        const data = reactive<Edit_Cell_Type>(new Edit_Cell_Type({
            value: params.value,
            params: params,
            parents
        }));
        const key = params.column ? params.column.getColId() : '';
        r_$changeEv = r_$change.pipe(debounceTime(500)).subscribe((val: any) => {
            params.node.setDataValue(key, val);
        })
        // @ts-ignore
        data.type = params.type || 'input';
        // @ts-ignore
        data.placeholder = params.placeholder || '';
        // @ts-ignore
        data.disabled = params.disabled || false;
        // @ts-ignore
        data.format = p.params?.format || data.format;
        //@ts-ignore
        data.value = params.value;
        //@ts-ignore
        data.background = params.data && params.data._color && params.data._color || params.background || '#00f';
        //@ts-ignore
        data.isNA = !!params.isNA;
        //@ts-ignore
        data.PopupContaine = params.PopupContaine;
        //@ts-ignore
        data.setvalue_redrawRows = params.setvalue_redrawRows;

        //@ts-ignore
        if (params.NA_rules) {
            //@ts-ignore
            let obj = (params.NA_rules as Array<Edit_cell_Rules>).find(x => params.data[x.key] == x.row_key);
            if (obj && obj.col_keys.some(x => x == key)) {
                data.isNA = true;
            }
        }
        //@ts-ignore
        if (params.type_rule && params.type_rule.type == 'select') {
            //@ts-ignore
            let obj = (params.type_rule.rules as Array<Edit_cell_Rules>).find(x => params.data[x.key] == x.row_key);
            if (obj && obj.col_keys.some(x => x == key)) {
                data.type = 'select';
            }
        }
        r_$ev_blur = Edit_cells_server.$blur.pipe(
            filter(x => (x.key === key) && (x.node == params.node))
        ).subscribe(x => {
            inputRef.value?.focus();
        })

        const setDataValue = (key: string, vlaue: any) => {
            let obj = params.data;
            // params.colDef.
            obj[key] = vlaue;

            // 当前值改变，同时也改变其他字段值
            if ((params as any)?.before_setDataValue_handleObj) {
                obj = (params as any).before_setDataValue_handleObj?.(obj);
            }

            setTimeout(() => {
                params.api.applyTransaction({ update: [obj] });

                Edit_cells_server.$blur.next({ key, node: params.node, })

                // 修改后是否重绘当前行
                if (data.setvalue_redrawRows) {
                    setTimeout(() => {
                        params.api.redrawRows({ rowNodes: [params.node] });
                    }, 10);
                }
            }, 0);
        }
        const change = (e: any) => {
            data.value = e.target.value;
            // params.node.setDataValue(key, data.value);
            setDataValue(key, data.value)
            // r_$change.next(data.value);
            // setTimeout(() => {
            //     inputRef.value?.focus();
            // }, 100);
        }
        const changeCheckbox = (e: any) => {
            data.value = e.target.checked;
            // params.node.setDataValue(key, data.value);
            setDataValue(key, data.value)

            // r_$change.next(data.value);
        }
        const selectChange = (value: any) => {
            data.value = value;
            // r_$change.next(data.value);
            // params.node.setDataValue(key, data.value);
            // 延迟为了动画流畅
            setTimeout(() => {
                setDataValue(key, data.value)
            }, 500);
        }
        const numberChange = (value: any) => {
            data.value = value;
            // r_$change.next(data.value);
            // params.node.setDataValue(key, data.value);
            setDataValue(key, data.value)

            // setTimeout(() => {
            //     inputRef.value?.focus();
            // }, 100);
        }
        if (data.type == 'date' && params.value) {
            //@ts-ignore
            data.value = dayjs(params.value);
        }
        // onMounted(() => {
        //     nextTick(() => {
        //         // 聚焦但页面不往下滑动
        //         // inputRef.value?.focus();

        //         inputRef.value?.focus({ preventScroll: true });
        //         // window.scrollTo(0, 0)
        //         // inputRef.value?.blur();
        //     })
        // })
        return {
            key,
            params,
            data,
            change,
            numberChange,
            changeCheckbox, selectChange,
            inputRef
        }
    },
    render() {
        let that: any = this;

        const getOption = () => {
            let el: any = '';
            let arr: { label: any, value: any }[] = this.params.data[`_${this.key}`] || []
            if (arr) {
                el = arr.map((x: any) => <a-select-option disabled={x.dsiabled} value={x.value}>{x.label}</a-select-option>)
            }
            if (arr.length && arr.length == 1) {
                // this.data.value = arr[0].value;
            }
            return el;
        }
        const getTlp = () => {
            const disabledFn = () => {
                // 传入禁用，直接禁用
                if (this.data?.disabled) return true;
                // 表格禁用，单元格也禁用
                if (this.data.parents?.disabled) return true;
                // 计算函数
                if (this.params?.disabledFn) {
                    return this.params?.disabledFn(this.data.params?.data)
                }
                // 默认不禁用
                return false
            }
            let el: any = '';
            let style: any;
            switch (this.data.type) {
                case 'downloadFileByUrl':
                    el = <span
                        style={{ color: 'blue', }}
                        class='point'
                        onClick={() => {
                            this.params.data.FilePath && DownloadService.downloadFileByUrl(this.params.data.FilePath);
                        }}
                    >
                        {this.data.value}
                    </span>
                    break;
                case 'text':
                    el = <span title={this.data.value} class={['ellipsis', this.params?.lineClass ?? 'line4', this.params?.class || '',]}>{this.data.value}</span>
                    break;
                case 'render_fn':
                    let e = this.params && this.params?.getRender(this.data, this.params)
                    el = e ?? <span></span>;
                    break;
                case 'Color_div':
                    style = {
                        'width': '25px', 'height': '25px',
                        'background': this.data.value
                    }
                    el = <div style={style}></div>;
                    break;
                case 'No': el = <span>{this.params.rowIndex + 1}</span>; break;
                case 'input': el = <a-input
                    ref='inputRef'
                    onChange={this.change}
                    disabled={disabledFn()}
                    size="middle" value={this.data.value}
                    placeholder=" " title={this.data.value}
                    v-slots={this.params.slot}
                />; break;
                case 'inputnumber': el = <a-input-number ref='inputRef'
                    onChange={this.numberChange}
                    disabled={disabledFn()}
                    size="middle" value={this.data.value}
                    placeholder=" " title={this.data.value}
                    v-slots={this.params.slot}
                    min={1}
                />; break;
                case 'date': el = <a-date-picker
                    value={this.data.value}
                    placeholder={'DD/MM/YYYY'}
                    size="middle" onChange={this.selectChange}
                    disabled={disabledFn()}
                    format={this.data.format}
                    getPopupContainer={this.data?.PopupContaine}>
                </a-date-picker>;
                    break;
                case 'time': el = <a-time-picker value={this.data.value} placeholder=' ' size="middle" onChange={this.selectChange}
                    disabled={disabledFn()}
                    value-format={this.data.format} format={this.data.format}
                    getPopupContainer={this.data?.PopupContaine || ((triggerNode: { parentNode: any; }) => triggerNode.parentNode.parentNode.parentNode)} />;
                    break;
                case 'background_text': el = <div class='padding-left-10' style={`background: ${this.data.background};`}>{this.data.value}</div>; break;
                case 'color_text':
                    let showText = this.data.value || this.params.data[this.key];
                    if (this.data.placeholder) showText = showText || this.data.placeholder;
                    let line: any = ''
                    if (this.params.need_underline) {
                        if (this.key.split(' ').length > 1) {
                            // key有两个，text取istrue的字段
                            showText = this.params.data[this.params?.istrue]
                            // 是否加划线取underline这个字段
                            if (!this.params.data[this.params?.underline]) {
                                line = 'text-decoration:line-through'
                            }
                        }
                    }
                    el = <div class={this.data.placeholder ? '' : 'padding-left-10'} title={showText}
                        style={`color: ${this.data.background};${line}`}>{showText}</div>;
                    break;
                case 'bgcolor_text':
                    let showText2 = this.data.value || this.params.data[this.key];
                    if (this.data.placeholder) showText2 = showText2 || this.data.placeholder;
                    style = 'width: 100%;margin: 5px 0;border-radius: 22px;color: #fff;text-align: center;max-width:100px'
                    el = <div style={`background-color: ${this.data.background};${style}`} title={showText2}>{showText2}</div>;
                    break;
                case 'combinationText':
                    let text = '';
                    let arr = this.key.split(' ');
                    let split = this.params['split'] || '';
                    arr.map((x, i) => {
                        text += this.params.data[x] ?? '';
                        if (text !== '') {
                            if (i != arr.length - 1) text += split;
                        }
                    });
                    el = <div title={text}>{text}</div>;
                    break;
                case 'select':
                    // 有固定的下拉项
                    if (this.params.selOption) {
                        let options = this.params.selOption;
                        if (this.params?.transformOption) {
                            options = this.params?.transformOption(options, this.data.params?.data) || [];
                        }
                        el = <a-select
                            value={this.data.value}
                            onChange={this.selectChange}
                            style="width: 100%"
                            size="middle"
                            placeholder={this.params?.placeholder ?? "Please Select"}
                            allowClear={this.params?.allowClear ?? true}
                            disabled={disabledFn()}
                            getPopupContainer={this.data?.PopupContaine}
                            options={options}
                            showSearch={this.params?.showSearch ?? true}
                            class={this.params?.getClass?.(this.data.value) ?? ''}
                            filterOption={(input: string, option: any) => {
                                let label = option.label || option.Description || option.value || '';
                                return `${label}`.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                        >
                        </a-select>
                    } else {
                        el = <a-select
                            value={this.data.value}
                            onChange={this.selectChange}
                            style="width: 100%"
                            size="middle"
                            placeholder={this.params?.placeholder ?? "Please Select"}
                            allowClear={this.params?.allowClear ?? true}
                            getPopupContainer={this.data?.PopupContaine}
                            showSearch={this.params?.showSearch ?? true}
                            filterOption={(input: string, option: any) => {
                                let label = option.label || option.Description || option.value || '';

                                return `${label}`.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                        >
                            {getOption()}
                        </a-select>
                    }
                    break;
                case 'status':
                    // let bgclass: any = ''
                    // switch (this.data.value) {
                    //     case '0': bgclass = 'value0'; break;
                    //     case '1': bgclass = 'value1'; break;
                    //     case '2': bgclass = 'value2'; break;
                    // }
                    const classarr = ['value0', 'value1', 'value2']
                    el = <a-select
                        value={this.data.value}
                        onChange={this.selectChange}
                        style='width: 100%;'
                        // class={`${bgclass}`}
                        class={classarr[this.data.value]}
                        size="middle"
                        placeholder="Please Select"
                        disabled={disabledFn()}
                        getPopupContainer={this.data?.PopupContaine || ((triggerNode: { parentNode: any; }) => triggerNode.parentNode.parentNode.parentNode.parentNode)}
                    >
                        {getOption()}
                    </a-select>;
                    break;
                case 'status_div':
                    let a: { label: any, value: any, color: any }[] = this.params.data[`_${this.key}`] || []
                    el = a.filter(e => e.value == this.data.value).map(q => {
                        style = {
                            'background': q.color, 'font-size': '1.2rem',
                            'width': '100%', 'height': '100%', 'color': '#fff',
                            'display': 'flex', 'align-items': 'center', 'justify-content': 'center',
                        }
                        return <div style={style}> {q.label}</div>
                    })
                    break;
                case 'img':
                    style = {
                        // maxWidth: '100px', minWidth: '50px',
                        // maxHeight: '100px', minHeight: '50px',
                        // display: 'block', margin: '3px 0',
                        // width: this.params.width, height: this.params.height,
                        maxWidth: '100%',
                        maxHeight: '100%',
                    }
                    el =
                        <div
                            v-viewer
                            style={{
                                width: '100px',
                                height: '80px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin: '5px 0'
                            }}
                        >
                            <img src={verifyPhoto(this.data.value) + '?' + Math.random()} style={style} alt="" class={'point'} />
                        </div>
                    if (!this.data.value) {
                        el = <div></div>
                    }
                    break;
                case 'Icon':
                    el = <div class='flex align-items: center'>
                        <i class={`iconfont ${this.data.background}`} style={this.params.IconStyle}></i>
                        <span class='margin-left-5'>{this.data.value}</span>
                    </div>
                    break;
                case 'Checkbox':
                    el = <a-checkbox
                        checked={this.data.value || false}
                        onChange={this.changeCheckbox}
                        disabled={disabledFn()}
                    >
                    </a-checkbox>
                    break;
                default: el = <a-input-number ref='inputRef' onChange={this.numberChange} disabled={this.data.disabled} size="small" min={0.01} step={0.01} string-mode placeholder=" " value={this.data.value} />;
                    break;
            }
            return el;
        }
        return (
            <>
                {
                    this.data.isNA ? <div class='text-center'>N/A</div> : getTlp()
                }
            </>
        );
    }
}); 
