import { getFileName, getObjectByMap, getSortValue, handleDate, Pagination, QueryParams } from "@/utile";
import DownloadService from "@/http/download";
import useStore from '@/store/metaData';

export type HandButtonType =
    'ExportXML' | 'PenaltyRecord_ExportExcel' | 'epxort' | 'epxortPDF' | 'SaveReportAs' | 'Search' |
    'SelectTen' | 'ViewTen' | 'RefreshTen'

export const BtnClick = (c: HandButtonType, pobj: any) => {
    const { data, pagination, model, props,
        e, selectCurrentRow, woStore, store,
        tableRef, edit, canSave, canSaveReportAs
    } = pobj

    const epxort = () => {
        data.exportExcelLoading = true;
        // @ts-ignore
        let obj = new QueryParams({
            PageNumber: pagination.currentPage,
            PageSize: pagination.pageSize,
        });
        if (data.sort) {
            obj.IsDesc = getSortValue(data.sort.sort);
            obj.OrderByField = data.sort.row.getColId()
        } else {
            obj.IsDesc = '';
            obj.OrderByField = '';
        }
        if (data.QueryParams) {
            obj = new QueryParams({ ...data.QueryParams, ...obj });
        }
        obj = {
            ...obj, ...model,
            ...getObjectByMap(props.httpParamsMap?.export || [], model)
        }
        obj = handleDate(obj);

        props.http?.export && props.http.export(obj).then((x: { ExcelPath: string; TempPath: string; }) => {
            data.exportExcelLoading = false;
            DownloadService.downloadFileByUrl(x.ExcelPath);
        }).catch(() => data.exportExcelLoading = false);
    }

    const PenaltyRecord_ExportExcel = () => {
        data.exportExcelLoading = true;
        // @ts-ignore
        let obj = new QueryParams({
            PageNumber: 1,
            PageSize: 0,
            OrderField: 'UID'
        });
        if (data.sort) {
            obj.IsDesc = getSortValue(data.sort.sort);
            // obj.OrderByField = data.sort.row.getColId()
        } else {
            obj.IsDesc = '';
            // obj.OrderByField = '';
        }
        if (data.QueryParams) {
            obj = new QueryParams({ ...data.QueryParams, ...obj });
        }
        obj = {
            ...obj,
            //  ...model,
            LoginName: store.userName,
            ...getObjectByMap(props.httpParamsMap?.export || [], model)
        }
        obj = handleDate(obj);

        props.http?.export && props.http.export(obj).then((x: { ExcelPath: string; TempPath: string; }) => {
            data.exportExcelLoading = false;
            DownloadService.downloadFileByUrl(x.ExcelPath);
        }).catch(() => data.exportExcelLoading = false);
    }
    /**
     * e:一般没有，存在 那就是悬浮menu下载按钮
     */
    const epxortPDF = (e?: any) => {
        const exportfn = () => {
            data.exportExcelLoading = true;
            let WorkType = (e && e.item.role) || props.pageConfig?.down_PDF_WorkType
            let obj = {
                ...getObjectByMap(props.httpParamsMap?.export || [], model),
                WorkType,
                InspectionNo: model.ID,
                ReportType: 'DownloadReport',
                LoginName: store.userName,
                FileType: 'pdf',
            }
            props.http?.exportPDF && props.http.exportPDF(obj).then((x: any) => {
                data.exportExcelLoading = false;

                let str = x.ReportPath;
                let ar = str.split(';')
                ar.map((Path: any) => {
                    if (Path) {
                        let aa = {
                            SourceFrom: 1,
                            Path,
                            FileName: getFileName(Path)
                        }
                        DownloadService.download(aa).then((x: any) => {
                            DownloadService.downloadFileByBlob(x, getFileName(Path));
                        })
                    }
                })
            }).catch(() => data.exportExcelLoading = false);
        }
        // 当前选中行的初始数据
        const entity = handleDate(data.getRowEntity)
        const modelobj = handleDate(model)
        const ignoreArr = []
        // 判断是否更改了
        const isEditing = Object.keys(entity).some((i: string) => {
            if (i in modelobj) {
                if (entity[i] == modelobj[i]) {
                    return false
                } else {
                    return true
                }
            }
        })
        // console.log('entity', entity);
        // console.log('modelobj', modelobj);

        if (isEditing && canSave.value && (!canSaveReportAs.value)) {
            edit(() => {
                exportfn()
            })
            // http.confirm({
            //     title: 'Information',
            //     content: '是否需要保存修改后的再匯出',
            //     okText: '保存修改后匯出',
            //     cancelText: '直接匯出',
            //     onOk: () => {
            //         edit(() => {
            //             exportfn()
            //         })
            //     },
            //     onCancel: () => {
            //         exportfn()
            //     }
            // })
        } else {
            exportfn()
        }

    }
    const ExportXML = () => {
        data.exportXMLLoading = true;
        // @ts-ignore
        let obj = new QueryParams({
            PageNumber: pagination.currentPage,
            PageSize: pagination.pageSize,
        });
        if (data.sort) {
            obj.IsDesc = getSortValue(data.sort.sort);
            obj.OrderByField = data.sort.row.getColId()
        } else {
            obj.IsDesc = '';
            obj.OrderByField = '';
        }
        if (data.QueryParams) {
            obj = new QueryParams({ ...data.QueryParams, ...obj });
        }
        obj = { ...obj, ...getObjectByMap(props.httpParamsMap?.toxml || [], model) }
        props.http?.toxml && props.http.toxml(obj).then((x: { ExcelPath: string; TempPath: string; }) => {
            data.exportXMLLoading = false;
            DownloadService.downloadFileByUrl(x.ExcelPath);
        }).catch(() => data.exportXMLLoading = false);
    }
    const SaveReportAs = () => {
        const { ID } = model
        let obj = { ID, LoginName: store.userName }
        props.http?.SaveAs && props.http.SaveAs(obj).then((x: ListResult) => {
            let Entity = x.Entity || x.CurrentEntity
            if (props.pageConfig?.initApiType == 'List') {
                data.rowData = x.TableData || x.Data;
                pagination.currentPage = x.CurrentPage;
                pagination.totalPages = x.TotalPages;
                pagination.totalRecords = x.TotalRecords;
                selectCurrentRow();
            } else {
                woStore.updataShowResultByRow(Entity);
            }
            Entity && patchValue(model, { ...Entity });
            http.notify('SaveAs successfully..');
        })
    }

    const SelectTen = (e: any) => {
        model.MainItem = e.target.checked;
    }
    const AboutTen = (typeTen: 'ViewTen' | 'RefreshTen') => {
        const Get_MainitemTrue = () => {
            let obj = { LoginName: store.userName, ...getObjectByMap(props.httpParamsMap?.viewTen || [], model) };
            props.http?.MainItemList && props.http.MainItemList(obj).then((x: any) => {
                data.rowData = x.MainItemList;
                selectCurrentRow();

                Object.assign(pagination,
                    new Pagination({
                        currentPage: 1,
                        totalPages: 1,
                        totalRecords: x.MainItemList.length,
                    })
                )
                data.QueryParams = new QueryParams
            })
        }
        return (z?: boolean) => {
            Get_MainitemTrue()
            if (typeTen == 'ViewTen') {
                data.viewbtnname = true
            } else if (typeTen == 'RefreshTen' && z) {
                tableRef.value.clearSelect();
            }
        }
    }
    switch (c) {
        case "epxort": epxort(); break;
        case "epxortPDF": epxortPDF(e); break;
        case "ExportXML": ExportXML(); break;
        case 'PenaltyRecord_ExportExcel': PenaltyRecord_ExportExcel(); break;
        case 'SaveReportAs': SaveReportAs(); break;
        case 'Search': Summary_Search(pobj); break;
        case 'SelectTen': SelectTen(e); break;
        case 'ViewTen':
        case 'RefreshTen': AboutTen(c)(e); break;
    }
}


import { AgGridRowEvent_e } from "../useList";
import { debounceTime, from, groupBy, map, mergeMap, reduce, Subject, Unsubscribable } from "rxjs";
import { ColumnDef } from "../../ag-table/types/column";
import { InitListResult, ListResult } from "../types/types";
import { ColumnDefRule } from "../types";
import { getColor16 } from "../components/echarts/echarts";
import { patchValue } from "../../form";
import { http } from "@/http";
import { colors_200, generateColors } from "./colors";
/**summary search动态列定义 */
export const Summary_Search = (pobj: any) => {
    const { model, props, data, pagination, refs } = pobj
    const store = useStore();
    let obj: any = { ...model, LoginName: store.userName }
    obj = { ...obj, ...getObjectByMap(props.httpParamsMap?.search || [], model) }
    obj = handleDate(obj);
    // 先把rowdata设置为空再重新渲染
    data.rowData = []
    // 后端要传字段是PreparedBy
    if (props.pageConfig?.chart == 0) {
        obj = { ...obj, 'PreparedBy': obj['ConNo'] }
    }
    props.http?.search && props.http.search(obj).then((x: InitListResult) => {

        pagination.totalPages = x.TableData.TotalPages
        pagination.totalRecords = x.TableData.TotalRecords;
        pagination.currentPage = x.TableData.CurrentPage;
        // setSelectRowByStoreSearchResult();
        data.rowData = x.TableData.Data;
        getDynamicColumnDefs(x)

        getDynamicEchartEcharts(x)
        if (props.pageConfig?.chart == 3) {
            const { ListRef } = refs

            try {
                ListRef.value.setRowData(x.TableData?.MainItemList || [])
            } catch (error) {

            }

            // props.http?.MainItemList && props.http.MainItemList({ LoginName: store.userName, PrimaryID: obj?.VersionNoID }).then((x2: any) => {
            //     x2.MainItemList.map((e: any) => e.Color = getColor16())
            //     ListRef.value.setRowData(x2.MainItemList)
            // })
        }
    })
    const getDynamicEchartEcharts = (obj: any) => {
        let x_Data: any = []
        let y_Data: any = []
        /**SeriesData中所有对象的name */
        let yname: any = []
        switch (props.pageConfig?.chart) {
            case 0:
            case 1:
                // x_Data
                obj.TableData.Data.forEach((c: any) => {
                    if (props.pageConfig?.chart == 0) {
                        x_Data.unshift(c['ContractorNo'] || '')
                    } else {
                        x_Data.unshift(c['User name'] || '')
                    }
                })
                data.dynamicEchartsXdata = x_Data

                // y_Data
                const str = 'Total no. of reports'
                yname = obj.TableData?.TitleList.filter((a: any) => a.includes(str))
                yname.push(yname.shift())

                yname.forEach((d: any) => {
                    /**SeriesData的一个对象 */
                    let ystr = {}
                    /**SeriesData的一个对象的data */
                    let cc: any = []

                    let color: string = ''
                    obj.TableData.Data.map((f: any) => {
                        if (f[d]) {
                            cc.unshift(f[d])

                            let one = data.dynamicColumnDefs.filter(((h: any) => h.field == d))[0]
                            color = one.cellRendererParams.background
                        } else {
                            let str7 = d.slice(0, 7)
                            let nums: number = 0
                            Object.keys(f).filter(o => o.includes(str7)).forEach(keys => {
                                if (/no. of reports/i.test(keys)) {
                                    nums = f[keys]
                                } else {
                                    // nums += f[keys]
                                }
                            })
                            f[d] = nums
                            cc.unshift(nums)
                            let one = data.dynamicColumnDefs.filter((g: any) => g.headerName == str7)[0]
                            let two = one.children.filter(((h: any) => h.field == d))[0]
                            color = two.cellRendererParams.background
                        }
                    });

                    ystr = {
                        name: d,
                        color,
                        data: cc
                    }
                    y_Data.push(ystr)
                });
                data.dynamicEchartsYdata = y_Data
                // console.log('x', data.dynamicEchartsXdata);
                // console.log('y', data.dynamicEchartsYdata);
                break;
            case 2:
                // x_Data
                x_Data = obj.TableData.TitleList.filter((e: any) => e.includes('違規次數'))
                data.dynamicEchartsXdata = x_Data

                // y_Data
                let colors = ['#80BFFE', '#A580FE', '#75DD98', '#BAD76B', '#FE80F2', '#DB6CA4', '#FEBF80', '#FE8080', '#905826', '#26B2AD', ...colors_200];
                try {
                    let colorlength = obj.TableData.Data?.length - 210;
                    if (colorlength < 0) {
                        colors = colors.concat(generateColors(colorlength))
                    }
                } catch (error) {

                }

                obj.TableData.Data.map((c: { [x: string]: any; Color: string; Performance: any; }, i: number) => {
                    c.Color = colors[i]
                    y_Data.push({
                        name: `${c?.['Code'] ?? ''}-${c?.Performance ?? ''}`,
                        color: c.Color,
                        data: x_Data?.reduce((arr: any[], cur: string | number) => {
                            return [...arr, c[cur] || 0,]
                        }, [])
                    })
                })
                data.dynamicEchartsYdata = y_Data

                break;
        }

    }
    const getDynamicColumnDefs = (obj: any) => {
        let c = props.pageConfig?.columnDefRules;
        let column: ColumnDef[] = [];
        if (c) {
            // console.log(obj);
            let titleArr = c.titleFields?.split('.');
            let content: string[] = eval(`obj.${c.titleFields}`);
            content = content.filter(x => {
                if (c?.before?.some((y: { field: string; }) => y.field == x)) {
                    return false;
                }
                if (c?.after?.some((y: { field: string; }) => y.field == x)) {
                    return false;
                }
                return true
            });
            let groups: any[] = []
            from(content).pipe(
                // @ts-ignore
                groupBy((p) => {
                    if (c?.groupRule == '7String') {
                        return p.slice(0, parseInt(c?.groupRule));
                    }
                }),
                // @ts-ignore
                mergeMap((group$: any) =>
                    group$.pipe(reduce((acc: any, cur: any) => [...acc, cur], [group$.key]))
                ),
                map((arr: any[]) => {
                    return arr//.slice(1)
                })
            ).subscribe((group: any) => {
                groups.push(group);
            });

            column = handleGroup(c, groups, obj);
            // @ts-ignore;
            data.dynamicColumnDefs = [...c.before, ...column, ...c.after];
        }
    }
    const handleGroup = (c: ColumnDefRule, groups: any[], obj: any): any => {
        let arr: ColumnDef[] = [];

        if (c.isGroup) {
            groups.map((x: string[], index: number) => {
                let groupName = x[0]

                // 多加一个字段，因为后端没传，所以判断chart 直接加上去
                if (props.pageConfig?.chart == 1 || props.pageConfig?.chart == 0) {
                    let newstr = `${groupName} ${groupName} Total no. of reports`

                    x.push(newstr)
                    obj.TableData?.TitleList.push(newstr)
                }

                let gEnd = x.slice(1);
                if (props.pageConfig?.chart == 1 || props.pageConfig?.chart == 0) {
                    gEnd.shift()
                }
                let g = {
                    headerName: eval(c.groupName || ''),
                    children: gEnd.map(y => {
                        let headerName = y.replace(groupName, '');
                        let cellRendererParams: any = { type: 'color_text', background: 'none' };
                        // if (headerName.trim() == 'no. of reports'.trim()) {
                        //     cellRendererParams = { type: 'bgcolor_text', background: 'red' };
                        // }
                        if (props.pageConfig?.chart == 1 || props.pageConfig?.chart == 0) {
                            const str = 'Total no. of reports'
                            let colors = ['#80BFFE', '#A580FE', '#75DD98', '#BAD76B', '#FE80F2', '#905826', ...colors_200]
                            if (RegExp(str).test(headerName)) {
                                cellRendererParams = {
                                    type: 'bgcolor_text', background: colors[index]// getColor16()
                                }
                            }
                        }
                        let cellClass: string[] = [];
                        if (c.nullObject) {
                            c.nullObject.headColumn.some(z => new RegExp(z, 'i').test(y)) && (cellRendererParams.placeholder = c.nullObject.placeholder);
                            cellClass = c.nullObject.cellClass || [];
                        }
                        let minWidth = y.length * 6
                        if (props.pageConfig?.chart == 1 || props.pageConfig?.chart == 0) {
                            const str = 'Total no. of reports'
                            if (RegExp(str).test(headerName)) {
                                minWidth = y.length * 3.5
                            }
                        }
                        return {
                            field: y, headerName,
                            minWidth,
                            cellRenderer: 'edit_cellRenderer',
                            cellRendererParams,
                            wrapText: true, cellClass,
                            // autoHeight: props.pageConfig?.chart === 3,
                        }
                    })
                }
                // console.log(g);

                arr.push(g)
            })
        } else {
            groups.map((x: string[]) => {
                x.map((y, i) => {
                    // console.log(y);

                    i != 0 && arr.push({
                        field: y, headerName: y, minWidth: y.length * 12.5, cellRenderer: 'edit_cellRenderer', cellRendererParams: {
                            type: 'color_text', background: 'none'
                        },
                        // autoHeight: props.pageConfig?.chart === 3,
                    })
                })
            })
        }
        return arr;
    }
}