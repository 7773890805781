import { FormlyFieldGroup } from "@/components/form/types";
import { reactive } from "vue";


export const LoginFields = () => {
  let isProd = process.env.NODE_ENV === 'production';
  let arr: FormlyFieldGroup[] = [
    {
      className: 'login-input',
      fieldGroup: [
        {
          key: "Name", type: 'Input', templateOptions: {
            label: "Username", isRow: true,
            required: true,
            minLength: 3, maxLength: 16
          },
        }
      ]
    },
    {
      className: 'login-input',
      fieldGroup: [
        {
          key: "Password", type: "Password", templateOptions: {
            label: "Password", isRow: true,
            required: isProd
          },
        },
      ]
    },
  ];
  return reactive(arr);
}

