import http from '../../http';
class LoginHttp extends http {
    login(data: any, loading: boolean = false) {
        // @ts-ignore
        return this.post('oauth/login', data, true);
    }
    
    exit(params: any) {
        // @ts-ignore
        return this.get('oauth/exit', params, true);
    }
    saveAvatar(data: any, loading: boolean = false) {
        // @ts-ignore
        return this.put('users/PersonalSettingEdit', data, true);
    }
}
export default new LoginHttp;