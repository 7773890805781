import { CloseCircleOutlined } from "@/antd-icon";
import { isNil } from "@/utile";
import { debounceTime, Subject } from "rxjs";
import { defineComponent, inject, onUnmounted, reactive, ref } from "vue";
import './index.scss';


export default defineComponent({
    emits: ['ok', 'formSearch'],
    props: {
        fields: { type: Array, default: () => [] },
        title: { type: String, default: () => '' },
        btnText: { type: String, default: () => '' },
        okBtnText: { type: String, default: () => 'Ok' },
        resetText: { type: String, default: () => '重設' },
        closeBtnText: { type: String, default: () => 'Close' },
        showCloseBtn: { type: Boolean, default: () => false },
        disabled: { type: Boolean, default: () => false },
        labelWidth: { type: String, default: () => '120px' },
        btnAlign: { type: String, default: 'center' },
        Icon: {
            type: Object,
            default: () => (
                <i class="iconfont icon-chaxun" style={'color:inherit;font-size:13px;margin-right:9px'}></i>)
        },
        OnOkIcon: {
            type: Object,
            default: () => (
                <i class="iconfont icon-chaxun" style={'color:#fff;font-size:13px;margin-right:9px'}></i>)
        },
        ResetIcon: {
            type: Object,
            default: () => (
                <i class="iconfont icon-refresh" style={'color:#fff;font-size:13px;margin-right:9px'}></i>)
        },
        CloseIcon: {
            type: Object,
            default: () => (
                <CloseCircleOutlined />)
        },
        Width: {
            type: String,
            default: '13.5%'
        },
        permissionType: { type: String, default: 'Enquiry' },
        openbtnclass: { type: String, default: () => '' },
        openbtnstyle: { type: String || Object, default: () => '' },
        class: { type: String, default: () => '' },
        defaultModel: { type: Object, default: () => { } },
        hasQueryModel_addDefailt: { type: Object, default: () => { } },
    },
    setup(p, { emit }) {
        const data = reactive({
            visible2: false
        });
        const model = reactive<any>({
            ...p.defaultModel
        });
        const formRef = ref();
        const handleOk = () => {
            data.visible2 = false;
        };
        const search = () => {
            formRef.value.submit();
        };
        const finish = () => {
            let obj = { ...model };
            if (Object?.values(model)?.some(x => !isNil(x))) {
                obj = { ...obj, ...p.hasQueryModel_addDefailt }
            }
            emit('ok', obj);
            handleOk();
        };
        const reset = () => {
            formRef.value.reset();
        };
        const showDrawer = () => {
            data.visible2 = true;
        };
        const onClose = () => {
            data.visible2 = false;
        };
        let $ev_search: Subject<any> = new Subject();

        const formSearch = (e: any) => {
            $ev_search.next(e);
        }
        $ev_search.pipe(debounceTime(500)).subscribe((e: any) => {
            emit('formSearch', e)
            //     SearchCancel: 'Y'
        })
        onUnmounted(() => {
            $ev_search.unsubscribe()
        })
        return {
            model,
            data,
            formRef,
            handleOk,
            search,
            finish,
            reset,
            onClose,
            showDrawer,
            formSearch
        }
    },
    render() {
        const isMobile: any = inject('isMobile')

        return (
            <>
                <a-button
                    v-BtnPermission={{ type: this.permissionType }}
                    class={`ant-btn-blue margin-right-5 ${this.openbtnclass}`}
                    style={this.openbtnstyle}
                    size="small" onClick={this.showDrawer} disabled={this.disabled}
                    icon={this.Icon}
                > {this.btnText}</a-button>
                <a-drawer
                    placement="right"
                    class={`drawers ${this.class} ${isMobile.value ? 'drawers-m' : ''}`}
                    width={isMobile.value ? '100%' : this.Width}
                    maskStyle={{ backgroundColor: 'rgba(0,0,0,.20)' }}
                    visible={this.data.visible2}
                    onClose={this.onClose}
                    title={this.title}
                    closable={isMobile.value ? true : false}
                >
                    <b-form ref="formRef" fields={this.fields} model={this.model} layout='vertical'
                        labelCol={{ style: { width: this.labelWidth } }} labelWidth={this.labelWidth}
                        onFinish={this.finish} noMarginBottom size={isMobile.value ? 'large' : 'default'}
                        onSearch={this.formSearch}
                    >
                        <div>
                            <a-button size="small" type="primary" class='ant-btn-blue' onClick={this.search} icon={this.OnOkIcon}>{this.okBtnText}</a-button>
                            <a-button size="small" class='ant-btn-purple' onClick={this.reset} icon={this.ResetIcon}>{this.resetText}</a-button>
                            {this.showCloseBtn && (<a-button size="small" class='ant-btn-blue' icon={this.CloseIcon} onClick={this.onClose} >{this.closeBtnText}</a-button>)}
                        </div>
                    </b-form>
                </a-drawer>
            </>
        );
    }
})