import AgGrid from "@/components/ag-table";
import edit_cellRendererComponent from '@/components/ag-table/edit_cell';
import Card from "@/components/form-list-group/components/card";
import Collapse from "@/components/form-list-group/components/Collapse";
import useStore from '@/store/metaData';
import useWoStore from "@/store/woManagement";
import { ID_Description } from "@/types";
import { GridApi, RowNode } from "ag-grid-community";
import dayjs from 'dayjs';
import { defineComponent, inject, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import actionCellRenderer from "../ag-table/actionCellRenderer";
import { AgGridRowEvent, GridEvent } from "../ag-table/types/grid";
import Modal from "./components/modal";
import './index.scss';




export default defineComponent({
    components: {
        Card,
        AgGrid,
        edit_cellRenderer: edit_cellRendererComponent,
        actionCellRenderer,
        Modal,
        Collapse
    },
    // props: {
    //     options: {
    //         type: () => new List,
    //         default: () => new List
    //     },
    //     id: {
    //         type: String
    //     },
    // },
    props: ['options', 'id', 'listrefresh'],

    setup(prop) {
        const woStore = useWoStore();
        const primaryKey = prop.options.pageConfig?.formPrimaryKey as string;

        let tableRef = ref();
        let photoModalRef = ref();
        let model = reactive<any>({});
        let store = useStore();
        let rowData: any[] = [];
        let oldRowData: any[] = [];
        let active_field: any;
        let selectConfing: any = {};

        let api: GridApi = new GridApi;

        let data = reactive({
            oldRowData,
            rowData,
            height: '',
            active_field,
            selectConfing,

            api,

        });
        data.active_field = prop.options.defaultFilter_value;
        const onrowdata = ref()
        /**设置行数据 */
        const setRowData = (arrs: any, force: boolean = false) => {

            onrowdata.value = arrs
            const arr = arrs.ItemList || arrs.History || arrs
            data.rowData = arr;
            if (prop.options.defaultFilter) {
                if (force) {
                    data.oldRowData = arr;
                    toggleRowData(data.active_field, prop.options.defaultFilter_field)
                } else {
                    toggleRowData(prop.options.defaultFilter_value, prop.options.defaultFilter_field)
                }
            }

            data.height = setHeight(arr);
        }
        /**设置高度 */
        const setHeight = (arr: any[]) => {
            let h = 80;
            let l = arr?.length || 1;
            let height = h + l * 40;

            // 把init得到的下拉项，塞进当前行 row
            data.rowData.map(x => {
                Object.keys(data.selectConfing).map(k => x[`_${k}`] = data.selectConfing[k])
                // 初始没有orderno
                if (!x.OrderNo) {
                    x.OrderNo = x.Performance
                }

            })
            sizeColumnsToFit()

            return height + 'px';
        }
        /**设置选择 */
        const setSelect = (obj: any) => {
            prop.options.formSelectConfing?.map((x: any) => {

                let arr: any = [];
                if (x.type == 'string') {
                    arr = obj[x.mapKey].map((y: string) => ({ label: y, value: y }))
                } else if (x.type == 'ID_Description') {
                    // 在这里加上颜色，不用在grid中判断
                    arr = obj[x.mapKey].map((y: ID_Description) => {
                        const colors = ['#4040cc', '#d63434', '#6ad13e']
                        return { label: y.Description, value: y.ID, color: colors[y.ID as any] }
                    })
                }
                data.selectConfing[x.key] = arr;

            })
        }
        /**切换行数据 */
        const toggleRowData = (value: any, field: string) => {
            if (!data.oldRowData.length) {
                data.oldRowData = data.rowData;
            }
            data.active_field = value;
            data.rowData = data.oldRowData.filter(row => row[field] == value);
            if (prop.options.isOrder) {
                data.rowData = data.rowData.sort((a, b) => {
                    if (prop.options.isDesc) {
                        return a[prop.options.orderField || ''] > b[prop.options.orderField || ''] ? -1 : 1
                    } else return a[prop.options.orderField || ''] > b[prop.options.orderField || ''] ? 1 : -1
                })
            } else if (prop.options.isFixedOrder && prop.options.fixedOrder_rules) {
                data.rowData = data.rowData.map(x => {
                    let r = prop.options.fixedOrder_rules?.find((_r: any) => _r.field == x[prop.options.orderField || '']);
                    if (r) {
                        return { ...x, _order: r.order, _color: r.color }
                    }
                    return x;
                }).sort((a, b) => {
                    if (prop.options.isDesc) {
                        return a['_order'] > b['_order'] ? -1 : 1
                    } else return a['_order'] > b['_order'] ? 1 : -1
                })
            }

            setTimeout(() => {
                data.height = setHeight(data.rowData);
            }, 100);
        }


        const router = useRouter();
        const getList = () => {
            // let obj: any = {};
            // obj = { ...obj, ...getObjectByMap(prop.options.httpParamsMap?.getList || []) };
            // prop.options.http?.getList && prop.options.http.getList(obj).then((x: any) => {
            //     // pagination.totalPages = x.TableData.TotalPages
            //     // pagination.totalRecords = x.TableData.TotalRecords;
            //     // pagination.currentPage = x.TableData.CurrentPage;
            //     // setSelectRowByStoreSearchResult();
            //     data.rowData = x.TableData.Data;
            // }) 

        }

        /**刷新照片编号 */
        const refreshPhotoNumber = (o: any) => {
            let obj: any = { [primaryKey]: o[primaryKey], LoginName: store.userName };

            // obj = { ...obj, ...getObjectByMap(prop.options.httpParamsMap?.updataRowPhotoNumber || []),  };
            prop.options.http?.updataRowPhotoNumber && prop.options.http.updataRowPhotoNumber(obj).then((x: { Photos: number }) => {
                let nodes = tableRef.value.grid.api.getRenderedNodes();
                nodes && nodes.some((node: RowNode) => {
                    let obj: any = node.data;
                    if (obj[primaryKey] == o[primaryKey]) {
                        // node.setSelected(true);
                        obj.Photo = x.Photos;
                        node.setData(obj);
                        return true;
                    }
                })
            })
        }

        /**单元格操作 */
        const cellAction = (e: AgGridRowEvent) => {
            let key = e.column?.getColId();
            // console.log(key);
            // console.log(prop.options.pageConfig?.edit_photo_column_field);

            if (key == prop.options.pageConfig?.edit_photo_column_field) {
                let ID = e.data[prop.options.pageConfig?.formPrimaryKey || 'ID'];
                // console.log(ID);

                photoModalRef.value.open(ID);
            }

        }
        onMounted(() => {
            setTimeout(() => {
                prop.options.tabelHeaderHeight && tableRef.value.grid.api.setHeaderHeight(prop.options.tabelHeaderHeight);
            }, 1000);
        })
        prop.options.firstApi && prop.options.firstApi == 'getList' && getList();

        const onGridReady = (params: GridEvent) => {
            data.api = params.api;
        }
        const sizeColumnsToFit = () => {

            setTimeout(() => {
                try {
                    data.api?.sizeColumnsToFit();
                } catch (error) {
                }
            }, 200);
        }
        return {
            tableRef, photoModalRef,
            data,
            model,
            setRowData,
            toggleRowData,
            setSelect,
            getList,
            cellAction, onGridReady,
            refreshPhotoNumber, onrowdata
        }
    },
    render() {
        const isMobile: any = inject('isMobile')
        let obj: any = { minHeight: '0px', maxHeight: '600px', height: this.data.height };
        if (this.options.tableHeight) {
            obj = { height: this.options.tableHeight }
        }
        let cp: any = <></>
        let gdiv: any = <></>
        gdiv = <ag-grid
            ref="tableRef"
            style={obj}
            class="ag-theme-alpine"
            columnDefs={this.options.columnDefs}
            rowData={this.data.rowData}
            on_cellClicked={this.cellAction}
            suppressRowClickSelection={true}
            rowHeight={43}
            on_gridReady={this.onGridReady}
        >
        </ag-grid>
        if (this.options.need_collapse) {
            let TextSlot = {
                header: () => {
                    return <b style={{ 'color': '#000', 'user-select': 'none' }}>{this.options.collapse_heard_text}</b>
                }
            }
            cp = <Collapse v-slots={TextSlot}>{gdiv}</Collapse>
        }
        else if (this.options.isShowtitle) {
            /**巡查日期 */
            const date = this.onrowdata?.InspectionDate && dayjs(this.onrowdata?.InspectionDate).isValid()
                && dayjs(this.onrowdata?.InspectionDate).format('DD/MM/YYYY')
            /** 所有金额*/
            let allamount: number = 0
            this.data.rowData.forEach(e => {
                // 罚款次数
                const PenaltyCount = e['PenaltyCount'] || 1;
                if (e.Amercement && e.Cost) {
                    allamount += e.Cost * PenaltyCount;
                }
            })
            const datastyle = {
                'background': 'red', 'color': '#fff',
                'padding': date ? '2px 15px' : 0
            }
            cp = <div class={this.options.showoptions.class}>

                {
                    isMobile.value ? <a-row gutter={20}>
                        <a-col xs={10}>
                            <div><p class='padding-left-10 ptitle' style={'margin-bottom:0;padding:20px'}>{this.options.showoptions.title}</p></div>
                        </a-col>
                        <a-col xs={14}>
                            <div class='flex align-items-center margin-right-2-100 justify-content-end' >
                                <p class='ptitle' style={"font-size:15px;margin-bottom:0;padding:20px"}>巡視日期:</p>
                                <div><span style={datastyle} >{date}</span></div>
                            </div>
                        </a-col>
                    </a-row> : <a-row gutter={20}>
                        <a-col xxl={12} xl={12} xs={24}>
                            <div><p class='padding-left-10 ptitle' style={'margin-bottom:0;padding:20px'}>{this.options.showoptions.title}</p></div>
                        </a-col>
                        <a-col xxl={12} xl={12} xs={24}>
                            <div class='flex align-items-center margin-right-2-100 justify-content-end' >
                                <p class='ptitle' style={"font-size:15px;margin-bottom:0;padding:20px"}>巡視日期:</p>
                                <div><span style={datastyle} >{date}</span></div>
                            </div>
                        </a-col>
                    </a-row>
                }

                <div>{gdiv}</div>
                <div style={'margin-right: 10%;'} class='flex justify-content-end'>
                    <p style={"font-size:17px;padding-right:0px;font-weight: 500; "}>{this.options.showoptions.footer}</p>
                    <p style={'color:red; font-size: 17px;font-weight: 700;padding: 0px 10px 10px 20px;'}>{allamount}</p>
                </div>
            </div>
        } else {
            cp = gdiv
        }

        return <div class={this.options.class}>{cp}</div>
    }
})