export const pick = <T, K extends keyof T>(
    object: T,
    keys: K[]
): Pick<T, K> => {
    return keys.reduce((result, key) => {
        if (key in object) {
            result[key] = object[key];
        }
        return result;
    }, {} as Pick<T, K>);
};

export const omit = <T, K extends keyof T>(
    object: T,
    keys: K[]
): Omit<T, K> => {
    const result = { ...object };

    keys.forEach(key => {
        delete result[key];
    });

    return result as Omit<T, K>;
};


export const pickBy = <T extends object>(
    object: T,
    predicate: (value: T[keyof T], key: keyof T) => boolean
): Partial<T> => {
    return (Object.keys(object) as Array<keyof T>).reduce<Partial<T>>((result, key) => {
        const value = object[key];
        if (predicate(value, key)) {
            result[key] = value;
        }
        return result;
    }, {});
};

export const omitBy = <T extends object>(
    object: T,
    predicate: (value: T[keyof T], key: keyof T) => boolean
): Partial<T> => {
    return (Object.keys(object) as Array<keyof T>).reduce((result, key) => {
        const value = object[key];
        if (!predicate(value, key)) {
            result[key] = value;
        }
        return result;
    }, {} as Partial<T>);
};

export const isNil = (value: any): value is null | undefined => {
    return value === null || value === undefined;
};