// import { isObject } from 'util';
import { MenuItem } from '../types';
import { findOne } from './transform';

/**
 * 获取url字符串最后的片段, 不过滤？后的参数
 * @param url string
 */
export function getUrlLastFragment(url: string): string {
    let fragments = url.split('/');
    if (fragments.length > 0) return fragments[fragments.length - 1];
    return '';
}
/**
 * 获取url字符串最后的片段, 过滤？后的参数
 * @param url string
 */
export function getLastUrl(url: string) {
    let index = url.lastIndexOf("\/");
    let lastUrl = url.substr(index + 1, url.length);
    let hasQuestionMark = /\?/.test(lastUrl);
    if (hasQuestionMark) { // 有问号。说明带有参数,再截取问号前的URL
        lastUrl = lastUrl.substr(0, lastUrl.lastIndexOf("?"))
    }
    return lastUrl;
}
/**
 * 首字母转小写
 * @param str string
 */
export function pojo(str: string): string {
    let initial = str.substr(0, 1);
    let remaining = str.substr(1) || '';
    return initial.toLowerCase() + remaining;
}

/**
 * 因为路由所取后台数据有些id前加了home或page前缀,直接显示在地址栏不好看。路由path配置时去掉了home或page前缀;
 * 进行路由匹配时先把home或page前缀去掉
 * @param path string
 */
export function handlerPathAndReplace(path: string): string {
    // 如果path直接等于'home' 或 'page' 说明不是带这两个字为前缀的。直接return原path
    if (pojo(path) == 'home' || pojo(path) == 'page') return path;
    let prefix = path.substr(0, 4) || '';
    if (pojo(prefix) == 'home' || pojo(prefix) == 'page') return path.substr(4) || '';
    return path;
}
/** 找到 FirstMenu 的路由路径数组 */
export function getUrlByFirstMenu(menu: MenuItem): string[] {
    /// @ts-ignore
    let subUrl = [];
    if (menu.FirstMenu) {
        /// @ts-ignore
        let sub = findOne(menu.subMenu, menu.FirstMenu.toString(), 'id');
        if (sub) {
            subUrl = getUrlByFirstMenu(sub);
        }
    }
    /// @ts-ignore
    return [menu.RoutePath].concat(subUrl);
}
/** 如果没找到 FirstMenu 的路由路径数组， 就找第一个启用的 */
export function getUrlByFirstEnableMenu(menu: MenuItem): string[] {
    /// @ts-ignore
    let subUrl = [];
    if (menu.subMenu) {
        menu.subMenu.some(x => {
            if (x.Read) {
                subUrl = getUrlByFirstEnableMenu(x);
            }
            return x.Read
        })
        /// @ts-ignore
        return [menu.RoutePath].concat(subUrl);
    }
    return [menu.RoutePath];
}

/**
 * 验证URL前缀是否以/开头
 * @param str 
 */
export function testPrefix(str: string): string {
    let isPass = /^\//.test(str);
    if (!isPass) {
        str = '/' + str;
    }
    return str;
}
/**
 * 验证URL后缀是否以/结尾
 * @param url 
 */
export function testSuffix(str: string): string {
    let isPass = /\/$/.test(str);
    if (!isPass) {
        str = str + '/';
    }
    return str;
}

/**
 * 拼接 url
 * @param url 请求时所对应的后端控制器 如：http://localhost:17071/api/Login/loginin 中的 Login/loginin
 * @param urlPrefix 请求时 IP+端口 如：http://localhost:17071/api/Login/loginin 中的 http://localhost:17071 如果是访问本机JSON，则应为空字符口串''
 * @param prefix 请求时 控制器 前到IP前的字符 如：http://localhost:17071/api/Login/loginin 中的 /api;如果是访问本机JSON，则应为空字符口串''
 */
export function pieceUrl(url: string, urlPrefix: string, prefix: string): string {
    url = testPrefix(url);
    let _urlPrefix = urlPrefix ? testSuffix(urlPrefix) : '';
    return _urlPrefix + prefix + url;
}

/**
 * 对象转URL查询字符串
 * @param obj 
 */
export function object2QueryString(obj: any): string {
    return Object.keys(obj).map(function (key) {
        return "".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(obj[key]));
    }).join('&');
};

/**
 * 获取对象有效值 过滤属性值为对象的属性
 * @param obj 
 */
export function getValidValue(obj: any): any {
    return Object.keys(obj).filter(x => {
        return !!x;
    }).filter(x => {
        return x[0] != '_'
    }).filter(x => {
        return obj[x] !== undefined && obj[x] !== null && obj[x] !== '';
    }).filter(x => {
        // return isObject(obj[x]);
    }).reduce((acc, cur) => {
        /// @ts-ignore
        acc[cur] = obj[cur];
        return acc;
    }, {})
}

/**
 * 获取queryString
 * @param str 
 */
export function getQueryString(str: string): string {
    let isHasQueryString = /\?/.test(str);
    if (isHasQueryString) {
        return str.substr(str.indexOf('?'));
    }
    return '';
}

/**
 * 获取去掉queryString后的字符
 * @param str 
 */
export function cutQueryString(str: string): string {
    let isHasQueryString = /\?/.test(str);
    if (isHasQueryString) {
        return str.substr(0, str.indexOf('?'));
    }
    return str;
}

