import { BarSeriesOption } from 'echarts/charts';
import { v4 as uuid } from 'uuid';
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { getNewSeries, getNewXaxis } from './ChartFn';
import echarts, { ECOption } from './echarts';


export default defineComponent({
    props: {
        xaxisData: {
            type: Object,
        },
        yaxisData: {
            type: Array,
        },
        c: {
            type: Object
        }
    },
    setup(p) {
        const setOption = computed(() => {

            let option: ECOption

            let xAxis: any;
            if (p.c?.hiddenzero) {
                const { legendData, SeriesData } = getNewSeries(p.yaxisData, p)
                xAxis = getNewXaxis(p.xaxisData, legendData)
                option = {
                    // title: {
                    //     text: '暂无数据',
                    //     show: true,
                    //     textStyle: {
                    //       color: 'red',
                    //       fontSize: 25
                    //     },
                    //     textAlign: 'center',
                    //     top: '50%',
                    //     left: '45%'
                    // },
                    /**图表离容器的距离 */
                    grid: {
                        top: "20px",
                        left: "50px",
                        right: "45px",
                        bottom: "30px"
                    },
                    //鼠标滑过提示栏
                    tooltip: {
                        trigger: "axis",
                        position: 'bottom',
                        confine: true,
                        axisPointer: {
                            type: "shadow",
                            shadowStyle: {
                                color: 'rgba(180, 180, 180, 0.05)',
                                // width: '1'
                            }
                        },
                        formatter: function (params: any) {
                            let html = '';
                            if (params.length != 0) {
                                let getName = params[0].name;
                                if (getName) {
                                    html += getName + '<br/>';
                                    for (let i = 0; i < params.length; i++) {
                                        // 如果为0 为空的数据我们不要了(你们可以直接判断 > 0)
                                        if (
                                            params[i].value != null &&
                                            params[i].value != 0 &&
                                            params[i].value != ''
                                        ) {
                                            html += params[i].marker;
                                            html += params[i].seriesName + ' ' + '<b style="color:black">' + params[i].value + '</b>' + '<br/>';
                                        }
                                    }
                                }

                            }
                            return html;
                        }
                    },
                    //图表选择栏
                    // legend: {
                    //     icon: "square",
                    //     // top: 20,
                    //     bottom: 0,
                    //     itemHeight: 15, //修改icon图形大小
                    //     textStyle: {
                    //         fontSize: 15,
                    //         color: "#000",
                    //     },
                    //     data: legendData,
                    // },
                    //x坐标轴
                    xAxis: xAxis,
                    //y坐标轴
                    yAxis: [
                        {
                            type: "value",
                        },
                    ],
                    // 数据展示区域
                    series: SeriesData,
                };
            } else {
                let SeriesData: any;
                // let legendData: any = [];
                SeriesData = p.yaxisData;
                SeriesData?.map((e: BarSeriesOption) => {
                    // legendData.push(e.name)
                    e.type = "bar";
                    // e.barWidth = 30;
                    e.barGap = '5%';
                    e.barCategoryGap = 20;
                    e.label = {
                        show: true, //开启显示
                        position: 'right', //在上方显示
                        color: 'black',
                        fontSize: 10,
                        // offset: [2, 6],
                        // rotate: 45,
                        formatter: (params: any) => {
                            if (!p.c?.showzero) {
                                return `${params.value ? params.value : ' '}`
                            } else {
                                return params.value
                            }
                        },
                    };
                    // e.emphasis = {
                    //     itemStyle: {
                    //         color: '#a8a8a8' // 设置高亮时的颜色
                    //     }
                    // };
                })
                option = {
                    // 滚动条
                    dataZoom: [
                        {
                            id: 'dataZoomY',
                            yAxisIndex: [0, 1],
                            // 数据量少不显示滚动条
                            show: p.xaxisData?.length <= 3 ? false : true, // 是否显示滑动条，不影响使用
                            type: 'slider', // 这个 dataZoom 组件是 slider 型 dataZoom 组件
                            start: 100, // 默认显示0%，
                            // startValue: SeriesData.length - 3, // 从末尾开始--进行倒序
                            // endValue: SeriesData.length - 1, // 一次性展示5个
                            width: 10,
                            borderColor: 'transparent',
                            fillerColor: 'rgba(205,205,205,1)',
                            zoomLock: true, // 这个开启之后只能通过鼠标左右拉动，不能滚动显示
                            showDataShadow: false, // 是否显示数据阴影 默认auto
                            backgroundColor: '#fff',
                            showDetail: false, // 即拖拽时候是否显示详细数值信息 默认true
                            realtime: true, // 是否实时更新
                            filterMode: 'filter',
                            handleIcon: 'circle',
                            handleStyle: {
                                color: 'rgba(205,205,205,1)',
                                borderColor: 'rgba(205,205,205,1)'
                            },
                            handleSize: '80%',
                            moveHandleSize: 10,
                            // 设置展示数量
                            maxValueSpan: 2,
                            minValueSpan: 2,
                            brushSelect: false // 刷选功能，设为false可以防止拖动条长度改变 ************（这是一个坑）
                        },
                        {
                            type: 'inside',
                            yAxisIndex: [0, 1],
                            start: 0, // 默认显示0%,
                            zoomOnMouseWheel: false, // 滚轮是否触发缩放
                            moveOnMouseMove: true, // 鼠标滚轮触发滚动
                            moveOnMouseWheel: true,
                        }
                    ],
                    // title: {
                    //     text: '暂无数据',
                    //     show: true,
                    //     textStyle: {
                    //       color: 'red',
                    //       fontSize: 25
                    //     },
                    //     textAlign: 'center',
                    //     top: '50%',
                    //     left: '45%'
                    // },
                    /**图表离容器的距离 */
                    grid: {
                        top: "10px",
                        left: "150px",
                        right: "45px",
                        bottom: "30px"
                    },
                    //鼠标滑过提示栏
                    tooltip: {
                        trigger: "axis",
                        position: 'bottom',
                        confine: true,
                        axisPointer: {
                            type: "shadow",
                            shadowStyle: {
                                color: 'rgba(180, 180, 180, 0.6)',
                                // width: '1'
                            }
                        },
                        // formatter: function (params: any) {
                        //     var html = '';
                        //     if (params.length != 0) {
                        //         //console.info(params[0]);
                        //         var getName = params[0].name;
                        //         html += getName + '<br/>';
                        //         for (var i = 0; i < params.length; i++) {
                        //             // 如果为0 为空的数据我们不要了(你们可以直接判断 > 0)
                        //             if (
                        //                 params[i].value != null &&
                        //                 params[i].value != 0 &&
                        //                 params[i].value != ''
                        //             ) {
                        //                 html += params[i].marker;
                        //                 html += params[i].seriesName + ': ' + params[i].value + '<br/>';
                        //             }
                        //         }
                        //     }
                        //     return html;
                        // }
                    },
                    //图表选择栏
                    // legend: {
                    //     icon: "square",
                    //     // top: 20,
                    //     bottom: 0,
                    //     itemHeight: 15, //修改icon图形大小
                    //     textStyle: {
                    //         fontSize: 15,
                    //         color: "#000",
                    //     },
                    //     data: legendData,
                    // },
                    //x坐标轴
                    xAxis: [
                        {
                            type: "value",
                        },
                    ],
                    //y坐标轴
                    yAxis: [
                        {
                            type: "category",
                            data: p.xaxisData as any,
                            axisLine: {
                                show: false
                            },
                            axisTick: {
                                show: false
                            },
                            // x轴下面文字描述
                            axisLabel: {
                                show: true,
                                interval: 0,
                                // rotate: -45,
                                color: '#000',
                                fontSize: '14px',
                                align: 'center',
                                padding: [0, 0, 0, -120],
                                formatter: function (value: string) {
                                    // return value.split('').join('\n')
                                    let str = value.split(' ').filter(z => z).map((x, i) => {
                                        let a = i + 1
                                        if (x.split('').length <= 10) {
                                            if (a % 2 === 0) {
                                                x += '\n'
                                            }
                                            return x
                                        } else {
                                            x += '\n'
                                            return x
                                        }
                                    })

                                    return str.join(' ')
                                }
                            },
                        },
                    ],
                    // 数据展示区域
                    series: SeriesData,
                };
            }
            return option
        })

        const chartId = ref<any>(1);
        chartId.value = uuid().replace(/-/g, "");
        let myChart: echarts.ECharts
        let divId: HTMLElement
        const setEchart = () => {
            divId = document.getElementById(chartId.value) as HTMLElement
            myChart.dispose()
            myChart = echarts.init(
                divId
            );
            myChart.setOption(setOption.value)
            window.addEventListener("resize", function () {
                myChart.resize();
            })
        }

        onMounted(() => {
            myChart = echarts.init(
                divId = document.getElementById(chartId.value) as HTMLElement
            );
            setEchart()
        })

        watch(p, () => {
            setEchart()
        })
        return {
            chartId
        }
    },
    render() {

        return (
            <>
                <div id={this.chartId} style='width: 100%;height:700px;border-top: 2px solid #e7e7e7;' class=''></div>
            </>
        )
    }
})