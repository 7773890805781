// Function to convert HSV to RGB
const hsvToRgb = (h: number, s: number, v: number): [number, number, number] => {
    let r = 0, g = 0, b = 0;
    let i = Math.floor(h * 6);
    let f = h * 6 - i;
    let p = v * (1 - s);
    let q = v * (1 - f * s);
    let t = v * (1 - (1 - f) * s);

    switch (i % 6) {
        case 0: r = v, g = t, b = p; break;
        case 1: r = q, g = v, b = p; break;
        case 2: r = p, g = v, b = t; break;
        case 3: r = p, g = q, b = v; break;
        case 4: r = t, g = p, b = v; break;
        case 5: r = v, g = p, b = q; break;
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

// Function to convert RGB to HEX
const rgbToHex = (r: number, g: number, b: number): string => {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)?.toUpperCase()}`;
}

// Function to generate visually distinct colors
export const generateColors = (numColors: number): string[] => {
    const colors: string[] = [];
    const goldenRatioConjugate = 0.618033988749895; // Approximate value of (1 + sqrt(5)) / 2 - 1
    let hue = Math.random(); // Start with a random hue to avoid similar starting points

    for (let i = 0; i < numColors; i++) {
        hue += goldenRatioConjugate;
        hue %= 1; // Ensure hue stays within [0, 1)
        const saturation = 0.5 + Math.random() * 0.5; // Varying saturation for better distinction
        const brightness = 0.5 + Math.random() * 0.5; // Varying brightness for better distinction
        const [r, g, b] = hsvToRgb(hue, saturation, brightness);
        colors.push(rgbToHex(r, g, b));
    }

    return colors;
}
// const colors = generateColors(200);



export const colors_200 = [
    "#D39E20",
    "#420B8F",
    "#1AC92F",
    "#B7223B",
    "#4C85C8",
    "#76980F",
    "#A127A7",
    "#56E3B4",
    "#903905",
    "#3D2FDA",
    "#5B9E49",
    "#BC1569",
    "#1EAED3",
    "#E1D55A",
    "#AC36F5",
    "#35A45A",
    "#C24642",
    "#5779DB",
    "#72C311",
    "#D83FBF",
    "#14B7A2",
    "#CF9444",
    "#6429F6",
    "#54DE54",
    "#912E4C",
    "#3E7FAD",
    "#828A42",
    "#C02DDF",
    "#4EC78F",
    "#973819",
    "#161B87",
    "#62D726",
    "#8F045C",
    "#2D8990",
    "#9B832B",
    "#5909AB",
    "#20CA42",
    "#B8545D",
    "#5B84C7",
    "#BFEB64",
    "#C75CC3",
    "#049972",
    "#F17714",
    "#654FD6",
    "#42BF2F",
    "#8A234F",
    "#369FC8",
    "#E2E007",
    "#B53EE8",
    "#43B06F",
    "#C32915",
    "#3751C9",
    "#4A920A",
    "#8C3F7A",
    "#029D95",
    "#A06B04",
    "#6A43AE",
    "#32EB40",
    "#D42F53",
    "#285C8E",
    "#B7D736",
    "#91139E",
    "#29EBA0",
    "#B3633E",
    "#4340A0",
    "#2E900C",
    "#D70B7C",
    "#6FDBEF",
    "#ECD858",
    "#6A22A0",
    "#4AA062",
    "#E02426",
    "#5C84E0",
    "#4C8001",
    "#E845D6",
    "#57CDB7",
    "#F69D38",
    "#514089",
    "#2BDD21",
    "#F35A8F",
    "#4AB4F0",
    "#F3FC76",
    "#892EA3",
    "#04F077",
    "#95402C",
    "#313CA2",
    "#ACF87C",
    "#861060",
    "#088F93",
    "#C5991D",
    "#8E5BCE",
    "#01FC28",
    "#CC485A",
    "#4D88D5",
    "#B9D965",
    "#971D99",
    "#53AB90",
    "#F86D0F",
    "#4530F1",
    "#4BEC27",
    "#820440",
    "#0DBEF4",
    "#E3D82F",
    "#9D03F0",
    "#3E955D",
    "#C6160A",
    "#6F89E2",
    "#83CD34",
    "#9C468C",
    "#4FD3C6",
    "#C27907",
    "#9A6FF6",
    "#60D262",
    "#E40B45",
    "#014D88",
    "#889736",
    "#DA09FD",
    "#39C287",
    "#C45024",
    "#4547BE",
    "#53853D",
    "#E156A9",
    "#138491",
    "#ECC517",
    "#54168D",
    "#39894B",
    "#E86C73",
    "#4E6DA4",
    "#8DCB1D",
    "#9E3497",
    "#1BDEB2",
    "#D59B66",
    "#7758FC",
    "#61B757",
    "#930B40",
    "#3380A3",
    "#FBFF5C",
    "#8537A2",
    "#1E9954",
    "#88210F",
    "#04178B",
    "#6DF007",
    "#EC44BE",
    "#2B9694",
    "#C5A45C",
    "#8D4CF1",
    "#2EB03B",
    "#BC5367",
    "#3570B0",
    "#9FC22C",
    "#9B12A4",
    "#77FBCC",
    "#D46427",
    "#362DBD",
    "#68B751",
    "#8D245B",
    "#327C8D",
    "#A59723",
    "#9358BA",
    "#50C474",
    "#E22925",
    "#3B60C1",
    "#6EA22A",
    "#CD27B5",
    "#0C9E88",
    "#B27F3E",
    "#5E4A96",
    "#37D932",
    "#F84980",
    "#4286B3",
    "#ABBE04",
    "#D436FA",
    "#338C61",
    "#EC8061",
    "#2730AB",
    "#47971A",
    "#AE3182",
    "#1C7C81",
    "#EDB601",
    "#8018F3",
    "#6BE681",
    "#F67886",
    "#5983C1",
    "#AACD59",
    "#D85CD6",
    "#65F0CA",
    "#FD7B19",
    "#260DC1",
    "#3A862D",
    "#AC4372",
    "#3B7286",
    "#E2DD28",
    "#8722B7",
    "#4BEC8A",
    "#DD6F63",
    "#143FF1",
    "#ABFF5C",
    "#AE228F",
    "#329B94",
    "#259898",
]