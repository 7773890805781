import { defineComponent, inject, reactive, ref } from "vue";
import tui from "tui-image-editor";
import "tui-image-editor/dist/tui-image-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";
import { getFileName } from "@/utile";
import verifyPhoto from "@/pipe/verifyPhoto";
import "./index.scss";

export default defineComponent({
    props: {
        _this: {
            type: Object
        },
    },
    emits: ['save'],
    setup(p, { emit }) {
        const open = ref<boolean>(false);
        const data = reactive({
            ImageEditor: null,
            slots: () => {
                footer: () => <span>12</span>
            },
            open: () => {

            },
            body: () => {

            },
            rowID: null
        });


        const Open = (e: any) => {
            open.value = true;
            data.rowID = e.ID
            const options: tuiImageEditor.IOptions = {
                cssMaxWidth: 700,
                cssMaxHeight: 500,

                selectionStyle: {
                    cornerSize: 20,
                    rotatingPointOffset: 70,

                },
                includeUI: {
                    loadImage: {
                        path: verifyPhoto(e.path) + `?timestemp=${Math.random() * 10000000}`,
                        name: getFileName(e.path)
                    },
                    // initMenu: 'filter',
                    menuBarPosition: 'bottom',
                    usageStatistics: false
                }
            };
            setTimeout(() => {
                // @ts-ignore
                const imageEditor = new tui(document.querySelector('#tui-image-editor-container'), options);
                // @ts-ignore
                data.ImageEditor = imageEditor;
                // setTimeout(() => {
                //     // @ts-ignore
                //     let el: HTMLLIElement = document.querySelector('.tie-btn-filter');
                //     el?.click();
                // }, 500);
            });
        };
        const onOpen = () => {

        };
        const close = () => {
            open.value = false;
            data.rowID = null
        };
        const handleOk = () => {
            open.value = false;
            data.rowID = null
        };

        const handleConfirm = () => {
            open.value = false;
            data.rowID = null
        };

        const reset = () => { };
        // open.value = !!p.option.defaultVisible;

        let _this = {
            ...p,
            data,
            open,
            Open,
            close,
            handleOk,
            handleConfirm,
            reset,

        }
        // const { modalTitleRef, slots } = useDrag(p.option, open, p._this, emit, _this);
        /* data.open = () => {
            const { openComponent } = useOpenComponent(p.option, open, p._this, _this);
            return openComponent
        } */
        /*  data.body = () => {
             const { body } = useBody(p.option, open, p._this, _this)
             return body
         } */
        // data.slots = () => {
        //     return slots
        // }
        const save = () => {
            // @ts-ignore
            let file = data.ImageEditor.toDataURL();
            emit('save', { ID: data.rowID, file });
            handleOk();
        }
        return {
            // slots,
            // modalTitleRef,
            save,
            ..._this,
        }
    },
    render() {
        const isMobile: any = inject('isMobile')
        return (
            <a-modal
                class={isMobile.value ? 'edit-photo-modal-wrap edit-photo-modal-wrap-m' : 'edit-photo-modal-wrap'}
                width={'90vw'}
                title='Edit Photo'
                visible={this.open}
                onChange={this.close}
                style={''}
                closable={true}
                maskClosable={false}
                onOk={this.handleOk}
                wrapClassName={'edit-photo'}
                footer={null}
            // v-slots={this.open ? this.data.slots() : {}}
            >
                <div id="tui-image-editor-container"></div>
                <a-button class="photo-edit-save-btn" type="primary" danger onClick={this.save} style={{ top: isMobile.value ? '10px' : '' }}>Save</a-button>
            </a-modal>
        )
    }
})