import verifyPhoto from "@/pipe/verifyPhoto";
import useStore from '@/store/metaData'
import { defineComponent, reactive } from "vue";
import { v4 as uuid } from 'uuid';

export default defineComponent({
    props: ['options'],
    setup(p) {

        const store = useStore()
        const data = reactive<any>({
            PhotoList: [],
            itemlist: []
        })
        const getid = (x: any) => {
            data.PhotoList = x.PhotoList
            data.itemlist = x.Entity.ItemList

        }

        return {
            getid,
            data
        }
    },
    render() {

        return (
            <div class={this.options?.class}>
                <div style='padding: 20px 20px 10px 20px;'><p class='ptitle'>已上載相片</p></div>
                {
                    this.data.PhotoList && this.data.PhotoList.map((e: any, i: any) => (
                        <div style='padding: 20px 20px 10px 20px;text-align: center;' key={e?.ID ?? uuid()}>
                            <div v-viewer >
                                <img src={
                                    verifyPhoto(e.FilePath)}
                                    alt=""
                                    class={'point'}
                                    style={{
                                        maxWidth: '80%',
                                        maxHeight: '320px',
                                        margin: '1px 0',
                                    }} />
                            </div>
                            <div class='flex justify-content-center'>
                                <div class='width-80-100'>
                                    {this.data.itemlist?.filter((a: { ID: any; }) => a.ID === e.FID)?.[0]?.Description || ''}
                                </div>
                            </div>
                            {/* <a-image
                                src={verifyPhoto(e.FilePath)}
                                height='200px'
                                width='100%'
                            ></a-image> */}
                        </div>
                    ))
                }

            </div>
        )
    }
})